import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { SubjectService } from 'src/app/services/subject.service';
import { SubSink } from 'subsink';
import { AppState } from './../../../app.state';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { UserData } from './../../../model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilService } from 'src/app/services/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { date } from 'faker';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-asset-grid',
  templateUrl: './asset-grid.component.html',
  styleUrls: ['./asset-grid.component.scss'],
})
export class AssetGridComponent implements OnInit {
  @Output() selectionChangeEvent = new EventEmitter<string>();
  sub = new SubSink();
  sites = ['Last Hour'];
  selectedSite = 'Last Hour';
  searchContent;
  selectedSiteHistory = 'All';
  view: string = 'map';
  assetData: any = [];
  //image: string = "https://image.shutterstock.com/image-vector/solar-system-sun-planets-vector-260nw-751091653.jpg";
  image: string = 'assets/images/process-new-3x.jpg';
  csvoptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  // coordinates = [
  //   { name: "RHF 2", x: 50, y: 550},
  //   { name: "BD MILL", x: 234, y: 575},
  //   { name: "BD HOT SAW", x: 300, y: 624 },
  //   { name: "TANDEM MILL", x: 444, y: 580 },
  //   { name: "KV-0 MILL", x: 725, y: 470 },
  //   { name: "KV-1 MILL", x: 320, y: 420 },
  //   { name: "INDUCTION HEATER", x: 880, y: 560 },
  // ];
  gridOverview = {
    OPCUABD: [
      // { name: 'Running Shift', value: 'A' },
      // { name: 'Rate of Production', value: 0 },
    ],
  };
  subscription_assetData: any;
  selectAssetSet: any = undefined;
  coordinate: any;
  popupOpened: boolean = false;
  // currentUser: Observable<User[]> ;
  // products: Observable<Product[]> ;
  // userInfo$: Observable<any>;
  subscription_alertData: any;
  constructor(
    private router: Router,
    private http: HttpService,
    private subject: SubjectService,
    private store: Store<AppState>,
    private fs: FirestoreService,
    private util: UtilService,
    private modalService: NgbModal,
    private utilSerice: UtilService,
    private db : AngularFirestore
  ) {
    this.getSections();
    // this.currentUser = this.store.select(state => state.user);
    // this.products = this.store.select(state => state.product);
    //this.userInfo$ = this.store.pipe(select(userInfo));
  }
  ngOnInit(): void {
    // this.downloadFurnace()
          this.gridAssetData();

    // this.currentUser = this.store.select(state => state.user);
    this.store.pipe(select('user')).subscribe((domain) => {
      // use the value of domain here
    });
  }
  // downloadFurnace(){
  //   this.db.collection('/Asset_OEE/SONA/HistoryData/Furnace_CBM/SONA01UNIT01SENSOR02OFFS',ref =>
  //   ref.orderBy('timestamp',"desc")).get().subscribe(data =>{
  //     let final: any = [];
  //     let array: any = { csv: [], title: '', filename: '' }
  //     data.forEach((ele:any) =>{
  //       let d = ele.data()
  //       array.title = `${d.asset} - ${d.asset_code}`;
  //       array.filename = `${d.asset} Temperature Data`;
  //       d.tags.forEach((tag) => {
  //         if(tag.tag == "Temperature"){
  //           let obj = {Timestamp:moment(d.timestamp.seconds*1000).format("DD MMM YYYY HH:mm:ss") , Temp:tag.value, Unit:"Deg C"}
  //           final.push(obj);
  //         }
  //       });

  //     })
  //      array.csv = final;
  //       this.csvexport(array);
  //   },(err) =>{
  //   })
  // }
  getClick(coordinate: any, type: string, content?) {
    if (type == 'single') {
      this.selectAsset(coordinate);
    } else {
      this.coordinate = coordinate;
      this.popupOpen(content);
    }
  }

  popupOpen(content, templateName?) {
    this.selectAssetSet = undefined;
    let modalObj: any = {};
    if (templateName == 'mapCoordinateSetting') {
      modalObj = { size: 'xl',backdrop: 'static' };
    }
    this.popupOpened = true;
    this.modalService.open(content, modalObj).result.then(
      (result) => {
        this.popupOpened = false;
      },
      (reason) => {
        this.popupOpened = false;
      }
    );
  }

  saveCoordinate() {
    if (this.selectAssetSet && this.coordinate) {
      this.selectAssetSet.x = this.coordinate.offsetX;
      this.selectAssetSet.y = this.coordinate.offsetY;
      this.sub.sink = this.fs
        .fireStoreDocUpdate('ASSETS', this.selectAssetSet.id, {
          x: this.selectAssetSet.x,
          y: this.selectAssetSet.y,
        })
        .subscribe(
          (response: any) => {
            this.modalService.dismissAll();
            if (response.status == 'success') {
            } else {
              this.util.showSnakbarMessage(
                'error',
                'Updating Asset Coodinate Failed!',
                'end',
                'top'
              );
            }
          },
          (err) => {
            this.modalService.dismissAll();
            this.util.showSnakbarMessage(
              'error',
              'Updating Asset Coodinate Failed!',
              'end',
              'top'
            );
          }
        );
    }
  }

  gridAssetData() {
    /***************FS**************** */
    this.utilSerice.setLoaderValue(true);
    let assetParams = {
      category: 'ASSETS',
      conditions: [{ key: 'is_maintenance_asset', symbol: '==', value: false }],
    };
    this.sub.sink = this.fs.updateAssetTime(assetParams).subscribe(
      (response: any) => {
        this.utilSerice.setLoaderValue(false);
        if (response.status == 'success') {
          this.assetData = response.data;
          // this.assetData = this.assetData.filter((a) => {
          //   return a.assetcode != 'OPCUAMILL_AREA' && a.assetcode != 'OPCUAUTILITY';
          // });
          this.assetData.forEach((element: any) => {
            element.customName = element.customName
              ? element.customName
              : element.asset;
            element.alert_count = 0;
            element.mode =
              element.alert_count == 0 || element.alert_count
                ? 'Healthy'
                : 'Not Healthy';
            if (!element.assetImage) {
              element.assetImage =
                './assets/images/assetimage/assetdefaultimage.jpeg';
            }
          });
          this.updateAssetAlert(0);
        } else {
          this.util.showSnakbarMessage(
            'error',
            'Getting Asset Data Failed!',
            'end',
            'top'
          );
        }
      },
      (err) => {
        this.utilSerice.setLoaderValue(false);
      }
    );
       this.util.setLoaderValue(false);
  }

  updateAssetAlert(index) {
    let alertParams = {
      category: 'ALERTS',
      conditions: [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.assetData[index].assetcode,
        },
        {
          key: 'state',
          symbol: '==',
          value: 'new',
        },
        {
          key: 'timestamp',
          symbol: '>=',
          value: moment().subtract(3, 'days').startOf('day').toDate(),
        },
        {
          key: 'timestamp',
          symbol: '<=',
          value: moment().toDate(),
        },
      ],
    };

    this.sub.sink = this.fs.getData(alertParams).subscribe((response: any) => {
      if (response.status == 'success') {
        this.assetData[index].alert_count = response.data.length;
        this.assetData[index].mode =
          this.assetData[index].alert_count == 0 ||
          this.assetData[index].alert_count
            ? 'Healthy'
            : 'Not Healthy';
        if (index < this.assetData.length - 1) {
          index++;
          this.updateAssetAlert(index);
        }
      }
    });
  }

  getSections() {
    let sections: any = [];
    let params = { category: 'SECTIONS' };
    this.sub.sink = this.fs.getData(params).subscribe((data: any) => {
      if (data.status == 'success') {
        data.data.forEach((d: any) => {
          sections.push(d.sectionName);
        });
      }
      localStorage.setItem('sections', JSON.stringify(sections));
    });
  }
  changeAsset(event) {
    this.selectAssetSet = event;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  selectionChanged(value: any) {
    this.selectionChangeEvent.emit(value);
  }

  setFilters(selectedValue: any, feild: string) {
    if (feild === 'site') {
      this.selectedSite = selectedValue;
    }
  }

  setView(event: any, view: any) {
    this.view = event;
    if (view == 'grid') {
    }
  }
  selectAsset(data: any) {
    if (data.asset) {
      this.subject.setSelectedAssetData(data);
      this.util.setGlobalSelectedAsset(data.assetcode);
      this.router.navigate(['page/assetgrid/assetdetails', data.id]);
      //this.router.navigate(["page/asset", data.id]);
    }
  }

  download() {
    let array: any = { csv: [], title: '', filename: '' };
    array.title = 'Asset Detaiils';
    array.filename = array.title;
    let final: any = [];
    this.assetData.forEach((d) => {
      let obj = {};
      obj['Asset'] = d.customName ? d.customName : d.asset;
      obj['Last Update Time'] = new Date(d?.timestamp?._seconds * 1000);
      obj['Alert Count'] = d.alert_count;
      final.push(obj);
    });
    // console.log(final);
    array.csv = final;
    this.csvexport(array);
  }
  csvexport(data) {
    if (data) {
      this.csvoptions['title'] = data.title;
      this.csvoptions['filename'] = data.filename;
      const csvExporter = new ExportToCsv(this.csvoptions);
      csvExporter.generateCsv(data.csv);
    }
  }

  // checkClass(status:number,count:number) {
  //   if(status==0) {
  //     return "off";
  //   } else if(status==1) {
  //     if(count>0) {
  //       return "critical"
  //     } else {
  //       return "on";
  //     }
  //   }
  // }

  coordinateSetting() {}
}
