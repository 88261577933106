import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, AfterViewInit {
  showLoader:boolean = false;
  constructor(private utilService:UtilService,private router:Router, private changeDet: ChangeDetectorRef) {
    this.utilService.getLoaderValue().subscribe((value)=>{
      this.showLoader = value;
    });
   }

  ngOnInit(): void {
    // this.router.navigate(['page/executive-dashboard'])
  }
ngAfterViewInit(){
  this.changeDet.detectChanges();
}
}
