<mat-tree
  #tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="example-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <div
      [ngClass]="
        selectedNode == node.equipmentId || selectedNode == node.id
          ? 'mat-tree-node selected'
          : 'mat-tree-node'
      "
    >
      <button
        (click)="selectNode(node)"
        class="arrow-style textformat"
        style="padding-left: 10px !important"
        mat-icon-button
        [title]="node.equipmentName? node.equipmentName:node.departmentName"
      matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        {{ node.equipmentName? node.equipmentName: node.departmentName }}
      </button>
    </div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div
    [ngClass]="
    selectedNode == node.equipmentId || selectedNode == node.id
      ? 'mat-tree-node selected'
      : 'mat-tree-node'
  "
      style="position: relative;padding-left: 10px !important;cursor: pointer;"
      (click)="selectNode(node, 'parent')"

    >
      <span
      class="textformat disableClick"
      [title]="node.equipmentName? node.equipmentName:node.departmentName"
        style="cursor: pointer"
        >
        {{ node.equipmentName? node.equipmentName: node.departmentName }}
        </span
      >
      <button class="arrow-style"  mat-icon-button matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror icon">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
    </div>
    <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
    <div
      [class.example-tree-invisible]="!treeControl.isExpanded(node)"
      role="group"
      style="position: relative;padding-left: 7px !important;cursor: pointer;"
    >
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
