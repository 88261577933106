import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValueObj'
})
export class GetValueObjPipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    return value[args[0]?.key];
  }

}
