import { Component, Input, OnChanges, OnInit } from "@angular/core";
import * as highcharts from "highcharts";
import { Chart } from 'highcharts';
import moment from "moment";

@Component({
  selector: 'app-splinechart',
  templateUrl: './splinechart.component.html',
  styleUrls: ['./splinechart.component.scss']
})
export class SplinechartComponent implements OnInit {
  @Input() data: any;
  @Input() width: any;
  @Input() height: any;
  highcharts = highcharts;
  splinChartOptions: any;
  shouldUpdateChanges = true;
  plotOptions = {
    column:{
      pointWidth:10,
      dataLabels:{
        style:{
          enabled: true,
          style: {
              fontWeight: 'bold'
          }
        },
        
      }
    },
    bar:{
      pointWidth:10,
      stacking: 'normal'
    },
    series: {
      turboThreshold: 4000
    }
  }
  constructor() { }
  ngOnInit() {
    this.splinechart(this.data[0]);
    console.log(this.data[0])
  }
  splinechart(data: any) {
    this.splinChartOptions = {
      time: {
        useUTC: false,
      },
      chart: {
        type: data.type ? data.type : 'spline',
        backgroundColor: 'transparent',
        showAxes: true,
        marginRight: data.mr,
        marginTop: data.mt,
        marginLeft: data.ml,
        marginBottom: data.mb,
        styledMode: data.styleMode ? data.styleMode : false,
        events: data.livedata ? data.livedata : null,
        reflow: true,
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        style: { fontFamily: 'SF Pro Text' },
        height: data.height ? data.height : 260,
      },
      credits: {
        enabled: false,
        style: {
          color: '#999999',
          cursor: 'pointer',
          fontSize: '9px',
        },
        text: 'Highcharts.com',
      },
      title: {
        text: data.chartname ? data.chartname : '',
        align: 'left',
        style: {
          color: 'var(--graph-text-color)',
          fontSize: '16px',
          fontWeight: 'lighter',
        },
        useHTML: false,
      },
      subtitle: {
        text: '',
      },
      legend: data.legend
        ? data.legend
        : {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            squareSymbol: true,
            symbolHeight: 7,
            symbolWidth: 7,
            symbolRadius: 0,
            itemStyle: {
              color: 'var(--graph-text-color)',
              fontWeight: 'ligther',
              fontSize: 12,
            },
            y: 20,
          },
      accessibility: data.accessibility ? data.accessibility : null,
      xAxis: {
        gridLineWidth: 0,
        plotBands: data.plotBands != undefined ? data.plotBands : null,
        lineWidth: 0,
        endOnTick: false,
        startOnTick: false,
        lineColor: 'var(--graph-text-color)',
        crosshair: true,
        type: 'datetime',
        labels: {
          style: {
            color: 'var(--graph-text-color)',
            fontSize: 11,
            overflow:"hidden",
            display:'inline-block',
            textOverflow:'ellipsis'
          },
          step: 1,
          maxStaggerLines: 1,
          formatter: function () {
            if (data.format || data.format != undefined) {
              return moment(this['value']).format('HH:mm:ss');
            } else if (data.dateformat == 'day') {
              return moment(this['value']).format('DD-MM-YY');
            } else {
              return this['value'];
            }
          },
        },
        title: {
          style: {
            color: 'var(--graph-text-color)',
            fontSize: 11,
            fontFamily: 'sfpro-sb',
          },
          text: data.label_x ? data.label_x : '',
        },
        categories: data.xaxis,
        //tickInterval: data.tickinterval_x,

        time: {
          useUTC: false,
        },
      },
      yAxis: data.y
        ? data.y
        : {
            tickInterval: 2000,
            offset: 0,
            lineColor: 'var(--graph-text-color)',
            gridLineColor: 'var(--gridline-color)',
            lineWidth: 0,
            // tickInterval: data.tickinterval_y,
            // plotLines: data.threshold ? data.threshold : null,
            endOnTick: true,
            labels: {
              style: {
                color: 'var(--graph-text-color)',
                // fontSize:data.y_label_size ? data.y_label_size : 11
                fontSize: 10,
              },
              formatter: function (): any {
                if (!this['isFirst']) {
                  // let number=this['axis'].defaultLabelFormatter.call(this)
                  // return this['axis'].defaultLabelFormatter.call(number/ 1000, 0) + 'k';
                  return (this['pos'] / 1000).toFixed(0) + 'k';
                }
              },
            },
            title: {
              style: {
                color: 'var(--graph-text-color)',
                fontSize: 10,
                fontFamily: 'sfpro-r',
              },
              text: data.label_y,
            },
          },
      tooltip: data.tooltip ?? {
        enabled: data.tooltipenabled ? data.tooltipenabled : false,
        time: {
          useUTC: false,
        },
        xDateFormat: '%d-%m-%y %H:%M:%S',
        headerFormat:
          '<span style="font-size:14px; font-weight:bolder">' +
          data.chartname +
          '<br/> </span>' +
          "<span style='font-size:14px;'>on {point.key}</span><table>",
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          data.unit ?? '' +
          '</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
        backgroundColor: '#eeeeee',
        borderColor: '#eeeeee',
        zIndex:9
      },
      plotOptions: data.plotOptions ? data.plotOptions : this.plotOptions,
      series: data.yaxis,
    };
    if(this.width) {
      this.splinChartOptions.chart.width = this.width;
    }
    if(this.height) {
      this.splinChartOptions.chart.height = this.height;
    }
    return this.splinChartOptions;

  }

}
