import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboardcard',
  templateUrl: './dashboardcard.component.html',
  styleUrls: ['./dashboardcard.component.scss']
})
export class DashboardcardComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }

  @Input () cardData: any;
  set in(value:any)
  {
    this.cardData = value
  }
}
