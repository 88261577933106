import { Component, Input, OnInit,  Output, EventEmitter } from '@angular/core';
import moment from 'moment-timezone';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sidebarmenu',
  templateUrl: './sidebarmenu.component.html',
  styleUrls: ['./sidebarmenu.component.scss']
})
export class SidebarmenuComponent implements OnInit {
  @Input() selectedAssetData: any = {};
  @Input() sidebarSelected: any = {};
  @Output() menuClick = new EventEmitter();
  loggedinUser:any
  constructor(private auth:AuthService) { 
    this.loggedinUser  = this.auth.getUserObject();
  }

  ngOnInit(): void {
    setTimeout(() =>{
    },2000)
  }

  sidebarMenuClicked(event: any, data: any, type: string) {
    event.stopImmediatePropagation();
    event.selectedMenuData = data;
    event.selectedMenuType = type;
    this.menuClick.emit(event);
  }
}
