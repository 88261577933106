<div class="date">
  <section *ngIf="!shouldSetDayLimit">
    <mat-form-field appearance="fill" style="width: -webkit-fill-available">
      <input
        matInput
        [matDatepicker]="picker"
        [max]="maxDate"
        [(ngModel)]="selectedDate"
        (dateChange)="dateChangd()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </section>
  <section *ngIf="shouldSetDayLimit">
    <mat-form-field appearance="fill" style="width: -webkit-fill-available">
      <input
        matInput
        [matDatepicker]="picker"
        [min]="dayLimit"
        [max]="maxDate"
        [(ngModel)]="selectedDate"
        (dateChange)="dateChangd()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker" style="
      font-size: 14px;
      display: flex;
      position: relative;
      left: 10px;"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </section>
</div>
