import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import moment from 'moment-timezone';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilService } from 'src/app/services/util.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html',
  styleUrls: ['./expansion.component.scss'],
})
export class ExpansionComponent implements OnInit {
  checklist;
  @Input('checklist')
  set in(val) {
    this.checklist = val;
  }
  @Input() checklistType: string = 'today';
  @Output() editCheckList = new EventEmitter();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  ackData = ['Normal', 'Abnormal', 'Standby'];
  frequencyDropdown: any = {
    data: ['Daily', 'Weekly', 'Monthly'],
    selected: {},
  };
  criticalityDropdown: any = ['Low', 'Medium', 'Moderate', 'High'];
  selectedAck = '';
  activeField: any = {};
  datatable: any = {
    header: [],
    rowData: [],
    inputData: [],
  };
  dropdownCategory: any = {
    Criticality: {
      value: this.criticalityDropdown,
      type: 'arrayString',
    },
    Acknowledge: {
      value: this.ackData,
      type: 'arrayString',
    },
  };
  maintenanceID = undefined;
  constructor(private fs: FirestoreService, private util: UtilService) {}
  ngOnInit(): void {
    this.initiateValues();
  }
  initiateValues() {
    // console.log(this.checklist,']]]]]]]]]]]]]]]]]]]]')
    if (
      this.checklist.maintenanceData &&
      this.checklist.maintenanceData.length > 0
    ) {
      this.maintenanceID = this.checklist.maintenanceData[0].id;
      // console.log(this.maintenanceID)
      this.checklist.check_points =
        this.checklist.maintenanceData[0].check_points;
      this.checklist.check_points.forEach((c, index) => {
        this.activeField[index] = true;
      });
    }
    if (this.checklist.headerColumns) {
      this.checklist.headerColumns.forEach((column: any) => {
        if (column.columncomesunder == 'CheckPoints') {
          this.datatable.header.push(column);
        } else if (column.columncomesunder == 'Maintenance') {
          this.datatable.inputData.push(column);
        }
      });
    }
  }
  changeDropdown(event, type) {}
  // Below is old filled percentage
  submitChecklist(e) {
    // console.log(this.maintenanceID)
    let acknowledgeCount = 0;
    let checkPointHeader = 0;
    let filterHeaderDropdown = e.headerColumns.filter((headerData) => {
      return headerData.columnType == 'Dropdown';
    });
    if (filterHeaderDropdown.length > 0) {
      e.check_points.forEach((checkPointData) => {
        filterHeaderDropdown.forEach((headerData) => {
          if (
            headerData.dropdownCategory &&
            headerData.dropdownCategory.label &&
            headerData.dropdownCategory.label == 'Acknowledge'
          ) {
            if (!checkPointData[headerData.columnId]) {
              checkPointData[headerData.columnId] =
                headerData.dropdownCategory.value[0];
            }
          }
        });
      });
    }
    let fixedFields = this.datatable.header.length;
    if (e.check_points.length > 0) {
      e.check_points.forEach((element: any) => {
        if (
          Object.keys(element).indexOf('header') != -1
        ) {
          checkPointHeader++;
        }

        if (
          Object.keys(element).length != 1 &&
          Object.keys(element).indexOf('header') == -1 &&
          fixedFields < Object.keys(element).length
        ) {
          acknowledgeCount++;
        }
      });
    }
    let ackAvg =
      (acknowledgeCount / (e.check_points.length - checkPointHeader)) * 100;
    e.percentage = parseFloat(ackAvg.toFixed(2));
    let maintenanceDate;
    let currentTime = moment();
    let startofDay = moment().startOf('day');
    let endTime = moment().startOf('day').add(6, 'hours');
    if (this.checklistType == 'today') {
      if (moment(currentTime).isBetween(startofDay, endTime)) {
        maintenanceDate = moment()
          .subtract(1, 'day')
          .startOf('day')
          .add(22, 'hours')
          .toDate();
      } else {
        maintenanceDate = moment().toDate();
      }
    } else {
      if (moment(currentTime).isBetween(startofDay, endTime)) {
        maintenanceDate = moment()
          .subtract(2, 'day')
          .startOf('day')
          .add(22, 'hours')
          .toDate();
      } else {
        maintenanceDate = moment()
          .subtract(1, 'day')
          .startOf('day')
          .add(22, 'hours')
          .toDate();
      }
    }
    if (this.maintenanceID == undefined) {
      // e['checklistid'] = e.id;
      e['maintenanceDate'] = maintenanceDate;
      e['actualFilledDate'] = moment().toDate();
      e['email'] = localStorage.getItem('email');
      e['type'] = 'checklist';
      this.getCheclist(e.checklistId).subscribe(
        (data: any) => {
          this.util.setLoaderValue(false);
          if (data.status == 'success') {
            if (data.data && data.data.length) {
              if (
                data.data[0].maintenanceData &&
                data.data[0].maintenanceData.length > 0
              ) {
                let maintenanceID = data.data[0].maintenanceData[0].id;
                this.updateChecklist(maintenanceID, e);
              } else {
                this.fs
                  .fireStoreDocInsert('MAINTENANCE', e)
                  .subscribe((data: any) => {
                    if (data.status == 'success') {
                      this.util.showSnakbarMessage(
                        'success',
                        'Maintenance saved successfully',
                        'end',
                        'top'
                      );
                    }
                  });
              }
            } else {
              // console.log('no doc found')
            }
          } else {
            this.util.showSnakbarMessage(
              'error',
              'Maintenance Updation failed',
              'end',
              'top'
            );
          }
        },
        (err) => {
          this.util.setLoaderValue(false);
          this.util.showSnakbarMessage(
            'error',
            'Maintenance Updation failed',
            'end',
            'top'
          );
        }
      );
    } else if (this.maintenanceID != undefined) {
      delete e.maintenanceData;
      e['email'] = localStorage.getItem('email');
      e['maintenanceDate'] = maintenanceDate;
      e['actualFilledDate'] = moment().toDate();
      this.updateChecklist(this.maintenanceID, e);
    }
  }
  // Below is actuall filled percentage
  // submitChecklist(e) {
  //   let acknowledgeCount = 0;
  //   let checkPointHeader = 0;
  //   let filterHeaderDropdown = e.headerColumns.filter((headerData)=>{
  //     return headerData.columnType== "Dropdown"
  //   })
  //   if(filterHeaderDropdown.length>0) {
  //     e.check_points.forEach((checkPointData)=>{
  //       filterHeaderDropdown.forEach((headerData)=>{
  //         if(headerData.dropdownCategory &&  headerData.dropdownCategory.label &&  headerData.dropdownCategory.label=="Acknowledge") {
  //           if(!checkPointData[headerData.columnId]) {
  //             checkPointData[headerData.columnId] = headerData.dropdownCategory.value[0];
  //           }
  //         }
  //       })

  //     });
  //   }
  //   let fixedFields = this.datatable.header.length;
  //   let totalInputFields=0
  //   let keys_to_fill = this.datatable.inputData.map((items)=>{
  //     return items.columnId
  //   })
  //   if(e.check_points.length>0) {
  //     for (let check_point of e.check_points){
  //        checkPointHeader+= Object.keys(check_point).includes('header')?1:0
  //        if(!Object.keys(check_point).includes('header')){
  //         let checked_keys=Object.keys(check_point)
  //         for(let key of checked_keys){
  // acknowledgeCount+= keys_to_fill.includes(key)?1:0
  //         }
  //        }
  //     }

  //       // e.check_points.forEach((element: any) => {
  //       //   if (
  //       //     Object.keys(element).length != 1 &&
  //       //     Object.keys(element).indexOf('header') != -1
  //       //   ) {
  //       //     checkPointHeader++;
  //       //   }
  //       //   if (
  //       //     Object.keys(element).length != 1 &&
  //       //     Object.keys(element).indexOf('header') == -1 &&
  //       //     fixedFields < Object.keys(element).length
  //       //   ) {
  //       //     acknowledgeCount++;
  //       //   }
  //       // });
  //   }
  //   totalInputFields = e.check_points.length - checkPointHeader;
  //   totalInputFields=totalInputFields?totalInputFields*(this.datatable.inputData.length):1
  //   // let ackAvg = (acknowledgeCount/(e.check_points.length-checkPointHeader))*100;
  //   let ackAvg=(acknowledgeCount/totalInputFields)*100
  //   e.percentage = parseFloat(ackAvg.toFixed(2));
  //   // console.log(e.percentage)
  //   let maintenanceDate;
  //   let currentTime = moment()
  //   let startofDay = moment().startOf('day')
  //   let endTime = moment().startOf('day').add(6, "hours")
  //   if(this.checklistType=='today'){
  //     if(moment(currentTime).isBetween(startofDay,endTime)){
  //       maintenanceDate = moment().subtract(1,'day').startOf('day').add(22,'hours').toDate()
  //     }else{
  //       maintenanceDate = moment().toDate()
  //     }
  //   }else{
  //     if(moment(currentTime).isBetween(startofDay,endTime)){
  //       maintenanceDate = moment().subtract(2,'day').startOf("day").add(22,'hours').toDate()
  //     }else{
  //       maintenanceDate = moment().subtract(1,'day').startOf("day").add(22,'hours').toDate()
  //     }
  //   }
  //   if(this.maintenanceID==undefined) {
  //     // e['checklistid'] = e.id;
  //     e['maintenanceDate'] = maintenanceDate;
  //     e['actualFilledDate']=moment().toDate()
  //     e['email'] = localStorage.getItem('email')
  //     e['type'] = "checklist";
  //     this.getCheclist(e.checklistId).subscribe((data: any) => {
  //       this.util.setLoaderValue(false);
  //       if ( data.status == 'success'){
  //         if(data.data && data.data.length) {
  //           if(data.data[0].maintenanceData && data.data[0].maintenanceData.length>0) {
  //             let maintenanceID = data.data[0].maintenanceData[0].id;
  //             this.updateChecklist(maintenanceID, e)
  //           }else{
  //             this.fs.fireStoreDocInsert('MAINTENANCE', e).subscribe((data: any) => {
  //               if (data.status == 'success') {
  //                 this.util.showSnakbarMessage('success', 'Maintenance saved successfully', 'end', 'top');
  //               }
  //             });
  //           }
  //         }else{
  //           // console.log('no doc found')
  //         }
  //       }else{
  //       this.util.showSnakbarMessage('error','Maintenance Updation failed','end','top')
  //       }
  //     },(err) =>{
  //       this.util.setLoaderValue(false)
  //       this.util.showSnakbarMessage('error','Maintenance Updation failed','end','top')
  //     });

  //   } else if(this.maintenanceID!=undefined) {
  //     delete e.maintenanceData;
  //     e['email']  = localStorage.getItem('email')
  //     e['maintenanceDate'] = maintenanceDate;
  //     e['actualFilledDate']=moment().toDate()
  //    this.updateChecklist(this.maintenanceID, e)
  //   }
  // }
  editChecklist(checklist) {
    this.editCheckList.emit(checklist);
  }

  toggleFunc(i: any) {
    this.activeField[i] = !this.activeField[i];
    // this.activeField = i == this.activeField ? null : i
  }
  deleteChecklist(data) {
    Swal.fire({
      title: 'Do you want to delete this Checklist?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Delete`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.fs.fireStoreDocDelete('CHECKLIST', data.id).subscribe((d: any) => {
          if (d.status == 'success') {
            this.util.showSnakbarMessage(
              'success',
              'Checklist deleted successfully!',
              'end',
              'top'
            );
            this.editCheckList.emit('delete');
          } else {
            this.util.showSnakbarMessage(
              'error',
              'Checklist deletion failed',
              'end',
              'top'
            );
          }
        });
      } else if (result.isDenied) {
      }
    });
  }

  checkAction(action) {
    let access = this.util.getAccessForAction(
      'Maintenance',
      'Checklist',
      action
    );
    return access;
  }
  getCheclist(id) {
    this.util.setLoaderValue(true);
    let filter: any = [
      {
        key: 'checklistId',
        symbol: '==',
        value: id,
      },
    ];
    let params = {
      category: 'CHECKLIST',
      type: 'checklist',
      //checklistType:"today"
    };
    if (filter.length) {
      params['conditions'] = filter;
    }
    return this.fs.getData(params);
  }
  updateChecklist(maintenanceID, data) {
    this.fs
      .fireStoreDocUpdate('MAINTENANCE', maintenanceID, data)
      .subscribe((data: any) => {
        if (data.status == 'success') {
          this.util.showSnakbarMessage(
            'success',
            'Maintenance Updated successfully',
            'end',
            'top'
          );
        } else {
          this.util.showSnakbarMessage(
            'error',
            'Maintenance Updation failed',
            'end',
            'top'
          );
        }
      });
  }
}
