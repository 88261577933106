import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl, FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from 'src/app/services/http.service';
import { UtilService } from 'src/app/services/util.service';
import { Store } from '@ngrx/store';
import { AppState } from './../../app.state';
import { UserData } from './../../model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
// import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BroadcastChannel } from 'broadcast-channel';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginObj: any;
  loading: boolean = false;
  formSubmitted = false;
  hide : boolean = true;
  isClicked:boolean=false
  constructor(
    private http: HttpService, private util: UtilService,
    private router: Router, private cookie: CookieService,
    //private store: Store<AppState>
    private formBuilder: FormBuilder,
    private fstore: FirestoreService,
    //private db: AngularFirestore,
    private authService:AuthService,
    private fs:FirestoreService
  ) {
    this.loginForm = this.formBuilder.group({
      useremail: [null, [Validators.required, Validators.email, Validators.maxLength(40)]],
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      remember: [null, []]
    },
      { validator: this.patternValidator('password') }
    );
  }

  ngOnInit() {
    this.isClicked=false
    // window.addEventListener('message', (event:any) => {
    //   // get out the message
    //   console.log(event.data);
    //   // and you can even send message back to parent window too.
    // }, false);
    // const channel = new BroadcastChannel('app-data');
    // console.log(channel)
    // channel.onmessage = (message) => {
    //   console.log('Received message', message);
    // }
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) return;
      if (event.key === 'abc') {
        console.log(event)
        // Do something with event.newValue
      }
     });
    let checkRemember: any = this.cookie.get("jsplloginremember");
    if (checkRemember) {
      if (JSON.parse(checkRemember) === true) {
        this.loginForm.get("useremail")?.setValue(this.cookie.get("jspluserid"));
        this.loginForm.get("password")?.setValue(this.cookie.get("jsplpassword"));
        this.loginForm.get("remember")?.setValue(true);
      } else {
        this.cookie.delete("jspluserid");
        this.cookie.delete("jsplpassword");
        this.loginForm.get("remember")?.setValue(false);
      }
    }

  }

  patternValidator(key: string) {
    return (group: FormGroup) => {
      const input = group.controls[key];
      if (!input.value) return null;
      if (!input.value.match(/\d/)) {
        return input.setErrors({ hasNumber: true });
      }
      else if (!input.value.match(/[A-Z]/)) {
        return input.setErrors({ hasCapitalCase: true });
      }
      else if (!input.value.match(/[a-z]/)) {
        return input.setErrors({ hasLowerCase: true });
      }
      else if (!input.value.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        return input.setErrors({ hasSpecialCharacters: true });
      }

    };
  }

  login(data: any, callback?) {
    this.formSubmitted = true;
    let json = {
      userid: data.value.useremail,
      password: data.value.password,
      remember: data.value.remember,
      cliend_id:"JSPL"
    };
    this.isClicked=true
    this.authService.login(json);
    this.authService.getAuthStatusListener().subscribe((res:any)=>{
      this.isClicked=false
    let requestBody:any=  {
        username: "admin@blpadmin.co.in",
        password: "Sjq73cdd5SAhs"
    }

      this.fs.getToken(requestBody).subscribe((res:any)=>{
        this.util.setToken(res)
         localStorage.setItem('tokens',JSON.stringify(res))

      })
    })
    /********************FS********************** */
    // this.fstore.login(json).subscribe((data: any) => {
    //   if (data.size == 1) {
    //     let fetcheddata: any = [];
    //     data.forEach(element => {
    //       fetcheddata.push(element.data())
    //     })
    //     for (let key in fetcheddata[0]) {
    //       if (key != "password") {
    //         sessionStorage.setItem(key, fetcheddata[0][key]);
    //       }
    //     }
    //     if (json.remember) {
    //       this.cookie.set("userid", json.userid, 365);
    //       this.cookie.set("password", json.password, 365);
    //     } else {
    //       this.cookie.delete("userid");
    //       this.cookie.delete("password");
    //     }
    //     this.util.showSnakbarMessage("success", "Successfully logged in", "end", "top");
    //     this.router.navigateByUrl("page");
    //   } else {
    //     this.util.showSnakbarMessage("error", "Invalid user name", "end", "top");
    //   }
    // },(err)=>{
    //   console.log(err);
    // });



  }
  passwordView() {
    this.hide = !this.hide;
  }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
