<mat-form-field *ngIf="variables.length > 0" style="background-color: #ffffff !important;" >
  <ng-container *ngIf="!isMultiple">
    <mat-select
      [(value)]="selectedData"
      (selectionChange)="selectionChanged($event)"
    >
      <mat-select-filter
        [placeholder]="'Filter'"
        [displayMember]="displayMember"
        [array]="variables"
        (filteredReturn)="filteredList = $event"
      ></mat-select-filter>
      <mat-option *ngIf="selectCaption != ''">Select</mat-option>
      <!-- <mat-option
        matTooltip="'TAG'"
        matTooltipPosition="below"
        *ngFor="let item of filteredList"
        [value]="item"
      >
        {{ item[displayMember] }}
      </mat-option> -->
      <ng-container *ngIf="type == 'object'">
        <mat-option  [title]="item[displayMember]" *ngFor="let item of filteredList" [value]="item">
          {{ item[displayMember] }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="type == 'string'" >
        <mat-option [title]="item" *ngFor="let item of filteredList" [value]="item">
          {{ item }}
        </mat-option>
      </ng-container>
    </mat-select>
  </ng-container>
  <ng-container *ngIf="isMultiple">
    <ng-container *ngIf="noTags">
      <span style="font-size: 12px; position: absolute;display: flex;">Select Tags</span></ng-container>
    <mat-select
      cdkConnectedOverlay
      [(value)]="selectedData"
      (selectionChange)="selectionChanged($event)"
      multiple
      disableOptionCentering
      panelClass="myPanelClass"
    >
      <mat-select-filter
      *ngIf="type=='object'"
        [placeholder]="'Filter'"
        [displayMember]="displayMember"
        [array]="variables"
        (filteredReturn)="filterData($event)"
      ></mat-select-filter>
      <mat-option *ngIf="selectCaption != ''">Select</mat-option>
      <ng-container *ngIf="type == 'object'">
        <mat-option  [title]="item[displayMember]" *ngFor="let item of filteredList" [value]="item">
          {{ item[displayMember] }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf = "type == 'string'" >
        <mat-option [title]="item" *ngFor="let item of filteredList" [value]="item">
          {{ item }}
        </mat-option>
      </ng-container>
    </mat-select>
  </ng-container>
</mat-form-field>
