import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-single',
  templateUrl: './card-single.component.html',
  styleUrls: ['./card-single.component.scss']
})
export class CardSingleComponent implements OnInit {
  @Input() widgetInfo: any = {};
  constructor() {
  }

  ngOnInit(): void {
    // console.log(this.widgetInfo)
  }

}
