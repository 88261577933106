import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-searchable-textbox',
  templateUrl: './searchable-textbox.component.html',
  styleUrls: ['./searchable-textbox.component.scss']
})
export class SearchableTextboxComponent implements OnInit {
  @Input() data:any= [];
  @Input() searchField:string="";
  @Output() searchEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  changeEvent(event:any) {
    let searchValue = event.target.value;
    let searchResult:any = [];
    if(this.searchField) {
      _.each(this.data, (obj, key) => {   
        if(obj[this.searchField].indexOf(searchValue)>-1) {
          searchResult.push(obj)
        }
      });
    }
    this.searchEvent.emit(searchResult);
  }
}
