<div class="siteHome">
  <div class="mainCon">
    <div class="leftCon">
      <div class="left_content" style="height: 124%;">
        <!-- <img src="../../../assets/images/Group 34@3x.png" class="leftConImage" alt="" /> -->
        <img src="../../../assets/images/login1.jpg" class="leftConImage" alt="" />
        <h3 class="left_text" style="margin-top: 80px">
          <!-- Asset Health & Predictive -->
          <!-- Rail Mill -->
          <!-- <h3 style="color: #ffff;
          position: relative;
          left: 95px;
          bottom: 5px !important;
          margin-bottom: 10px !important;
          font-size: 32px;
          font-weight: 600;">
          Maintenance
        </h3>
          <hr  style="width:100%;text-align:left;margin-left:6px; margin-top: 8px !important; margin-bottom: 5px !important; position: relative; bottom: 1px;"> -->
         </h3>

        <!-- <span class="login_desc"
          >Reduce unplanned downtime, Improve asset <br>
          <span style="color: #ffff;
          font-size: 20px;
          margin-bottom: 10px !important;
          position: relative;
          left: 115px;
          bottom: 5px !important;">availability, and OLE</span>
      </span> -->
        <br />
        <br />
      </div>
    </div>
    <div class="rightCon" style="height: 110%;">
      <div class="login_card">
        <div class="imgcon">
          <img src="../../../assets/images/predict.ai logo.svg" style="height: 45px" />
        </div>
        <form
          [formGroup]="loginForm"
          (ngSubmit)="login(loginForm)"
          class="kt-form"
          autocomplete="nope"
        >
          <div class="inputcon">
            <label>Username</label>
            <input
              class="form-control"
              formControlName="useremail"
              id="username"
              placeholder="Username"
              type="text"
              autocomplete="off"
            />
            <div
              *ngIf="
                loginForm.get('useremail')?.touched &&
                loginForm.get('useremail')?.invalid
              "
              class="text-danger"
            >
              <div
                *ngIf="loginForm.get('useremail')?.errors?.required"
                class="errorTextInput"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Username is Required.
              </div>
              <div
                *ngIf="loginForm.get('useremail')?.errors?.email"
                class="errorTextInput"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Please Enter a Valid Email
              </div>
            </div>
          </div>

          <div class="inputcon" *ngIf="checked">
            <label>New Password</label>
            <input
            id="password"
            class="form-control"
            #password
            autocomplete="off"
            [type]="hide ? 'password' : 'text'"
            placeholder="New Password"
            formControlName="password"
            maxlength="40"
            minlength="8"
            (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()">
            <mat-icon class="placeholder" (click)="passwordView()" class="password_view" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <!-- <app-password-strength [passwordToCheck]="loginForm.value.password" (passwordStrength)="passwordValid($event)" ></app-password-strength> -->
            <div
              *ngIf="
                loginForm.get('password')?.touched &&
                loginForm.get('password')?.invalid
              "
              class="text-danger"
            >
              <div
                *ngIf="loginForm.get('password')?.errors?.required"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Password is Required.
              </div>
              <div
                *ngIf="loginForm.get('password')?.errors?.minlength"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Must be at least 8 Characters!
              </div>
              <div
                *ngIf="loginForm.get('password')?.errors?.hasNumber"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Must contain at least 1 Number!
              </div>
              <div
                *ngIf="loginForm.get('password')?.errors?.hasCapitalCase"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Must contain at least 1 in Capital Case!
              </div>
              <div
                *ngIf="loginForm.get('password')?.errors?.hasLowerCase"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
               Must contain at least 1 in Lower Case!
              </div>
              <div
                *ngIf="loginForm.get('password')?.errors?.hasSpecialCharacters"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Must contain at least 1 in Special Character!
              </div>
            </div>
          </div>

          <div class="inputcon1" *ngIf="checked">
            <label>Confirm New Password</label>
            <input
            id="password"
            class="form-control"
            #password
            autocomplete="off"
            [type]="hide ? 'password' : 'text'"
            placeholder="Confirm Password"
            formControlName="confirmpassword"
            maxlength="40"
            minlength="8"
            (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()">
            <mat-icon class="placeholder" (click)="passwordView()" class="password_view" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <div
              *ngIf="
                loginForm.get('confirmpassword')?.touched &&
                loginForm.get('confirmpassword')?.invalid
              "
              class="text-danger"
            >
              <div
                *ngIf="loginForm.get('confirmpassword')?.errors?.required"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Confirmpassword is Required.
              </div>
              <div
                *ngIf="loginForm.get('confirmpassword')?.errors?.notEquivalent"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Password does not match.
              </div>
            </div>
          </div>

          <div class="login_right_actions">
            <ng-container *ngIf="IsClicked">
              <button class="btn btn-primary" type="button" disabled >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </ng-container>
            <ng-container *ngIf="!IsClicked">
              <button
              *ngIf="!checked"
              class="btn btn-brand btn-pill btn-elevate"
              id="login_submit"
              type="submit"
            >
              Submit
            </button>
            </ng-container>
            <ng-container *ngIf="!IsClicked">
              <button
              *ngIf="checked"
              class="btn btn-brand btn-pill btn-elevate"
              id="login_submit"
              type="submit"
              [disabled]="!loginForm.valid"
            >
              Update
            </button>
              </ng-container>

            <div style="padding-top: 25px">
              <button
              *ngIf="!checked"
              class="btn btn-brand btn-pill btn-elevate signin"
              routerLink="/login"
              id="login_submit"
              type="submit"
            >
              Sign In
            </button>
            </div>
            <div style="padding-bottom: 10px; padding-top: -2px; ">
              <button
              *ngIf="checked"
              class="btn btn-brand btn-pill btn-elevate signin"
              routerLink="/login"
              id="login_submit"
              type="submit"
            >
              Sign In
            </button>
            </div>
          </div>
          <br>
          <br>
          <p style="position: relative; top: 5px; right: 10px">
          <a class="cursor-pointer">Copyright</a> <span class="copyright">@ 2023 BLP Industry.AI. All
            rights reserved</span>
        </form>
      </div>
    </div>
  </div>
</div>
