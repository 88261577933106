import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExecutiveDashboardRoutingModule } from './executive-dashboard-routing.module';
import { ExecutiveDashboardComponent } from './executive-dashboard.component';
import { CommonComponentsModule } from '../../common/common.module';
import { CommonComponentPageModule } from '../common/common.module';
import { HistorydashboardComponent } from './historydashboard/historydashboard.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule} from "@angular/forms";
import { BackupExecutiveDashboardComponent } from './backup-executive-dashboard/backup-executive-dashboard.component'

@NgModule({
  declarations: [
    ExecutiveDashboardComponent,
    HistorydashboardComponent,
    BackupExecutiveDashboardComponent
  ],
  imports: [
    CommonModule,
    ExecutiveDashboardRoutingModule,
    CommonComponentsModule,
    CommonComponentPageModule,
    MatSlideToggleModule,
    FormsModule
  ]
})
export class ExecutiveDashboardModule { }
