import { Component, Input, OnInit } from '@angular/core';
import * as highcharts from "highcharts";
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  chartOptions :any= {}
  @Input('data')
  set in(val:any){
    this.plotChart(val)
  }
  highcharts:any = highcharts
  constructor() { }
  donutChart: Chart;
  plotChart(data:any) {
    return (this.chartOptions = {
      chart: {
        plotBorderWidth: 0,
        plotShadow: false,
        marginBottom: data.mb ?? 0,
        marginTop: -10,
        marginRight: 10,
        marginLeft: data.ml??0,
        height: data.height ?? undefined,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.2f}%</b>',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Production Trends',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            // connectorShape: 'fixedOffset',
            // alignTo: 'connectors',
            alignTo: 'alignto',
            connectorShape: 'crookedLine',
            crookDistance: '90%',
            format: '{point.description}',
          },
          center: data.center ?? ['50%', '50%'],
          startAngle: -90,
          endAngle: -180,
          size: data.size ?? '65%',
          showInLegend: true,
        },
      },

      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'center',
        // symbolPadding: 7,
        symbolWidth: 7,
        symbolHeight: 7,
        squareSymbol: true,
        symbolRadius: 0,
        useHTML: true,
        x: 20,
        y: 0,
        padding: 0,
        itemMarginBottom: 10,
        labelFormatter: function () {
          return `<div style="font-size:12px;align-items:center;display:grid;gap:4px">
           <div style="font-family:'sfpro-sb';
           width: 380px;
    display: inline-block;
   white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;">${this['name']}</div>
           <div style="font-family:'sfpro-r';color:${this['color']}">${this['description']}
           <span style="color:#000; margin-left:50px">${this['hour']}</span>
           <div/>
           </div>`;
        },
      },
      series: [
        {
          name: data.title ? data.title : '',
          dataLabels: {
            color: '#2C3A43',
            style: { fontSize: '12px' },
            distance: data.distance ?? 5,
          },
          data: data.productionData,
          type: 'pie',
          showInLegend: data.showInLegend ?? false,
          innerSize: '50%',
        },
      ],
    });
  }

  ngOnInit(): void {
  }


}

