<div class="card imageWidget">
    <div class="cardTitle">
        <div class="row">
            <div class="col-sm-8 padding-left-0">{{widgetInfo?.title}}</div>
        </div>
    </div>
    <div *ngIf="widgetInfo?.options?.diagramCategory=='Charging'">
        <img src="./../../../../assets/images/position1.PNG" class="wid100" />
    </div>
    <div *ngIf="widgetInfo?.options?.diagramCategory=='Walking Beam'">
        <img src="./../../../../assets/images/position2.PNG" class="wid100" />
    </div>
    <div *ngIf="widgetInfo?.options?.diagramCategory=='Discharging'">
        <img src="./../../../../assets/images/position3.PNG" class="wid100" />
    </div>
    <div class="trackingWidget" *ngIf="widgetInfo?.options?.diagramCategory=='Tracking'">
        <div class="row row-cols-5 padding10">
            <div class="col " *ngFor="let zone of zoneData">
                <div class="zoneDiv">
                    <div class="head">{{zone}}</div>
                </div>
            </div>
        </div>
        <div class="zoneDiv2">
            <ng-container *ngFor="let zone of zoneData">
                <div class="material" *ngFor='let in of counter(6) ;let i = index'
                    [ngStyle]="{'top':i%2=== 0 ? '20px' : '0' }">
                    <div class="text" [ngbPopover]="popContent" triggers="focus">U12325</div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="burnerWidget" *ngIf="widgetInfo?.options?.diagramCategory=='Burner Status'">
        <div class="row padding10">
            <div *ngFor="let position of positionStatus">
                <div class="row">
                    <div class="positionName col-3">{{position.positionName}}</div>
                    <div class="col-12 rowPad" *ngFor="let rowData of position.positionRow">
                        <div class="row">
                            <div class="position pad0" *ngFor="let row of rowData.value">
                                <div class="round" [ngClass]="row?.status"></div>
                                <div class="name">{{row.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<ng-template #popContent>
    <div class="row popContent" style="width:300px">
        test contest
    </div>
</ng-template>