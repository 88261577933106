<div class="siteHome">
  <div class="mainCon">
    <div class="leftCon">
      <div class="left_content" style="height: 124%;">
        <img src="../../../assets/images/login1.jpg" class="leftConImage" alt="" />
        <!-- <h3 class="left_text" style="margin-top: 80px">
          Rail Mill

         </h3> -->
        <br />
        <br />
      </div>
    </div>
    <div class="rightCon" style="height: 110%;">
      <div class="login_card">
        <div class="imgcon">
          <img src="../../../assets/images/predict.ai logo.svg" style="height: 45px" />
        </div>
        <form
          [formGroup]="loginForm"
          (ngSubmit)="login(loginForm)"
          method="post"
          action=""
          autocomplete="off"
        >
          <div class="inputcon">
            <label>Username</label>
            <input
              type="text"
              id="username"
              #username
              placeholder="Username"
              formControlName="useremail"
              autocomplete="nope"
              maxlength="40"
            />
            <div
              *ngIf="
                loginForm.get('useremail')?.touched &&
                loginForm.get('useremail')?.invalid
              "
              class="text-danger"
            >
              <div
                *ngIf="loginForm.get('useremail')?.errors?.required"
                class="errorTextInput"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Username is required.
              </div>
              <div
                *ngIf="loginForm.get('useremail')?.errors?.email"
                class="errorTextInput"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Invalid Username
              </div>
            </div>
          </div>
          <div class="inputcon">
            <label>Password</label>
            <input
            id="password"
            #password
            autocomplete="new-password"
            [type]="hide ? 'password' : 'text'"
            placeholder="Password"
            formControlName="password"
            maxlength="40"
            minlength="8"
            (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()">
            <mat-icon class="placeholder" (click)="passwordView()" class="password_view" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <div
              *ngIf="
                loginForm.get('password')?.touched &&
                loginForm.get('password')?.invalid
              "
              class="text-danger"
            >
              <div
                *ngIf="loginForm.get('password')?.errors?.required"
                class="errorTextPawwsord"
              >
              <img src="../../../assets/images/alert-f-svgrepo-com.svg" style="height: 15px; position: relative; bottom: 2px;" />
              Password is required.
              </div>

            </div>
          </div>
          <div class="row login_extra">
            <div class="col text-left">
              <label class="checkbox"
                ><input
                  formControlName="remember"
                  name="remember"
                  type="checkbox" />
                  <span style="position: relative; bottom: 2px; left: 3px;">Remember me</span></label>
            </div>
            <div class="col text-right">
              <a routerLink="/forgot-password" class="font-size-10p forgotPassword">Forgot Password ?</a>
            </div>
          </div>
          <div class="line"></div>
          <div class="">
            <ng-container *ngIf="!isClicked">
            <button type="submit" class="btn" [disabled]="!loginForm.valid">
              Sign In
            </button>
          </ng-container>
            <ng-container *ngIf="isClicked">
              <button class="btn btn-primary" type="button" disabled >
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </ng-container>

          </div>
          <br>
          <br>
          <p style="position: relative; top: 20px; right: 10px !important">
          <a class="cursor-pointer">Copyright</a> <span class="copyright">@ 2023 BLP Industry.AI. All
            rights reserved</span>
        </p>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- <lib-ngx-BLPLogin [loginImg]="LoginImage" [formVal]="formVal" (userFormVAl)="login($event)" (forgotPasswordStatus)="forgetpasswordStatus($event)">
</lib-ngx-BLPLogin> -->
