import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  @Input() widgetInfo: any = {};
  datatable1: any = {
    header: [
      {
        label: "Pass No",
        field: "pass_no"
      },
      {
        label: "Calliber",
        field: "calliber"
      },
      {
        label: "Top Roll Gap",
        field: "top_rol_gap"
      },
      {
        label: "Table Tilt",
        field: "table_tilt"
      },
      {
        label: "Table Tilt East",
        field: "table_tilt_east"
      },
      {
        label: "Table Tilt West",
        field: "table_tilt_west"
      },
      {
        label: "Entry Speed",
        field: "entry_speed"
      },
      {
        label: "Rolling Speed",
        field: "rolling_speed"
      },
      {
        label: "Kick off Speed",
        field: "kick_off_speed"
      },
      {
        label: "Descaling",
        field: "descaling"
      },
      {
        label: "Pass Lub",
        field: "pass_lub"
      },
      {
        label: "Table Extent",
        field: "table_extent"
      },

    ],
    data: [
      { 
        "pass_no": "1",
        "calliber": "K-1",
        "top_rol_gap": "19.08mm", 
        "table_tilt": "0mm", 
        "table_tilt_east": "0mm", 
        "table_tilt_west": "0mm", 
        "entry_speed": "0mps", 
        "rolling_speed": "0mps", 
        "kick_off_speed": "0mps", 
        "descaling": "ON", 
        "pass_lub": "ON", 
        "table_extent": "0" 
      },
      { 
        "pass_no": "2",
        "calliber": "K-2",
        "top_rol_gap": "19.08mm", 
        "table_tilt": "0mm", 
        "table_tilt_east": "0mm", 
        "table_tilt_west": "0mm", 
        "entry_speed": "0mps", 
        "rolling_speed": "0mps", 
        "kick_off_speed": "0mps", 
        "descaling": "ON", 
        "pass_lub": "ON", 
        "table_extent": "0" 
      },
      { 
        "pass_no": "3",
        "calliber": "K-3",
        "top_rol_gap": "19.08mm", 
        "table_tilt": "0mm", 
        "table_tilt_east": "0mm", 
        "table_tilt_west": "0mm", 
        "entry_speed": "0mps", 
        "rolling_speed": "0mps", 
        "kick_off_speed": "0mps", 
        "descaling": "ON", 
        "pass_lub": "ON", 
        "table_extent": "0" 
      },
    ]
  }
  constructor() { }

  ngOnInit(): void {
    // console.log(this.datatable1.header)
  }

}
