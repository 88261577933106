import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnInit {
 keys:any=[]
@Input()cardData:any
set in(val:any){
this.cardData=val
}
  constructor() { }

  ngOnInit(): void {
 this.keys=(Object.keys(this.cardData))
  }

}
