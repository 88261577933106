
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenubarComponent } from './menubar/menubar.component';
import {MatTabsModule} from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        MenubarComponent
    ],
    imports: [
        // BrowserModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        RouterModule
     ],
    exports:[
        FooterComponent,
        HeaderComponent,
        MenubarComponent
    ],
    providers: [],

  })
  export class CommonComponentPageModule { }
