import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shiftwise',
  templateUrl: './shiftwise.component.html',
  styleUrls: ['./shiftwise.component.scss']
})
export class ShiftwiseComponent implements OnInit {
  @Input() widgetInfo: any = {};
  constructor() { }

  ngOnInit(): void {
  }

}
