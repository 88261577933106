<div class="htoolbar">
  <div class="firstrow">
    <div class="headertext">
      <a (click)="redirectPage('dashboard')" class="cursor-pointer">Overview</a>
      /
      <a (click)="redirectPage('assetdetail')" class="cursor-pointer"
        >Asset Detail</a
      >
      /
      <b>Monitor</b>
    </div>
    <div class="toolbar">
      <!-- <div class="filter pL10">52

                <app-datetimerangepicker [dates]="selectedDateRanges"
                    (dateChangeEvent)="filterChange($event, 'date')"></app-datetimerangepicker>
            </div> -->
      <app-daterangepicker
        [opens]="'right'"
        [InitialDates]="InitialDates"
        (selectedDates)="filterChange($event, 'date')"
      ></app-daterangepicker>
    </div>
  </div>
</div>

<div class="d-fc p-20">
  <div class="row pT-10">
    <div class="col-md-3 pR-0">
      <div class="card filterDiv">
        <div class="cardTitle">Filters</div>
        <div class="card-body">
          <div class="filter" *ngIf="filter.asset.data.length > 0">
            <label>Select Asset</label>
            <app-dropdown
              [values]="filter.asset.data"
              [selected]="filter.asset.selected"
              [displayMember]="'asset'"
              (newSelectionEvent)="changeFilter($event, 'asset')"
              type="object"
            >
            </app-dropdown>
          </div>
          <div class="filter" *ngIf="filter.raw.data.length > 0">
            <label>Select Tags (Max 10 tags)</label>
            <!-- <app-dropdown [values]="filter.raw.data" [selected]="filter.raw.selected"
                             (newSelectionEvent)="changeFilter($event,'raw')" [displayMember]="'tag'" type="object">
                        </app-dropdown> -->
            <app-searchable-dropdown
              *ngIf="showTags"
              [values]="filter.raw.data"
              [displayMember]="'component'"
              [selectedData]="filter.raw.selected"
              [maxTags]="10"
              (newSelectionEvent)="changeFilter($event, 'raw')"
              [isMultiple]="true"
            ></app-searchable-dropdown>
            
          </div>
          <div class="filter">
            <label>Select Aggregation Time</label>
            <app-dropdown
              [values]="filter.aggregation.data"
              [selected]="filter.aggregation.selected"
              (newSelectionEvent)="changeFilter($event, 'aggregation')"
              [displayMember]="'time'"
              type="object"
            >
            </app-dropdown>
            <hr>
            <div class="row" style="padding-top: 20px">
              <div class="col-6">
                <button
                  class="btn btn-primary"
                  style="height: 37px; padding: 5px 10px"
                  (click)="clearTags()"
                >
                  Clear
                </button>
              </div>
              <div class="col-6 text-right">
                <button
                  class="btn btn-primary"
                  style="height: 37px; padding: 5px 10px"
                  (click)="loadChartData()"
                >
                  Load Graph
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="border"></div> -->
          <div class="pT-10 pL-10"></div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="card">
        <div class="cardTitle">
          <!-- Data  -->
          <!-- <img class="download" src="../../../../assets/images/download-svgrepo-com.png" alt="" (click)="download()"> -->
        </div>
        <div class="card-body">
          <div
          *ngIf="!chartData || this.chartData[0]?.xaxis.length===0"
          style="
            height: calc(100vh - 215px);
            padding: 20px;
            font-size: 18px;
            font-family: 'sfpro-r';
          "
        >
        <ng-container>
          <app-message *ngIf="selectedTagFilter" [message]="'No Data Found'"></app-message>
        </ng-container>
        <ng-container>
          <app-message *ngIf="!selectedTagFilter" [message]="'Please select a tag to view the Graph'"></app-message>
        </ng-container>
          </div>
          <ng-container *ngIf="chartData && this.chartData[0]?.xaxis.length > 0">
            <app-stockchart [data]="chartData"></app-stockchart>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
