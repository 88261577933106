import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit {
  @Input() widgetInfo: any = {};
  zoneData: any = [
    "ZONE 1",
    "ZONE 2",
    "ZONE 3",
    "ZONE 4",
    "ZONE 5RR",
  ]
  zoneDate2: any;
  positionStatus: any = [
    {
      "positionName": "A SIDE",
      "positionRow": [
        {
          row: "1",
          value: [{
            name: "1A",
            status: "ON"
          },
          {
            name: "1C",
            status: "ON"
          },
          {
            name: "3A",
            status: "ON"
          },
          {
            name: "3C",
            status: "ON"
          },
          {
            name: "3E",
            status: "ON"
          },
          {
            name: "5A",
            status: "ON"
          },
          {
            name: "5C",
            status: "ON"
          },
          {
            name: "5E",
            status: "ON"
          },
          {
            name: "7A",
            status: "ON"
          },
          {
            name: "7C",
            status: "ON"
          },
          {
            name: "7E",
            status: "ON"
          },]
        },
        {
          row: "2",
          value: [{
            name: "2A",
            status: "ON"
          },
          {
            name: "2C",
            status: "ON"
          },
          {
            name: "4A",
            status: "ON"
          },
          {
            name: "4C",
            status: "ON"
          },
          {
            name: "4E",
            status: "ON"
          },
          {
            name: "6A",
            status: "ON"
          },
          {
            name: "6C",
            status: "ON"
          },
          {
            name: "6E",
            status: "ON"
          },
          {
            name: "8A",
            status: "ON"
          },
          {
            name: "8C",
            status: "ON"
          },
          {
            name: "8E",
            status: "ON"
          },]
        }
      ]
    },

    {
      "positionName": "B SIDE",
      "positionRow": [
        {
          row: "1",
          value: [{
            name: "1A",
            status: "ON"
          },
          {
            name: "1C",
            status: "ON"
          },
          {
            name: "3A",
            status: "ON"
          },
          {
            name: "3C",
            status: "ON"
          },
          {
            name: "3E",
            status: "ON"
          },
          {
            name: "5A",
            status: "ON"
          },
          {
            name: "5C",
            status: "ON"
          },
          {
            name: "5E",
            status: "ON"
          },
          {
            name: "7A",
            status: "ON"
          },
          {
            name: "7C",
            status: "ON"
          },
          {
            name: "7E",
            status: "ON"
          },]
        },
        {
          row: "2",
          value: [{
            name: "2A",
            status: "ON"
          },
          {
            name: "2C",
            status: "ON"
          },
          {
            name: "4A",
            status: "ON"
          },
          {
            name: "4C",
            status: "ON"
          },
          {
            name: "4E",
            status: "ON"
          },
          {
            name: "6A",
            status: "ON"
          },
          {
            name: "6C",
            status: "ON"
          },
          {
            name: "6E",
            status: "ON"
          },
          {
            name: "8A",
            status: "ON"
          },
          {
            name: "8C",
            status: "ON"
          },
          {
            name: "8E",
            status: "ON"
          },]
        }
      ]
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

  counter(i: number) {
    return new Array(i);
  }

}
