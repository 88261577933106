<ng-container *ngIf="control && control?.invalid && control?.touched">
  <ul>
    <li class="error-msge" *ngIf="control.hasError('required')">
      This field is required
    </li>
    <li *ngIf="control.hasError('pattern')">pattern is invalid</li>
    <li *ngIf="control.hasError('maxlength')">
      the length is over the max limit
    </li>
  </ul>
</ng-container>
