<div
  class="container-fluid"
  style="height: calc(100vh - 130px); overflow-y: scroll">
  <!-- <div class="toolbar">
    <div class="headertext" style="margin-top: 2px">Executive Dashboard</div>
    <div>Last Updated: {{yesterDayDate}}</div>
  </div>
  <hr class="hr" style="margin-top: 2px" /> -->
  <div style="padding-right: 10px; padding-top: 5px">
    <marquee
      hspace="20"
      direction="left"
      style="
        height: 30px;
        background-color: #fff;
        border-radius: 5px;
        padding: 5px 0px;
      ">
      <span>
        <span style="font-weight: bold">{{ yesterDayDate }}: </span>
        <span style="margin-right: 10px"
          ><span style="color: #2a7cf6">Structure (MT) : </span>
          <span>{{ yesterdayData.strutureAct }}</span>
          <!-- / <span>{{yesterdayData.strutureTarget}}</span> -->
        </span>
        <!-- {{yesterdayData.struturePercent}} % -->
        <span style="margin-right: 10px"
          ><span style="color: #2a7cf6">Rail (MT) : </span>
          <span>{{ yesterdayData.railAct }}</span>
          <!-- /
        <span>{{yesterdayData.railTarget}}</span> -->
        </span>
        <!-- {{yesterdayData.railPercent}} % -->
        <span style="margin-right: 10px"
          ><span style="color: #2a7cf6">Total (MT) : </span>
          <span>{{ yesterdayData.totalAct }}</span>
          <!-- / <span>{{yesterdayData.totalTarget}}</span> -->
        </span>
        <!-- {{yesterdayData.totalPercent}} % -->
        <span style="margin-left: 150px">
          <span style="font-weight: bold">{{ currentdate }}: </span>
          <span style="margin-right: 10px"
            ><span style="color: #2a7cf6">Structure (MT) : </span>
            <span>{{ curretMonthData.strutureAct }}</span> /
            <span>{{ curretMonthData.strutureTarget }}</span></span
          >
          {{ curretMonthData.struturePercent }} %
          <span style="margin-right: 10px"
            ><span style="color: #2a7cf6">Rail (MT) : </span>
            <span>{{ curretMonthData.railAct }}</span> /
            <span>{{ curretMonthData.railTarget }}</span></span
          >{{ curretMonthData.railPercent }} %
          <span style="margin-right: 10px"
            ><span style="color: #2a7cf6">Total (MT) : </span>
            <span>{{ curretMonthData.totalAct }}</span> /
            <span>{{ curretMonthData.totalTarget }}</span></span
          >{{ curretMonthData.totalPercent }} %
        </span>
      </span>
    </marquee>
    <div
      class="exe_dashboard"
      style="padding-left: 1% !important; padding-right: 1% !important">
      <div class="row" style="padding: 0px !important">
        <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          style="padding: 3px !important">
          <div class="topCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> MTD vs Monthly Production
              </div>
              <div class="insight" [ngStyle]="{'color':montlyProductionMTD.productionValue>=montlyProductionMTD.actual_tonne_to_be_done?'green':'red'}">
                <span class="value"
                    >
                    <span style="color: #000;">Variance : </span>
                    {{ montlyProductionMTD.difference }} MT</span>
                 <span style="margin-left: 6px;">
                  <i class="fa fa-level-up" aria-hidden="true" *ngIf="montlyProductionMTD.productionValue>=montlyProductionMTD.actual_tonne_to_be_done"></i>
                  <i class="fa fa-level-down" aria-hidden="true"*ngIf="montlyProductionMTD.productionValue<montlyProductionMTD.actual_tonne_to_be_done"></i>
                 </span>
              </div>
            </div>
            <div class="graph" style="height: 0px">
              <app-semi-guage [data]="montlyProductionMTD"></app-semi-guage>

              <div class="mtdCard">
                <div class="value">
                  {{
                    montlyProductionMTD.Value ? montlyProductionMTD.Value : 0
                  }}%
                </div>
                <!-- <hr style="color: #d8d8d8;
                margin: auto;
                margin-left: 168px;
                margin-right: 168px;
                border-width: 2px;"> -->
                <!-- <div class="target" style="font-size: 13px;
                font-weight: 600;
                position: relative;
                left: 98px;
                top: 37px;">{{monthlyTargetValue}}</div> -->
                <div class="value" style="margin-top: 5px">
                  {{ montlyProductionMTD.productionValue }} /
                  <span style="color: #2a7cf6">{{ monthlyTargetValue }}</span>
                </div>
                <div style="margin-top: 10px;display: grid;">
                  <div>
                  <span style="font-size: 12px">Current Rolling Rate : </span>
                  <span class="value" style="color: #2a7cf6"
                    >{{ montlyProductionMTD.mtPerDay_current }} MT per Day</span
                  >
                  </div>
                  <div>
                  <span style="font-size: 12px">Required Rolling Rate : </span>
                  <span class="value" style="color: #2a7cf6"
                    >{{ montlyProductionMTD.mtPerDay }} MT per Day</span
                  >
                  </div>
                </div>
              </div>
              <!-- <ng-container
                *ngIf="
                  !productionInMT?.length ||
                  !productionInMT[0]?.yaxis[0]?.data?.length
                "
              >
                <div class="nodata">
                  <app-infomessage [message]="'No Data Found'"></app-infomessage>
                </div>
              </ng-container>
              <ng-container *ngIf="productionInMT[0]?.yaxis[0]?.data?.length">
                <app-splinechart [data]="productionInMT"></app-splinechart>
              </ng-container> -->
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          style="padding: 3px !important">
          <div class="topCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> Weekly Production
              </div>
            </div>
            <div class="graph">
              <ng-container
                *ngIf="
                  !weeklyProduction?.length ||
                  !weeklyProduction[0]?.yaxis[0]?.data?.length
                ">
                <div class="nodata">
                  <app-infomessage
                    [message]="'No Data Found'"></app-infomessage>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  weeklyProduction[0]?.yaxis[0]?.data?.length &&
                  weeklyProduction[0]?.yaxis[1]?.data?.length
                ">
                <app-splinechart [data]="weeklyProduction"></app-splinechart>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          style="padding: 3px !important">
          <div class="topCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> Monthly Production
              </div>
              <ng-container *ngFor="let data of monthwiseProduction_Percent">
                <div class="">
                  <span> {{data['month']}} : </span>
                  <span style="color: #2a7cf5;"> {{data['value']}}  </span>
                </div>
              </ng-container>

            </div>
            <div class="graph">
              <ng-container
                *ngIf="
                  !monthlyProduction?.length ||
                  !monthlyProduction[0]?.yaxis[0]?.data?.length
                ">
                <div class="nodata">
                  <app-infomessage
                    [message]="'No Data Found'"></app-infomessage>
                </div>
              </ng-container>
              <ng-container
                *ngIf="monthlyProduction[0]?.yaxis[0]?.data?.length">
                <app-splinechart [data]="monthlyProduction"></app-splinechart>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          style="padding: 3px !important">
          <div class="bottomCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> MTD Production &
                Byproducts
              </div>
            </div>
            <div class="graph">
              <ng-container *ngIf="!productionTrend.productionData?.length">
                <app-infomessage [message]="'No Data Found'"></app-infomessage>
              </ng-container>
              <ng-container *ngIf="productionTrend.productionData?.length">
                <div class="productiontrend">
                  <div style="height: 220px">
                    <app-donut-chart [data]="productionTrend"></app-donut-chart>
                  </div>
                </div>
                <div
                  class="productiontrendCard"
                  style="
                    display: flex;
                    justify-content: space-evenly;
                    position: relative;
                    bottom: 40px;
                    height: 30px;
                    margin-left: 10px;
                  ">
                  <div class="row">
                    <ng-container
                      *ngFor="let data of productionTrend.productionData">
                      <div class="col-6">
                        <div style="margin-bottom: 5px">
                          <span class="header"
                            ><i
                              class="fa fa-square boxIcon"
                              [style.color]="data.color"
                              aria-hidden="true"></i>
                            <span style="font-weight: 600">{{
                              data.name
                            }}</span></span
                          >
                          <span class="content">
                            <span style="margin-left: 10px"
                              >{{ data.value }} MT</span
                            >
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          style="padding: 3px !important">
          <div class="bottomCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> FY
                {{ currentYear }} Production
              </div>
            </div>
            <!-- <div class="graph" style="display: flex; justify-content:space-between">
              <ng-container *ngIf="!ytdProduction.productionData?.length">
                <app-infomessage [message]="'No Data Found'"></app-infomessage>
              </ng-container>
              <ng-container *ngIf="ytdProduction.productionData?.length && ytdProduction.productionData[0]?.description!=''">
                <div class="ytdProduction">
                <app-donut-chart [data]="ytdProduction"></app-donut-chart>
              </div>
              <div class="percentval" style="position: relative;
              width: fit-content;
              height: fit-content;
              top: 130px;
              right: 128px;">
                <div class="value">{{ ytdProduction.productionData[0].productionValue}}%</div>
              </div>
              <div class="productiontrendCard">
                <ng-container *ngFor="let data of ytdProduction.productionData">
                    <div style="margin-bottom: 5px;">
                      <div class="header" ><i class="fa fa-square boxIcon" [style.color]="data.color" aria-hidden="true"></i>
                      <span style="font-weight: 600;">{{data.name}}</span></div>
                      <div class="content">
                        <span style="margin-left: 10px;">{{data.value}} MT</span>
                      </div>
                    </div>
          </ng-container>
            </div>
              </ng-container>
            </div> -->
            <div class="graph">
              <ng-container
                *ngIf="
                  !yearlyPrdocutionMTD?.Value && yearlyPrdocutionMTD.Value == ''
                ">
                <app-infomessage [message]="'No Data Found'"></app-infomessage>
              </ng-container>

              <ng-container
                *ngIf="
                  yearlyPrdocutionMTD?.Value && yearlyPrdocutionMTD.Value != ''
                ">
                <app-gaugechart
                  [ChartData]="yearlyPrdocutionMTD"></app-gaugechart>
              </ng-container>
              <ng-container
                *ngIf="
                  yearlyPrdocutionMTD?.Value && yearlyPrdocutionMTD.Value != ''
                ">
                <div
                  class="yearlyTrendCard"
                  style="
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    text-align: center;
                  ">
                  <div style="font-size: 12px; font-weight: 600">
                    Target :
                    <span style="color: #ffad8a !important">
                      {{ yearlyPrdocutionMTD.targetValue }} MT</span
                    >
                  </div>
                  <div style="font-size: 12px; font-weight: 600">
                    Actual :
                    <span style="color: #2a7cf6">
                      {{ yearlyPrdocutionMTD.productionValue }} MT</span
                    >
                  </div>
                </div></ng-container
              >
            </div>
            <!-- <div class="graph"  style="height: 0px;">
              <app-semi-guage [data]="yearlyPrdocutionMTD"></app-semi-guage>

              <div class="mtdCard">
                <div class="value">{{ yearlyPrdocutionMTD.Value }}%</div>
                <div class="value" style="margin-top: 5px;">{{yearlyPrdocutionMTD.productionValue}} / <span style="color:#2a7cf6">{{monthlyTargetValue}}</span> </div>

            </div>
            </div> -->
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          style="padding: 3px !important">
          <div class="bottomCard">
            <!-- <div class="title">
        <div class="headTitle">
          <i class="fa fa-circle title-dot"></i> KPI
        </div>
      </div> -->
            <div class="graph">
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">KPI</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiHeader">Yesterday</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiHeader">MTD</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiHeader">YTD</div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiHeader">Best Ever</div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">Power (Kwh/MT)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ powerConsumption.yesterday }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ powerConsumption.mtd }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ powerConsumption.ytd }}
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div
                    class="kpiValue">
                    142
                    <!-- {{ powerConsumption.bestEver }} -->
                    <!-- <span style="color: #595959;
                  line-height: 0.7;
                  font-size: 10px;">kwh/MT </span> -->
                    <span style="font-size: 11px">
                      (Dec 22)
                      <!-- ({{ powerConsumption.bestEverMonth }}) -->
                      </span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">BFG (Nm^3/MT)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ bfgConsumption.yesterday }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ bfgConsumption.mtd }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ bfgConsumption.ytd }}
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiValue" >
                    <!-- {{ bfgConsumption.bestEver }} -->
                      908
                    <!-- <span style="color: #595959;;
                  line-height: 0.7;
                  font-size: 10px;"> Nm^3/MT</span> -->
                    <span style="font-size: 11px"
                      >
                      (Dec 22)
                      <!-- ({{ bfgConsumption.bestEverMonth }}) -->
                      </span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">HFO (L/MT)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ hfoConsumption.yesterday }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ hfoConsumption.mtd }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ hfoConsumption.ytd }}
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiValue">
                    <!-- {{ hfoConsumption.bestEver }} -->
                    NA
                    <!-- <span style="font-size: 11px"
                      >({{ hfoConsumption.bestEverMonth }})</span
                    > -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">Mill Utilization (%)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ millUtilization.yesterday }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ millUtilization.mtd }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ millUtilization.ytd }}
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiValue" >
                    69
                    <!-- {{ millUtilization.bestEver }} -->
                    <span style="font-size: 11px">
                      (Dec 22)
                      <!-- ({{ millUtilization.bestEverMonth }}) -->
                      </span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">Quality Rate (%)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ qualityRate.yesterday }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ qualityRate.mtd }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ qualityRate.ytd }}
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiValue" >
                    97
                    <!-- {{ qualityRate.bestEver }} -->
                    <span style="font-size: 11px"
                      >
                      (Apr 23)
                      <!-- ({{ qualityRate.bestEverMonth }}) -->
                      </span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">Performance (%)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    <!-- {{performance.yesterday}} -->
                    100
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue"><!-- {{performance.mtd}} -->100</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue"><!-- {{performance.ytd}} -->100</div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiValue">
                    100
                    <!-- {{performance.bestEver}} <span style="font-size: 11px;">({{performance.bestEverMonth}})</span> -->
                  </div>
                </div>
              </div>
              <div class="row" style="margin-bottom: -10px">
                <div
                  class="col-md-3"
                  style="padding: 1px 5px 5px 15px !important">
                  <div class="kpiHeader">OEE (%)</div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ oee.yesterday }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ oee.mtd }}
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="padding: 5px !important; padding-top: 1px !important">
                  <div class="kpiValue">
                    {{ oee.ytd }}
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style="padding: 1px 15px 5px 5px !important">
                  <div class="kpiValue">
                    63
                    <!-- {{ oee.bestEver }} -->
                    <span style="font-size: 11px"
                      >
                      (Apr 23)
                      <!-- ({{ oee.bestEverMonth }}) -->
                      </span
                    >
                  </div>
                </div>
              </div>
              <!-- <ng-container
        *ngIf="
          !productionInMT?.length ||
          !productionInMT[0]?.yaxis[0]?.data?.length
        "
      >
        <div class="nodata">
          <app-infomessage [message]="'No Data Found'"></app-infomessage>
        </div>
      </ng-container>
      <ng-container *ngIf="productionInMT[0]?.yaxis[0]?.data?.length">
        <app-splinechart [data]="productionInMT"></app-splinechart>
      </ng-container> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 0px !important">
        <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          style="padding: 3px !important">
          <div class="topCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> Weekly Dispatch
              </div>
            </div>
            <div class="graph">
              <ng-container
                *ngIf="
                  !weekly_dispatch?.length ||
                  !weekly_dispatch[0]?.yaxis[0]?.data?.length
                ">
                <div class="nodata">
                  <app-infomessage
                    [message]="'No Data Found'"></app-infomessage>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  weekly_dispatch[0]?.yaxis[0]?.data?.length &&
                  weekly_dispatch[0]?.yaxis[1]?.data?.length
                ">
                <app-splinechart [data]="weekly_dispatch"></app-splinechart>
              </ng-container>
            </div>
          </div>
        </div>
          <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          style="padding: 3px !important">
          <div class="topCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> Monthly Dispatch
              </div>
              <ng-container *ngFor="let data of monthwiseDispatch_Percent">
                <div class="">
                  <span> {{data['month']}} : </span>
                  <span style="color: #2a7cf5;"> {{data['value']}}  </span>
                </div>
              </ng-container>

            </div>
            <div class="graph">
              <ng-container
                *ngIf="
                  !monthly_dispatch?.length ||
                  !monthly_dispatch[0]?.yaxis[0]?.data?.length
                ">
                <div class="nodata">
                  <app-infomessage
                    [message]="'No Data Found'"></app-infomessage>
                </div>
              </ng-container>
              <ng-container
                *ngIf="monthly_dispatch[0]?.yaxis[0]?.data?.length">
                <app-splinechart [data]="monthly_dispatch"></app-splinechart>
              </ng-container>
            </div>
          </div>
        </div>
         <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          style="padding: 3px !important">
          <div class="topCard">
            <div class="title">
              <div class="headTitle">
                <i class="fa fa-circle title-dot"></i> FY
                {{ currentYear }} Dispatch
              </div>
            </div>
            <div class="graph" style="display: grid;grid-template-columns: 70% 30%;">
              <ng-container
                *ngIf="
                  !yearly_dispatch?.Value && yearly_dispatch.Value == ''
                ">
                <app-infomessage [message]="'No Data Found'"></app-infomessage>
              </ng-container>

              <ng-container
                *ngIf="
                  yearly_dispatch?.Value && yearly_dispatch.Value != ''
                ">
                <div>
                   <app-gaugechart [ChartData]="yearly_dispatch"></app-gaugechart>
                </div>
                 <div
                  class="yearlyTrendCard"
                  style="
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    gap: 15px;
                  ">
                  <div style="font-size: 12px; font-weight: 600">
                    Target :
                    <span style="color: #ffad8a !important">
                      {{ yearly_dispatch.targetValue }} MT</span
                    >
                  </div>
                  <div style="font-size: 12px; font-weight: 600">
                    Actual :
                    <span style="color: #2a7cf6">
                      {{ yearly_dispatch.productionValue }} MT</span
                    >
                  </div>
                </div>

              </ng-container>
              <!-- <ng-container
                *ngIf="
                  yearly_dispatch?.Value && yearly_dispatch.Value != ''
                ">
                <div
                  class="yearlyTrendCard"
                  style="
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    text-align: center;
                  ">
                  <div style="font-size: 12px; font-weight: 600">
                    Target :
                    <span style="color: #ffad8a !important">
                      {{ yearly_dispatch.targetValue }} MT</span
                    >
                  </div>
                  <div style="font-size: 12px; font-weight: 600">
                    Actual :
                    <span style="color: #2a7cf6">
                      {{ yearly_dispatch.productionValue }} MT</span
                    >
                  </div>
                </div></ng-container
              > -->
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 0px !important">
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          style="padding: 3px !important">
          <div class="dailyPlanCard" style="height: 225px;">
            <div class="title" style="padding: 0">
              <div
                class="headTitle"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start !important;
                  font-size: 16px;
                  width: 100%;
                  margin-bottom: 3px;
                ">
                <div>
                  Rail Mill - Product List
                </div>
              </div>
            </div>
            <div
              class="row"
              style="
                margin: 0px;
                padding: 0px;
                margin-right: 8px;
                margin-top: 5px;
              ">
              <ng-container *ngFor="let card of productList; index as i">
                <div class="col-md-3">
                  <div class="card" [ngStyle]="card.style">
                    <img
                      class="card-img-top"
                      [src]="card.imgSrc"
                      alt="Card image cap" />
                    <ng-container *ngFor="let product of card.products">
                      <div class="card-body" style="padding: 8px 15px;">
                        <h6 class="card-title">{{ product.header }}</h6>
                        <ng-container *ngFor="let value of product.values">
                          <div class="values">
                            <i
                              class="fa fa-square boxIcon"
                              style="color: rgb(237, 131, 38)">
                            </i
                            >{{ value }}
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="footerCard">
              <span
                >Total Sections -
                <span style="color: #2a7cf5"> 60 Nos. </span>and Total
                combination -
                <span style="color: #2a7cf5"> 213 Nos.</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="marqueeTag">
        <img src="assets/images/Jindal-logo.png" alt="" style="width: 100px" />
        <marquee
          hspace="20"
          direction="left"
          style="
            height: 20px;
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            padding: 0px;
            margin-inline: -11px;
            margin-bottom: 3px;
          ">
          <span>
            <span style="font-weight: 500; color: #2a7cf6; font-size: 16px"
              >WELCOME TO JSP RAIL MILL ( CAPACITY 0.75 MILLION TON)
            </span>
          </span>
        </marquee>
        <img src="assets/images/Jindal-logo.png" alt="" style="width: 100px" />
      </div>
      <!-- <marquee
      hspace="20"
      direction="left"
      style="
        height: 40px;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        padding:  0px;
        margin-inline: -11px;
        margin-bottom: 3px;
      "
    >
    <span>
      <img src="assets/images/Jindal-logo.png" alt="" style="width: 100px;">
      <span style="font-weight: bold;">WELCOME TO JSPL RAIL MILL ( CAPACITY 0.75 Million) </span>
      <img src="assets/images/Jindal-logo.png" alt="" style="width: 100px;">
    </span>
    </marquee> -->
    </div>
  </div>
</div>
<ng-template #chargingSheetContent let-modal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
      style="font-size: 14px; color: #0a0d1a">
      Charging Sheet Details
    </h4>
    <i
      class="fa fa-times"
      style="cursor: pointer"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"></i>
  </div>
  <div class="modal-body">
    <div style="padding: 0%; padding-right: 1%; padding-bottom: 1%">
      <div
        class="alertable card bg-color"
        style="height: calc(100vh - 270px); position: relative; padding: 10px">
        <ng-container *ngIf="!chargingSheetData.data.length">
          <div
            class="font-size-12"
            style="
              height: calc(100vh - 210px);
              padding: 20px;
              font-size: 18px;
              font-family: 'sfpro-r';
            ">
            <app-infomessage [message]="'No Data Found'"></app-infomessage>
          </div>
        </ng-container>
        <div
          *ngIf="chargingSheetData && chargingSheetData.data.length"
          style="height: calc(100vh - 250px); overflow: auto">
          <div class="table" style="padding: 0%">
            <table id="alerts">
              <thead>
                <tr class="header">
                  <ng-container *ngFor="let head of chargingSheetData.header">
                    <th>{{ head.label }}</th>
                  </ng-container>
                </tr>
              </thead>
              <tbody></tbody>
              <tbody>
                <tr
                  *ngFor="
                    let data of chargingSheetData.data | paginate : configPage
                  ">
                  <ng-container *ngFor="let head of chargingSheetData.header">
                    <td *ngIf="head.field == 'timestamp'">
                      {{ formatTimestamp(data[head.field]) }}
                    </td>
                    <td *ngIf="head.field != 'timestamp'">
                      {{ data[head.field] ? data[head.field] : 'NA' }}
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style="position: absolute; bottom: -9px"
            *ngIf="
              chargingSheetData.data && chargingSheetData.data.length > 10
            ">
            <app-pagination (changePage)="changePage($event)"></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"></div>
</ng-template>
