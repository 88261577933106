<div
  class="image-container container-fluid"
  (window:resize)="onResize($event)"
  (click)="onAreaClick($event)"
  (dblclick)="doubleClick($event)"
  #element
>
  <img [src]="src" style="width: 1250px;" class="wid100" />
  <div class="coordinates-container">
    <div
      class="area"
      [ngClass]="{
        critical: data.commErrStatus,
        on: data.alert_count == 0,
        off: !data.commErrStatus && data.alert_count > 0
      }"
      [ngStyle]="getCoordinateStyle(data)"
      *ngFor="let data of _coordinates"
      (click)="onAreaClick(data)"
      [ngbPopover]="popContent"
      triggers="mouseenter:mouseleave"
      (contextmenu)="onAreaEdit(data)"
    >
      <ng-template #popContent>
        <div class="row popContent">
          <div class="col-sm-12">
            <div class="assetName">
              {{ data.customName ? data.customName : data.asset }}
              <span *ngIf="data.commErrStatus" class="comm-text"
                >(Not Communicating)</span
              >
            </div>
            <div>
              <span class="assetalert" *ngIf="data.status"
                ><i class="fa fa-warning"></i> {{ data.alert_count }} Alerts (In
                last 3 days)</span
              >
            </div>
            <div>
              <span class="comm-status">
                Data Collected At -
                {{ data.timestamp._seconds * 1000 | date: "medium" }}</span
              >
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
