import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-infomessage',
  templateUrl: './infomessage.component.html',
  styleUrls: ['./infomessage.component.scss']
})
export class InfomessageComponent implements OnInit {
@Input()message:any
  constructor() { }

  ngOnInit(): void {
  }

}
