<div class="datatableWidget">
    <div class="card">
        <table class="table">
            <thead>
                <tr>
                    <th *ngFor="let head of datatable1.header">{{head.label}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of datatable1.data">
                    <td *ngFor="let head of datatable1.header">{{data[head.field]}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
