import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { date } from 'faker';
import moment from 'moment';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UtilService } from 'src/app/services/util.service';
import * as  _ from 'lodash';
import { grep } from 'highcharts';
@Component({
  selector: 'app-historydashboard',
  templateUrl: './historydashboard.component.html',
  styleUrls: ['./historydashboard.component.scss'],
})
export class HistorydashboardComponent implements OnInit {
  asset: any = [];
  selectedAssetval: any;
  initialDates: any;
  isGraph = true;
  HistoryChartData: any = [];
  tableData: any = {
    header: [{ label: 'Time Stamp', field: 'timestamp' }],
    data: [],
  };
  filteredData: any = {
    selectedAsset: [],
    startDate: date,
    endDate: date,
  };
  configPage: any;
  dummyJson: any = [
    {
      color: '#427bff',
      marker: { enabled: false },
      name: 'Current Production In MT',
      data: [
        { x: 1664605800000, y: 10 },
        { x: 1664692200000, y: 30 },
        { x: 1664778600000, y: 20 },
        { x: 1664865000000, y: 70 },
        { x: 1664951400000, y: 30 },
        { x: 1665037800000, y: 10 },
        { x: 1665124200000, y: 20 },
        { x: 1665210600000, y: 90 },
        { x: 1665297000000, y: 40 },
        { x: 1665340200000, y: 20 },
      ],
    },
    {
      color: '#427bff',
      marker: { enabled: false },
      name: 'Current Production In PCs',
      data: [
        { x: 1664605800000, y: 30 },
        { x: 1664692200000, y: 10 },
        { x: 1664778600000, y: 70 },
        { x: 1664865000000, y: 40 },
        { x: 1664951400000, y: 90 },
        { x: 1665037800000, y: 10 },
        { x: 1665124200000, y: 60 },
        { x: 1665210600000, y: 80 },
        { x: 1665297000000, y: 90 },
        { x: 1665340200000, y: 20 },
      ],
    },
    {
      color: '#427bff',
      marker: { enabled: false },
      name: 'Rolled Quantity',
      data: [
        { x: 1664605800000, y: 10 },
        { x: 1664692200000, y: 30 },
        { x: 1664778600000, y: 20 },
        { x: 1664865000000, y: 40 },
        { x: 1664951400000, y: 30 },
        { x: 1665037800000, y: 90 },
        { x: 1665124200000, y: 10 },
        { x: 1665210600000, y: 80 },
        { x: 1665297000000, y: 50 },
        { x: 1665340200000, y: 20 },
      ],
    },
    {
      color: '#427bff',
      marker: { enabled: false },
      name: 'Rate of Rolling',
      data: [
        { x: 1664605800000, y: 20 },
        { x: 1664692200000, y: 20 },
        { x: 1664778600000, y: 90 },
        { x: 1664865000000, y: 60 },
        { x: 1664951400000, y: 30 },
        { x: 1665037800000, y: 60 },
        { x: 1665124200000, y: 50 },
        { x: 1665210600000, y: 10 },
        { x: 1665297000000, y: 50 },
        { x: 1665340200000, y: 20 },
      ],
    },
  ];
  datapointsForCurrentProduction: any[];
  graphData:any=[]
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: SubjectService,
    private fs:FirestoreService,
    private util:UtilService
  ) {}

  ngOnInit(): void {
    this.configPage = { itemsPerPage: 7, currentPage: 1 };
    this.initialDates = {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().toDate(),
    };
    this.activatedRoute.params.subscribe((data: any) => {
      this.selectedAssetval = [data.code];
      this.asset = [
        'Current Production In MT',
        'Current Production In PCs',
        'Rolled Quantity',
        'Rate of Rolling',
      ];
      this.filteredData.selectedAsset.push(data.code);
      this.tableData.header.push({
        label: data.code,
        field: data.code.toLowerCase().replace(/\s/g, ''),
      });
      this.filteredData.startDate = this.initialDates.startDate;
      this.filteredData.endDate = this.initialDates.endDate;
    });
    this.service.selectedAssetDashboardData$.subscribe((data: any) => {
      if (data != null) {
        if (this.isGraph) {
          this.prepareChartData(data);
        } else {
          this.getTableData(data);
        }
      } else {
        this.getHistoryData();
      }
    });
  }
  changePage(event) {
    this.configPage.currentPage = event;
  }

  navigate() {
    this.router.navigate(['page/executive-dashboard']);
  }
  selectedAsset(asset: any) {
    this.filteredData['selectedAsset'] = asset;
  }
  customchange(data: any) {
    this.filteredData['startDate'] = data?.startDate?.$d;
    this.filteredData['endDate'] = data?.endDate?.$d;
  }
  getHistoryData() {
    this.util.setLoaderValue(true)
    this.HistoryChartData = [];
    const colors =["#427bff","#FFC300","#00c120","#ec8d22","#5abc71"]
this.graphData=[]
    this.tableData.header = [];
    this.filteredData['type']='shift'
    this.filteredData['assetCode']='OPCUARHF2'
    // console.log(this.filteredData)
    this.fs.getKpisforExecutiveDashboard(this.filteredData).subscribe((data:any)=>{
   if(data.size){
        data=data.docs
        data.forEach((doc:any)=>{
          data.push(doc.data())
    })
    data =data.filter((doc:any)=>{
        return doc.type==this.filteredData.type && doc.assetcode==this.filteredData.assetCode
      })
      let groupedData={}
      this.datapointsForCurrentProduction=[]
      groupedData=_.groupBy(data,(d:any)=>{
        return moment(d.Date?.seconds *1000).format('DD-MM-YYYY')
     })
     this.filteredData.selectedAsset.forEach((asset:any,count:any)=>{
      this.datapointsForCurrentProduction=[]    
      let graphObj:any={}
      graphObj['color']=colors[count]
      graphObj['name']=asset
      if(asset=='Current Production In PCs'){
        graphObj['unit']='PCs'
      }
     else if(asset=='Rate of Rolling'){
        graphObj['unit']='Blooms/hr'
      }
      else{
        graphObj['unit']='Ton'
      }
      graphObj['marker']={ enabled: false }
     for (let key in groupedData){
       let obj:any={x:'',y:''}
       let totalBlooms:any=0
       let totalHours:any=0
       let value:any=0
     groupedData[key].forEach((dayData:any,index:any)=>{
       if(index==0){
     obj['x']=dayData?.Date?.seconds*1000
       }
       dayData.KPIS.forEach((kpi:any)=>{
        if(asset=='Current Production In MT'){
         if(kpi.name=='Total_Shift_tons'){
          value+=kpi.value
         }
        }
        else if(asset=='Current Production In PCs'){
          if(kpi.name=='Total_Shift_Discharge'){
            value+=kpi.value
           }
        }
        else if(asset=='Rate of Rolling'){
          if(kpi.name=='Total_Shift_Discharge'){
            totalBlooms+=kpi.value
          }
          else if(kpi.name=='Running_hours'){
           totalHours+=kpi.value
          }
        }
       })
       if(totalBlooms && totalHours){
        value=totalBlooms/totalHours
       }
         if(value){
         obj['y']=Number(value.toFixed(2))
         }
         else{
           obj['y']=0.00
         }
     })
     this.datapointsForCurrentProduction.push(obj)
     }
      graphObj['data']=this.datapointsForCurrentProduction
      this.graphData.push(graphObj)
     })
     this.service.setSelectedAssetDashboardData(this.graphData)
    setTimeout(() => {
      if (this.isGraph) {
        this.prepareChartData(this.graphData);
      } else {
        this.getTableData(this.graphData);
      }
    }, 10);
   
  }
  else{
    this.util.setLoaderValue(false)
  }
    })
  }
  prepareChartData(data: any) {
    this.HistoryChartData = [];
    let chart: any = {
      accessibility: {
        announceNewData: { enable: true, minAnnounceInterval: 15000 },
      },
      format: true,
      label_y: '',
      mb: 70,
      min_y: 0,
      ml: 80,
      mr: 25,
      mt: 20,
      unit: '',
      xaxis: [],
      series: [],
    };
    data?.forEach((doc: any) => {
      let obj: any = {
        color: doc.color,
        data: [],
        marker: doc.marker,
        name: doc.name,
        unit:doc.unit,
        tooltip: {
          xDateFormat: '%d-%m-%y',
          valueDecimals: 2,
          valueSuffix: doc.unit?` ${doc.unit}`:'',
        },
        type: 'line',
        yAxis: 0,
      };
      for (let ele of doc.data) {
        let val: any = [];
        val.push(ele.x, ele.y);
        obj.data.push(val);
      }
      chart.series.push(obj);
    });
    if (data.length == chart.series.length) {
      this.HistoryChartData.push(chart);
      this.util.setLoaderValue(false)
    }
  }

  toggleChange(event: any) {
    if (!event.checked) {
      this.isGraph = false;
      this.configPage.currentPage = 1;
      this.service.selectedAssetDashboardData$.subscribe((data: any) => {
        this.getTableData(data);
      });
    } else {
      this.isGraph = true;
      this.service.selectedAssetDashboardData$.subscribe((data: any) => {
        this.prepareChartData(data);
      });
    }
  }
  getTableData(data: any) {
    this.configPage.currentPage = 1;
    this.tableData.data = [];
    this.tableData.header = [{ label: 'Time Stamp', field: 'timestamp' }];
    this.filteredData.selectedAsset.forEach((ele: any) => {
      this.tableData.header.push({
        label: ele,
        field: ele.toLowerCase().replace(/\s/g, ''),
      });
    });
    let value: any = [];
    this.tableData.data = [
      {
        timestamp: date,
        currentProductionInMt: Number,
        currentProductionInPcs: Number,
        rolledQuantity: Number,
        rateOfRolling: Number,
      },
    ];
    data?.forEach((doc: any) => {
      doc?.data?.forEach((ele: any, index: any) => {
        this.tableData.data[index] = {
          timestamp: date,
          currentProductionInMt: Number,
          currentProductionInPcs: Number,
          rolledQuantity: Number,
          rateOfRolling: Number,
        };
        this.tableData.data[index].timestamp = ele.x;
      });
    });
    data?.forEach((doc: any) => {
      doc?.data?.forEach((ele: any, index: any) => {
        if (doc.name == 'Current Production In MT') {
          this.tableData.data[index].currentProductionInMt = ele.y;
        }
      });
      doc.data.forEach((ele: any, index: any) => {
        if (doc.name == 'Current Production In PCs') {
          this.tableData.data[index].currentProductionInPcs = ele.y;
        }
      });
      doc.data.forEach((ele: any, index: any) => {
        if (doc.name == 'Rolled Quantity') {
          this.tableData.data[index].rolledQuantity = ele.y;
        }
      });
      doc.data.forEach((ele: any, index: any) => {
        if (doc.name == 'Rate of Rolling') {
          this.tableData.data[index].rateOfRolling = ele.y;
        }
      });
    });
    this.util.setLoaderValue(false)
  }
}
