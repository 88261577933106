import { UserData } from './../model/user.model'
import { Action } from '@ngrx/store';

export const CURRENT_USER = 'CURRENT_USER';

export function userReducer(state: UserData[]=[], action:any) {
  switch (action.type) {
    case CURRENT_USER: {
        return [...state, action.payload];
    }
    default:
        return state;
    }
}