import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-formfield-validator',
  templateUrl: './formfield-validator.component.html',
  styleUrls: ['./formfield-validator.component.scss']
})
export class FormfieldValidatorComponent {
  @Input()
  public controlName: string;
  constructor(@Optional() private controlContainer: ControlContainer) {} 
  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }
  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }
}
