<div class="htoolbar">
  <div class="firstrow">
    <div class="headertext">Dashboard</div>
    <div style="display: flex" *ngIf="view == 'map'">
      <div style="display: flex; align-items: center">
        <div class="area area-on"></div>
        <span style="font-size: 14px; margin: 0px 10px 0px 5px">No Alerts</span>
      </div>
      <div style="display: flex; align-items: center">
        <div class="area area-critical"></div>
        <span style="font-size: 14px; margin: 0px 10px 0px 5px">Alerts</span>
      </div>
      <div style="display: flex; align-items: center">
        <div class="area area-off"></div>
        <span style="font-size: 14px; margin: 0px 10px 0px 5px"
          >Not Communicating</span
        >
      </div>
    </div>

    <div class="toolbar">
      <input
        type="text"
        placeholder="Search"
        [(ngModel)]="searchContent"
        class="wid100 input searchBox"
        *ngIf="view == 'grid'"
      />
      <div>
        <button
          class="btn btn-default createBtn"
          style="margin: 0px 10px; height: 30px; padding: 6px"
          (click)="download()"
          *ngIf="view == 'grid'"
        >
          Download
        </button>
      </div>
      <div class="btn-group" role="group" aria-label="...">
        <button
          type="button"
          class="btn btn-default"
          title="Map View"
          [ngClass]="{ active: view == 'map' }"
          (click)="setView('map', view)"
        >
          <i class="fa fa-map-o"></i>
        </button>
        <button
          type="button"
          class="btn btn-default"
          title="Grid View"
          [ngClass]="{ active: view == 'grid' }"
          (click)="setView('grid', view)"
        >
          <i class="fa fa-th"></i>
        </button>
      </div>
      <!-- <app-dropdown
				[values]="sites"
				[selected]="selectedSite"
				(newSelectionEvent)="selectionChanged($event)"
			></app-dropdown> -->
    </div>
  </div>
</div>

<div class="d-fc p-20">
  <ng-container *ngIf="view == 'map'">
    <div>&nbsp;</div>
    <app-image-map
      [src]="image"
      [coordinates]="assetData"
      (onClick)="getClick($event, 'single')"
    ></app-image-map>
  </ng-container>

  <div class="row" *ngIf="view == 'grid'">
    <div
      class="col-md-6 col-sm-6 col-xs-6 assetGrid"
      (click)="selectAsset(data)"
      *ngFor="let data of assetData | filter: searchContent"
    >
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <div class="row firstRow">
                <div class="col-10 padding0">
                  <div class="name">
                    {{ data.customName ? data.customName : data.asset }}
                  </div>
                  <div class="action">
                    <ng-container *ngIf="!data?.commErrStatus">
                      <img src="assets/images/asset_healthy_icon/healthy.png" />
                      Data Collected At :
                      {{ data?.timestamp?._seconds * 1000 | date: "medium" }}
                    </ng-container>
                    <ng-container *ngIf="data?.commErrStatus">
                      <img
                        src="assets/images/asset_healthy_icon/nothealthy.png"
                      />
                      Data Collected At :
                      {{ data?.timestamp?._seconds * 1000 | date: "medium" }}
                    </ng-container>
                    <!-- <ng-container *ngIf="!data.status">
                      <img src="assets/images/asset_healthy_icon/warning.png" />
                      Not Healthy
                    </ng-container> -->
                  </div>
                </div>
                <div class="col-2 padding0 alertInfo">
                  <div class="value">{{ data.alert_count }}</div>
                  <div class="label">Alerts</div>
                </div>
              </div>
              <div class="row secondRow">
                <ng-container
                  *ngFor="
                    let parameter of gridOverview[data.assetcode];
                    let j = index
                  "
                >
                  <div
                    [ngClass]="
                      j == 0
                        ? 'col-4 padding0 paramInfo first'
                        : 'col-4 padding0 paramInfo'
                    "
                  >
                    <div class="label">{{ parameter.name }}</div>
                    <div class="value">{{ parameter.value }}</div>
                  </div>
                </ng-container>

                <!-- <div class="col-4 padding0 paramInfo">
                  <div class="label">Rate of Production</div>
                  <div class="value">15</div>
                </div>
                <div class="col-4 padding0 paramInfo">
                  <div class="label">Parameter</div>
                                    <div class="value">3</div>
                </div> -->
              </div>
            </div>
            <div class="col-4 imagePart">
              <img
                [src]="data.assetImage"
                src="assets/images/dashboard/asset_image_large.PNG"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #coordinateSetting let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      Asset Coordinates Setting
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Select Asset</p>
    <app-searchable-dropdown
      [values]="assetData"
      [displayMember]="'asset'"
      [selectedData]="selectAssetSet"
      (newSelectionEvent)="changeAsset($event)"
    ></app-searchable-dropdown>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      [disabled]="!selectAssetSet"
      (click)="saveCoordinate()"
      class="btn btn-info"
    >
      <i class="fa fa-save"></i> Save
    </button>
  </div>
</ng-template>
<ng-template #mapCoordinateSetting let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      Asset Coordinates Setting
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <app-searchable-dropdown
        [values]="assetData"
        [displayMember]="'asset'"
        [selectedData]="selectAssetSet"
        (newSelectionEvent)="changeAsset($event)"
      ></app-searchable-dropdown>
      <input type="text" />
      <input type="text" />
    </div>
    <img [src]="image" class="wid100" />
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      [disabled]="!selectAssetSet"
      (click)="saveCoordinate()"
      class="btn btn-info"
    >
      <i class="fa fa-save"></i> Save
    </button>
  </div>
</ng-template>
