<mat-accordion class="example-headers-align" multi="false">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> {{ checklist?.checklist_name }} </mat-panel-title>
      <mat-panel-description>
        <div>{{ checklist?.agency }}</div>
      </mat-panel-description>
      <mat-panel-description>
        <div>{{ checklist?.frequency }}</div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="content">
      <div class="card">
        <div class="subContent">
          <div class="card">
            <div class="checklist-title">
              {{ checklist?.checklist_name }}
            </div>
            <div class="row">
              <div class="col-3">
                <span class="label-color">Asset : </span> {{ checklist.asset }}
              </div>
              <div class="col-3">
                <span class="label-color">Section : </span>
                {{ checklist.agency }}
              </div>
              <div class="col-3">
                <span class="label-color">Area : </span> {{ checklist.area }}
              </div>
              <div class="col-3">
                <span class="label-color">Frequency: </span>
                {{ checklist.frequency }}
              </div>
            </div>
            <div class="row"></div>
          </div>
          <!-- <div class="card" *ngFor="let point of checklist.check_points">
            <div class="row description">
              <div class="col-12 normDetail">
                <div class="label">Description</div>
                <div class="value">
                  {{ point.description }}
                </div>
              </div>
            </div>
            <div class="row rowTop">
              <div class="col-4 normDetail">
                <div class="label">Equipment Name</div>
                <div class="value">{{ point?.equipment.equipmentName }}</div>
              </div>
              <div class="col-3 normDetail">
                <div class="label">Standard Norm</div>
                <div class="value">{{ point?.standard_norm }}</div>
              </div>
              <div class="col-3 criticalityDetail">
                <div class="label">Criticality</div>
                <div class="value">{{ point?.criticality }}</div>
              </div>
              <div class="col-2 actionBtn">
                <app-dropdown
                  [values]="ackData"
                  [selected]="selectedAck"
                  [displayMember]="'name'"
                  (newSelectionEvent)="point['ack'] = $event['value']"
                  type="object"
                >
                </app-dropdown>
              </div>
            </div>
            <div class="row rowBottom">
              <div class="col-3">
                <div class="row h-100">
                  <div class="col label">Action Taken:</div>
                  <div class="col">
                    <input type="text" [(ngModel)]="point['action_taken']" />
                  </div>
                </div>
              </div>
              <div class="col-9">
                <div class="row h-100">
                  <div class="col-6">
                    <div class="row h-100">
                      <div class="col-2 label padLeft0">Observation:</div>
                      <div class="col-10 input">
                        <input
                          type="text"
                          [(ngModel)]="point['observations']"
                          class="wid100"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row h-100">
                      <div class="col-2 label">Remarks:</div>
                      <div class="col-10 input">
                        <input
                          type="text"
                          [(ngModel)]="point['remarks']"
                          class="wid100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- {{checklist.headerColumns|json}}
          {{checklist.check_points|json}} -->
          <table id="alerts">
            <tr class="header">
              <th *ngFor="let header of datatable.header">
                {{ header.columnName }}
              </th>
              <th>Actions</th>
            </tr>
            <ng-container
              *ngFor="let point of checklist.check_points; let i = index"
            >
              <ng-container *ngIf="point.header">
                <div class="headerTextStyle">{{ point.header }}</div>
              </ng-container>
              <ng-container *ngIf="!point.header">
                <tr class="acc pointHead">
                  <td *ngFor="let header of datatable.header">
                    <!-- <ng-container *ngIf="header.columnType == 'Dropdown'">
                      {{
                        point[header.columnId][header.dropdownCategory.display]
                      }}
                    </ng-container> -->
                    <ng-container
                      *ngIf="
                        header.columnType == 'Textbox' ||
                        header.columnType == 'Dropdown'
                      "
                    >
                      {{ point[header.columnId] }}
                    </ng-container>
                  </td>
                  <td style="width: 5%" (click)="[toggleFunc(i)]">
                    <i class="fa fa-angle-down" *ngIf="!activeField[i]"></i>
                    <i class="fa fa-angle-up" *ngIf="activeField[i]"></i>
                  </td>
                </tr>
                <tr
                  *ngIf="datatable.inputData.length"
                  class="acc-panel pointBody"
                  [ngClass]="!activeField[i] ? 'active' : 'inactive'"
                >
                  <td colspan="7">
                    <div class="acc-expand">
                      <div
                        class="innerlist row"
                        style="align-items: baseline !important"
                      >
                        <div
                          class="col"
                          *ngFor="let field of datatable.inputData"
                        >
                          <!-- <pre>{{field|json}}</pre> -->
                          <label>{{ field["columnName"] }}</label>
                          <ng-container
                            *ngIf="field['columnType'] == 'Textbox'"
                          >
                            <input
                              type="text"
                              class="wid100"
                              [(ngModel)]="point[field.columnId]"
                            />
                          </ng-container>
                          <ng-container
                            *ngIf="field['columnType'] == 'Dropdown'"
                          >
                            <ng-container
                              *ngIf="
                                field['dropdownCategory'].type == 'arrayString'
                              "
                            >
                              <app-dropdown
                                [values]="
                                  dropdownCategory[
                                    field['dropdownCategory']['label']
                                  ].value
                                "
                                [selected]="point[field.columnId] ||''"
                                (newSelectionEvent)="
                                  point[field.columnId] = $event
                                "
                              >
                              </app-dropdown>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                field['dropdownCategory'].type == 'arrayObject'
                              "
                            >
                              <app-dropdown
                                [values]="
                                  dropdownCategory[
                                    field['dropdownCategory']['label']
                                  ].value
                                "
                                [selected]="point[field.columnId]"
                                (newSelectionEvent)="
                                  point[field.columnId] = $event
                                "
                                type="object"
                                [displayMember]="
                                  field['dropdownCategory']['display']
                                "
                              >
                              </app-dropdown>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </table>
        </div>
      </div>
      <button
        *ngIf="checkAction('Update Maintainance')"
        type="button"
        class="btn btn-default createBtn"
        (click)="submitChecklist(checklist)"
      >
        Save
      </button>
      <button
        *ngIf="checkAction('Edit Checklist')"
        type="button"
        (click)="editChecklist(checklist)"
        style="margin-right: 10px"
        class="btn btn-default createBtn"
      >
        Add Points
      </button>
      <button
        *ngIf="checkAction('Delete Checklist')"
        type="button"
        (click)="deleteChecklist(checklist)"
        style="
          margin-right: 10px;
          background-color: transparent !important;
          color: #595959 !important;
        "
        class="btn btn-secondary btn-outline createBtn"
      >
        Delete Checklist
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
