<div class="card listWidget">
    <div class="cardTitle">
        <div class="row">
            <div class="col-12 pad0">{{widgetInfo?.title}}</div>
        </div>
    </div>
    <div class="padding10">
        <ng-container *ngIf="!widgetInfo.options || widgetInfo.options.listDisplayType=='Horizontal'">
            <table class="wid100">
                <!-- <tr *ngFor="let param of widgetInfo?.tagData">
                    <td>{{param.component}}</td>
                    <td>{{param.value}} &nbsp;{{param.unit}}</td>
                </tr> -->
                <tr *ngFor="let param of widgetInfo?.options?.tagMapping">
                    <td>{{param.label}}</td>
                    <td>{{param.data.value}} &nbsp;{{param.data.unit}}</td>
                </tr>
            </table>
        </ng-container>
        <ng-container *ngIf=" widgetInfo.options.listDisplayType=='Vertical'">
            <ul class="listVertical">
                <!-- <li *ngFor="let param of widgetInfo?.tagData">
                    <div class="value">{{param.value}} <span class="unit">{{param.unit}}</span></div>
                    <div class="label">{{param.component}}</div>
                </li> -->
                <li *ngFor="let param of widgetInfo?.options?.tagMapping">
                    <div class="value">{{param.data.value}} <span class="unit">{{param.data.unit}}</span></div>
                    <div class="label">{{param.label}}</div>
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf=" widgetInfo.options.listDisplayType=='Skid Monitoring'">
            <ul class="listSkid">
                <li class="row" *ngFor="let param of widgetInfo?.tagData">
                    <div class="col-4 padding0">
                        <div class="">Name</div>
                        <div>Circuit Skid 01</div>
                    </div>
                    <div class="col-4 padding-right-0">
                        <div class="">Temp</div>
                        <div>21 c</div>
                    </div>
                    <div class="col-4">
                        <div class="">Flow</div>
                        <div>21 m3/hr</div>
                    </div>
                </li>
            </ul>
        </ng-container>
        
    </div>
</div>