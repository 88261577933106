import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { Moment } from "moment";
import { Subscription } from 'rxjs';
import { AggregateError } from 'sequelize/types';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { environment } from './../../../../environments/environment';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
  chartData: any = [];
  InitialDates = {}
  assetData: any = [];
  SelectTags:any='Select Tag'
  selectedAssetData: any = {};
  filter: any = {
    asset: {
      data: [],
      selected: {}
    },
    raw: {
      data: [],
      selected: ""
    },
    aggregation: {
      //data: [{time:"Live",collection:"localHistory"},{time:"10 Minutes",collection:"Aggregate10MinuiteData"},{time:"60 Minutes",collection:'Aggregate60MinuiteData'},{time:"1 Day",collection:"Aggregate1440MinuiteData"}],
      //{ time: "Live", collection: "localHistory" },
      data: [
      { time: "Raw Data", collection: "HISTORY" },
      { time: "10 Minutes", collection: "Aggregate10MinuiteData", disableOption:false,interval: "10_min" },
      { time: "60 Minutes", collection: "Aggregate60MinuiteData", disableOption:false,interval: "60_min"  },
      { time: "1 Day", collection: "Aggregate1440MinuiteData", disableOption:false,interval: "1_day"  }],
      selected: {},
    }
  };
  csvoptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  uniqueCode: string = "";
  subscription_tagData: any;
  historyResponse: any = [];
  graphData: any = { datapoints: {}, tagdetails: {} };
  historySubcription: any;
  startDate: any = moment().startOf('day').toDate();
  endDate: any = moment().toDate();
  liveDataSubscription: Subscription | undefined;
  liveData: any;
  liveTagData: any;
  showTags: boolean = false;
  diffmin: number;
  selectedTagFilter: boolean = false;
  constructor(private fs: FirestoreService, private router: Router, private activeRoute: ActivatedRoute,
    private subject: SubjectService, private util: UtilService) {
    this.activeRoute.params.subscribe(param => {
      this.uniqueCode = param.code;
    });
    let assetParams = {
      category: "ASSETS",
      conditions:[{key:"is_maintenance_asset",symbol:"==",value:false}]
    }
    this.fs.getData(assetParams).subscribe((response: any) => {
      this.filter.asset.data = response.data
      if (this.uniqueCode) {
        let assetFilter = this.filter.asset.data.filter((assetdata) => {
          return assetdata.id == this.uniqueCode;
        });
        if (assetFilter.length > 0) {

          this.filter.asset.selected = assetFilter[0];
          this.loadTags();

        }
      }
    });
  }

  ngOnInit(): void {
    let start = moment(this.startDate);
    let end = moment(this.endDate);
    // this.diffmin = end.diff(start, 'minute');
    this.diffmin = 60
    this.filter.aggregation.selected = this.filter.aggregation.data[1];
    if(!environment.cloud) {
      this.socketconnection();
    }
  }

  loadTags() {
    let filter = [
      {
        key: "assetcode",
        symbol: "==",
        value: this.filter.asset.selected.assetcode
      },
      {
        key: "removedtag",
        symbol: "==",
        value: false
      }
    ];
    let tagsParams = {
      category: "THRESHOLDS",
      conditions: filter
    }
    this.showTags =false;
    this.subscription_tagData = this.fs.getData(tagsParams).subscribe((response: any) => {
      if (response.status == "success") {

        this.filter.raw.data = response.data;
        this.showTags =true;
        this.filter.raw.data.forEach((element: any) => {
          element.component = element.component.replace(/_/g, " ");
        });
        this.filter.raw.selected = [this.filter.raw.data[0]];
        this.loadLiveTags();
      }
    });
  }
  filterChange(event: any, type: string) {
    this.startDate = moment(event.startDate.$d).format("MM/DD/YYYY HH:mm:ss");
    this.endDate = moment(event.endDate.$d).format("MM/DD/YYYY HH:mm:ss");
    let start = moment(this.startDate);
    let end = moment(this.endDate);
    this.diffmin = end.diff(start, 'minute');
    let collection = this.filter.aggregation.selected.collection
    if ( collection == 'HISTORY' && this.diffmin > 60) {
      this.util.showSnakbarMessage("warning", "Please select 1 hour range for Raw Data!", "end", "top");
    } else if (this.diffmin > 1440 && collection =='Aggregate10MinuiteData' ) {
      this.util.showSnakbarMessage("warning", "Please select 60 min or 1 day aggregation from this daterange!", "end", "top");
    } else if (this.diffmin > 10080 && collection =='Aggregate60MinuiteData' ) {
      this.util.showSnakbarMessage("warning", "Please 1 day aggregation from this daterange!", "end", "top");
    }else{
      this.loadChartData()
    }
  }

  changeFilter(data: any, type: string) {
   this.filter[type].selected = data
    if (type == "asset") {
      this.subject.setSelectedAssetData(data);
      this.loadTags();
    }
    else if(type == 'aggregation'){
      let collection = this.filter.aggregation.selected.collection
      if ( collection == 'HISTORY' && this.diffmin > 60) {
        this.util.showSnakbarMessage("warning", "Please select 1 hour range for Raw Data!", "end", "top");
      } else if (this.diffmin > 1440 && collection =='Aggregate10MinuiteData' ) {
        this.util.showSnakbarMessage("warning", "Please select 60 min or 1 day aggregation from this daterange!", "end", "top");
      } else if (this.diffmin > 10080 && collection =='Aggregate60MinuiteData' ) {
        this.util.showSnakbarMessage("warning", "Please 1 day aggregation from this daterange!", "end", "top");
      }else{
        this.loadChartData()
      }
    }
    else if(type == 'raw'){
      if(data.length){
        this.selectedTagFilter = true;
      }
      else{
        this.selectedTagFilter = false;
      }
    }
  }
  redirectPage(type: string) {
    if (type == "dashboard") {
      this.router.navigate(["page/assetgrid"]);
    } else if (type == "assetdetail") {
      this.router.navigate(["page/assetgrid/assetdetails", this.uniqueCode]);
    }
  }

  loadLiveTags() {
    let params = {
      category: "CURRENTDATA",
      conditions: [
        {
          key: "asset",
          symbol: "==",
          value: this.filter.asset.selected.asset
        },
        {
          key: "assetcode",
          symbol: "==",
          value: this.filter.asset.selected.assetcode
        }
      ]
    }
    this.liveDataSubscription = this.fs.getData(params).subscribe((response: any) => {
      if (response.status == "success") {
        this.liveData = response.data;
        this.liveData.forEach((element: any) => {
          this.liveTagData = _.unionBy(this.liveTagData, element.tags, "tag");
        });

        let updatedData = Math.max.apply(
          Math,
          this.liveData.map((o) => {
            return o.timestamp._seconds;
          })
        );
        let lastTimestamp = moment(updatedData * 1000);
        let back60mins = lastTimestamp.subtract(1, 'hours');
        let start = back60mins.format("MM/DD/YYYY HH:mm:ss")
        let end = lastTimestamp.format("MM/DD/YYYY HH:mm:ss")
        this.InitialDates = {
          startDate: start,
          endDate: end
        }
        this.loadChartData();
      }
    });
  }


  loadChartData() {
    // console.log(this.filter.aggregation.selected.collection )
    if (this.filter.aggregation.selected.collection == 'HISTORY' && this.diffmin > 60) {
      this.util.showSnakbarMessage("warning", "Please select 1 hour range for Raw Data!", "end", "top");
    } else {
      this.historyResponse = [];
      let params ={}
      let collection = this.filter.aggregation.selected.collection
      if(collection == 'HISTORY'){
        params = {
          //  "category":collection,
          //  "customerId": "BLP",
          //  "assetcode": this.filter.asset.selected.assetcode,
          //  "asset": this.filter.asset.selected.asset,
          //  "startTime": moment(this.startDate).unix(),
          //  "endTime": moment(this.endDate).unix(),
           fromDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          toDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
            asset_code:[this.filter.asset.selected.assetcode],
            tag_name:[],
            collection:collection

          //  "tags":this.filter.raw.selected
          }
          for(let tags of this.filter.raw.selected){
            params['tag_name'].push(tags.tag)
          }
       }
       else{
         params = {
          //  "category":"AGGREGATE",
          //  "collection":`/Asset_OEE/JSPL/${collection}/wind/${this.filter.asset.selected.assetcode}`,
          //  "conditions":[
          //  {
          //  "key": "timestamp",
          //  "symbol": ">=",
          //  "value": moment(this.startDate).toDate()
          //  },
          //  {
          //  "key": "timestamp",
          //  "symbol": "<=",
          //  "value": moment(this.endDate).toDate()
          //  }
          //  ]
          fromDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          toDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
            asset_code:[this.filter.asset.selected.assetcode],
            interval: this.filter.aggregation.selected['interval'],
            tag_name:[],
            collection:collection
          }
          for(let tags of this.filter.raw.selected){
            params['tag_name'].push(tags.tag)
          }
       }
       this.util.setLoaderValue(true);
       this.fs.getAgrregatedData(params).then((data:any)=>{
        if(data.status=='success'){
          if(data.Data && data.Data.length){
            this.graphData = { datapoints: {}, tagdetails: {} };
            this.historyResponse = data.Data
            this.prepareTagData()
          }
        else{
          this.util.showSnakbarMessage("info", "No data found for selected date time range", "end", "top");
          this.util.setLoaderValue(false);
        }
        }
        else{
          this.util.showSnakbarMessage("info", "No data found for selected date time range", "end", "top");
          this.util.setLoaderValue(false);
        }
       }).catch((err)=>{
        this.util.setLoaderValue(false)
       })

      // this.fs.getHistoryData(params).subscribe((response: any) => {
      //   this.util.setLoaderValue(false);
      //   if (response.data.length > 0) {
      //     this.graphData = { datapoints: {}, tagdetails: {} };
      //     this.historyResponse = response.data
      //     if(collection != 'HISTORY'){
      //       this.historyResponse.forEach(d =>{
      //         d.messageid = d.timestamp._seconds;
      //         d.timestamp = moment(d.timestamp._seconds * 1000).toDate()
      //       })
      //     }

      //       this.prepareTagData()

      //   } else if((response.data.length == 0 || response.data.data.length == 0))  {
      //     this.util.showSnakbarMessage("info", "No data found for selected date time range", "end", "top");
      //   }
      // });
    }

  }

  prepareTagData() {
    return new Promise((resolve, reject) => {
      let xaxis: any = [];
      this.graphData = {datapoints:{},tagdetails:{}}
      this.historyResponse.forEach((d, index) => {
        // d.tags.forEach((historyTagData) => {
        //   this.filter.raw.selected.forEach((configtagData: any) => {
        //     if (configtagData.tag == historyTagData.tag || configtagData.tag == historyTagData.component) {
        //       let date = moment(d.timestamp).format("DD/MMM/YYYY, HH:mm:ss");
        //       if (date) {
        //         if (xaxis.indexOf(date) == -1) {
        //           xaxis.push(date);
        //         }

        //         if (!this.graphData["datapoints"][historyTagData.tag]) {
        //           this.graphData["datapoints"][historyTagData.tag] = [];
        //         }
        //         if (!this.graphData["tagdetails"][historyTagData.tag]) {
        //           this.graphData["tagdetails"][historyTagData.tag] = {};
        //         }

        //         let xValue = new Date(d.timestamp).getTime() * 1000;
        //         let chartTagDataFilter = this.graphData["datapoints"][historyTagData.tag].filter((chartTagData) => {
        //           return chartTagData.x == xValue;
        //         })
        //         if (chartTagDataFilter.length == 0) {
        //           this.graphData["datapoints"][historyTagData.tag].push(
        //             {
        //               x: new Date(d.timestamp).getTime() * 1000,
        //               y: typeof historyTagData.value == "string" ? parseFloat(historyTagData.value) : historyTagData.value || 0,
        //               //y: Math.floor(Math.random() * 999) + 1
        //             }
        //           );
        //           this.graphData.tagdetails[historyTagData.tag] = configtagData;
        //         }

        //       }

        //     }
        //   });
        // });
          this.filter.raw.selected.forEach((configtagData: any) => {
            if (configtagData.tag == d.tag_name) {
              let date = moment(d.timestamp).subtract(330,'minutes').format("DD/MMM/YYYY, HH:mm:ss");
              if (date) {
                if (xaxis.indexOf(date) == -1) {
                  xaxis.push(date);
                }

                if (!this.graphData["datapoints"][d.tag_name]) {
                  this.graphData["datapoints"][d.tag_name] = [];
                }
                if (!this.graphData["tagdetails"][d.tag_name]) {
                  this.graphData["tagdetails"][d.tag_name] = {};
                }

                let xValue:any = moment(d.timestamp).subtract(330,'minutes').unix() * 1000;
                let chartTagDataFilter = this.graphData["datapoints"][d.tag_name].filter((chartTagData) => {
                  return chartTagData.x == xValue;
                })
                if (chartTagDataFilter.length == 0) {
                  this.graphData["datapoints"][d.tag_name].push(
                    {
                      x:  moment(d.timestamp).subtract(330,'minutes').unix() * 1000,
                      y: typeof d.tag_value == "string" ? parseFloat(d.tag_value ) : d.tag_value  || 0,
                      //y: Math.floor(Math.random() * 999) + 1
                    }
                  );
                  this.graphData.tagdetails[d.tag_name] = configtagData;
                }

              }

            }
            else{
              // this.util.setLoaderValue(false);
              // reject(false)
            }
          });
      });
      resolve({ gd: this.graphData, xaxis: xaxis })
    }).then((data: any) => {
      this.chartData = [];
      setTimeout(() => {
        this.chartData.push(
          this.compareTagsForMonitor({
            data: data.gd.datapoints,
            tagdetails: data.gd.tagdetails,
            xaxis: data.xaxis,
          })[0]

        );
        this.util.setLoaderValue(false);
        // console.log(this.chartData[0].xaxis.length)
      }, 100)

    }).catch((err)=>{
      this.util.setLoaderValue(false);
    })

  }


  compareTagsForMonitor(Data) {
    const colors = ["#427bff", "#FFC300", "#00c120", "#ec8d22", "#5abc71"]
    const final_array: any = [];
    let y_current: any = [];
    let yaxis: any = [];
    let self = this;
    let i = 0;
    for (let key in Data.data) {
      let obj;
      let y: any;
      y = {
        title: {
          enabled: false,
          text: `${Data.tagdetails[key].unit}`,
          style: {
            color: colors[i]
          },

        },
        opposite:false,
        labels: {
          enabled: false,
          style: {
            color: colors[i]
          }
        },
      }
      yaxis.push(y);
      obj = {
        marker: {
          enabled: false,
        },
        name: `${Data.tagdetails[key].component}`,
        color: colors[i],
        yAxis: i,
        tooltip: {
          xDateFormat: '%d-%m-%y, %H:%M:%S',
          valueDecimals: 2,
          valueSuffix: ` ${Data.tagdetails[key].unit}`,
        },
        type: "line",
        data: (function () {
          let data: any = [],
            time = new Date().getTime(),
            x,
            y,
            i = -Data.data[key].length;
          for (i; i < 0; i += 1) {
            y = Data.data[key][i + Data.data[key].length].y;
            x = Data.xaxis[i + Data.xaxis.length];
            let time2 = new Date(x).getTime();
            data.push([time2, y]);
          }
          return data;
        })(),
      };
      y_current.push(obj);
      i++
    }
    yaxis[yaxis.length - 1] = { ...yaxis[yaxis.length - 1], ...{ opposite: true } }
    final_array.push({
      xaxis: Data.xaxis,
      yaxis: yaxis,
      series: y_current,
      label_y: "Values",
      //threshold: Data.th,
      threshold: [
        {
          "color ": "transparent ",
          "width ": 1,
          "value ": 99999,
          "zIndex ": 5,
          "label ": {
            "align ": "center ",
            "style ": {
              "fontSize ": "10px ",
              "color ": "transparent "
            },
            "text ": "Upper Threshold 99999 - L20 ",
            "textAlign ": "center ",
            "useHTML ": false,
            "verticalAlign ": "top "
          }
        }
      ],
      accessibility: {
        announceNewData: {
          enabled: true,
          minAnnounceInterval: 15000,
          announcementFormatter: function (allSeries, newSeries, newPoint) {
            if (newPoint) {
              return "New point added. Value: " + newPoint.y;
            }
            return false;
          },
        },
      },
      events: {
        load: function (series) {
          self.subject.monitordata.subscribe((value) => {
            if (series != undefined) {
              series.target.series.forEach((se, index) => {
                if (se.name && value[se.name]) {
                  se.addPoint([value[se.name].x, value[se.name].y], true, true);
                }
              });
            }
          });
        }
      },

      mr: 25,
      mt: 20,
      ml: 80,
      mb: 70,
      min_y: 0,
      unit: "",
      format: true,
      // tickinterval_y: Data.y_interval,
      // tickinterval_x: 10,
    });
    return final_array;
  }


  socketconnection() {
    this.subject.setupSocketConnection();
    this.historySubcription = this.subject.getHistoryUpdateListener().subscribe((historyUpdate: any) => {
      if (historyUpdate && historyUpdate.messageid) {
        this.subject.setLastUpdatedDate(historyUpdate.messageid);
      }
      if (this.historyResponse && this.historyResponse.length > 0) {
        if (historyUpdate.asset == this.filter.asset.selected.asset && historyUpdate.assetcode == this.filter.asset.selected.assetcode) {
          this.historyResponse.shift();
          this.historyResponse.push(historyUpdate);
          this.updatechartForAsset(historyUpdate);
        }
      }
    });
  }

  updatechartForAsset(data) {
    let x;

    x = data.messageid * 1000;

    let obj = {};
    // widgetData.forEach(element => {
    this.filter.raw.selected.forEach((configtagData: any) => {
      let tag = configtagData.tag;
      if (tag) {
        let filterTagData = data.tags.filter((tagdata) => {
          return tagdata.tag == tag;
        });
        if (filterTagData.length > 0) {
          obj[filterTagData[0].component] = {
            x: x,
            y: typeof filterTagData[0].value == "string" ? parseFloat(filterTagData[0].value) : filterTagData[0].value,
            //state: element.live
          }
        }
      }

    });
    if (Object.keys(obj).length > 0) {
      this.subject.monitorGraphDataUpdate(obj);
    }

  }

  download(){
    let array: any = { csv: [], title:'', filename:''};
    // array.title = this.filter.threshold.asset.selected.asset+"-"+"Threshold";
    // array.filename = array.title;
    let final:any = []
    // this.configurationList.forEach(d =>{
    //   let obj = {}
    //   obj['Asset'] = this.filter.threshold.asset.selected.asset
    //   obj['Tag'] = d.tag
    //   obj['Description'] = d.component
    //   obj['Min Threshold'] = d.lower
    //   obj['Max Threshold'] = d.upper
    //   final.push(obj)
    // })
    array.csv = final;
    this.csvexport(array);
  }
  csvexport(data) {
    if (data) {
      this.csvoptions['title'] = data.title;
      this.csvoptions['filename'] = data.filename;
      const csvExporter = new ExportToCsv(this.csvoptions);
      csvExporter.generateCsv(data.csv);
    }
  }
  clearTags() {
    this.filter.raw.selected = [];
  }

}
