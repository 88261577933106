<div class="card gridWidget">
    <div class="cardTitle">
        <div class="row">
            <div class="col-12 pad0">{{widgetInfo?.title}}</div>
        </div>
    </div>
    <div class="row padding10">
        <!-- <div class="padding10 " [ngClass]="gridWidth" *ngFor="let param of widgetInfo?.tagData">
            <div class="card cardWidget">
                <div class="label">{{param.component}}</div>
                <div class="value">{{param.value}} <span class="unit">{{param.unit}}</span></div>
            </div>
        </div> -->
        <div class="padding10 " [ngClass]="gridWidth" *ngFor="let param of widgetInfo?.options?.tagMapping">
            <div class="card cardWidget">
                <div class="label">{{param.label}}</div>
                <div class="value">{{param?.data?.value}} <span class="unit">{{param?.data?.unit}}</span></div>
            </div>
        </div>
    </div>
</div>