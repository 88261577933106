import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  private selectedAssetData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private assetData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentUserInfi$: BehaviorSubject<any> = new BehaviorSubject(null);
  private lastUpdatedDate$: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedAssetDashboardData$:BehaviorSubject<any>=new BehaviorSubject(null)
  public filterData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private socket;
  private historyUpdated = new Subject();
  updatedgraphdata = new EventEmitter();
  getselectedmenu = new EventEmitter();

  monitordata = new EventEmitter();
  constructor() {}
  /* Get Sections Asset Data */

  /* Get Selected Asset Data */
  getCurrentUserInfo(): Observable<any> {
    return this.currentUserInfi$.asObservable();
  }
  setselectedmenu(data){
    this.getselectedmenu.emit(data)
  }
  /* Set Selected Asset Data */
  setCurrentUserInfo(value: any) {
    this.currentUserInfi$.next(value);
  }

  /* Get Selected Asset Data */
  getSelectedAssetData(): Observable<any> {
    return this.selectedAssetData$.asObservable();
  }

  /* Set Selected Asset Data */
  setSelectedAssetData(value: any) {
    this.selectedAssetData$.next(value);
  }
  setSelectedAssetDashboardData(data:any){
    this.selectedAssetDashboardData$.next(data)
  }

  /* Get Selected Asset Data */
  getAssetData(): Observable<any> {
    return this.assetData$.asObservable();
  }

  /* Set Selected Asset Data */
  setAssetData(value: any) {
    this.assetData$.next(value);
  }

  sendupdatedgraphdata(data) {
    this.updatedgraphdata.emit(data);
  }

  /* Get last updated date */
  getLastUpdatedDate(): Observable<any> {
    return this.lastUpdatedDate$.asObservable();
  }

  /* Set last updated */
  setLastUpdatedDate(value: any) {
    this.lastUpdatedDate$.next(value);
  }

  // sendUpdatedMonitorData(data) {
  //   this.getMonitordata.emit(data);
  // }

  setupSocketConnection() {
    //if (!this.socket && environment.mode == "edge") {
      this.socket = io(environment.socket_endpoint);
      this.socket.on('history_room', (data) => {
        if (data) {
          this.historyUpdated.next(data);
        }
      });
    //}
  }
  getHistoryUpdateListener() {
    return this.historyUpdated.asObservable();
  }

  monitorGraphDataUpdate(data) {
    this.monitordata.emit(data);
  }

  sendFilterData(data) {
    this.filterData$.next(data);
  }

}

