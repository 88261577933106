<div class="reportContent">
    <div class="htoolbar">
        <div class="firstrow">
            <div class="headertext"><a>Reports</a></div>
            <div class="toolbar">
                <div class="filter pL10">
                    <app-datetimerangepicker [dates]="selectedDateRanges"
                        (dateChangeEvent)="filterChange($event, 'date')"></app-datetimerangepicker>
                </div>
                <!-- <div class="filter pL10">
                    <input type="text" placeholder="Search"/>
                </div> -->
                 <div class="filter pL10" *ngIf="assetData.length>0">
                    <app-dropdown [values]="assetData" [selected]="selectedAssetData" [displayMember]="'asset'"
                    (newSelectionEvent)="filterChange($event, 'asset')" type="object">
                    </app-dropdown>
                </div>
                <div class="filter pL10" >
                    <button class="btn btn-primary"><i class="fa fa-download"></i>DOWNLOAD</button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-fc p-20">
        <table  class="datatable ">
            <tr class="header">
                <th *ngFor="let head of datatable.header">
                    {{head.displayValue}}
                </th>
            </tr>
            <ng-container *ngIf="datatable.data && datatable.data.length>0">
                <tr *ngFor="let data of datatable.data">
                    <td *ngFor="let head of datatable.header">
                        {{data[head.propertyValue]}}
                    </td>
                </tr>
            </ng-container>
            
        </table>
    </div>
</div>
