import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilService } from 'src/app/services/util.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-modal-popups',
  templateUrl: './modal-popups.component.html',
  styleUrls: ['./modal-popups.component.scss'],
})
export class ModalPopupsComponent implements OnInit {
  lastMaintenance: any;
  @ViewChild('vm', { static: false }) vm: ElementRef<HTMLElement>;
  @ViewChild('check', { static: false }) check: ElementRef<HTMLElement>;
  @ViewChild('pm', { static: false }) pm: ElementRef<HTMLElement>;
  headerList = [
    { columnName: 'Activities', columnId: 'activityName' },
    { columnName: 'Frequency', columnId: 'frequency' },
    { columnName: 'Last Maintenance', columnId: 'maintenanceDate' },
    { columnName: 'Next Maintenance', columnId: 'nextMaintenance' },
  ];
  checklist: any;
  equipmentList: any = [];
  pathArray: any[] = [];
  equipmentPath: any = '';
  @Input() screen: string;
  predictiveList: any[];
  activeField: any = {};
  @Input('values')
  set in(val: any) {
    if (val.type == 'equipment') {
      this.lastMaintenance = val.data;
      this.getEquipments();
      setTimeout(() => {
        let el: HTMLElement = this.vm.nativeElement;
        el.click();
      }, 10);
    } else if (val.type == 'checklist') {
      this.checklist = val.data;
      setTimeout(() => {
        let el: HTMLElement = this.check.nativeElement;
        el.click();
      }, 10);
    } else if (val.type == 'predictive') {
      this.predictiveList = val.data;
      this.predictiveList.forEach((c, index) => {
        this.activeField[index] = true;
      });
      setTimeout(() => {
        let el: HTMLElement = this.pm.nativeElement;
        el.click();
      }, 10);
    }
  }
  getEquipments() {
    let params = {
      category: 'EQUIPMENTS',
      conditions: [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.lastMaintenance.assetcode,
        },
        {
          key: 'agency',
          symbol: '==',
          value: this.lastMaintenance.agency,
        },
      ],
    };
    this.fs.getData(params).subscribe((data: any) => {
      if (data.status == 'success') {
        this.equipmentList = data.data;
        this.pathArray.unshift(this.lastMaintenance.equipmentName);
        if (this.lastMaintenance.parentId != this.lastMaintenance.assetcode) {
          this.findParentPath(this.lastMaintenance.parentId);
        } else {
          this.makePathString();
        }
      }
    });
  }
  findParentPath(parentId) {
    let parent = this.equipmentList.filter((e) => {
      return parentId == e.equipmentId;
    });
    this.pathArray.unshift(parent[0].equipmentName);
    if (parent[0].parentId == parent[0].assetcode) {
      this.makePathString();
    } else {
      this.findParentPath(parent[0].parentId);
    }
  }
  makePathString() {
    this.pathArray.forEach((s, index) => {
      let seperator = index != this.pathArray.length - 1 ? ' > ' : '';
      this.equipmentPath += `${s}${seperator}`;
    });
  }
  tpeOf(value) {
    return typeof value;
  }
  comments;
  constructor(
    private modalService: NgbModal,
    private fs: FirestoreService,
    private util: UtilService
  ) {}
  ngOnInit(): void {}
  formatDate(date,type?:any) {
    if (date && date != 'null') {
      if (typeof date != 'object') {
        if(Number(date) != NaN){
          date = type && type=='dateTime'?moment(Number(date)).format('DD-MMM-YYYY, hh:mm:ss a')
          :moment(Number(date)).format('DD-MMM-YYYY');
        }else{
          date = type && type=='dateTime'?moment(date).format('DD-MMM-YYYY, hh:mm:ss a') :moment(date).format('DD-MMM-YYYY');
        }
      } else {
        date = type && type=='dateTime'?
        moment(date._seconds * 1000).format('DD-MMM-YYYY, hh:mm:ss a'):moment(date._seconds * 1000).format('DD-MMM-YYYY');
      }
    }
    return date ? date : 'NA';
  }
  open(content) {
    let modalOptions = { size: 'lg' };
    this.modalService.open(content, modalOptions).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  addComents() {
    let collectionName;
    let collectionName2;
    // console.log(this.lastMaintenance);
    if (this.screen && this.screen == 'equipments') {
      collectionName = 'EQUIPMENTS';
      collectionName2 = 'MAINTENANCE';
    } else if (this.screen && this.screen == 'maintenance') {
      collectionName = 'MAINTENANCE';
      collectionName2 = 'EQUIPMENTS';
    }
    if (this.comments && this.comments != '') {
      let id = this.lastMaintenance.id;
      delete this.lastMaintenance.id;
      this.lastMaintenance.comments = this.lastMaintenance.comments
        ? this.lastMaintenance.comments
        : [];
      let obj = {
        comment: this.comments,
        timestamp: moment().toDate(),
        commentsBy: localStorage.getItem('email'),
      };
      this.lastMaintenance.comments.push(obj);
      this.fs
        .fireStoreDocUpdate(collectionName, id, this.lastMaintenance)
        .subscribe((response: any) => {
          if (response.status == 'success') {
            this.saveMaintenance(collectionName2);
          } else {
            this.util.showSnakbarMessage(
              'error',
              'Comments Added Failed.',
              'end',
              'top'
            );
          }
        });
    }
  }
  saveMaintenance(collection) {
    let params = {
      category: collection,
      conditions: [
        {
          key: 'equipmentId',
          symbol: '==',
          value: this.lastMaintenance.equipmentId,
        },
      ],
    };
    if (collection == 'MAINTENANCE') {
      if (typeof this.lastMaintenance.maintenanceDate == 'string') {
        this.lastMaintenance.maintenanceDate = moment(
          this.lastMaintenance.maintenanceDate
        ).toDate();
      } else {
        this.lastMaintenance.maintenanceDate = moment(
          this.lastMaintenance.maintenanceDate._seconds * 1000
        ).toDate();
      }
      params.conditions.push({
        key: 'maintenanceDate',
        symbol: '==',
        value: this.lastMaintenance.maintenanceDate,
      });
    }
    this.fs.getData(params).subscribe((data: any) => {
      if (data.status == 'success' && data.data.length) {
        let lastMaintenance = data.data[0];
        if (this.comments && this.comments != '') {
          let id = lastMaintenance.id;
          delete lastMaintenance.id;
          lastMaintenance.comments = lastMaintenance.comments
            ? lastMaintenance.comments
            : [];
          let obj = {
            comment: this.comments,
            timestamp: moment().toDate(),
            commentsBy: localStorage.getItem('email'),
          };
          lastMaintenance.comments.push(obj);
          this.fs
            .fireStoreDocUpdate(collection, id, lastMaintenance)
            .subscribe((response: any) => {
              if (response.status == 'success') {
                this.util.showSnakbarMessage(
                  'success',
                  'Comments Added successfully..',
                  'end',
                  'top'
                );
                this.comments = '';
              } else {
                this.util.showSnakbarMessage(
                  'error',
                  'Comments Added Failed.',
                  'end',
                  'top'
                );
              }
            });
        }
      }
    });
  }
  toggleFunc(i: any) {
    this.activeField[i] = !this.activeField[i];
    // this.activeField = i == this.activeField ? null : i
  }

  predictiveMaintenanceDelete(data:any,index:number) {

    Swal.fire({
      title: 'Do you want to delete the maintenance record?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Delete`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        data.maintenanceHistory.splice(index, 1);
        this.fs.fireStoreDocUpdate("PREVENTIVE_MAINTENANCE", data.id,{maintenanceHistory:data.maintenanceHistory}).subscribe((response:any)=>{
          if(response.status == "success"){
            this.util.showSnakbarMessage("success", "Maintenance Record Deleted Successfully!", "end", "top");
          } else {
            this.util.showSnakbarMessage("error", response.message, "end", "top");
          }
        }, (err) => {
          console.error(err);
          this.util.showSnakbarMessage("error", "Maintenance Record Deletion failed", "end", "top");
        });
      } else if (result.isDenied) {
      }
    })
  }

}
