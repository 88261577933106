<div class="progressBarData" >
    <div class="title">{{data.name}}</div>
    <div>
        <span class="value">{{data.value}}</span>
        <span class="unit">{{data.unit}}</span>
    </div>
    <!-- <mat-progress-bar mode="determinate" [value]="data.value"></mat-progress-bar> -->
    <div class="meter blue nostripes">
        <span [ngStyle]="{'width':data.value+'%'}" style="width: 33.3%"></span>
      </div>
</div>
