import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotComponent } from './components/forgot/forgot.component';
import { LoginComponent } from './components/login/login.component';
// import { AlertComponent } from './components/page/alert/alert.component';
import { AnalyticsComponent } from './components/page/analytics/analytics.component';
import { AssetGridComponent } from './components/page/asset-grid/asset-grid.component';
import { AssetdetailsComponent } from './components/page/assetdetails/assetdetails.component';
import { DatasetComponent } from './components/page/assetdetails/dataset/dataset.component';
// import { ConfigurationComponent } from './components/page/configuration/configuration.component';
import { MonitorComponent } from './components/page/monitor/monitor.component';
import { OverviewComponent } from './components/page/overview/overview.component';
import { PageComponent } from './components/page/page.component';
import { ReportsComponent } from './components/page/reports/reports.component';
import { AuthGuardGuard } from "./services/auth-guard.guard";
import { AuthGuard } from './services/auth/auth.guard'
import { GenaidashboardComponent } from './components/genaidashboard/genaidashboard.component';
import { ConversationAiComponent } from './components/page/conversation-ai/conversation-ai.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data:{title:"Login"} },
  { path: 'forgot-password', component: ForgotComponent , data:{title:"Forgot Password"}},
  // {path: 'genaidashboard', loadChildren: ()=> import('../app/components/genaidashboard/genaidashboard.module').then(m=> m.GenaidashboardModule)},
  // { path:'genaidashboard',loadChildren:()=>import('../app/components/genai/genai.module').then(m=>m.GenaiModule)},
  // { path:'genaidashboard',loadChildren:()=>import('./../app/components/genaidashboard/genaidashboard.module').then(m=>m.GenaidashboardModule)},

  { path: 'genaidashboard', component: GenaidashboardComponent , data:{title:"Genai"}},
  {
    path: 'page', component: PageComponent, pathMatch: 'prefix',
    canActivate: [AuthGuard],
    children: [
      { path:'',redirectTo:'executive-dashboard',pathMatch: 'full'},
      {
        path: 'executive-dashboard',
        loadChildren: () => import('../app/components/page/executive-dashboard/executive-dashboard.module').then(m => m.ExecutiveDashboardModule)
      },
      { path: 'dashboard', component: AssetGridComponent },
      { path: 'assetgrid', component: AssetGridComponent , data:{title:"Dashboard"}},
      { path: 'assetgrid/assetdetails/:code', component: AssetdetailsComponent, data:{title:"AssetDetails"} },
      { path: 'assetgrid/monitor/:code', component: MonitorComponent, data:{title:"Monitor"} },
      { path: 'assetgrid/dataset/:code', component: DatasetComponent, data:{title:"Live data"} },
      {
        path: 'tracking',
        loadChildren: () => import('./../app/components/page/tracking/tracking.module').then(m => m.TrackingModule)

      },
      {
        path: 'alerts',
        loadChildren: () => import('./../app/components/page/alert/alert.module').then(m => m.AlertModule)

      },

      {
        path: 'configurations',
        loadChildren: () => import('./../app/components/page/configuration/configuration.module').then(m => m.ConfigurationModule)

      },
      {
        path: 'maintenance',
        loadChildren: () => import('./../app/components/page/maintaince/maintaince.module').then(m => m.MaintainceModule)
      },
      {
        path:'reports',
        loadChildren:()=>import('./../app/components/page/ppc/ppc.module').then(m=>m.PpcModule)
      },
      {
        path: 'spectrometer',
        loadChildren: () => import('./../app/components/page/spectrometer/spectrometer.module').then(m => m.SpectrometerModule)
      },
      {
        path: 'genaidashboard',
        loadChildren: () => import('./../app/components/genaidashboard/genaidashboard.module').then(m => m.GenaidashboardModule)
      },
      {
        path: 'conversationai',
        component: ConversationAiComponent
      },
      {
        path: 'time-series',
        loadChildren: () => import('./../app/components/page/time-series/time-series.module').then(m => m.TimeSeriesModule)
      },
       {
        path: 'analytics',
        loadChildren:()=>import('../app/components/page/analytics/analytics.module').then(m=>m.AnalyticsModule)
      },
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
