import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() date: Date;
  @Input() feedDays: number;
  @Input() futureDays: number;
  @Input() disabled: boolean;
  @Output() dateChangeEvent = new EventEmitter<Date>();
  today = moment().startOf('day').toDate();
  selectedDate = moment().startOf('day').toDate();
  dayLimit = moment().subtract(0, 'days').toDate();
  shouldSetDayLimit = false;
  maxDate: Date;
  constructor() {
    this.feedDays = 30;
    this.date = new Date();
    this.disabled = false;
    this.selectedDate = moment(this.date).startOf('day').toDate();
    this.today = moment(this.date).startOf('day').toDate();
    this.dayLimit = new Date();
  }

  ngOnInit() {
    if (!isNaN(this.feedDays) && this.feedDays >= 0) {
      this.dayLimit = moment()
        .subtract(this.feedDays.toString(), 'days')
        .toDate();
      this.shouldSetDayLimit = true;
    }
    if (this.date && this.date.getTime() > 0) {
      this.selectedDate = moment(this.date).startOf('day').toDate();
    }
    if (this.futureDays) {
      this.maxDate = moment()
        .add(this.futureDays, 'days')
        .startOf('day')
        .toDate();
    } else {
      // this.maxDate = moment().startOf('day').toDate();
    }
  }

  dateChangd() {
    this.dateChangeEvent.emit(this.selectedDate);
  }
  ngOnChanges(change: SimpleChanges) {
    // if (change.date.currentValue) {
    //   this.selectedDate = change.date.currentValue;
    //   this.disabled = true;
    // }
    if (change.date) {
      this.selectedDate = change.date.currentValue;
      this.disabled = true;
    }
  }
}
