import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExecutiveDashboardComponent } from './executive-dashboard.component';
import { HistorydashboardComponent } from './historydashboard/historydashboard.component';

const routes: Routes = [
  {
    path:'',component:ExecutiveDashboardComponent},
   { path:'history/:code',component:HistorydashboardComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExecutiveDashboardRoutingModule { }
