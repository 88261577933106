import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilService } from 'src/app/services/util.service';
import Swal from 'sweetalert2';
import { environment } from './../../../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  buildNumber: string ="";
  role: string = "";
  appVersion: string = "";
  buildDesc: string = "";
  username = localStorage.getItem('username');
  passwordForm: FormGroup;
  currentUser:any;
  userFirstLetter:string="A";
  passwordFormValue: boolean = false;
  selectedRouteArray: any = [];
  selectedRoute: any;
  navigateHome: boolean = false;
  constructor(private activatedRoute: ActivatedRoute ,private router: Router, private cookie: CookieService, private auth:AuthService,
    private modalService: NgbModal, public formBuilder: FormBuilder, private util: UtilService,
    private fs: FirestoreService) {
    this.buildNumber = this.padLeadingZeros(environment.build_number,3);
    this.role = localStorage.getItem("role") || "";
    this.appVersion = environment.appVersion;
    this.buildDesc = "Build Number " + this.buildNumber;

    this.passwordForm = this.formBuilder.group({
      oldpassword: [null, [Validators.required, Validators.minLength(8)]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmpassword: [null, [Validators.required]],
    }, { validator: this.pwdConfirming('password', 'confirmpassword') });
  }

  ngOnInit(): void {
    this.selectedRouteArray=this.activatedRoute['_routerState']['snapshot']['url'].split('/');
    this.selectedRoute=this.selectedRouteArray[this.selectedRouteArray.length - 1];
    this.navigateHome = false;
    this.getCurrentUser();
  }

  adminPage() {
    this.router.navigate(["/admin/users"]);
  }
  navigatePage() {
    //this.router.navigateByUrl("page/configurations/"+tab.href);
    this.router.navigate(["page"]);
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    let checkRemember:any = this.cookie.get("jsplloginremember");
    if (checkRemember != "" && JSON.parse(checkRemember)===false) {
      this.cookie.delete("jspluserid");
      this.cookie.delete("jsplpassword");
    } 
    this.auth.logout();
  }

  getCurrentUser() {
    let loggedinUser:any = this.auth.getUserObject();
    let params = {email:loggedinUser.email}
    this.fs.getRoleSettings(params).subscribe((response: any) => {
      let data=response[0].payload.doc.data()
       data['id']=response[0].payload.doc.id
      localStorage.setItem('roleSetting',JSON.stringify(response[0].payload.doc.data().roleSetting))
      if(response) {
        this.currentUser =data
        this.userFirstLetter = this.currentUser.name.charAt(0)
      }
    });
  }

  padLeadingZeros(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  pwdConfirming(key: string, confirmationKey: string) {
    return (group: FormGroup) => {
      const input = group.controls[key];
      const confirmationInput = group.controls[confirmationKey];

      if (!input.value || !confirmationInput.value) return null;
      if (!input.value.match(/\d/)) {
        return input.setErrors({ hasNumber: true });
      }
      else if (!input.value.match(/[A-Z]/)) {
        return input.setErrors({ hasCapitalCase: true });
      }
      else if (!input.value.match(/[a-z]/)) {
        return input.setErrors({ hasLowerCase: true });
      }
      else if (!input.value.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        return input.setErrors({ hasSpecialCharacters: true });
      }

      if (input.value !== confirmationInput.value) {
        return confirmationInput.setErrors({ notEquivalent: true });
      }

    };
  }

  open(content, type?) {
    let modalOptions: any = { size: "lg" , backdrop:'static'}; 
    if (type == "PASSWORD") {
      this.passwordForm.reset();
    }
    this.modalService.open(content, modalOptions).result.then((result) => {
    }, (reason) => {
    });
  }

  editSave(user, type) {
    if (this.currentUser) {
      let json: any = {};
      if (type == "password") {
        json = {
          id: this.currentUser.id,
          email: this.currentUser.email,
          oldpassword: user.value.oldpassword,
          password: user.value.password,
          update: type
        };
    // console.log(this.currentUser)
        //if (this.currentUser.password === json["oldpassword"]) {
        let updateData = { documentId: this.currentUser.id, password: user.value.password, oldpassword: user.value.oldpassword };
        // console.log(updateData)
        this.fs.passwordUpdate(updateData).subscribe((response: any) => {
          if (response.status == "success") {

            this.util.showSnakbarMessage("success", "User password updated successfully!", "end", "top");
          } else {
            this.util.showSnakbarMessage("error", response.message, "end", "top");
          }

        }, (err) => {
          console.error(err);
          this.util.showSnakbarMessage("error", "User password update failed", "end", "top");
        });
        //}
      }
      this.modalService.dismissAll();
    }

  }

  closePopup(){
    if(this.passwordForm.value){
      for(let key in this.passwordForm.value){
        if(this.passwordForm.value[key] != null && this.passwordForm.value[key] != ''){
          this.passwordFormValue = true;
        }
      }
      if(this.passwordFormValue){
        Swal.fire({
          title: 'Do you want to close?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Close',
          denyButtonText: 'Cancel'
        }).then(result => {
          this.util.setLoaderValue(true);
          if(result.isConfirmed){
            this.util.setLoaderValue(false);
            this.modalService.dismissAll();
          }
          else if(result.isDenied){
            this.util.setLoaderValue(false);
          }
        })
      }
      else if(!this.passwordFormValue){
        this.util.setLoaderValue(false);
        this.modalService.dismissAll();
      }
    }
    
  }
genAI(){
  this.selectedRoute = '';
  this.router.navigate(['genaidashboard'])
}
navigateToHome(){
  this.selectedRoute = '';
  this.navigateHome = true;
  this.router.navigate(['page'])
}
}
