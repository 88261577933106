import { Component, OnInit, DoCheck, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UtilService } from 'src/app/services/util.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStartDate } from '@angular/material/datepicker';
@Component({
  selector: 'app-backup-executive-dashboard',
  templateUrl: './backup-executive-dashboard.component.html',
  styleUrls: ['./backup-executive-dashboard.component.scss']
})
export class BackupExecutiveDashboardComponent implements OnInit, DoCheck {
  // currentdate: any;
  // searchContent;
  // productionInMT: any = [];
  // productionInPcs: any = [];
  // rolledQty: any = [];
  // rateOfRolling: any = [];
  // dailyPlanCard: any = [];
  // productionTrend: any = {};
  // currentShiftDetails: any = [];
  // shiftTimings = [
  //   { shift: 'A', timing: [6, 7, 8, 9, 10, 11, 12, 13] },
  //   { shift: 'B', timing: [14, 15, 16, 17, 18, 19, 20, 21] },
  //   { shift: 'C', timing: [22, 23, 24, 1, 2, 3, 4, 5] },
  // ];
  // datapointsForCurrentProductionInMt: any = [];
  // datapointsForCurrentProductionInPcs: any = [];
  // datapointsForRateofRolling: any = [];
  // datapointsForRolledQty: any = [];
  // filteredData: any = {
  //   startDate: moment().startOf('month').toDate(),
  //   endDate: moment().toDate(),
  // };
  // planNumber: any;
  // chargingSheetNo: any;
  // chargingSheetData: any = {
  //   header: [
  //     { label: 'Campaign No', field: 'CPNPL' },
  //     { label: 'Charging NO', field: 'CSHNO' },
  //     { label: 'Planned Order', field: 'PLNUM' },
  //     {label:"Raw Section", field:"MATNR"},
  //     { label: 'Final Section', field: 'PLNBEZ' },
  //     { label: 'Length (Mts)', field: 'ZCUN_LENGTH' },
  //     { label: 'Weight', field: 'WEIGHT_PC' },
  //   ],
  //   data: [],
  // };
  // configPage: any = { itemsPerPage: 7, currentPage: 1 };
  // constructor(
  //   private router: Router,
  //   private service: SubjectService,
  //   private fs: FirestoreService,
  //   private util: UtilService,
  //   private detectRef: ChangeDetectorRef,
  //   private modalService: NgbModal
  // ) {}
  ngDoCheck(): void {
  //   this.detectRef.detectChanges();
  }

  ngOnInit(): void {
  //   // this.getKpiData();
  //   // this.getCurrentShiftData();
  //   // this.getLivedata();
  //   // this.rolledQtyChartData();

  //   this.currentdate = moment().format('MMM-YYYY');
  //   this.currentShiftDetails = [
  //     { name: 'Current Shift', value: 'NA' },
  //     { name: 'Missroll', value: 'NA' },
  //     { name: 'Critical Alerts', value: 'NA' },
  //   ];
  //   this.dailyPlanCard = [];
  //   let productionObj: any = [
  //     { color: 'green', name: 'Rolled Quantity', y: 91.15, description: '91.15 %' },
  //     { color: '#6FA9FF', name: 'Mill Scale', y: 2.97, description: '2.97 %' },
  //     { color: '#F9E091', name: 'Missroll', y: 0.98, description: '0.98 %' },
  //     { color: '#FD927C', name: 'End Crop', y: 4.90, description: '4.90 %' },
  //   ];
  //   this.productionTrend['productionData'] = productionObj;
  }
  // navigateToMapView(route: any) {
  //   this.router.navigate(['page/' + route]);
  //   this.service.setselectedmenu({ index: 1 });
  // }
  // changePage(page: any) {
  //   this.configPage['currentPage'] = page;
  // }
  // getKpiData() {
  //   this.util.setLoaderValue(true);
  //   this.filteredData['type'] = 'shift';
  //   this.filteredData['assetCode'] = 'OPCUARHF2';
  //   let result: any = [];
  //   this.fs
  //     .getKpisforExecutiveDashboard(this.filteredData)
  //     .subscribe((data: any) => {
  //       if (data.size) {
  //         result = data.docs;
  //         result.forEach((doc: any) => {
  //           result.push(doc.data());
  //         });
  //         result = result.filter((doc: any) => {
  //           return (
  //             doc.type == this.filteredData.type &&
  //             doc.assetcode == this.filteredData.assetCode
  //           );
  //         });
  //         this.productionInMTChartData(result);
  //         this.productionInPcsChartData(result);
  //         this.rateOfRollingChartData(result);
  //       } else {
  //         this.util.setLoaderValue(false);
  //       }
  //     });
  // }
  // getCurrentShiftData() {
  //   this.util.setLoaderValue(true);
  //   let shiftParams = {
  //     collection: '/Asset_OEE/JSPL/Delay_Logs',
  //     startDate: moment().startOf('day').toDate(),
  //     endDate: moment().endOf('day').toDate(),
  //   };
  //   let shiftData: any = [];
  //   let groupedData: any = {};
  //   let key: any;
  //   this.fs.getCurrentShiftData(shiftParams).subscribe((result: any) => {
  //     if (result.length) {
  //       result.forEach((doc: any) => {
  //         shiftData.push(doc.payload?.doc?.data());
  //       });
  //       groupedData = _.groupBy(shiftData, 'shift');
  //       this.shiftTimings.forEach((time: any) => {
  //         if (time['timing'].includes(Number(moment().format('H')))) {
  //           key = time.shift;
  //           this.currentShiftDetails[0].value = key;
  //         }
  //       });
  //       let missroll: any = 0;
  //       console.log(groupedData, key);
  //       if (key != 'C' && groupedData[key]) {
  //         groupedData[key].forEach((currentShift: any) => {
  //           missroll += currentShift.misroll;
  //         });
  //       }
  //       let yestCShiftData: any = [];
  //       if (key == 'C') {
  //         let cShiftParams = {
  //           collection: '/Asset_OEE/JSPL/Delay_Logs',
  //           startDate: moment().startOf('day').toDate(),
  //           endDate: moment().endOf('day').toDate(),
  //         };

  //         this.fs.getCurrentShiftData(cShiftParams).subscribe((result: any) => {
  //           if (result.length) {
  //             result.forEach((doc: any) => {
  //               yestCShiftData.push(doc.payload?.doc?.data());
  //             });
  //             yestCShiftData = yestCShiftData.filter((ele: any) => {
  //               return ele.shift == 'C';
  //             });

  //             groupedData[key].forEach((currentShift: any) => {
  //               missroll += currentShift.missroll;
  //             });
  //             yestCShiftData.forEach((yestCdata: any) => {
  //               missroll += yestCdata.misroll;
  //             });
  //           } else {
  //             this.util.setLoaderValue(false);
  //           }
  //         });
  //       }

  //       this.currentShiftDetails[1].value = missroll ? missroll : 0;
  //     } else {
  //       this.shiftTimings.forEach((time: any) => {
  //         if (time['timing'].includes(Number(moment().format('H')))) {
  //           key = time.shift;
  //           this.currentShiftDetails[0].value = key;
  //         }
  //       });
  //       this.util.setLoaderValue(false);
  //     }
  //   });
  //   let alertsParams: any = {
  //     collection: '/Asset_OEE/JSPL/WarnData',
  //     startDate: moment().startOf('day').toDate(),
  //     endDate: moment().endOf('day').toDate(),
  //   };

  //   this.fs.getCurrentShiftData(alertsParams).subscribe((result: any) => {
  //     let alertsData: any = [];
  //     if (result.length) {
  //       result.forEach((doc: any) => {
  //         alertsData.push(doc.payload?.doc?.data());
  //       });
  //       alertsData = alertsData.filter((alert: any) => {
  //         return alert.alertType == 'High';
  //       });
  //       this.currentShiftDetails[2].value = alertsData?.length
  //         ? alertsData?.length
  //         : 0;
  //     } else {
  //       this.util.setLoaderValue(false);
  //     }
  //   });
  //   this.util.setLoaderValue(true);
  // }
  // showHistoryData(event: any) {
  //   this.router.navigate(['page/executive-dashboard/history', event]);
  //   if (event == 'Current Production In MT') {
  //     this.service.setSelectedAssetDashboardData([
  //       this.productionInMT[0]?.yaxis[0],
  //     ]);
  //   } else if (event == 'Current Production In PCs') {
  //     this.service.setSelectedAssetDashboardData([
  //       this.productionInPcs[0]?.yaxis[0],
  //     ]);
  //   } else if (event == 'Rolled Quantity') {
  //     this.service.setSelectedAssetDashboardData([this.rolledQty[0]?.yaxis[0]]);
  //   } else if (event == 'Rate of Rolling') {
  //     this.service.setSelectedAssetDashboardData([
  //       this.rateOfRolling[0]?.yaxis[0],
  //     ]);
  //   }
  // }

  // productionInMTChartData(dataPoints: any) {
  //   this.productionInMT = [];
  //   let groupedData = {};
  //   this.datapointsForCurrentProductionInMt = [];
  //   groupedData = _.groupBy(dataPoints, (d: any) => {
  //     return moment(d.Date?.seconds * 1000).format('DD-MM-YYYY');
  //   });
  //   for (let key in groupedData) {
  //     let obj: any = { x: '', y: '' };
  //     let value: any = 0;
  //     groupedData[key].forEach((dayData: any, index) => {
  //       if (index == 0) {
  //         obj['x'] = dayData?.Date?.seconds * 1000;
  //       }
  //       dayData.KPIS.forEach((kpi: any) => {
  //         if (kpi.name == 'Total_Shift_tons') {
  //           value += kpi.value;
  //         }
  //       });
  //       if (value) {
  //         obj['y'] = Number(value.toFixed(2));
  //       } else {
  //         obj['y'] = 0.0;
  //       }
  //     });
  //     this.datapointsForCurrentProductionInMt.push(obj);
  //   }
  //   let chart: any = {
  //     chartname: '',
  //     type: 'spline',
  //     mb: 60,
  //     mr: 30,
  //     ml: 75,
  //     mt: 10,
  //     label_y: '',
  //     label_x: '',
  //     height: 140,
  //     dateformat: 'day',
  //     unit: 'Ton',
  //     xaxis: [],
  //     yaxis: [],
  //   };
  //   let data = {
  //     color: '#427bff',
  //     marker: { enabled: false },
  //     unit: 'Ton',
  //     name: 'Weight In MT',
  //     data: [],
  //   };
  //   data.data = this.datapointsForCurrentProductionInMt;
  //   chart.chartname = ' Charged Quantity';
  //   chart.label_y = 'MT';
  //   chart.yaxis.push(data);
  //   this.productionInMT.push(chart);
  //   this.util.setLoaderValue(false);
  // }

  // productionInPcsChartData(dataPoints: any) {
  //   this.productionInPcs = [];
  //   let groupedData = {};
  //   this.datapointsForCurrentProductionInPcs = [];
  //   groupedData = _.groupBy(dataPoints, (d: any) => {
  //     return moment(d.Date?.seconds * 1000).format('DD-MM-YYYY');
  //   });
  //   for (let key in groupedData) {
  //     let obj: any = { x: '', y: '' };
  //     let value: any = 0;
  //     groupedData[key].forEach((dayData: any, index) => {
  //       if (index == 0) {
  //         obj['x'] = dayData?.Date?.seconds * 1000;
  //       }
  //       dayData.KPIS.forEach((kpi: any) => {
  //         if (kpi.name == 'Total_Shift_Discharge') {
  //           value += kpi.value;
  //         }
  //       });
  //       if (value) {
  //         obj['y'] = Number(value.toFixed(2));
  //       } else {
  //         obj['y'] = 0.0;
  //       }
  //     });
  //     this.datapointsForCurrentProductionInPcs.push(obj);
  //   }
  //   let chart: any = {
  //     chartname: '',
  //     type: 'spline',
  //     mb: 60,
  //     mr: 30,
  //     ml: 65,
  //     mt: 10,
  //     label_y: '',
  //     label_x: '',
  //     height: 140,
  //     dateformat: 'day',
  //     unit: 'Pcs',
  //     xaxis: [],
  //     yaxis: [],
  //   };
  //   chart.chartname = 'Charged Pieces';
  //   chart.label_y = 'Pcs';
  //   let data = {
  //     color: '#427bff',
  //     marker: { enabled: false },
  //     unit: 'PCs',
  //     name: 'No of PCs',
  //     data: [],
  //   };
  //   data.data = this.datapointsForCurrentProductionInPcs;
  //   chart.yaxis.push(data);
  //   this.productionInPcs.push(chart);
  // }

  // rolledQtyChartData() {
  //   let chart: any = {
  //     chartname: 'Rolled Quantity',
  //     type: 'spline',
  //     mb: 60,
  //     mr: 30,
  //     ml: 65,
  //     mt: 10,
  //     label_y: '',
  //     label_x: '',
  //     height: 140,
  //     dateformat: 'day',
  //     unit: '',
  //     xaxis: [],
  //     yaxis: [],
  //   };
  //   chart.chartname = 'Rolled Quantity';
  //   chart.label_y = 'MT';
  //   let data = {
  //     color: '#427bff',
  //     marker: { enabled: false },
  //     name: 'Rolled Quantity',
  //     data: [
  //       { x: 1664605800000, y: 10 },
  //       { x: 1664692200000, y: 30 },
  //       { x: 1664778600000, y: 20 },
  //       { x: 1664865000000, y: 40 },
  //       { x: 1664951400000, y: 30 },
  //       { x: 1665037800000, y: 90 },
  //       { x: 1665124200000, y: 10 },
  //       { x: 1665210600000, y: 80 },
  //       { x: 1665297000000, y: 50 },
  //       { x: 1665340200000, y: 20 },
  //     ],
  //   };
  //   chart.yaxis.push(data);
  //   this.rolledQty.push(chart);
  // }
  // rateOfRollingChartData(dataPoints: any) {
  //   this.rateOfRolling = [];
  //   let groupedData = {};
  //   this.datapointsForRateofRolling = [];
  //   groupedData = _.groupBy(dataPoints, (d: any) => {
  //     return moment(d.Date?.seconds * 1000).format('DD-MM-YYYY');
  //   });
  //   for (let key in groupedData) {
  //     let obj: any = { x: '', y: '' };
  //     let totalBlooms: any = 0;
  //     let totalHours: any = 0;
  //     let value: any = 0;
  //     groupedData[key].forEach((dayData: any, index) => {
  //       if (index == 0) {
  //         obj['x'] = dayData?.Date?.seconds * 1000;
  //       }
  //       dayData.KPIS.forEach((kpi: any) => {
  //         if (kpi.name == 'Total_Shift_Discharge') {
  //           totalBlooms += kpi.value;
  //         } else if (kpi.name == 'Running_hours') {
  //           totalHours += kpi.value;
  //         }
  //       });
  //       value = totalBlooms / totalHours;
  //       if (value) {
  //         obj['y'] = Number(value.toFixed(2));
  //       } else {
  //         obj['y'] = 0.0;
  //       }
  //     });
  //     this.datapointsForRateofRolling.push(obj);
  //   }
  //   let chart: any = {
  //     chartname: 'Rate of Rolling',
  //     type: 'spline',
  //     mb: 60,
  //     mr: 30,
  //     ml: 65,
  //     mt: 10,
  //     label_y: '',
  //     label_x: '',
  //     height: 140,
  //     dateformat: 'day',
  //     unit: 'Blooms/Hr',
  //     xaxis: [],
  //     yaxis: [],
  //   };
  //   chart.chartname = 'Rate Of Rolling';
  //   chart.label_y = 'Blooms/Hr';
  //   let data = {
  //     color: '#427bff',
  //     marker: { enabled: false },
  //     name: 'Rate Of Rolling',
  //     unit: 'Blooms/Hr',
  //     data: [],
  //   };
  //   data.data = this.datapointsForRateofRolling;
  //   chart.yaxis.push(data);
  //   this.rateOfRolling.push(chart);
  // }
  // getLivedata() {
  //   let params: any = {
  //     assetcode: 'OPCUARHF2',
  //   };
  //   this.chargingSheetNo = [];
  //   this.fs.getLiveData(params).subscribe((result: any) => {
  //     this.chargingSheetNo = result[0].tags.filter((tag: any) => {
  //       return tag.tag == 'MANUAL_PRODUCT_DATA.BISRA_CODE';
  //     });
  //     this.chargingSheetNo[0].value = 4861
  //     if (this.chargingSheetNo.length) {
  //       console.log(this.chargingSheetNo[0].value);
  //       this.getChargingSheetData('live');
  //     }
  //   });
  // }

  // getChargingSheetData(type?: any, data?: any) {
  //   if (type == 'live') {
  //     this.planNumber = '';
  //     let params: any = {
  //       limit: 1,
  //       sheetNo: this.chargingSheetNo[0].value
  //       // sheetNo:4861,
  //     };
  //     this.fs.getChargingSheetData(params).subscribe((result: any) => {
  //       let data: any = [];
  //       if (result.size) {
  //         data = result.docs;
  //         data.forEach((doc: any) => {
  //           this.planNumber = doc.data().CPNPL;
  //         });
  //         if (this.planNumber) {
  //           this.getCampaignData();
  //         }
  //       }
  //     });
  //   } else {
  //     this.chargingSheetData.data = [];
  //     let params: any = {
  //       sheetNo: this.chargingSheetNo[0].value,
  //       planNum: data,
  //     };
  //     this.fs.getChargingSheetData(params).subscribe((result: any) => {
  //       let data: any = [];
  //       if (result.size) {
  //         data = result.docs;
  //         data.forEach((doc: any) => {
  //           this.chargingSheetData['data'].push(doc.data());
  //         });
  //       }
  //     });
  //   }
  // }
  // getCampaignData() {
  //   let totalCampaignCount = 0
  //   this.dailyPlanCard = [];
  //   let params = {
  //     planNo: this.planNumber,
  //   };
  //   this.fs.getCampaignPlanData(params).subscribe((result: any) => {
  //     let data: any = [];
  //     if (result.size) {
  //       this.dailyPlanCard = [];
  //       data = result.docs;
  //       data.forEach((doc: any) => {
  //         if
  //         (true){
  //           totalCampaignCount+=Number(doc.data().PIECES)
  //           this.dailyPlanCard.push(doc.data());
  //         }
  //       });
  //       this.dailyPlanCard = _.orderBy(this.dailyPlanCard, "PLNUM", "asc")
  //       console.log(totalCampaignCount)
  //       this.dailyPlanCard = _.orderBy(
  //         this.dailyPlanCard,
  //         (d: any) => {
  //           return d.timestamp;
  //         },
  //         'desc'
  //       );
  //     }
  //   });
  // }
  // formatTimestamp(timestamp: any) {
  //   return moment(timestamp.seconds * 1000).format('DD-MM-YYYY');
  // }
  // openDialog(content?: any, data?: any) {
  //   console.log(data);
  //   if (data) {
  //     let plNum: any = data.PLNUM;
  //     this.getChargingSheetData('history', plNum);
  //     this.configPage['currentPage'] = 1;
  //     let modalOptions: any = { size: 'xl', centered: true, animation: true };
  //     this.modalService.open(content, modalOptions);
  //   }
  // }
}
