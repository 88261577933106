import { Component, OnInit,Input,OnDestroy } from '@angular/core';
import { result } from 'lodash';
import moment from 'moment';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SubSink } from 'subsink';
import * as statusTags from '../../../../../assets/json/statusTags.json'
@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit,OnDestroy {
  selectedAsset:any
  selectedDateType:any
  subsink=new SubSink();
  selectedDate
  readyCheckListTagsList:any
  lubricantTagsList:any
  selectedKpiTagsList:any
  selectedKpi:any
  readyChecklist: any = {
    header: [{ label: 'Tag', field: 'tag' },{label: 'Description',field: 'description' },{ label: 'Value', field: 'status' },{ label: 'Last Updated Time', field: 'dateTime' }],
    data: [],
  };
  Lubricant: any = {
    header: [{ label: 'Tag', field: 'tag' }, {label: 'Description',field: 'description' },{ label: 'Value', field: 'status' },{ label: 'Last Updated Time', field: 'dateTime' }],
    data: [],
  };
  tableData: any = {
    header: [{ label: 'Tag', field: 'tag' }, {label: 'Description',field: 'description' },{ label: 'Value', field: 'status' },{ label: 'Last Updated Time', field: 'dateTime' }],
    data: [],
  };
  rollsetTableRows= {} as {[key: string]: any};
  rollsetTableData:any=[]
  configPage: any;
  configPage1: any;
  passScheduleData:any=[]
@Input('selectedValues')
set in (val:any){
this.selectedAsset=val.asset
this.selectedDateType=val.date
this.selectedKpi=val['KPI']
if(val.customDates){
this.selectedDate=val.customDates
}
this.getTagsList()
}
  constructor(private fs:FirestoreService) { }
  ngOnDestroy(): void {
this.subsink.unsubscribe()
  }

  ngOnInit(): void {
    this.rollsetTableRows= {
    firstRow:[{label:"Parameters",field:"parameters",rowspan:3},{label:'Stand UR',field:"Stand_Ur",colspan:4},{label:'Stand E',field:"Stand_E",colspan:2},
    {label:'Stand UF',field:"Stand_Uf",colspan:4}],
    secondRow:[{label:"Horizontal",field:"Horizontal",colspan:2},{label:'Vertical',field:"Vertical",colspan:2},{label:'Horizontal',field:"Horizontal",colspan:2},
    {label:'Horizontal',field:"Horizontal",colspan:2}, {label:'Vertical',field:"Vertical",colspan:2}],
    thirdRow:[{label:"Top",field:"Top"},{label:'Bottom',field:"Bottom"},{label:'OS',field:"Os"},
    {label:'DS',field:"Ds"}, {label:'Top',field:"Top"},{label:'Bottom',field:"Bottom"},
    {label:'Top',field:"Top"},{label:'Bottom',field:"Bottom"},{label:'OS',field:"Os"},
    {label:'DS',field:"Ds"},]
  }
  this.rollsetTableData=[{title:"Roll Set ID",key1:[{value:"NA",colspan:4,id:"RS_ID_UR"}],key2:[{value:'NA',colspan:2,id:'RS_ID_E'}],key3:[{value:"NA",colspan:4,id:'RS_ID_UF'}]},

  {title:"Roll Set Type",key1:[{value:"NA",colspan:4,id:"RS_Type_UR"}],key2:[{value:'NA',colspan:2,id:"RS_Type_E"}],key3:[{value:"NA",colspan:4,id:"RS_Type_UF"}]},

  {title:`Diameter in ${'(mm)'} `,key1:[{value:"NA",id:"Diam_UR_H_Top"},{value:"NA",id:"Diam_UR_H_Bot"},{value:"NA",id:"Diam_UR_V_OS"},{value:"NA",id:"Diam_UR_V_DS"}],
  key2:[{value:"NA",id:"Diam_E_Top"},{value:"NA",id:"Diam_E_Bot"}],
  key3:[{value:"NA",id:"Diam_UF_H_Top"},{value:"NA",id:"Diam_UF_H_Bot"},{value:"NA",id:"Diam_UF_V_OS"},{value:"NA",id:"Diam_UF_V_DS"}]},

  {title:`Angle`,key1:[{value:"NA",id:"Angle_UR_H_Top"},{value:"NA",id:"Angle_UR_H_Bot"},{value:"NA",id:"Angle_UR_V_OS"},{value:"NA",id:"Angle_UR_V_DS"}],
  key2:[{value:"NA",id:"Angle_E_H_Top"},{value:"NA",id:"Angle_E_H_Bot"}],
  key3:[{value:"NA",id:"Angle_UF_H_Top"},{value:"NA",id:"Angle_UF_H_Bot"},{value:"NA",id:"Angle_UF_V_OS"},{value:"NA",id:"Angle_UF_V_DS"}]},

  {title:`Shimplate in ${'(mm)'} `,key1:[{value:"NA",id:"Shimplate_UR_Top"},{value:"NA",id:"Shimplate_UR_Bot"},{value:"NA",id:"Shimplate_UR_OS"},{value:"NA",id:"Shimplate_UR_DS"}],
  key2:[{value:"NA",id:"Shimplate_E_Top"},{value:"NA",id:"Shimplate_E_Bot"}],
  key3:[{value:"NA",id:"Shimplate_UF_Top"},{value:"NA",id:"Shimplate_UF_Bot"},{value:"NA",id:"Shimplate_UF_OS"},{value:"NA",id:"Shimplate_UF_DS"}]},
]
  }
  getTagsList(){
    if(this.selectedAsset.asset=='BD' || this.selectedAsset.asset=="BD2"){
      this.readyCheckListTagsList=statusTags['default'][this.selectedAsset.asset]['Process Interlocks']
  this.lubricantTagsList=statusTags['default'][this.selectedAsset.asset]['Drive Parameters']
  this.passScheduleData=statusTags['default'][this.selectedAsset.asset]['pass schedule']
  this.readyCheckListTagsList=this.readyCheckListTagsList.filter((tags:any)=>{
    return tags.tag!=''
  })
  this.lubricantTagsList=this.lubricantTagsList.filter((tags:any)=>{
    return tags.tag!=''
  })
  this.passScheduleData=this.passScheduleData.filter((tags:any)=>{
    return tags.tag!=''
  })
      }
      else if((this.selectedAsset.asset).toLowerCase()=='tandem'){
        if(this.selectedKpi.label=='Pass Schedule'){
        this.passScheduleData=statusTags['default'][(this.selectedAsset.asset).toLowerCase()]['pass schedule']
        this.passScheduleData=this.passScheduleData.filter((tags:any)=>{
          return tags.tag!=''
        })
      }
      if(this.selectedKpi.label!='Pass Schedule'){
        this.selectedKpiTagsList=statusTags['default'][(this.selectedAsset.asset).toLowerCase()][this.selectedKpi.field]
        this.selectedKpiTagsList=this.selectedKpiTagsList.filter((tags:any)=>{
          return tags.tag!=''
        })
      }
      }
      else if(this.selectedAsset.asset=='HOT SAW'){
        this.selectedKpiTagsList=statusTags['default'][this.selectedAsset.asset][this.selectedKpi.field]
        this.selectedKpiTagsList=this.selectedKpiTagsList.filter((tags:any)=>{
          return tags.tag!=''
        })
      }
      else if(this.selectedAsset.asset=='RHF2'){
        this.selectedKpiTagsList=statusTags['default'][this.selectedAsset.asset][this.selectedKpi.field]
        this.selectedKpiTagsList=this.selectedKpiTagsList.filter((tags:any)=>{
          return tags.tag!=''
        })
      }
      this.configPage = { itemsPerPage: 10, currentPage: 1 };
      this.configPage1 = { itemsPerPage: 10, currentPage: 1 };
      this.getLiveData()
  }
  changePage(page:any){
    this.configPage['currentPage']=page
  }
  changePage1(page:any){
    this.configPage1['currentPage']=page
  }
  getLiveData(){
    this.readyChecklist.data=[]
    this.Lubricant.data=[]
let params:any={
  assetcode:this.selectedAsset.assetcode
}
   this.subsink.sink=this.fs.getLiveData(params).subscribe((result:any)=>{
  this.readyChecklist.data=[]
  this.Lubricant.data=[]
  this.tableData.data=[]
  result[0].tags.forEach((tag:any)=>{
    if(this.selectedAsset.asset=='BD' || this.selectedAsset.asset=="BD2"){
for(let filteredTag of this.readyCheckListTagsList){
  if(filteredTag.tag==tag.tag){
    let obj:any={}
        obj['tag']=tag.tag
        obj['dateTime']=moment(tag.servertimestamp.seconds *1000).format("MMM d, YYYY, hh:mm:ss a")
        let unit:any=tag.unit??''
        obj['status']=`${Number(tag.value)} ${unit} `
        this.readyChecklist.data.push({...obj,...filteredTag})

  //   if(Number(tag.value)==1){
  //     let obj:any={}
  //     obj['tag']=tag.tag
  //     obj['status']=1
  //     this.readyChecklist.data.push({...obj,...filteredTag})
  //   }
  //   else if(Number(tag.value)==0){
  //     let obj:any={}
  //  obj['tag']=tag.tag
  //  obj['status']=0
  //  this.readyChecklist.data.push({...obj,...filteredTag})
  //   }
  //   else {
  //     let obj:any={}
  //     obj['tag']=tag.tag
  //     let unit:any=tag.unit??''
  //     obj['status']=`${Number(tag.value)} ${unit} `
  //     this.readyChecklist.data.push({...obj,...filteredTag})
  //   }
  }
  }
  for(let filteredTag of this.lubricantTagsList){
    if(filteredTag.tag==tag.tag){
      let obj:any={}
      obj['tag']=tag.tag
      obj['dateTime']=moment(tag.servertimestamp.seconds *1000).format("MMM d, YYYY, hh:mm:ss a")
      let unit:any=tag.unit??''
      obj['status']=`${Number(tag.value)} ${unit} `
      this.Lubricant.data.push({...obj,...filteredTag})
    //   if(Number(tag.value)==1){
    //     let obj:any={}
    //     obj['tag']=tag.tag
    //     obj['status']=1
    //     this.Lubricant.data.push({...obj,...filteredTag})
    //   }
    //   else if(Number(tag.value)==0){
    //     let obj:any={}
    //  obj['tag']=tag.tag
    //  obj['status']=0
    //  this.Lubricant.data.push({...obj,...filteredTag})
    //   }
    //   else {
    //     let obj:any={}
    //     obj['tag']=tag.tag
    //     let unit:any=tag.unit??''
    //     obj['status']=`${Number(tag.value)} ${unit} `
    //     this.Lubricant.data.push({...obj,...filteredTag})
    //   }
    }
    }
  }
  if(this.selectedAsset.asset=='BD' || this.selectedAsset.asset=="BD2" ||(this.selectedAsset.asset).toLowerCase()=="tandem"){
    for(let filteredTag of this.passScheduleData){
      if(filteredTag.tag==tag.tag){
            filteredTag['value']=tag.value
            filteredTag['unit']=tag.unit
      }
  }
}
if(((this.selectedAsset.asset).toLowerCase()=="tandem" || this.selectedAsset.asset=='HOT SAW' ||this.selectedAsset.asset=='RHF2') && this.selectedKpi.label!='Pass Schedule')
if(this.selectedKpiTagsList && this.selectedKpiTagsList.length){
  for (let filteredTag of this.selectedKpiTagsList){
    if(filteredTag.tag==tag.tag){
      let obj:any={}
          obj['tag']=tag.tag
          obj['dateTime']=moment(tag.servertimestamp.seconds *1000).format("MMM d, YYYY, hh:mm:ss a")
          let unit:any=tag.unit??''
          obj['status']=`${Number(tag.value)} ${unit} `
          this.tableData.data.push({...obj,...filteredTag})
    //   if(Number(tag.value)==1){
    //     let obj:any={}
    //     obj['tag']=tag.tag
    //     obj['status']=1
    //     this.tableData.data.push({...obj,...filteredTag})
    //   }
    //   else if(Number(tag.value)==0){
    //     let obj:any={}
    //  obj['tag']=tag.tag
    //  obj['status']=0
    //  this.tableData.data.push({...obj,...filteredTag})
    //   }
    //   else {
    //     let obj:any={}
    //     obj['tag']=tag.tag
    //     let unit:any=tag.unit??''
    //     obj['status']=`${Number(tag.value)} ${unit} `
    //     this.tableData.data.push({...obj,...filteredTag})
    //   }
    }
  }
}
if (this.selectedAsset.asset.toLowerCase() == 'tandem' && this.selectedKpi.label == 'Roll Set Configuration') {
          for (let rollSets of this.rollsetTableData) {
            for (let keys of Object.keys(rollSets)) {
              if (keys != "title") {
                for (let id of rollSets[keys]) {
                  if (id.id == tag.tag) {
                    id.value = tag.value
                  }
                }
              }
            }
          }
        }
  })

})
  }

}
