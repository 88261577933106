<div style="padding: 10px 0px">
  <div class="row">
    <div class="col-md-12" style="font-size: 14px; margin-bottom: 0px;display: flex; justify-content: space-between;">
      {{ date_frequency }}

    </div>
  </div>
  <hr>
  <ng-container *ngIf="!globalCards.length && !reportTableHead[selectedAssetData.asset]?.data.length && !shiftWiseData.length">
    <div  style="
    height: calc(100vh - 300px);
    padding: 20px;
    font-size: 18px;
    font-family: 'sfpro-r';
    width: -webkit-fill-available;
  ">
   <app-message [message]="'No KPIs Found'"></app-message>
    </div>
  </ng-container>
  <div class="row" style=" margin-left: -9px;row-gap: 8px;">
    <ng-container *ngFor="let data of globalCards">
      <div class="globalcardstyle">
        <app-single-card [cardData]="data"></app-single-card>
      </div>
    </ng-container>
  </div>
  <!-- <div style="cursor:pointer; padding-left: 983px; padding-bottom: 10px;">
    <span  (click)="reportDisplay('table')" [ngClass]="reportSelected=='table'?'cls1':'cls2'">Table </span>
    <span  (click)="reportDisplay('graph')" [ngClass]="reportSelected=='graph'?'cls1':'cls2'"> Graph</span>
  </div> -->
  <div *ngIf = "reportTableHead[selectedAssetData.asset]?.data.length" style="display: flex; justify-content: space-between;align-items: center;">
    <div class="tableHead"><i class="fa fa-circle dot" aria-hidden="true"></i> Hourly Production Data</div>
  </div>

  <div>
    <ng-container  *ngIf="reportSelected=='graph'" >
    <ng-container *ngFor="let data of graphs">
      <ng-container *ngIf="data.title != 'Efficiency'">
        <div class="col-md-12" style="padding: 0px !important;">
          <div class="card " style="margin-top: 10px">
            <div class="cardTitle">
              <div class="row">
                <div class="col-sm-8">{{ data.title }}</div>
                <div style="cursor:pointer;color: #595959; text-align: end; position: relative; left: 200px;">
                  Table <mat-slide-toggle (click)="reportDisplay()" checked="true" color="primary">Graph</mat-slide-toggle>
                </div>
              </div>
            </div>
            <div>
              <ng-container
                *ngIf="data.chart_data && data.chart_data.length > 0"
              >
                <app-linechart [data]="data.chart_data"></app-linechart>
              </ng-container>
              <!-- <ng-container
              *ngIf="!data.chart_data || data.chart_data.length == 0"
            >
              <div class="chartoverlay"></div>
              <div class="chartspinner"></div>
            </ng-container> -->
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="reportSelected=='table'">
  <ng-container  *ngIf = "reportTableHead[selectedAssetData.asset]?.data.length">
    <div class="table-container card bg-color">
      <div style="cursor:pointer;color: #595959; text-align: end;">
        Graph <mat-slide-toggle (click)="reportDisplay()" checked="true" color="primary">Table</mat-slide-toggle>
      </div>
      <table id="alerts">
          <tr class="header">
            <th *ngFor="let head of reportTableHead[selectedAssetData.asset]?.headers">
              {{head.name}}
            </th>
          </tr>
          <ng-container *ngIf = "reportTableHead[selectedAssetData.asset]?.data.length" >
          <ng-container *ngFor="let data of reportTableHead[selectedAssetData.asset]?.data | paginate: { itemsPerPage: 10, currentPage: p };">
            <tr>
              <ng-container *ngFor="let head of reportTableHead[selectedAssetData.asset]?.headers">
                <td>{{data[head.id]}}</td>
              </ng-container>
            </tr>
            </ng-container>
        </ng-container>
      </table>
      <div style="position: absolute; bottom: -220px;" *ngIf="reportTableHead[selectedAssetData.asset]?.data.length && reportTableHead[selectedAssetData.asset]?.data.length>5">
        <app-pagination (changePage)="p = $event"></app-pagination>
      </div>
    </div>
  </ng-container>
  </ng-container>
  </div>
  <!-- <div class="row">
    <app-searchable-dropdown
      [values]="kpiList"
      [displayMember]="'name'"
      [selected]="selectedKpi"
      [maxTags]="2"
      (newSelectionEvent)="changeFilter($event)"
      [isMultiple]="true"
    ></app-searchable-dropdown>
  </div> -->
  <div       *ngIf="shiftWiseData.length">
    <div style="display: flex; justify-content: space-between;align-items: center;margin-top:10px">
      <div class="tableHead"><i class="fa fa-circle dot" aria-hidden="true"></i> Shiftwise Production Data</div>
    </div>
  <div class="table-container card bg-color">
    <table
    *ngIf="selectedAssetData.asset == 'RHF2'"
      id="alerts"
      class="datatable"
    >
      <col />
      <col />
      <col />
      <colgroup span="4"></colgroup>
      <col />
      <tr class="header">
        <th style="min-width: 100px !important" rowspan="2">Timestamp</th>
        <th colspan="4" style="text-align: center">Shift A</th>
        <th colspan="4" style="text-align: center">Shift B</th>
        <th colspan="4" style="text-align: center">Shift C</th>
      </tr>
      <tr class="" style="background-color: #dddddd !important">
        <th scope="col">Charged/ Discharged</th>
        <!-- <th scope="col"></th> -->
        <th scope="col">BFG Consumption</th>
        <th scope="col">HFO Consumption</th>
        <th scope="col">Running Hours</th>
        <th scope="col">Charged/ Discharged</th>
        <!-- <th scope="col">Discharged</th> -->
        <th scope="col">BFG Consumption</th>
        <th scope="col">HFO Consumption</th>
        <th scope="col">Running Hours</th>
        <th scope="col">Charged/ Discharged</th>
        <!-- <th scope="col">Discharged</th> -->
        <th scope="col">BFG Consumption</th>
        <th scope="col">HFO Consumption</th>
        <th scope="col">Running Hours</th>
      </tr>
      <ng-container *ngIf="shiftWiseData.length">
        <ng-container *ngFor="let data of shiftWiseData">
          <tr>
            <td>{{ data?.date }}</td>
            <td>
              {{ data?.A_charged >= 0 ? data?.A_charged : "NA" }}/ {{
                data.A_discharge >= 0 ? data?.A_discharge : "NA"
              }}
            </td>
            <!-- <td>{{ data?.A_discharge ? data?.A_discharge : "NA" }}</td> -->
            <td>{{ data.A_fuel ? data.A_fuel.toFixed(2) + " Nm^3" : "NA" }}</td>
            <td>{{ data.A_hfo ? data.A_hfo.toFixed(2) + " kg" : "NA"}}</td>
            <td>{{ data.A_run_hrs >= 0 ? data.A_run_hrs + " hrs" : "NA" }}</td>
            <td>
              {{ data?.B_charged >= 0 ? data.B_charged : "NA" }}/ {{
                data?.B_discharge >= 0 ? data.B_discharge : "NA"
              }}
            </td>
            <!-- <td>{{ data?.B_discharge ? data.B_discharge : "NA" }}</td> -->
            <td>{{ data.B_fuel ? data.B_fuel.toFixed(2) + " Nm^3" : "NA" }}</td>
            <td>{{ data.B_hfo ? data.B_hfo.toFixed(2) + " kg" : "NA"}}</td>
            <td>{{ data.B_run_hrs >= 0 ? data.B_run_hrs + " hrs" : "NA" }}</td>
            <td>
              {{ data.C_charged >= 0 ? data.C_charged : "NA" }}/ {{
                data.C_discharge >= 0 ? data.C_discharge : "NA"
              }}
            </td>
            <!-- <td>{{ data.C_discharge ? data.C_discharge : "NA" }}</td> -->
            <td>{{ data.C_fuel ? data?.C_fuel.toFixed(2) + " Nm^3" : "NA" }}</td>
            <td>{{ data.C_hfo ? data.C_hfo.toFixed(2) + " kg" : "NA"}}</td>
            <td>{{ data.C_run_hrs >= 0 ? data.C_run_hrs + " hrs" : "NA" }}</td>
          </tr>
        </ng-container>
      </ng-container>
      <tr></tr>
    </table>
  <table
    *ngIf="selectedAssetData.asset != 'RHF2'"
    id="alerts"
    class="datatable"
  >
    <col />
    <col />
    <col />
    <colgroup span="4"></colgroup>
    <col />
    <tr class="header">
      <th style="min-width: 100px" rowspan="2">Timestamp</th>
      <th colspan="3" style="text-align: center">Shift A</th>
      <th colspan="3" style="text-align: center">Shift B</th>
      <th colspan="3" style="text-align: center">Shift C</th>
    </tr>
    <tr class="" style="background-color: #dddddd !important">
      <th scope="col">Total Blooms</th>
      <th scope="col">Avg Rate of Production</th>
      <th scope="col">Running Hours</th>
      <th scope="col">Total Blooms</th>
      <th scope="col">Avg Rate of Production</th>
      <th scope="col">Running Hours</th>
      <th scope="col">Total Blooms</th>
      <th scope="col">Avg Rate of Production</th>
      <th scope="col">Running Hours</th>
    </tr>
    <ng-container *ngIf="shiftWiseData.length">
      <ng-container *ngFor="let data of shiftWiseData">
        <tr>
          <td>{{ data?.date }}</td>
          <td>{{ data?.A_discharge >= 0 ? data.A_discharge : "NA" }}</td>
          <td>
            {{ data?.A_discharge ? (data.A_discharge / 8).toFixed(2) : "NA" }}
          </td>
          <td>{{ data.A_run_hrs > 0 ? data.A_run_hrs + " hrs" : "NA" }}</td>
          <td>{{ data?.B_discharge >= 0 ? data.B_discharge : "NA" }}</td>
          <td>
            {{ data?.B_discharge ? (data.B_discharge / 8).toFixed(2) : "NA" }}
          </td>
          <td>{{ data.B_run_hrs > 0 ? data.B_run_hrs + " hrs" : "NA" }}</td>
          <td>{{ data?.C_discharge >= 0 ? data.C_discharge : "NA" }}</td>
          <td>
            {{ data?.C_discharge ? (data.C_discharge / 8).toFixed(2) : "NA" }}
          </td>
          <td>{{ data.C_run_hrs > 0 ? data.C_run_hrs + " hrs" : "NA" }}</td>
        </tr>
      </ng-container>
    </ng-container>
    <tr></tr>
  </table>
  </div>
</div>

</div>
