import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-image-map',
  templateUrl: './image-map.component.html',
  styleUrls: ['./image-map.component.scss']
})
export class ImageMapComponent implements OnInit {
  @Input() src: string = "";
  _coordinates: any = [];
  @Input() type:string="normal";

  public img;
  public parentSize;

  @Input('coordinates')
  set in(val: any) {
    this._coordinates = val;
  }
  // @Input()  canEdit: boolean=false;

  @Output("onClick") onClick: EventEmitter<ImageMapCoordinate> = new EventEmitter();
  @Output("onDoubleClick") onDoubleClick: EventEmitter<ImageMapCoordinate> = new EventEmitter();
  @ViewChild('element')  element!: ElementRef;
  innerWidth: any;
  innerHeight: any;
  imgPath = this.src;
  imageViwerConfig:any = {
    
      btnClass: 'default', // The CSS class(es) that will apply to the buttons
      zoomFactor: 0.1, // The amount that the scale will be increased by
      containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
      //wheelZoom: true, // If true, the mouse wheel can be used to zoom in
      allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to enter fullscreen mode
      allowKeyboardNavigation: false, // If true, the left / right arrow keys can be used for navigation
      btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
          zoomIn: 'fa fa-plus',
          zoomOut: 'fa fa-minus',
          rotateClockwise: 'fa fa-repeat',
          rotateCounterClockwise: 'fa fa-undo',
          next: 'fa fa-arrow-right',
          prev: 'fa fa-arrow-left',
          fullscreen: 'fa fa-arrows-alt',
      },
      btnShow: {
          zoomIn: true,
          zoomOut: true,
          rotateClockwise: false,
          rotateCounterClockwise: false,
          next: false,
          prev: false
      }
  
  };
  constructor(private ele: ElementRef) {
    
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }


  getCoordinateStyle(coordinate: ImageMapCoordinate): object {
    return {
      top: `${coordinate.y}px`,
      left: `${coordinate.x}px`
    };
  }

  onAreaClick(coordinate?: any) {
    if(this.type=="config" && !coordinate.assetcode) {
      this.onClick.emit(coordinate);
    }
    if(this.type=="normal" && coordinate.assetcode) {
      this.onClick.emit(coordinate);
    }
  }

  doubleClick(e) {
    let x = e.pageX;
    let y = e.pageY
    this.onDoubleClick.emit(e);
  }

  onAreaCreate(x: number, y: number): ImageMapCoordinate {
    const coordinate = new ImageMapCoordinate({
      x,
      y
    });
    return coordinate;
  }

  onAreaEdit(coordinate: ImageMapCoordinate): ImageMapCoordinate {
    return coordinate;
  }

  onResize(event: any) {
    let areaDiv = this.ele.nativeElement.children[0].children[1].children;
  }

  public imgZoom(event, zoom = 1): void {
    const img = event.target.children[0];

    const parentHeight = event.target.offsetHeight;
    const parentWidth = event.target.offsetWidth;
    const posX = event.layerX;
    const posY = event.layerY;

    img.style.width = `${parentWidth * zoom}px`;
    console.log('parentWidth * zoom: ', img.width = parentWidth * zoom);
    img.style.height = `${parentHeight * zoom}px`;
    console.log('parentHeight * zoom: ', img.height = parentHeight * zoom);

    console.log(`\nx: ${posX} | y: ${posY}`);

    console.log('img.width: ', img.width);

    img.style.left = `-${(event.layerX * (zoom - 1))}px`;
    img.style.top = `-${(event.layerY * (zoom - 1))}px`;
  }

  public resetImgZoom(event, zoom = 1): void {
    const img = event.target.children[0];

    const parentHeight = event.target.offsetHeight;
    const parentWidth = event.target.offsetWidth;
    img.style.width = `${parentWidth}px`;
    img.style.height = `${parentHeight}px`;

    event.target.children[0].style.top = `${0}px`;
    event.target.children[0].style.left = `${0}px`;
  }


}


export class ImageMapCoordinate {
  x: number = 0;
  y: number = 0;
  name?: string;

  constructor(init?: Partial<ImageMapCoordinate>) {
    Object.assign(this, init);
  }
}

