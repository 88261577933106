import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackbarSeverity, SnackbarSeverity } from 'mat-snackbar-severity';
import { BehaviorSubject, Observable } from 'rxjs';
import * as configtabs from './../../assets/json/page_tabs.json';
import moment from "moment";
import * as filter from "../../assets/json/commonDropdownValues.json"
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private showLoader$: BehaviorSubject<any> = new BehaviorSubject(false);
 tokenValue$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private snackBarSeverity: MatSnackbarSeverity, private router:Router) { }
  shiftList: any = filter['default']['shift']
  getLoaderValue(): Observable<any> {
    return this.showLoader$.asObservable();
  }

  setLoaderValue(value:any) {
      this.showLoader$.next(value);
  }
  setToken(data:any){
    this.tokenValue$.next(data);
  }
  getToken(){
    return this.tokenValue$.asObservable()
  }

  showSnakbarMessage(severity:any,message:string,horizontal:any,vertical:any) {
    this.snackBarSeverity.open(severity,message, "", {
      duration: 3000,
      //panelClass: ['blue-snackbar'],
      horizontalPosition: horizontal,
      verticalPosition: vertical
    });
  }
  getTimeFormat(min) {
    if(min == 0 || min == '0'){
      return '00:00'
    }else{
      let num = min;
      let h:any = Math.floor(num / 60);
      let m:any = (num % 60)
      let s = Math.floor((num % 3600) % 60);
      // let hDisplay = h > 0 ? h + (h == 1 ? "" : "") : "";
      // let mDisplay = m > 0 ? m + (m == 1 ? "" : "") : "";
      // let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
     h = h<10? `0${h}`:h
     m = m<10? `0${m}`:m
    //  console.log(`${h}:${m}`)
      return `${h}:${m}`;
    }
  }
  navigatePage(tab: any) {
    this.router.navigate(["page/alert", tab.href]);
  }

  getTabs(screen) {
    let roleSetting:any = localStorage.getItem("roleSetting");
    if(roleSetting && roleSetting!="undefined") {
      roleSetting = JSON.parse(roleSetting)
    } else {
      roleSetting = {};
    }
    if(roleSetting.screens && roleSetting.screens.length>0) {
      let filterScreen = roleSetting.screens.filter((screenData)=>{
        return screenData.tabId == screen
      })
      if(filterScreen.length>0)  {
        return filterScreen[0].tabs;
      } else {
        return configtabs["default"][screen];
      }
    } else {
      return configtabs["default"][screen];
    }
  }

  getAccessForAction(screen,tab,action) {
    let roleSetting:any = localStorage.getItem("roleSetting");
    if(roleSetting && roleSetting!="undefined") {
      roleSetting = JSON.parse(roleSetting)
    } else {
      roleSetting = {};
    }
    if(roleSetting.screens && roleSetting.screens.length>0) {
      let filterScreen = roleSetting.screens.filter((screenData)=>{
        return screenData.text == screen
      })
      if(filterScreen.length>0)  {
        let selectedTabs = filterScreen[0].tabs;
        if(selectedTabs) {
          let filterTab = selectedTabs.filter((tabData)=>{
            return tabData.title==tab;
          });
          if(filterTab.length>0) {
            let seletedAction = filterTab[0].action.filter((actionData)=>{
              return actionData.name == action;
            });
            if(seletedAction.length>0) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          let selectedAction = filterScreen[0].action;
          if( selectedAction &&  selectedAction.length>0) {
            return true;
          } else {
            return false;
          }
        }



      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setGlobalSelectedAsset(assetcode:string) {
    localStorage.setItem('globalAssetcode', assetcode);
  }

  getGlobalSelectedAsset(data:any) {
    let selectedAsset = {};
    if(localStorage.getItem('globalAssetcode')) {
      let globalAssetcode = localStorage.getItem('globalAssetcode');
      let filterAsset = data.filter((assetData:any)=>{
        return assetData.assetcode==globalAssetcode
      });
      if(filterAsset.length>0) {
        selectedAsset = filterAsset[0]
      } else {
        selectedAsset = data[0];
      }
    } else {
      selectedAsset = data[0];
    }
    return selectedAsset;
  }

  setGlobalSelectedSection(assetcode:string) {
    localStorage.setItem('globalSection', assetcode);
  }

  getGlobalSelectedSection(data:any) {
    let selectedSection:any;
    if(localStorage.getItem('globalSection')) {
      let globalSection = localStorage.getItem('globalSection');
      let filterSection = data.filter((section:any)=>{
        return section==globalSection
      });
      if(filterSection.length>0) {
        selectedSection = filterSection[0]
      } else {
        selectedSection = data[0];
      }
    } else {
      selectedSection = data[0];
    }
    return selectedSection;
  }
  checkCurrentShift(data) {
    let start = moment().startOf("day").add(data.shiftStart, "hours").valueOf();
    let end = moment().startOf("day").add(data.shiftEnd, "hours").valueOf();
    let current = moment().valueOf();

    if (current >= start && current <= end) {
      return data.shift;
    } else if (data.shift == "Shift B") {
      let start = moment().startOf("day").valueOf();
      let end = moment().startOf("day").add(1, "hours").valueOf();
      if (current >= start && current <= end) {
        return data.shift;
      }
    } else {
      return "not matched";
    }
  }
  disableShifts(selectedDuration) {
    if (selectedDuration == "Today") {
      this.shiftList[0]["disableOption"] = true;
      let f = this.shiftList.filter((s) => {
        return this.checkCurrentShift(s) == s.shift;
      });
      if (f[0].shift == "Shift A") {
        this.shiftList[0]["disableOption"] = false;
        this.shiftList[1]["disableOption"] = true;
        this.shiftList[2]["disableOption"] = true;
      } else if (f[0].shift == "Shift B") {
        this.shiftList[0]["disableOption"] = false;
        this.shiftList[1]["disableOption"] = false;
        this.shiftList[2]["disableOption"] = true;
      } else if (f[0].shift == "Shift C") {
        this.shiftList[0]["disableOption"] = false;
        this.shiftList[1]["disableOption"] = false;
        this.shiftList[2]["disableOption"] = false;
      }
    } else {
      this.shiftList[0]["disableOption"] = false;
      this.shiftList[1]["disableOption"] = false;
      this.shiftList[2]["disableOption"] = false;
    }
    return this.shiftList;
  }
}
