import {Component, OnInit, DoCheck, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import moment from 'moment';
import {FirestoreService} from 'src/app/services/firestore.service';
import {SubjectService} from 'src/app/services/subject.service';
import {UtilService} from 'src/app/services/util.service';
import * as _ from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NUMBER} from 'sequelize/types';
@Component({
  selector: 'app-executive-dashboard',
  templateUrl: './executive-dashboard.component.html',
  styleUrls: ['./executive-dashboard.component.scss'],
})
export class ExecutiveDashboardComponent implements OnInit, DoCheck {
  currentdate: any;
  searchContent;
  productionInMT: any = [];
  productionInPcs: any = [];
  rolledQty: any = [];
  rateOfRolling: any = [];
  dailyPlanCard: any = [];
  currentShiftDetails: any = [];
  shiftTimings = [
    { shift: 'A', timing: [6, 7, 8, 9, 10, 11, 12, 13] },
    { shift: 'B', timing: [14, 15, 16, 17, 18, 19, 20, 21] },
    { shift: 'C', timing: [22, 23, 24, 1, 2, 3, 4, 5] },
  ];
  datapointsForCurrentProductionInMt: any = [];
  datapointsForCurrentProductionInPcs: any = [];
  datapointsForRateofRolling: any = [];
  datapointsForRolledQty: any = [];
  filteredData: any = {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().toDate(),
  };
  planNumber: any;
  planOrder: any;
  chargingSheetNo: any;
  chargingSheetData: any = {
    header: [
      { label: 'Campaign No', field: 'CPNPL' },
      { label: 'Charging NO', field: 'CSHNO' },
      { label: 'Planned Order', field: 'PLNUM' },
      { label: 'Raw Section', field: 'MATNR' },
      { label: 'Grade', field: 'GRADE' },
      { label: 'Heat No', field: 'HEATNO' },
    ],
    data: [],
  };
  configPage: any = { itemsPerPage: 7, currentPage: 1 };
  /***************** new design*****************/
  montlyProductionMTD: any = {};
  weeklyProduction: any = [];
  monthlyProduction: any = [];
  productionTrend: any = {};
  ytdProduction: any = {};
  yesterDayDate: any;
  monthlyTargets: any = [];
  monthlyTargetValue: any = 0;
  weeklyTargets: any = [];
  YearlyTargets: any = [];
  yearlyData: any = [];
  currentlyRunningPlan: any = [];
  powerConsumption: any = {
    yesterday: 'NA',
    mtd: 'NA',
    ytd: 'NA',
    bestEver: 'NA',
  };
  bfgConsumption: any = {
    yesterday: 'NA',
    mtd: 'NA',
    ytd: 'NA',
    bestEver: '1690517.48',
  };
  hfoConsumption: any = {
    yesterday: 'NA',
    mtd: 'NA',
    ytd: 'NA',
    bestEver: 'NA',
  };
  millUtilization: any = {
    yesterday: 'NA',
    mtd: 'NA',
    ytd: 'NA',
    bestEver: '91.46',
  };
  qualityRate: any = {
    yesterday: 'NA',
    mtd: 'NA',
    ytd: 'NA',
    bestEver: '98.8',
  };
  performance: any = {
    yesterday: 'NA',
    mtd: 'NA',
    ytd: 'NA',
    bestEver: '92.0',
  };
  oee: any = { yesterday: 'NA', mtd: 'NA', ytd: 'NA', bestEver: '63.04' };
  curretMonthData: any = {
    strutureAct: '',
    strutureTarget: '',
    struturePercent: '',
    railAct: '',
    railTarget: '',
    railPercent: '',
    totalAct: '',
    totalTarget: '',
    totalPercent: '',
  };
  yesterdayData: any = {
    strutureAct: '',
    strutureTarget: '',
    struturePercent: '',
    railAct: '',
    railTarget: '',
    railPercent: '',
    totalAct: '',
    totalTarget: '',
    totalPercent: '',
  };
  yearlyPrdocutionMTD: any = {};
  currentMonth: any;
  previousMonthData: any;
  PrevmonthlyTargets: any;
  currentYear: any;
  productList: any = [];
  monthwiseProduction_Percent: any = [];
  monthwiseDispatch_Percent: any = [];
  yearly_dispatch: any = {};
  raw_dispatch_data: {};
  dispatch_targets: {};
  weekly_dispatch: any = [];
  monthly_dispatch: any = [];
  constructor(
    private router: Router,
    private service: SubjectService,
    private fs: FirestoreService,
    private util: UtilService,
    private detectRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}
  ngDoCheck(): void {
    this.detectRef.detectChanges();
  }

  ngOnInit(): void {
    this.productList = [
      {
        style: { 'min-height': '518px' },

        products: [
          {
            header: 'Rail Type',
            values: [
              'IRS 52',
              'UIC54',
              'UIC60',
              '60E1 (RHH & normal Rail )',
              '60E2',
              'ZU1-60  Asymmetric Rail',
              '60E1A1 Asymmetric Rail',
            ],
          },
          {
            header: 'Crane Rail',
            values: ['CR80', 'CR100', 'JSPL CR120'],
          },
        ],
        imgSrc: 'assets/images/railmill_rails.jpg',
      },
      // {
      //   style:{'min-height':"430px",},
      //   products: [
      //     {
      //       header: 'Crane Rail',
      //       values: ['CR80', 'CR100', 'JSPL CR120'],
      //     },
      //   ],
      //   imgSrc: 'assets/images/railmill_rails.jpg',
      // },
      {
        style: { 'min-height': '518px' },

        products: [
          {
            header: 'Universal Beam (IPE/NPB/WPB)',
            values: ['350 mm - 900 mm'],
          },
          {
            header: 'HD/HP Section',
            values: ['Beam/Column 250 mm - 350 mm'],
          },
          {
            header: 'W Section American series',
            values: ['200 mm - 800 mm'],
          },
          { header: 'Universal Column', values: ['200 mm - 400 mm'] },
        ],
        imgSrc: 'assets/images/railmill_beams.png',
      },
      {
        style: { 'min-height': '518px' },
        products: [
          {
            header: 'Angle',
            values: ['250 x 250', 'Thickness - 22/23/24/25/28/29/30 /32/35 mm'],
          },
        ],
        imgSrc: 'assets/images/railmill_beams 2.png',
      },
      // {
      //   // style:{'min-height':"260px"},
      //   products: [{header: 'Universal Column', values: ['200 mm - 400 mm']}],
      //   imgSrc: 'assets/images/railmill_beams 3.png',
      // },
      {
        style: { 'min-height': '518px' },

        products: [
          { header: 'Sheet pile', values: ['Sheet pile IV'] },
          {
            header: 'Channel',
            values: ['ISMC 400'],
          },
          { header: 'Long Rail', values: ['260 Meter Long Rail'] },
        ],
        imgSrc: 'assets/images/railmill_sheetPile.png',
      },

      // {
      //   // style:{'min-height':"260px"},

      //   products: [
      //     {
      //       header: 'Channel',
      //       values: ['ISMC 400'],
      //     },
      //   ],

      //   imgSrc: 'assets/images/railmill_channels.jpg',
      // },
      // {
      //   // style:{'min-height':"260px"},

      //   products: [{ header: 'Long Rail', values: ['260 Meter Long Rail'] }],
      //   imgSrc: 'assets/images/Long_Rail.jpg',
      // },
    ];
    this.getChargingdata();
    this.getRailMillTarget();
    this.get_dispatch_targets();
    if (moment().format('DD') == '01') {
      this.currentdate = moment().subtract(1, 'month').format('MMM-YYYY');
    } else {
      this.currentdate = moment().format('MMM-YYYY');
    }

    this.currentShiftDetails = [
      { name: 'Current Shift', value: 'NA' },
      { name: 'Missroll', value: 'NA' },
      { name: 'Critical Alerts', value: 'NA' },
    ];
    this.dailyPlanCard = [];
  }
  navigateToMapView(route: any) {
    this.router.navigate(['page/' + route]);
    this.service.setselectedmenu({ index: 1 });
  }
  changePage(page: any) {
    this.configPage['currentPage'] = page;
  }
  getChargingdata() {
    this.util.setLoaderValue(true);
    this.chargingSheetNo = '';
    this.fs.getChargingSheetNO().subscribe((result: any) => {
      this.chargingSheetNo = result[0].Current_Charging_Sheet;
      if (this.chargingSheetNo) {
        this.getChargingSheetData('live');
      } else {
        this.util.setLoaderValue(false);
      }
    });
  }

  getChargingSheetData(type?: any, data?: any) {
    if (type == 'live') {
      this.planNumber = '';
      let params: any = {
        limit: 1,
        sheetNo: this.chargingSheetNo,
        // sheetNo:4895,
      };
      this.fs.getChargingSheetData(params).subscribe((result: any) => {
        let data: any = [];
        if (result.size) {
          data = result.docs;
          data.forEach((doc: any) => {
            this.planNumber = doc.data().CPNPL;
            this.planOrder = doc.data().PLNUM;
          });
          if (this.planNumber && this.planOrder) {
            this.getCampaignData();
          } else {
            this.util.setLoaderValue(false);
          }
        }
      });
    } else {
      this.chargingSheetData.data = [];
      let params: any = {
        // sheetNo: this.chargingSheetNo[0].value,
        sheetNo: this.chargingSheetNo,
        planNum: data,
        limit: 2,
      };
      this.chargingSheetData['data'] = [];
      this.fs.getChargingSheetData(params).subscribe((result: any) => {
        let data: any = [];
        if (result.size) {
          data = result.docs;
          data.forEach((doc: any) => {
            this.chargingSheetData['data'].push(doc.data());
          });
        } else {
          this.util.setLoaderValue(false);
        }
      });
    }
  }
  getCampaignData() {
    let totalCampaignCount = 0;
    this.dailyPlanCard = [];
    let params = {
      planNo: this.planNumber,
    };
    this.fs.getCampaignPlanData(params).subscribe((result: any) => {
      let data: any = [];
      if (result.size) {
        this.dailyPlanCard = [];
        data = result.docs;
        data.forEach((doc: any) => {
          if (true) {
            totalCampaignCount += Number(doc.data().PIECES);
            this.dailyPlanCard.push(doc.data());
          }
        });
        this.currentlyRunningPlan = this.dailyPlanCard.filter((doc: any) => {
          return doc.PLNUM == this.planOrder;
        });
        this.dailyPlanCard = [
          ...this.currentlyRunningPlan,
          ...this.dailyPlanCard,
        ];
        // this.dailyPlanCard = _.orderBy(this.dailyPlanCard, "PLNUM", "asc")
        // this.dailyPlanCard = _.orderBy(
        //   this.dailyPlanCard,
        //   (d: any) => {
        //     return d.timestamp;
        //   },
        //   'desc'
        // );
        this.util.setLoaderValue(false);
      }
    });
    this.util.setLoaderValue(false);
  }
  formatTimestamp(timestamp: any) {
    return moment(timestamp.seconds * 1000).format('DD-MM-YYYY');
  }
  openDialog(content?: any, data?: any) {
    if (data) {
      let plNum: any = data.PLNUM;
      // console.log(plNum);
      this.getChargingSheetData('history', plNum);
      this.configPage['currentPage'] = 1;
      let modalOptions: any = { size: 'xl', centered: true, animation: true };
      this.modalService.open(content, modalOptions);
    }
  }
  getRailMillTarget() {
    if (moment().format('MMM') == 'Apr' || moment().format('MMM') == 'May') {
      let prevResultData: any = [];
      let params: any = {
        frequency: '',
        year: '',
      };
      params['frequency'] = 'Monthly';
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
      let currentYear: any = params['year'];
      this.fs.getRailMillTarget(params).subscribe((res: any) => {
        let data = res.docs;
        data.forEach((doc: any) => {
          prevResultData.push(doc.data());
        });
        this.PrevmonthlyTargets = prevResultData[0][currentYear];
      });
    }

    let resultData: any = [];
    this.monthlyTargets = [];
    let params: any = {
      frequency: '',
      year: '',
    };
    params['frequency'] = 'Monthly';
    if (
      moment().format('MMM') == 'Jan' ||
      moment().format('MMM') == 'Feb' ||
      moment().format('MMM') == 'Mar'
    ) {
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
      this.currentYear = `${moment()
        .subtract(1, 'year')
        .format('YY')}-${moment().format('YY')}`;
    } else if (
      moment().format('MMM') == 'Apr' &&
      moment().format('DD') == '01'
    ) {
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
      this.currentYear = `${moment()
        .subtract(1, 'year')
        .format('YY')}-${moment().format('YY')}`;
    } else {
      params['year'] = `${moment().format('YYYY')}-${moment()
        .add(1, 'year')
        .format('YYYY')}`;
      this.currentYear = `${moment().format('YY')}-${moment()
        .add(1, 'year')
        .format('YY')}`;
    }
    let currentYear: any = params['year'];

    this.fs.getRailMillTarget(params).subscribe((res: any) => {
      let data = res.docs;
      data.forEach((doc: any) => {
        resultData.push(doc.data());
      });
      this.monthlyTargets = resultData[0][currentYear];
      if (this.PrevmonthlyTargets && this.PrevmonthlyTargets.length) {
        this.monthlyTargets = [
          ...this.PrevmonthlyTargets,
          ...this.monthlyTargets,
        ];
      } else {
        this.monthlyTargets = this.monthlyTargets;
      }

      this.getProductionData();
      if (moment().format('MMM') == 'Apr' || moment().format('MMM') == 'May') {
        this.currentMonth = moment().format('MMM');
        this.getPreviousMonthProductionData();
      }
    });
  }
  getPreviousMonthProductionData() {
    this.previousMonthData = [];
    let params: any = {
      startDate: '',
      endDate: '',
    };
    if (this.currentMonth == 'Apr') {
      params['startDate'] = moment('02', 'MM').startOf('month').toDate();
      params['endDate'] = moment('03', 'MM').endOf('month').toDate();
    } else if (this.currentMonth == 'May') {
      params['startDate'] = moment('03', 'MM').startOf('month').toDate();
      params['endDate'] = moment('03', 'MM').endOf('month').toDate();
    }
    this.fs.getProductionData(params).subscribe((data: any) => {
      if (data && data?.length) {
        let result: any = [];
        result = data;
        result = _.groupBy(result, (d: any) => {
          return moment(d.Date.seconds * 1000).format("MMM'YY");
        });
        this.previousMonthData = result;
      }
    });
  }
  getProductionData() {
    this.yearlyData = [];
    let params: any = {
      startDate: '',
      endDate: '',
    };

    if (
      moment().format('MMM') == 'Jan' ||
      moment().format('MMM') == 'Feb' ||
      moment().format('MMM') == 'Mar'
    ) {
      params['startDate'] = moment('04', 'MM').subtract(1, 'year').toDate();
    } else if (
      moment().format('MMM') == 'Apr' &&
      moment().format('DD') == '01'
    ) {
      params['startDate'] = moment('04', 'MM').subtract(1, 'year').toDate();
    } else {
      params['startDate'] = moment('04', 'MM').toDate();
    }
    params['endDate'] = moment().toDate();
    // params['startDate']=moment(params['endDate']).subtract(12,'months').startOf('month').toDate()
    this.fs.getProductionData(params).subscribe((data: any) => {
      if (data && data?.length) {
        let result: any = [];
        result = data;
        let bestPowerConsumption: any = 0;
        let bestBfgConsumption: any = 0;
        bestPowerConsumption = _.orderBy(result, 'Power_consumption', 'asc');
        bestBfgConsumption = _.orderBy(result, 'BFG_consumption', 'asc');
        result = _.groupBy(result, (d: any) => {
          return moment(d.Date.seconds * 1000).format("MMM'YY");
        });
        this.yearlyData = result;
        setTimeout(() => {
          this.mergeData();
        }, 100);
      }
    });
  }
  mergeData() {
    if (this.previousMonthData) {
      this.yearlyData = { ...this.yearlyData, ...this.previousMonthData };
    } else {
      this.yearlyData = this.yearlyData;
    }
    this.prepareMonthlyChartData(this.yearlyData);
    this.prepareWeeklyChartData(this.yearlyData);
    this.prepareYearlyData(this.yearlyData);
    this.prepareMTDData(this.yearlyData);
  }
  prepareWeeklyChartData(dataPoints?: any) {
    let chart: any = {
      chartname: '',
      type: 'line',
      mb: 40,
      mr: 30,
      ml: 75,
      mt: 10,
      label_y: '',
      styledMode: true,
      label_x: '',
      height: 170,
      tooltipenabled: true,
      unit: 'MT',
      xaxis: ['W1', 'W2', 'W3', 'W4'],
      tooltip: {
        enabled: true,
        time: {
          useUTC: false,
        },
        xDateFormat: '%d-%m-%y',
        headerFormat:
          '<span style="font-size:14px; font-weight:bolder">' +
          '<br/> </span>' +
          "<span style='font-size:14px;'>on {point.key} {point.date}</span><table>",
        pointFormat:
          '<div>{point.date}</div>' +
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          'MT' +
          '</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
        backgroundColor: '#eeeeee',
        borderColor: '#eeeeee',
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            borderRadius: 30,
            style: {
              fontWeight: 'bold',
            },
            allowOverlap: true,
            y: -10,
            distance: 10,
            shape: 'callout',
            //   formatter: function ():any {
            //     if (!this["isFirst"]) {
            //     return (this['y']/1000).toFixed(1)+ 'k';
            //     }
            // },
            StyleSheet: {
              fontSize: '20px',
            },
          },
        },
      },
      yaxis: [
        {
          color: '#2a7cf6',
          marker: { enabled: true },
          unit: 'Ton',
          name: 'Actual',
          data: [
            {
              y: 0,
              dataLabels: {
                className: 'actual',
                enabled: false,
                allowOverlap: true,
              },
            },
            {
              y: 0,
              dataLabels: {
                className: 'actual',
                enabled: false,
                allowOverlap: true,
              },
            },
            {
              y: 0,
              dataLabels: {
                className: 'actual',
                enabled: false,
                allowOverlap: true,
              },
            },
            {
              y: 0,
              dataLabels: {
                className: 'actual',
                enabled: false,
                allowOverlap: true,
              },
            },
          ],
        },
        {
          color: '#FFAD8A',
          marker: { enabled: true },
          unit: 'Ton',
          name: 'Target',
          // data:[]
          data: [
            { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
            { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
            { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
            { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
          ],
        },
      ],
    };
    let params: any = {
      frequency: 'Weekly',
      year: '',
    };
    if (
      moment().format('MMM') == 'Jan' ||
      moment().format('MMM') == 'Feb' ||
      moment().format('MMM') == 'Mar'
    ) {
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
    } else if (
      moment().format('MMM') == 'Apr' &&
      moment().format('DD') == '01'
    ) {
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
    } else {
      params['year'] = `${moment().format('YYYY')}-${moment()
        .add(1, 'year')
        .format('YYYY')}`;
    }
    this.fs.getRailMillTarget(params).subscribe((res: any) => {
      let resultData: any = [];
      this.weeklyTargets = [];
      let data = res.docs;
      data.forEach((doc: any) => {
        resultData.push(doc.data());
      });
      this.weeklyTargets = resultData;
      setTimeout(() => {
        this.prepareMonth_and_DayData(this.yearlyData);
        this.preparekpiData(this.yearlyData);
      }, 10);
      // let weekWiseData:any=_.chunk(dataPoints[moment().format('MMM')],7)
      let weekWiseData: any = [];
      let Day1: boolean = true;
      let week1: any = [];
      let week2: any = [];
      let week3: any = [];
      let week4: any = [];
      let month: any;
      let weeklyDurationDates: any = [];
      if (moment().format('DD') == '01') {
        month = moment().subtract(1, 'month').format('MMM YYYY');
      } else {
        month = moment().format('MMM YYYY');
      }
      let keys: any = [
        moment().subtract(2, 'month').format("MMM'YY"),
        moment().subtract(1, 'month').format("MMM'YY"),
        moment().format("MMM'YY"),
        moment().add(1, 'month').format("MMM'YY"),
      ];
      for (let key of keys) {
        if (dataPoints[key]) {
          dataPoints[key].forEach((data: any, index: any) => {
            // if(moment(data.Date.seconds *1000).format("ddd")=='Thu' || Number(moment(data.Date.seconds *1000).format("D"))==Number(moment().subtract(2,'day').format("D"))){
            //   if(week1.length==7 || Number(moment(data.Date.seconds *1000).format("D"))==Number(moment().subtract(2,'day').format("D"))){
            //     Day1=true
            //     if(Number(moment(data.Date.seconds *1000).format("D"))==Number(moment().subtract(2,'day').format("D"))){
            //       week1.push(data)
            //     }
            //   }
            //   else{
            //     console.log(week1)
            //   }
            //   if(week1?.length && Day1){
            //     weekWiseData.push(week1)
            //   }
            //   week1=[]
            //   week1.push(data)
            // }
            // else{
            //   if(index==0){
            //      Day1=false
            //   }
            //   week1.push(data)
            // }

            //  weeklyDurationDates[0]=[`${moment(this.weeklyTargets[0][month][0].startDate.seconds *1000).format("DD-MMM")}-${moment(this.weeklyTargets[0][month][0].endDate.seconds *1000).format('DD-MMM')}`]
            //  weeklyDurationDates[1]=[`${moment(this.weeklyTargets[0][month][1].startDate.seconds *1000).format("DD-MMM")}-${moment(this.weeklyTargets[0][month][1].endDate.seconds *1000).format('DD-MMM')}`]
            //  weeklyDurationDates[2]=[`${moment(this.weeklyTargets[0][month][2].startDate.seconds *1000).format("DD-MMM")}-${moment(this.weeklyTargets[0][month][2].endDate.seconds *1000).format('DD-MMM')}`]
            //  weeklyDurationDates[3]=[`${moment(this.weeklyTargets[0][month][3].startDate.seconds *1000).format("DD-MMM")}-${moment(this.weeklyTargets[0][month][3].endDate.seconds *1000).format('DD-MMM')}`]
            // console.log(weeklyDurationDates)
            let DateObj: any;
            if (
              this.weeklyTargets[0][month][0].startDate &&
              this.weeklyTargets[0][month][0].endDate
            ) {
              DateObj = `${moment(
                this.weeklyTargets[0][month][0].startDate.seconds * 1000
              ).format('DD-MMM')} to ${moment(
                this.weeklyTargets[0][month][0].endDate.seconds * 1000
              ).format('DD-MMM')}`;
              weeklyDurationDates.push(DateObj);
              if (
                moment(data.Date.seconds * 1000).valueOf() >=
                  moment(
                    this.weeklyTargets[0][month][0].startDate.seconds * 1000
                  ).valueOf() &&
                moment(data.Date.seconds * 1000).valueOf() <=
                  moment(this.weeklyTargets[0][month][0].endDate.seconds * 1000)
                    .endOf('day')
                    .valueOf()
              ) {
                week1.push(data);
              }
            } else {
              DateObj = `${moment(
                this.weeklyTargets[0][month][0].startDate.seconds * 1000
              ).format('DD-MMM')} to ${moment(
                this.weeklyTargets[0][month][0].endDate.seconds * 1000
              ).format('DD-MMM')}`;
              weeklyDurationDates.push(DateObj);
              if (
                Number(moment(data.Date.seconds * 1000).format('D')) >= 1 &&
                Number(moment(data.Date.seconds * 1000).format('D')) <= 7 &&
                key == moment().format('MMM')
              ) {
                week1.push(data);
              } else {
                week1.push({ Production: 0 });
              }
            }

            if (
              this.weeklyTargets[0][month][1].startDate &&
              this.weeklyTargets[0][month][1].endDate
            ) {
              DateObj = `${moment(
                this.weeklyTargets[0][month][1].startDate.seconds * 1000
              ).format('DD-MMM')} to ${moment(
                this.weeklyTargets[0][month][1].endDate.seconds * 1000
              ).format('DD-MMM')}`;
              weeklyDurationDates.push(DateObj);
              if (
                moment(data.Date.seconds * 1000).valueOf() >=
                  moment(
                    this.weeklyTargets[0][month][1].startDate.seconds * 1000
                  ).valueOf() &&
                moment(data.Date.seconds * 1000).valueOf() <=
                  moment(this.weeklyTargets[0][month][1].endDate.seconds * 1000)
                    .endOf('day')
                    .valueOf()
              ) {
                week2.push(data);
              }
            } else {
              if (
                Number(moment(data.Date.seconds * 1000).format('D')) > 7 &&
                Number(moment(data.Date.seconds * 1000).format('D')) <= 14 &&
                key == moment().format('MMM')
              ) {
                week2.push(data);
              } else {
                week2.push({ Production: 0 });
              }
            }

            if (
              this.weeklyTargets[0][month][2].startDate &&
              this.weeklyTargets[0][month][2].endDate
            ) {
              DateObj = `${moment(
                this.weeklyTargets[0][month][2].startDate.seconds * 1000
              ).format('DD-MMM')} to ${moment(
                this.weeklyTargets[0][month][2].endDate.seconds * 1000
              ).format('DD-MMM')}`;
              weeklyDurationDates.push(DateObj);
              if (
                moment(data.Date.seconds * 1000).valueOf() >=
                  moment(
                    this.weeklyTargets[0][month][2].startDate.seconds * 1000
                  ).valueOf() &&
                moment(data.Date.seconds * 1000).valueOf() <=
                  moment(this.weeklyTargets[0][month][2].endDate.seconds * 1000)
                    .endOf('day')
                    .valueOf()
              ) {
                week3.push(data);
              }
            } else {
              if (
                Number(moment(data.Date.seconds * 1000).format('D')) > 14 &&
                Number(moment(data.Date.seconds * 1000).format('D')) <= 21 &&
                key == moment().format('MMM')
              ) {
                week3.push(data);
              } else {
                week3.push({ Production: 0 });
              }
            }

            if (
              this.weeklyTargets[0][month][3].startDate &&
              this.weeklyTargets[0][month][3].endDate
            ) {
              DateObj = `${moment(
                this.weeklyTargets[0][month][3].startDate.seconds * 1000
              ).format('DD-MMM')} to ${moment(
                this.weeklyTargets[0][month][3].endDate.seconds * 1000
              ).format('DD-MMM')}`;
              weeklyDurationDates.push(DateObj);
              if (
                moment(data.Date.seconds * 1000).valueOf() >=
                  moment(
                    this.weeklyTargets[0][month][3].startDate.seconds * 1000
                  ).valueOf() &&
                moment(data.Date.seconds * 1000).valueOf() <=
                  moment(this.weeklyTargets[0][month][3].endDate.seconds * 1000)
                    .endOf('day')
                    .valueOf()
              ) {
                week4.push(data);
              }
            } else {
              if (
                Number(moment(data.Date.seconds * 1000).format('D')) > 21 &&
                Number(moment(data.Date.seconds * 1000).format('D')) <= 31 &&
                key == moment().format('MMM')
              ) {
                week4.push(data);
              } else {
                week4.push({ Production: 0 });
              }
            }
          });
        }
      }

      weeklyDurationDates = weeklyDurationDates.slice(0, 4);
      weekWiseData.push(week1, week2, week3, week4);
      weekWiseData.forEach((weeKData: any, index: any) => {
        let date: any;
        let weekobj: any = {};
        // date=`${moment(weeKData[0].Date.seconds*1000).format('DD-MM')}-${moment(weeKData[weeKData.length-1].Date.seconds*1000).format('DD-MM')}`
        let cumulativeProduction = weeKData.reduce((init: any, obj: any) => {
          return init + Number(obj.Production) / 1000;
        }, 0);
        if (cumulativeProduction == 0) {
          weekobj['y'] = Number(Number(cumulativeProduction).toFixed(0));
          weekobj['date'] = weeklyDurationDates[index];
          weekobj['dataLabels'] = {
            className: 'actual',
            enabled: false,
            allowOverlap: true,
            x: 5,
            y: 5,
          };
        } else {
          weekobj['y'] = Number(Number(cumulativeProduction).toFixed(0));
          weekobj['date'] = weeklyDurationDates[index];
          weekobj['dataLabels'] = {
            className: 'actual',
            enabled: true,
            allowOverlap: true,
            x: 5,
            y: 5,
          };
        }

        chart.yaxis[0].data[index] = weekobj;
      });

      if (resultData.length && resultData[0][month]) {
        resultData[0][month].forEach((obj: any, i: any) => {
          let weekTarget = {};
          weekTarget['y'] = obj.target;
          weekTarget['dataLabels'] = {
            className: 'target',
            allowOverlap: true,
          };
          chart.yaxis[1].data[i] = weekTarget;
        });
      }
      //  console.log('no data');
      else chart.chartname = ' ';
      chart.label_y = 'MT';
      this.weeklyProduction.push(chart);
      this.util.setLoaderValue(false);
    });
  }
  prepareMonth_and_DayData(data: any) {
    let productionVal: any = 0;
    let productionpercent: any = 0;
    let millScaleVal: any = 0;
    let millScalepercent: any = 0;
    let endCropVal: any = 0;
    let endCropPercent: any = 0;
    let missrollVal: any = 0;
    let missrollPercent: any = 0;
    let railActual: any = 0;
    let strutureActual: any = 0;
    this.yesterDayDate = moment().subtract(1, 'day').format('DD-MMM');
    if (data[moment().format("MMM'YY")]) {
      data = data[moment().format("MMM'YY")];
      productionVal += data[data?.length - 1].Production / 1000;
      railActual += data[data?.length - 1].rail / 1000;
      strutureActual += data[data?.length - 1].structure / 1000;
      productionpercent += data[data?.length - 1].Production_percent;
      millScaleVal += data[data?.length - 1].Mill_Scale / 1000;
      millScalepercent += data[data?.length - 1].Mill_Scale_percent;
      endCropVal += data[data?.length - 1].End_Crop / 1000;
      endCropPercent += data[data?.length - 1].End_Crop_percent;
      missrollVal += data[data?.length - 1].Miss_Roll / 1000;
      missrollPercent += data[data?.length - 1].Miss_Roll_percent;
      this.yesterDayDate = moment(
        data[data?.length - 1].Date.seconds * 1000
      ).format('DD-MMM');
    } else {
      let previousMonthData: any = [];
      previousMonthData = data[moment().subtract(1, 'month').format("MMM'YY")];
      productionVal +=
        previousMonthData[previousMonthData?.length - 1].Production / 1000;
      railActual +=
        previousMonthData[previousMonthData?.length - 1].rail / 1000;
      strutureActual +=
        previousMonthData[previousMonthData?.length - 1].structure / 1000;
      productionpercent +=
        previousMonthData[previousMonthData?.length - 1].Production_percent;
      millScaleVal +=
        previousMonthData[previousMonthData?.length - 1].Mill_Scale / 1000;
      millScalepercent +=
        previousMonthData[previousMonthData?.length - 1].Mill_Scale_percent;
      endCropVal +=
        previousMonthData[previousMonthData?.length - 1].End_Crop / 1000;
      endCropPercent +=
        previousMonthData[previousMonthData?.length - 1].End_Crop_percent;
      missrollVal +=
        previousMonthData[previousMonthData?.length - 1].Miss_Roll / 1000;
      missrollPercent +=
        previousMonthData[previousMonthData?.length - 1].Miss_Roll_percent;
      this.yesterDayDate = moment(
        previousMonthData[previousMonthData?.length - 1].Date.seconds * 1000
      ).format('DD-MMM');
    }

    let currentWeek: any = moment().week();
    currentWeek = `Week ${currentWeek}`;
    let Targetvalue: any = 0;
    let railTarget: any = 0;
    let structureTarget: any = 0;
    this.monthlyTargets.forEach((monthTarget: any) => {
      if (monthTarget.month == moment().format('MMM YYYY')) {
        Targetvalue = monthTarget.target;
        railTarget = monthTarget.rail_target;
        structureTarget = monthTarget.structure_target;
      }
    });
    let daysInMonth = moment().daysInMonth();
    // this.weeklyTargets[0][moment().format("MMM YYYY")].forEach((week:any)=>{
    //   if(week.week==currentWeek){
    //     Targetvalue=week.target
    //   }
    // })
    Targetvalue = (Number(Targetvalue) / daysInMonth).toFixed(0);
    railTarget = (Number(railTarget) / daysInMonth).toFixed(0);
    structureTarget = (Number(structureTarget) / daysInMonth).toFixed();
    this.yesterdayData['totalAct'] = productionVal.toFixed(0);
    this.yesterdayData['totalTarget'] = Targetvalue;
    this.yesterdayData['totalPercent'] = (
      Number(productionVal / Targetvalue) * 100
    ).toFixed(2);
    this.yesterdayData['railAct'] = Number(railActual.toFixed(0));
    this.yesterdayData['railTarget'] = railTarget;
    this.yesterdayData['railPercent'] = (
      Number(railActual / railTarget) * 100
    ).toFixed(2);
    this.yesterdayData['strutureAct'] = Number(strutureActual.toFixed(0));
    this.yesterdayData['strutureTarget'] = structureTarget;
    this.yesterdayData['struturePercent'] = (
      Number(strutureActual / structureTarget) * 100
    ).toFixed(2);
  }
  prepareMTDData(data: any) {
    if (moment().format('DD') == '01') {
      data = data[moment().subtract(1, 'month').format("MMM'YY")];
    } else {
      data = data[moment().format("MMM'YY")];
    }
    this.montlyProductionMTD = {
      title: 'MTD',
      id: 'montlyProductionMTD',
      color: '#5599FF',
      up: true,
      productionValue: 0,
      diff_value: 2,
      height: '85%',
      Value: '',
      mtPerDay: 0,
      mtPerDay_current: 0,
      difference: 0,
    };
    let productionVal: any = 0;
    let productionpercent: any = 0;
    let Targetvalue: any = 0;
    let value: any = 0;
    let RequiredMT: any = 0;
    let currentMT: any = 0;
    let balance: any = 0;
    let millScaleVal: any = 0;
    let millScalepercent: any = 0;
    let endCropVal: any = 0;
    let endCropPercent: any = 0;
    let missrollVal: any = 0;
    let missrollPercent: any = 0;
    let railTarget: any = 0;
    let structureTarget: any = 0;
    let railActual: any = 0;
    let strutureActual: any = 0;
    let prod_with_scraps:any=0
    this.monthlyTargetValue = '';
    let CurrentMonth: any;
    if (moment().format('DD') == '01') {
      CurrentMonth = moment().subtract(1, 'month').format('MMM YYYY');
    } else {
      CurrentMonth = moment().format('MMM YYYY');
    }

    this.monthlyTargets.forEach((month: any) => {
      if (month.month == CurrentMonth) {
        Targetvalue = month.target;
        railTarget = month.rail_target;
        structureTarget = month.structure_target;
        this.monthlyTargetValue = month.target;
      }
    });
    let diff: any = moment(CurrentMonth, 'MMM YYYY')
      .endOf('month')
      .diff(moment().subtract(1, 'day'), 'days');
    let days_completed = Math.abs(
      moment(CurrentMonth, 'MMM YYYY').startOf('month').diff(moment(), 'days')
    );
    if (!data?.length) {
      this.montlyProductionMTD['mtPerDay'] = Number(
        (Targetvalue / diff).toFixed(0)
      );
    }
    let productionObj: any = [
      {
        color: '#2a7cf6',
        name: 'Rolled Quantity',
        y: '',
        description: '',
        value: '',
      },
      {
        color: '#FFAD8A',
        name: 'Mill Scale',
        y: '',
        description: '',
        value: '',
      },
      { color: '#aaf2a7', name: 'End Crop', y: '', description: '', value: '' },
      {
        color: '#ed8326',
        name: 'Misroll',
        y: 4.9,
        description: '4.90 %',
        value: 300,
      },
    ];
    this.productionTrend['productionData'] = [];
    this.productionTrend['title'] = 'MTD Production';
    data.forEach((doc: any) => {
     prod_with_scraps += doc.Yield ? doc.Yield / 1000 : 0;
      productionVal += doc.Production ? doc.Production / 1000 : 0;
      railActual += doc.rail ? doc.rail / 1000 : 0;
      strutureActual += doc.structure ? doc.structure / 1000 : 0;
      productionpercent += isNaN(doc.Production_percent)
        ? 0
        : doc.Production_percent;
      millScaleVal += doc.Mill_Scale ? doc.Mill_Scale / 1000 : 0;
      millScalepercent += isNaN(doc.Mill_Scale_percent)
        ? 0
        : doc.Mill_Scale_percent;
      endCropVal += doc.End_Crop ? doc.End_Crop / 1000 : 0;
      endCropPercent += isNaN(doc.End_Crop_percent) ? 0 : doc.End_Crop_percent;
      missrollVal += doc.Miss_Roll ? doc.Miss_Roll / 1000 : 0;
      missrollPercent += isNaN(doc.Miss_Roll_percent)
        ? 0
        : doc.Miss_Roll_percent;

    });
    productionObj[0]['y'] = productionVal && prod_with_scraps
      ? Number(Number((productionVal / prod_with_scraps) * 100).toFixed(2))
      : 0;
    productionObj[1]['y'] = millScaleVal && prod_with_scraps
      ? Number(Number((millScaleVal / prod_with_scraps) * 100).toFixed(2))
      : 0;
    productionObj[2]['y'] = endCropVal && prod_with_scraps
      ? Number(Number((endCropVal / prod_with_scraps) * 100).toFixed(2))
      : 0;
    productionObj[3]['y'] =missrollVal && prod_with_scraps
      ? Number(Number((missrollVal / prod_with_scraps) * 100).toFixed(2))
      : 0;

    productionObj[0]['description'] = productionpercent
      ? `${productionObj[0]['y']} %`
      : 0;
    productionObj[1]['description'] = millScalepercent
      ? `${productionObj[1]['y']} %`
      : 0;
    productionObj[2]['description'] = endCropPercent
      ? `${productionObj[2]['y']} %`
      : 0;
    productionObj[3]['description'] = missrollPercent
      ? `${productionObj[3]['y']} %`
      : 0;

    productionObj[0]['value'] = productionVal
      ? Number(productionVal).toFixed(2)
      : 0;
    productionObj[1]['value'] = millScaleVal
      ? Number(millScaleVal).toFixed(2)
      : 0;
    productionObj[2]['value'] = endCropVal ? Number(endCropVal).toFixed(2) : 0;
    productionObj[3]['value'] = missrollVal
      ? Number(missrollVal).toFixed(2)
      : 0;
    productionVal = productionVal.toFixed(0);

    let daysInMonth: any = moment().daysInMonth();
    let CurrentdayCount: any = moment().format('D');
    let actual_tonne_to_be_done: any;
    value = (productionVal / Targetvalue) * 100;
    balance = Targetvalue - productionVal;
    actual_tonne_to_be_done = Targetvalue / daysInMonth;
    if (diff == 0) {
      RequiredMT = balance;
    } else {
      RequiredMT = balance / diff;
    }
    if (days_completed > 0) {
      currentMT = productionVal / days_completed;
      actual_tonne_to_be_done = actual_tonne_to_be_done * days_completed;
    } else {
      currentMT = productionVal;
    }

    // Targetvalue=Number(Number((Targetvalue/daysInMonth)*CurrentdayCount).toFixed(2))
    // railTarget=Number(Number((railTarget/daysInMonth)*CurrentdayCount).toFixed(2))
    // structureTarget=Number(Number((structureTarget/daysInMonth)*CurrentdayCount).toFixed(2))
    this.montlyProductionMTD['productionValue'] = productionVal;
    this.curretMonthData['strutureAct'] = Number(strutureActual.toFixed(0));
    this.curretMonthData['strutureTarget'] = structureTarget;
    this.curretMonthData['struturePercent'] = (
      Number(strutureActual / structureTarget) * 100
    ).toFixed(2);
    this.curretMonthData['railAct'] = Number(railActual.toFixed(0));
    this.curretMonthData['railPercent'] = (
      Number(railActual / railTarget) * 100
    ).toFixed(2);
    this.curretMonthData['railTarget'] = railTarget;
    this.curretMonthData['totalAct'] = productionVal;
    this.curretMonthData['totalTarget'] = Targetvalue;
    this.curretMonthData['totalPercent'] = (
      Number(productionVal / Targetvalue) * 100
    ).toFixed(2);
    if (isNaN(RequiredMT)) {
      this.montlyProductionMTD['mtPerDay'] = 0;
    } else if (RequiredMT > 0) {
      this.montlyProductionMTD['mtPerDay'] = RequiredMT.toFixed(0);
    } else {
      this.montlyProductionMTD['mtPerDay'] = 0;
    }
    this.montlyProductionMTD['mtPerDay_current'] = currentMT
      ? currentMT.toFixed(0)
      : 0;
    this.montlyProductionMTD['difference'] = Number(
      (Number(actual_tonne_to_be_done) - Number(productionVal)).toFixed(0)
    );
    this.montlyProductionMTD['actual_tonne_to_be_done'] =
      actual_tonne_to_be_done;
    if (isNaN(value)) {
      this.montlyProductionMTD['Value'] = 0;
    } else {
      this.montlyProductionMTD['Value'] = value.toFixed(2);
    }
    this.productionTrend['productionData'] = productionObj;
  }

  prepareMonthlyChartData(dataPoints?: any) {
    let chart: any = {
      chartname: '',
      type: 'column',
      mb: 40,
      mr: 30,
      ml: 75,
      mt: 10,
      label_y: '',
      styledMode: true,
      label_x: '',
      height: 170,
      tooltipenabled: true,
      unit: 'MT',
      xaxis: [],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            borderRadius: 30,
            x: 5,
            y: -10,
            shape: 'callout',
            //   formatter: function ():any {
            //     if (!this["isFirst"]) {
            //     return (this['y']/1000).toFixed(1)+ 'k';
            //     }
            // }
          },
        },
      },
      yaxis: [
        {
          color: '#FFAD8A',
          marker: { enabled: false },
          unit: 'Ton',
          name: 'Target',
          data: [],
        },
        {
          color: '#2a7cf6',
          marker: { enabled: false },
          unit: 'Ton',
          name: 'Actual',
          data: [],
        },
      ],
    };
    chart.chartname = ' ';
    this.monthwiseProduction_Percent = [];
    let targets_series: any = [];
    chart.label_y = 'MT';
    for (let i = 2; i >= 0; i--) {
      let production: any = 0;
      let monthObj: any = {};
      chart.xaxis.push(moment().subtract(i, 'months').format("MMM'YY"));
      if (dataPoints[moment().subtract(i, 'months').format("MMM'YY")]) {
        dataPoints[moment().subtract(i, 'months').format("MMM'YY")].forEach(
          (monthData: any) => {
            production += monthData.Production / 1000;
            monthObj['y'] = Number(Number(production).toFixed(0));
          }
        );
        monthObj['dataLabels'] = {
          className: 'actual',
          allowOverlap: true,
          x: 5,
          y: -10,
          format: `{point.y} `,
        };
        chart.yaxis[1].data.push(monthObj);
      } else {
        monthObj['y'] = 0;
        monthObj['dataLabels'] = {
          className: 'actual',
          allowOverlap: true,
          x: 5,
          y: -10,
          format: `{point.y} `,
        };
        chart.yaxis[1].data.push(monthObj);
      }
      let targetObj: any = {};
      let current_month_traget = this.monthlyTargets.filter((monthTarget) => {
        return (
          monthTarget.month == moment().subtract(i, 'months').format('MMM YYYY')
        );
      });
      if (current_month_traget && current_month_traget.length) {
        targetObj['y'] = current_month_traget[0].target
          ? Number(Number(current_month_traget[0].target).toFixed(2))
          : 0;
        targetObj['dataLabels'] = {
          className: 'target',
          allowOverlap: true,
          x: 5,
          y: 10,
        };
        targetObj['month'] = current_month_traget[0].month;
        targets_series.push(targetObj);
      } else {
        targetObj['y'] = 0;
        targetObj['dataLabels'] = {
          className: 'target',
          allowOverlap: true,
          x: 5,
          y: 10,
        };
        targets_series.push(targetObj);
      }
      // this.monthlyTargets.forEach((monthTarget: any) => {
      //   if (
      //     monthTarget.month == moment().subtract(i, 'months').format('MMM YYYY')
      //   ) {
      //     targetObj['y'] = monthTarget.target
      //       ? Number(Number(monthTarget.target).toFixed(2))
      //       : 0;
      //     targetObj['dataLabels'] = {
      //       className: 'target',
      //       allowOverlap: true,
      //       x: 5,
      //       y: 10,
      //     };
      //     targetObj['month'] = monthTarget.month;
      //     targets_series.push(targetObj)

      //   }
      // })
      let obj: any = { month: '', value: '' };
      obj['month'] = moment().subtract(i, 'months').format("MMM'YY");
      obj['value'] = monthObj['y']
        ? `${Number((monthObj['y'] / targetObj['y']) * 100).toFixed(2)}%`
        : 0;
      this.monthwiseProduction_Percent.push(obj);
    }
    chart.yaxis[0].data = targets_series;
    //  let targets:any=`${Number(((chart.yaxis[1].data[0].y/chart.yaxis[0].data[0].y))*100).toFixed(2)}%`
    //  chart.yaxis[1].data[0].dataLabels['format']=`{point.y}(${(targets)})`
    //  let targets1:any=`${Number(((chart.yaxis[1].data[1].y/chart.yaxis[0].data[1].y))*100).toFixed(2)}%`
    //  chart.yaxis[1].data[1].dataLabels['format']=`{point.y}(${(targets1)})`
    //  let target2:any=`${Number(((chart.yaxis[1].data[2].y/chart.yaxis[0].data[2].y))*100).toFixed(2)}%`
    //  chart.yaxis[1].data[2].dataLabels['format']=`{point.y}(${(target2)})`
    this.monthlyProduction.push(chart);
    this.util.setLoaderValue(false);
  }
  prepareYearlyData(dataPoints?: any) {
    this.yearlyPrdocutionMTD = {
      title: 'yTD',
      name: 'Actual Production Percentage',
      plotcolor: '#2a7cf6',
      type: 'solidgauge',
      chartSize: '105%',
      up: true,
      mt: -20,
      productionValue: 0,
      innerRadius: '35%',
      outerRadius: '65%',
      radius: '65%',
      diff_value: 2,
      startAngle: -170,
      endAngle: 170,
      dataLabels: {
        step: 2,
        style: {
          color: '#595959',
          fontWeight: '500',
          marginBottom: 100,
          fontSize: 13,
        },
      },
      height: '220px',
      Value: '',
      mtPerDay: '',
    };
    // let ytdProductionData: any = [
    //   { color: '#eb9b34', name: 'Target', y: 100, description: '',value:900000,productionValue:Number},
    //   { color: '#72a7f7', name: 'Actual', y: 800000, description: '',value:330000 },
    // ];
    // this.ytdProduction['title']='YTD Production'

    let yearlyProductionVal: any = 0;
      let total_weight = 0;
    let keys: any = Object.keys(dataPoints);
    if (moment().format('MMM') == 'Apr') {
      keys = [moment('04', 'MM').format("MMM'YY")];
    } else if (moment().format('MMM') == 'May') {
      keys = [
        moment('04', 'MM').format("MMM'YY"),
        moment('05', 'MM').format("MMM'YY"),
      ];
    } else {
      keys = Object.keys(dataPoints);
    }
    //  keys = Object.keys(dataPoints);

    for (let key of keys) {
      if (dataPoints[key] && dataPoints[key].length) {
         dataPoints[key].forEach((doc) => {
           yearlyProductionVal += doc.rail_section.reduce((init, doc) => {
             return init + Number(doc.yield_mt);
           }, 0);
           yearlyProductionVal += doc.structure_section.reduce((init, doc) => {
             return init + Number(doc.yield_mt);
           }, 0);
         });
        // yearlyProductionVal += dataPoints[key].reduce((init: any, doc: any) => {
        //   return init + Number(doc.Production) / 1000;
        // }, 0);
      }
    }

    let params: any = {
      frequency: 'Yearly',
      year: ``,
    };
    if (
      moment().format('MMM') == 'Jan' ||
      moment().format('MMM') == 'Feb' ||
      moment().format('MMM') == 'Mar'
    ) {
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
    } else if (
      moment().format('MMM') == 'Apr' &&
      moment().format('DD') == '01'
    ) {
      params['year'] = `${moment()
        .subtract(1, 'year')
        .format('YYYY')}-${moment().format('YYYY')}`;
    } else {
      params['year'] = `${moment().format('YYYY')}-${moment()
        .add(1, 'year')
        .format('YYYY')}`;
    }

    this.fs.getRailMillTarget(params).subscribe((res: any) => {
      let resultData: any = [];
      this.YearlyTargets = [];
      let data = res.docs;
      data.forEach((doc: any) => {
        resultData.push(doc.data());
      });
      this.YearlyTargets = resultData;
      // ytdProductionData[0].y=this.YearlyTargets[0]?.target
      let TargetValue = this.YearlyTargets[0]?.target.toFixed(0);
      TargetValue = Number(TargetValue);
      this.yearlyPrdocutionMTD['targetValue'] = TargetValue;
      this.yearlyPrdocutionMTD['Value'] = Number(
        (Number(yearlyProductionVal / TargetValue) * 100).toFixed(2)
      );
      this.yearlyPrdocutionMTD['productionValue'] = Number(
        Number(yearlyProductionVal.toFixed(0))
      );
      // ytdProductionData[1].y=Number(yearlyProductionVal.toFixed(2))
      //   ytdProductionData[1].value=(yearlyProductionVal.toFixed(2))
      //   ytdProductionData[1].y=Number((((Number(ytdProductionData[1].value))/(Number(ytdProductionData[0].value)))*100).toFixed(2))
      //   ytdProductionData[0].y=Number(Number(100-ytdProductionData[1].y).toFixed(2))
      //  ytdProductionData[0].description=`${ytdProductionData[0].y} %`
      //  ytdProductionData[1].description=`${ytdProductionData[1].y} %`
      //   ytdProductionData[0].productionValue=(((ytdProductionData[1].value)/ytdProductionData[0].value)*100).toFixed(2)
    });
  }
  preparekpiData(data: any) {
    let monthKeys: any = [];
    let dayPowerConsumption: any = 0;
    let monthPowerConsumption: any = 0;
    let yearPowerConsumption: any = 0;
    let dayBfg: any = 0;
    let monthBfg: any = 0;
    let yearBgf: any = 0;
    let dayHfo: any = 0;
    let monthHfo: any = 0;
    let yearHfo: any = 0;
    let dayMill: any = 0;
    let monthMill: any = 0;
    let yearMill: any = 0;
    let dayQual: any = 0;
    let monthQual: any = 0;
    let yearQual: any = 0;
    let dayPerformance: any = 0;
    let monthPerformance: any = 0;
    let yearPerformance: any = 0;
    let dayOee: any = 0;
    let monthOee: any = 0;
    let yearOee: any = 0;
    let currentWeek: any;
    let yearlyTarget: any = 0;
    monthKeys = Object.keys(data);
    // if(moment().format('MMM')=='Apr'){
    //   monthKeys=['Apr']
    // }
    // else if(moment().format('MMM')=='May'){
    //   monthKeys=['Apr','May']
    // }
    // else {
    //   monthKeys=Object.keys(data)
    // }
    let yearDocCount: any = 0;
    let bestMillutilization: any = 0;
    let bestPerformance: any = 0;
    let bestOEE: any = 0;
    let bestBfg: any = 0;
    let bestHfo: any = 0;
    let bestPower: any = 0;
    let bestBfgArr: any = [];
    let bestHfoArr: any = [];
    let bestPowerArr: any = [];
    this.millUtilization['bestEver'] = 0;
    this.performance['bestEver'] = 0;
    let bestPerformanceArr: any = [];
    let bestmillUtilizationArr: any = [];
    let bestQual: any = 0;
    let bestQualArr: any = [];
    let bestOEEArr: any = [];
    let bestBgfRatio: any = 0;
    let bestHfoRatio: any = 0;
    let bestPowerRatio: any = 0;
    for (let key of monthKeys) {
      bestPerformance = 0;
      bestBfg = 0;
      bestHfo = 0;
      bestOEE = 0;
      bestPower = 0;
      bestQual = 0;
      bestMillutilization = 0;
      bestBgfRatio = 0;
      bestPowerRatio = 0;
      bestBgfRatio = 0;
      let monthVal: any = 0;
      let yearMonth: any = 0;
      let monthTarget: any = 0;
      let daysInMonth: any = 0;
      let CurrentdayCount: any = 0;
      if (data[key]) {
        data[key].forEach((dayData: any) => {
          if (dayData.Mill_utilization && dayData.Mill_utilization > 0) {
            bestMillutilization += Number(
              Number(dayData.Mill_utilization).toFixed(2)
            );
          }
          if (dayData.BFG_consumption && dayData.BFG_consumption > 0) {
            bestBfg += Number(Number(dayData.BFG_consumption).toFixed(2));
          }
          if (dayData.FOIL_TON && dayData.FOIL_TON > 0) {
            bestHfo += Number(Number(dayData.FOIL_TON * 1000).toFixed(2));
          }
          if (dayData.Power_consumption && dayData.Power_consumption > 0) {
            bestPower += Number(Number(dayData.Power_consumption).toFixed(2));
          }
          if (dayData.Quality_ratio && dayData.Quality_ratio > 0) {
            bestQual += Number(Number(dayData.Quality_ratio).toFixed(2));
          }
          // if(this.millUtilization['bestEver']<bestMillutilization){
          //   this.millUtilization['bestEver']=bestMillutilization
          // }

          monthVal = moment(dayData.Date.seconds * 1000).format('MMM YYYY');
          yearMonth = moment(dayData.Date.seconds * 1000).format('MMM YY');

          bestPerformance += Number(
            Number(Number(dayData.Production) / 1000).toFixed(2)
          );
        });
        bestMillutilization = Number(bestMillutilization / data[key].length);
        bestQual = Number(bestQual / data[key].length);
        if (key != moment().format("MMM'YY")) {
          bestmillUtilizationArr.push({
            value: bestMillutilization,
            key: key,
            month_year: yearMonth,
          });
          bestQualArr.push({
            value: bestQual,
            key: key,
            month_year: yearMonth,
          });
        }
        // bestBfgArr.push({value:bestBfg,key:key})
        // bestPowerArr.push({value:bestPower,key:key})
        this.millUtilization['bestEver'] = Number(
          Number(
            Math.max(...bestmillUtilizationArr.map((o) => o.value), 0)
          ).toFixed(2)
        );
        this.qualityRate['bestEver'] = Number(
          Number(Math.max(...bestQualArr.map((o) => o.value), 0)).toFixed(2)
        );
        // this.powerConsumption['bestEver']=Number(Number(Math.min(...bestPowerArr.map(o => o.value),0)).toFixed(2))
        // this.bfgConsumption['bestEver']=Number(Number(Math.min(...bestBfgArr.map(o => o.value),0)).toFixed(2))

        if (bestBfg && bestBfg > 0) {
          bestBgfRatio = Number(Number(bestBfg / bestPerformance).toFixed(6));
        }
        if (bestHfo && bestHfo > 0) {
          bestHfoRatio = Number(Number(bestHfo / bestPerformance).toFixed(6));
        }
        if (bestPower && bestPower > 0) {
          bestPowerRatio = Number(
            Number(bestPower / bestPerformance).toFixed(6)
          );
        }
        if (key == moment().format("MMM'YY")) {
          daysInMonth = moment(monthVal, 'MMM YYYY').daysInMonth();
          CurrentdayCount = moment().format('D');
          daysInMonth = Number(CurrentdayCount / daysInMonth);
        } else {
          daysInMonth = 1;
        }
        monthTarget = this.monthlyTargets.filter((monthTarget: any) => {
          return monthTarget.month == monthVal;
        });
        monthTarget = monthTarget[0].target;
        monthTarget = Number((monthTarget * daysInMonth).toFixed(2));
        bestPerformance = Number(
          Number((bestPerformance / monthTarget) * 100).toFixed(2)
        );

        if (bestBfg && bestBfg > 0 && key != moment().format("MMM'YY")) {
          bestBfgArr.push({
            value: bestBfg,
            key: key,
            ratio: bestBgfRatio,
            month_year: yearMonth,
          });
        }
        if (bestHfo && bestHfo > 0 && key != moment().format("MMM'YY")) {
          bestHfoArr.push({
            value: bestHfo,
            key: key,
            ratio: bestHfoRatio,
            month_year: yearMonth,
          });
        }
        if (bestPower && bestPower > 0 && key != moment().format("MMM'YY")) {
          bestPowerArr.push({
            value: bestPower,
            key: key,
            ratio: bestPowerRatio,
            month_year: yearMonth,
          });
        }
        bestOEE = Number(
          (bestMillutilization / 100) * (100 / 100) * (bestQual / 100) * 100
        ).toFixed(2);
        this.oee['bestEver'] = bestOEE;
        let currentDay: any;
        if (Number(moment().format('DD')) == 1) {
          currentDay = moment().subtract(1, 'day').format("MMM'YY");
        } else {
          currentDay = moment().format("MMM'YY");
        }
        if (
          moment(data[key][data[key].length - 1].Date.seconds * 1000).format(
            'DD'
          ) == moment().subtract(1, 'day').format('DD') &&
          currentDay == key
        ) {
          dayPowerConsumption = Number(
            Number(data[key][data[key].length - 1].Power_consumption).toFixed(2)
          );
          dayBfg = Number(
            Number(data[key][data[key].length - 1].BFG_consumption).toFixed(2)
          );
          dayHfo = Number(
            Number(data[key][data[key].length - 1].FOIL_TON * 1000).toFixed(2)
          );
          dayMill = Number(
            Number(data[key][data[key].length - 1].Mill_utilization).toFixed(2)
          );
          dayQual = Number(
            Number(data[key][data[key].length - 1].Quality_ratio).toFixed(2)
          );
          currentWeek = moment().week();
          currentWeek = `Week ${currentWeek}`;
          let DayTargetvalue: any = 0;
          this.monthlyTargets.forEach((monthTarget: any) => {
            if (monthTarget.month == moment().format('MMM YYYY')) {
              DayTargetvalue = monthTarget.target;
            }
          });
          let daysInMonth: any = 0;
          daysInMonth = moment().daysInMonth();
          DayTargetvalue = Number(
            (Number(DayTargetvalue) / daysInMonth).toFixed(2)
          );
          dayPerformance = Number(
            data[key][data[key].length - 1].Production / 1000
          ).toFixed(2);
          dayQual = Number(
            Number(data[key][data[key].length - 1].Quality_ratio).toFixed(2)
          );
          dayOee = Number(
            (dayMill / 100) * (100 / 100) * (dayQual / 100) * 100
          ).toFixed(2);
          this.powerConsumption['yesterday'] = Number(
            (dayPowerConsumption / dayPerformance).toFixed(2)
          );
          this.powerConsumption['yesterday'] =isNaN(this.powerConsumption['yesterday'])?0:(this.powerConsumption['yesterday'])
          this.bfgConsumption['yesterday'] = Number(
            (dayBfg / dayPerformance).toFixed(2)
          );
           this.bfgConsumption['yesterday'] =isNaN(this.bfgConsumption['yesterday'])?0:(this.bfgConsumption['yesterday'])
          this.millUtilization['yesterday'] = dayMill??0;
          this.performance['yesterday'] = dayPerformance??0;
          this.qualityRate['yesterday'] = isNaN(dayQual)?0:dayQual;
          this.oee['yesterday'] = isNaN(dayOee)?0:dayOee;
          this.hfoConsumption['yesterday'] = Number(
            (dayHfo / dayPerformance).toFixed(2)
          );
           this.hfoConsumption['yesterday'] =isNaN(this.hfoConsumption['yesterday'])?0:(this.hfoConsumption['yesterday'])
        }
        if (key != moment().format("MMM'YY")) {
          bestPerformanceArr.push({
            value: bestPerformance,
            key: key,
            month_year: yearMonth,
          });
          bestOEEArr.push({ value: bestOEE, key: key, month_year: yearMonth });
        }
        let month: any = moment().format("MMM'YY");
        if (moment().format('DD') == '01') {
          month = moment().subtract(1, 'month').format("MMM'YY");
        } else {
          month = moment().format("MMM'YY");
        }
        if (key == month) {
          let monthDocCount: any = 0;
          /*********************************** */
          //       dayPowerConsumption=Number(Number(data[key][data[key].length-1].Power_consumption).toFixed(2))
          //       dayBfg=Number(Number(data[key][data[key].length-1].BFG_consumption).toFixed(2))
          //       dayMill=Number(Number(data[key][data[key].length-1].Mill_utilization).toFixed(2))
          //       currentWeek=moment().week()
          //      currentWeek=`Week ${currentWeek}`
          // let DayTargetvalue:any=0
          // this.monthlyTargets.forEach((monthTarget:any)=>{
          //   if(monthTarget.month==moment().format('MMM YYYY')){
          //     DayTargetvalue=monthTarget.target
          //   }
          // })
          // let daysInMonth:any=0
          // daysInMonth=moment().daysInMonth()
          // DayTargetvalue=Number((Number(DayTargetvalue)/daysInMonth).toFixed(2))
          //       dayPerformance=Number((((data[key][data[key].length-1].Production/1000))/DayTargetvalue)*100).toFixed(2)
          //       dayQual=Number(Number(data[key][data[key].length-1].Production_percent).toFixed(2))
          //       dayOee=Number((dayMill/100)*(dayPerformance/100)*(dayQual/100)*100).toFixed(2)
          //       this.powerConsumption['yesterday']=dayPowerConsumption
          //       this.bfgConsumption['yesterday']=dayBfg
          //       this.millUtilization['yesterday']=dayMill
          //       this.performance['yesterday']=dayPerformance
          //       this.qualityRate['yesterday']="NA"
          //       this.oee['yesterday']="NA"
          /****************************** */

          let monthTarget: any = 0;
          this.monthlyTargets.forEach((month: any) => {
            if (month.month == moment().format('MMM YYYY')) {
              monthTarget = Number(month.target);
            }
          });
          let CurrentdayCount: any = 0;
          let numberOfDaysInMonth: any = 0;
          CurrentdayCount = moment().format('D');
          numberOfDaysInMonth = Number(moment().daysInMonth());
          CurrentdayCount = Number(CurrentdayCount);
          monthTarget = Number(
            (monthTarget / numberOfDaysInMonth) * CurrentdayCount
          );
          data[key].forEach((doc: any) => {
            monthDocCount += 1;
            monthPowerConsumption +=
              doc.Power_consumption && doc.Power_consumption >= 0
                ? Number(Number(doc.Power_consumption).toFixed(2))
                : 0;
            monthBfg +=
              doc.BFG_consumption && doc.BFG_consumption >= 0
                ? Number(Number(doc.BFG_consumption).toFixed(2))
                : 0;
            monthHfo +=
              doc.FOIL_TON && doc.FOIL_TON >= 0
                ? Number(Number(doc.FOIL_TON * 1000).toFixed(2))
                : 0;
            monthMill +=
              doc.Mill_utilization && doc.Mill_utilization >= 0
                ? Number(Number(doc.Mill_utilization).toFixed(2))
                : 0;
            monthPerformance +=
              doc.Production && doc.Production >= 0
                ? Number(Number(doc.Production / 1000).toFixed(2))
                : 0.0;
            monthQual +=
              doc.Quality_ratio && doc.Quality_ratio >= 0
                ? Number(Number(doc.Quality_ratio).toFixed(2))
                : 0;
            monthOee += Number(
              (monthMill / 100) *
                (monthPerformance / 100) *
                (monthQual / 100) *
                100
            ).toFixed(2);
          });
          monthPerformance = Number(
            Number(Number(monthPerformance)).toFixed(2)
          );
          monthMill = Number(Number(monthMill / monthDocCount).toFixed(2));
          monthQual = Number(Number(monthQual / monthDocCount).toFixed(2));
          monthOee = Number(
            (monthMill / 100) * (100 / 100) * (monthQual / 100) * 100
          ).toFixed(2);
          this.powerConsumption['mtd'] = Number(
            (monthPowerConsumption / monthPerformance).toFixed(2)
          );
          this.bfgConsumption['mtd'] = Number(
            (monthBfg / monthPerformance).toFixed(2)
          );
          this.hfoConsumption['mtd'] = Number(
            (monthHfo / monthPerformance).toFixed(2)
          );
          this.millUtilization['mtd'] = monthMill;
          this.performance['mtd'] = monthPerformance;
          this.qualityRate['mtd'] = monthQual;
          this.oee['mtd'] = monthOee;
        } else {
          yearlyTarget = this.YearlyTargets[0]?.target;
          let daysInYears: any = 0;
          let startDate = moment('04', 'MM').subtract(1, 'year').toDate();
          let endDate = moment().toDate();
          daysInYears = moment(endDate).diff(moment(startDate), 'days');
          yearlyTarget = Number((yearlyTarget / 365.5) * daysInYears);
          let yearKeys: any = [];
          // if (moment().format('MMM') == 'Apr') {
          //   yearKeys = ['Apr'];
          // } else if (moment().format('MMM') == 'May') {
          //   yearKeys = ['Apr', 'May'];
          // } else {
          //   yearKeys = Object.keys(data);
          // }
          yearKeys = Object.keys(data);
          if (yearKeys.includes(key)) {
            if (
              (moment().month() == 3 && moment().date() == 1) ||
              moment().month() < 3
            ) {
              data[key].forEach((doc: any) => {
                yearDocCount += 1;
                yearPowerConsumption +=
                  doc.Power_consumption && doc.Power_consumption >= 0
                    ? Number(Number(doc.Power_consumption).toFixed(2))
                    : 0;
                yearBgf +=
                  doc.BFG_consumption && doc.BFG_consumption >= 0
                    ? Number(Number(doc.BFG_consumption).toFixed(2))
                    : 0;
                yearHfo +=
                  doc.FOIL_TON && doc.FOIL_TON >= 0
                    ? Number(Number(doc.FOIL_TON * 1000).toFixed(2))
                    : 0;
                yearMill +=
                  doc.Mill_utilization && doc.Mill_utilization >= 0
                    ? Number(Number(doc.Mill_utilization).toFixed(2))
                    : 0;
                yearQual +=
                  doc.Quality_ratio && doc.Quality_ratio >= 0
                    ? Number(Number(doc.Quality_ratio).toFixed(2))
                    : 0;
                yearPerformance +=
                  doc.Production && doc.Production >= 0
                    ? Number(Number(doc.Production / 1000).toFixed(2))
                    : 0.0;
                yearOee += Number(
                  (monthMill / 100) *
                    (monthPerformance / 100) *
                    (monthQual / 100) *
                    100
                ).toFixed(2);
              });
            } else {
              if (moment(key, "MMM'YY").month() >= 3) {
                data[key].forEach((doc: any) => {
                  yearDocCount += 1;
                  yearPowerConsumption +=
                    doc.Power_consumption && doc.Power_consumption >= 0
                      ? Number(Number(doc.Power_consumption).toFixed(2))
                      : 0;
                  yearBgf +=
                    doc.BFG_consumption && doc.BFG_consumption >= 0
                      ? Number(Number(doc.BFG_consumption).toFixed(2))
                      : 0;
                  yearHfo +=
                    doc.FOIL_TON && doc.FOIL_TON >= 0
                      ? Number(Number(doc.FOIL_TON * 1000).toFixed(2))
                      : 0;
                  yearMill +=
                    doc.Mill_utilization && doc.Mill_utilization >= 0
                      ? Number(Number(doc.Mill_utilization).toFixed(2))
                      : 0;
                  yearQual +=
                    doc.Quality_ratio && doc.Quality_ratio >= 0
                      ? Number(Number(doc.Quality_ratio).toFixed(2))
                      : 0;
                  yearPerformance +=
                    doc.Production && doc.Production >= 0
                      ? Number(Number(doc.Production / 1000).toFixed(2))
                      : 0.0;
                  yearOee += Number(
                    (monthMill / 100) *
                      (monthPerformance / 100) *
                      (monthQual / 100) *
                      100
                  ).toFixed(2);
                });
              }
            }
          }
        }
      }
    }
    this.millUtilization['bestEverMonth'] = bestmillUtilizationArr.filter(
      (mill: any) => {
        return (
          Number(Number(mill.value).toFixed(2)) ==
          Number(this.millUtilization['bestEver'])
        );
      }
    );
    this.millUtilization['bestEverMonth'] =
      this.millUtilization['bestEverMonth'][0].month_year;
    this.qualityRate['bestEverMonth'] = bestQualArr.filter((mill: any) => {
      return (
        Number(Number(mill.value).toFixed(2)) ==
        Number(this.qualityRate['bestEver'])
      );
    });
    this.qualityRate['bestEverMonth'] =
      this.qualityRate['bestEverMonth'][0].month_year;

    this.bfgConsumption['bestEver'] = Number(
      Number(
        bestBfgArr[
          bestBfgArr.findIndex(
            (x) => x.ratio == Math.min(...bestBfgArr.map((o) => o.ratio))
          )
        ].ratio
      ).toFixed(2)
    );
    this.hfoConsumption['bestEver'] = Number(
      Number(
        bestHfoArr[
          bestHfoArr.findIndex(
            (x) => x.ratio == Math.min(...bestHfoArr.map((o) => o.ratio))
          )
        ].ratio
      ).toFixed(2)
    );
    this.powerConsumption['bestEver'] = Number(
      Number(
        bestPowerArr[
          bestPowerArr.findIndex(
            (x) => x.ratio == Math.min(...bestPowerArr.map((o) => o.ratio))
          )
        ].ratio
      ).toFixed(2)
    );
    this.performance['bestEver'] = Math.max(
      ...bestPerformanceArr.map((o) => o.value),
      0
    );
    this.oee['bestEver'] = Math.max(...bestOEEArr.map((o) => o.value), 0);
    this.oee['bestEverMonth'] = bestOEEArr.filter((mill: any) => {
      return (
        Number(Number(mill.value).toFixed(2)) == Number(this.oee['bestEver'])
      );
    });
    this.oee['bestEverMonth'] = this.oee['bestEverMonth'][0].month_year;
    this.bfgConsumption['bestEverMonth'] = bestBfgArr.filter((mill: any) => {
      return (
        Number(Number(mill.ratio).toFixed(2)) ==
        Number(Number(this.bfgConsumption['bestEver']).toFixed(2))
      );
    });
    this.hfoConsumption['bestEverMonth'] = bestHfoArr.filter((mill: any) => {
      return (
        Number(Number(mill.ratio).toFixed(2)) ==
        Number(Number(this.hfoConsumption['bestEver']).toFixed(2))
      );
    });
    this.bfgConsumption['bestEverMonth'] =
      this.bfgConsumption['bestEverMonth'][0].month_year;
    this.hfoConsumption['bestEverMonth'] =
      this.hfoConsumption['bestEverMonth'][0].month_year;
    this.powerConsumption['bestEverMonth'] = bestPowerArr.filter(
      (mill: any) => {
        return (
          Number(Number(mill.ratio).toFixed(2)) ==
          Number(Number(this.powerConsumption['bestEver']).toFixed(2))
        );
      }
    );
    this.powerConsumption['bestEverMonth'] =
      this.powerConsumption['bestEverMonth'][0].month_year;

    this.performance['bestEverMonth'] = bestPerformanceArr.filter(
      (mill: any) => {
        return (
          Number(Number(mill.value).toFixed(2)) ==
          Number(this.performance['bestEver'])
        );
      }
    );
    this.performance['bestEverMonth'] =
      this.performance['bestEverMonth'][0].month_year;

    if (yearDocCount > 0) {
      yearMill = Number(Number(yearMill / yearDocCount).toFixed(2));
      yearPerformance = Number(Number(yearPerformance).toFixed(2));
      yearQual = Number(Number(yearQual / yearDocCount).toFixed(2));
    }
    if (yearDocCount > 0) {
      if (this.millUtilization['mtd'] != 'NA') {
        yearMill = Number(
          Number((yearMill + this.millUtilization['mtd']) / 2).toFixed(2)
        );
      } else {
        yearMill = Number(Number(yearMill).toFixed(2));
      }
      if (this.performance['mtd'] != 'NA') {
        yearPerformance = Number(
          Number(yearPerformance + this.performance['mtd']).toFixed(2)
        );
      } else {
        yearPerformance = Number(Number(yearPerformance).toFixed(2));
      }
      if (this.qualityRate['mtd'] != 'NA') {
        yearQual = Number(
          Number((yearQual + this.qualityRate['mtd']) / 2).toFixed(2)
        );
      } else {
        yearQual = Number(Number(yearQual).toFixed(2));
      }
      yearOee = Number(
        (yearMill / 100) * (100 / 100) * (yearQual / 100) * 100
      ).toFixed(2);
    } else {
      if (this.millUtilization['mtd'] != 'NA') {
        yearMill = Number(
          Number(yearMill + this.millUtilization['mtd']).toFixed(2)
        );
      } else {
        yearMill = Number(Number(yearMill).toFixed(2));
      }
      if (this.performance['mtd'] != 'NA') {
        yearPerformance = Number(
          Number(yearPerformance + this.performance['mtd']).toFixed(2)
        );
      } else {
        yearPerformance = Number(Number(yearPerformance).toFixed(2));
      }
      if (this.qualityRate['mtd'] != 'NA') {
        yearQual = Number(
          Number(yearQual + this.qualityRate['mtd']).toFixed(2)
        );
      } else {
        yearQual = Number(Number(yearQual).toFixed(2));
      }
      yearOee = Number(
        (yearMill / 100) * (100 / 100) * (yearQual / 100) * 100
      ).toFixed(2);
    }
    if (this.bfgConsumption['mtd'] != 'NA') {
      yearBgf = yearBgf + monthBfg;
      yearBgf = Number((yearBgf / yearPerformance).toFixed(2));
      this.bfgConsumption['ytd'] = yearBgf;
      this.bfgConsumption['ytd'] = Number(
        Number(this.bfgConsumption['ytd']).toFixed(2)
      );
    } else {
      yearBgf = Number((yearBgf / yearPerformance).toFixed(2));
      this.bfgConsumption['ytd'] = yearBgf + 0;
    }
    if (this.hfoConsumption['mtd'] != 'NA') {
      yearHfo = yearHfo + monthHfo;
      yearHfo = Number((yearHfo / yearPerformance).toFixed(2));
      this.hfoConsumption['ytd'] = yearHfo;
      this.hfoConsumption['ytd'] = Number(
        Number(this.hfoConsumption['ytd']).toFixed(2)
      );
    } else {
      yearHfo = Number((yearHfo / yearPerformance).toFixed(2));
      this.hfoConsumption['ytd'] = yearHfo + 0;
    }
    if (this.powerConsumption['mtd'] != 'NA') {
      yearPowerConsumption = Number(
        (
          (yearPowerConsumption + monthPowerConsumption) /
          yearPerformance
        ).toFixed(2)
      );
      this.powerConsumption['ytd'] = yearPowerConsumption;
      this.powerConsumption['ytd'] = Number(
        Number(this.powerConsumption['ytd']).toFixed(2)
      );
    } else {
      yearPowerConsumption = Number(
        (yearPowerConsumption / yearPerformance).toFixed(2)
      );
      this.powerConsumption['ytd'] = yearPowerConsumption + 0;
    }
    this.millUtilization['ytd'] = yearMill;
    this.performance['ytd'] = yearPerformance;
    this.qualityRate['ytd'] = yearQual;
    this.oee['ytd'] = yearOee;
  }

  // Dispatch functions starts here..

  async get_dispatch_targets() {
    let frequencies = ['Weekly', 'Monthly', 'Yearly'];
    let current_year = '';
    this.dispatch_targets = {};
    for await (let freq of frequencies) {
      this.dispatch_targets[freq] = [];
      if (freq == 'Monthly') {
        if (
          moment().format('MMM') == 'Apr' ||
          moment().format('MMM') == 'May'
        ) {
          let prev_months = {
            collection: `/Asset_OEE/JSPL/Dispatch/${freq}/${moment()
              .subtract(1, 'year')
              .format('YYYY')}-${moment().format('YYYY')}`,
            conditions: [],
            type: 'history',
          };
          let response: any = await this.fs.getFireBaseData(prev_months);
          if (response.status && response.status == 'success') {
            this.dispatch_targets[freq] = [
              ...this.dispatch_targets[freq],
              ...response['data'],
            ];
          }
        }
      }
      if (
        moment().format('MMM') == 'Jan' ||
        moment().format('MMM') == 'Feb' ||
        moment().format('MMM') == 'Mar' ||
        (moment().format('MMM') == 'Apr' && moment().format('DD') == '01')
      ) {
        current_year = `${moment()
          .subtract(1, 'year')
          .format('YYYY')}-${moment().format('YYYY')}`;
      } else {
        current_year = `${moment().format('YYYY')}-${moment()
          .add(1, 'year')
          .format('YYYY')}`;
      }
      let reqParams = {
        collection: `/Asset_OEE/JSPL/Dispatch/${freq}/${current_year}`,
        conditions: [],
        type: 'history',
      };
      let response: any = await this.fs.getFireBaseData(reqParams);
      if (response.status && response.status == 'success') {
        this.dispatch_targets[freq] = [
          ...this.dispatch_targets[freq],
          ...response['data'],
        ];
      }
    }
    this.get_dispatch_data();
  }
  async get_dispatch_data() {
    let startDate, endDate;
    this.raw_dispatch_data = {};
    if (
      moment().format('MMM') == 'Jan' ||
      moment().format('MMM') == 'Feb' ||
      moment().format('MMM') == 'Mar' ||
      (moment().format('MMM') == 'Apr' && moment().format('DD') == '01')
    ) {
      startDate = moment('04', 'MM').subtract(1, 'year').toDate();
    } else {
      startDate = moment('04', 'MM').toDate();
    }
    endDate = moment().toDate();
    let reqParams = {
      collection: `/Asset_OEE/JSPL/PPC_Dispatch`,
      conditions: [
        { key: 'date', value: startDate, symbol: '>=' },
        { key: 'date', value: endDate, symbol: '<=' },
      ],
      type: 'history',
    };
    let response: any = await this.fs.getFireBaseData(reqParams);
    if (response.status && response.status == 'success') {
    response['data']=response['data'].map((docx)=>{
     docx['date'].seconds = moment(docx['date'].seconds * 1000).subtract(1, 'day').unix();
     return docx;
    })
      this.raw_dispatch_data = _.groupBy(response['data'], (d: any) => {
        return moment(d.date.seconds * 1000).format("MMM'YY");
      });
    }
    let prev_month_data: any = {};
    if (moment().format('MMM') == 'Apr' || moment().format('MMM') == 'May') {
      prev_month_data = await this.get_previous_months_dispatch();
    }
    this.raw_dispatch_data = { ...this.raw_dispatch_data, ...prev_month_data };
    for (let key in this.raw_dispatch_data) {
      this.raw_dispatch_data[key] = this.raw_dispatch_data[key].map((docx) => {
        docx['total_dispatch'] = 0;
        if (docx.groupWiseData) {
          for (let group_key in docx.groupWiseData) {
            docx['total_dispatch'] += docx.groupWiseData[group_key];
          }
        }
        return docx;
      });
    }
    this.prepare_yearly_dipatach();
    this.prepare_monthly_dispatch();
    this.prepare_weekly_dispatch()
  }
  get_previous_months_dispatch() {
    return new Promise(async (resolve, reject) => {
      let startDate,
        endDate,
        resulted_data = {};
      if (moment().format('MMM') == 'Apr') {
        startDate = moment('02', 'MM').startOf('month').toDate();
        endDate = moment('03', 'MM').endOf('month').toDate();
      } else if (moment().format('MMM') == 'May') {
        startDate = moment('03', 'MM').startOf('month').toDate();
        endDate = moment('03', 'MM').endOf('month').toDate();
      }
      let reqParams = {
        collection: `/Asset_OEE/JSPL/PPC_Dispatch`,
        conditions: [
          { key: 'date', value: startDate, symbol: '>=' },
          { key: 'date', value: endDate, symbol: '<=' },
        ],
        type: 'history',
      };
      let response: any = await this.fs.getFireBaseData(reqParams);
      if (response.status && response.status == 'success') {
       resulted_data=response['data'].map((docx)=>{
     docx['date'].seconds = moment(docx['date'].seconds * 1000).subtract(1, 'day').unix();
     return docx;
    })
        resulted_data = _.groupBy(response['data'], (d: any) => {
          return moment(d.date.seconds * 1000).format("MMM'YY");
        });
      }
      resolve(resulted_data);
    });
  }
  prepare_yearly_dipatach() {
    this.yearly_dispatch = {
      title: 'YTD',
      name: 'Dispatch Percentage',
      plotcolor: '#2a7cf6',
      type: 'solidgauge',
      chartSize: '155%',
      up: true,
      mt: 10,
      innerRadius: '35%',
      outerRadius: '65%',
      radius: '65%',
      diff_value: 2,
      startAngle: -170,
      endAngle: 170,
      dataLabels: {
        step: 2,
        style: {
          color: '#595959',
          fontWeight: '500',
          marginBottom: 100,
          fontSize: 13,
        },
      },
      height: '170px',
      Value: '',
      targetValue: '',
      productionValue: '',
    };
    let total_dispatch_data: any = 0;
    let keys: any = Object.keys(this.raw_dispatch_data);
    if (moment().format('MMM') == 'Apr') {
      keys = [moment('04', 'MM').format("MMM'YY")];
    } else if (moment().format('MMM') == 'May') {
      keys = [
        moment('04', 'MM').format("MMM'YY"),
        moment('05', 'MM').format("MMM'YY"),
      ];
    } else {
      keys = Object.keys(this.raw_dispatch_data);
    }
    for (let key of keys) {
      if (this.raw_dispatch_data[key] && this.raw_dispatch_data[key].length) {
        total_dispatch_data += this.raw_dispatch_data[key].reduce(
          (init: any, doc: any) => {
            return init + Number(doc.total_dispatch);
          },
          0
        );
      }
    }
    let TargetValue = this.dispatch_targets['Yearly'][0]?.dispatch.toFixed(0);
    TargetValue = Number(TargetValue);
    this.yearly_dispatch['targetValue'] = TargetValue;
    this.yearly_dispatch['Value'] = Number(
      (Number(total_dispatch_data / TargetValue) * 100).toFixed(2)
    );
    this.yearly_dispatch['productionValue'] = Number(
      Number(total_dispatch_data.toFixed(0))
    );
  }
  prepare_monthly_dispatch() {
    let chart: any = {
      chartname: '',
      type: 'column',
      mb: 40,
      mr: 30,
      ml: 75,
      mt: 10,
      label_y: '',
      styledMode: true,
      label_x: '',
      height: 170,
      tooltipenabled: true,
      unit: 'MT',
      xaxis: [],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            borderRadius: 30,
            x: 5,
            y: -10,
            shape: 'callout',
          },
        },
      },
      yaxis: [
        {
          color: '#FFAD8A',
          marker: { enabled: false },
          unit: 'Ton',
          name: 'Target',
          data: [],
        },
        {
          color: '#2a7cf6',
          marker: { enabled: false },
          unit: 'Ton',
          name: 'Actual',
          data: [],
        },
      ],
    };
    let targets_series: any = [];
    this.monthwiseDispatch_Percent = [];
    chart.label_y = 'MT';
    this.dispatch_targets['Monthly'] = this.dispatch_targets['Monthly'].map(
      (doc) => {
        delete doc['id'];
        for (let keys in doc) {
          return doc[keys];
        }
      }
    );
    this.dispatch_targets['Monthly'] = [].concat(
      ...this.dispatch_targets['Monthly']
    );
    for (let i = 2; i >= 0; i--) {
      let production: any = 0;
      let monthObj: any = {};
      chart.xaxis.push(moment().subtract(i, 'months').format("MMM'YY"));
      if (
        this.raw_dispatch_data[moment().subtract(i, 'months').format("MMM'YY")]
      ) {
        this.raw_dispatch_data[
          moment().subtract(i, 'months').format("MMM'YY")
        ].forEach((monthData: any) => {
          production += monthData.total_dispatch;
          monthObj['y'] = Number(Number(production).toFixed(0));
        });
        monthObj['dataLabels'] = {
          className: 'actual',
          allowOverlap: true,
          x: 5,
          y: -10,
          format: `{point.y} `,
        };
        chart.yaxis[1].data.push(monthObj);
      } else {
        monthObj['y'] = 0;
        monthObj['dataLabels'] = {
          className: 'actual',
          allowOverlap: true,
          x: 5,
          y: -10,
          format: `{point.y} `,
        };
        chart.yaxis[1].data.push(monthObj);
      }
      let targetObj: any = {};
      let current_month_traget = this.dispatch_targets['Monthly'].filter(
        (monthTarget) => {
          return (
            monthTarget.month ==
            moment().subtract(i, 'months').format('MMM YYYY')
          );
        }
      );
      if (current_month_traget && current_month_traget.length) {
        targetObj['y'] = current_month_traget[0].dispatch
          ? Number(Number(current_month_traget[0].dispatch).toFixed(2))
          : 0;
        targetObj['dataLabels'] = {
          className: 'target',
          allowOverlap: true,
          x: 5,
          y: 10,
        };
        targetObj['month'] = current_month_traget[0].month;
        targets_series.push(targetObj);
      } else {
        targetObj['y'] = 0;
        targetObj['dataLabels'] = {
          className: 'target',
          allowOverlap: true,
          x: 5,
          y: 10,
        };
        targets_series.push(targetObj);
      }
      let obj: any = { month: '', value: '' };
      obj['month'] = moment().subtract(i, 'months').format("MMM'YY");
      obj['value'] = monthObj['y']
        ? `${Number((monthObj['y'] / targetObj['y']) * 100).toFixed(2)}%`
        : 0;
      this.monthwiseDispatch_Percent.push(obj);
    }
    chart.yaxis[0].data = targets_series;
    this.monthly_dispatch.push(chart)

  }
  prepare_weekly_dispatch(){
     let chart: any = {
       chartname: '',
       type: 'line',
       mb: 40,
       mr: 30,
       ml: 75,
       mt: 10,
       label_y: '',
       styledMode: true,
       label_x: '',
       height: 170,
       tooltipenabled: true,
       unit: 'MT',
       xaxis: ['W1', 'W2', 'W3', 'W4'],
       tooltip: {
         enabled: true,
         time: {
           useUTC: false,
         },
         xDateFormat: '%d-%m-%y',
         headerFormat:
           '<span style="font-size:14px; font-weight:bolder">' +
           '<br/> </span>' +
           "<span style='font-size:14px;'>on {point.key} {point.date}</span><table>",
         pointFormat:
           '<div>{point.date}</div>' +
           '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
           '<td style="padding:0"><b>{point.y:.2f} ' +
           'MT' +
           '</b></td></tr>',
         footerFormat: '</table>',
         shared: true,
         useHTML: true,
         backgroundColor: '#eeeeee',
         borderColor: '#eeeeee',
       },
       plotOptions: {
         series: {
           dataLabels: {
             enabled: true,
             borderRadius: 30,
             style: {
               fontWeight: 'bold',
             },
             allowOverlap: true,
             y: -10,
             distance: 10,
             shape: 'callout',
             StyleSheet: {
               fontSize: '20px',
             },
           },
         },
       },
       yaxis: [
         {
           color: '#2a7cf6',
           marker: { enabled: true },
           unit: 'Ton',
           name: 'Actual',
           data: [
             {
               y: 0,
               dataLabels: {
                 className: 'actual',
                 enabled: false,
                 allowOverlap: true,
               },
             },
             {
               y: 0,
               dataLabels: {
                 className: 'actual',
                 enabled: false,
                 allowOverlap: true,
               },
             },
             {
               y: 0,
               dataLabels: {
                 className: 'actual',
                 enabled: false,
                 allowOverlap: true,
               },
             },
             {
               y: 0,
               dataLabels: {
                 className: 'actual',
                 enabled: false,
                 allowOverlap: true,
               },
             },
           ],
         },
         {
           color: '#FFAD8A',
           marker: { enabled: true },
           unit: 'Ton',
           name: 'Target',
           // data:[]
           data: [
             { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
             { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
             { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
             { y: 0, dataLabels: { className: 'target', allowOverlap: true } },
           ],
         },
       ],
     };
     let month: any;
      if (moment().format('DD') == '01') {
        month = moment().subtract(1, 'month').format('MMM YYYY');
      } else {
        month = moment().format('MMM YYYY');
      }
    this.raw_dispatch_data;
    let weeklyTargets=this.dispatch_targets['Weekly'][0][month]
    let weekly_data={}
    let default_start = moment(month, 'MMM YYYY').startOf('month').toDate();
    let default_end=moment(month,'MMM YYYY').endOf('month').toDate()
    let month_data=this.raw_dispatch_data[moment(month,'MMM YYYY').format("MMM'YY")]
    let weeklyDurationDates:any=[]
    for(let weeks of weeklyTargets){
       weekly_data[weeks.week] = [];
      if(weeks.startDate && weeks.endDate){
       let DateObj = `${moment(weeks.startDate.seconds * 1000).format('DD-MMM')} to ${moment(weeks.endDate.seconds * 1000).format('DD-MMM')}`;
       weeklyDurationDates.push(DateObj);
       weekly_data[weeks.week]=month_data.filter((docz)=>{
        return moment(docz.date.seconds * 1000).isSameOrAfter(moment(weeks.startDate.seconds *1000).startOf('day'))
        && moment(docz.date.seconds * 1000).isSameOrBefore(moment(weeks.endDate.seconds *1000).endOf('day'));
       });
      }
      else{
        if(weeks.week=='Week 1'){
          weeklyDurationDates.push(`1-${moment(month,'MMM YYYY').format('MMM')} to 7-${moment(month,'MMM YYYY').format('MMM')}`);
       weekly_data[weeks.week]=month_data.filter((docz)=>{
        return moment(docz.date.seconds * 1000).isSameOrAfter(moment(default_start).startOf('day'))
        && moment(docz.date.seconds * 1000).isSameOrBefore(moment(default_start).add(7,'days').endOf('day'));
       });
        }
         if(weeks.week=='Week 2'){
           weeklyDurationDates.push(`8-${moment(month,'MMM YYYY').format('MMM')} to 14-${moment(month,'MMM YYYY').format('MMM')}`);
       weekly_data[weeks.week]=month_data.filter((docz)=>{
        return moment(docz.date.seconds * 1000).isSameOrAfter(moment(default_start).add(8,'days').startOf('day'))
        && moment(docz.date.seconds * 1000).isSameOrBefore(moment(default_start).add(14,'days').endOf('day'));
       });
        }
         if(weeks.week=='Week 3'){
           weeklyDurationDates.push(`15-${moment(month,'MMM YYYY').format('MMM')} to 21-${moment(month,'MMM YYYY').format('MMM')}`);
       weekly_data[weeks.week]=month_data.filter((docz)=>{
        return moment(docz.date.seconds * 1000).isSameOrAfter(moment(default_start).add(15,'days').startOf('day'))
        && moment(docz.date.seconds * 1000).isSameOrBefore(moment(default_start).add(21,'days').endOf('day'));
       });
        }
          if(weeks.week=='Week 4'){
         weeklyDurationDates.push(`22-${moment(month,'MMM YYYY').format('MMM')} to 28-${moment(month,'MMM YYYY').format('MMM')}`);
       weekly_data[weeks.week]=month_data.filter((docz)=>{
        return moment(docz.date.seconds * 1000).isSameOrAfter(moment(default_start).add(22,'days').startOf('day'))
        && moment(docz.date.seconds * 1000).isSameOrBefore(moment(default_end).endOf('day'));
       });
        }

      }
    }
    let index=0
    for(let keys in weekly_data){
      if(weekly_data[keys] && weekly_data[keys].length){
          let weekobj: any = {};
          let cumulativeProduction = weekly_data[keys].reduce((init: any, obj: any) => {
            return init + Number(obj.total_dispatch)
          }, 0);
          if (cumulativeProduction == 0) {
            weekobj['y'] = Number(Number(cumulativeProduction).toFixed(0));
            weekobj['date'] = weeklyDurationDates[index];
            weekobj['dataLabels'] = {
              className: 'actual',
              enabled: false,
              allowOverlap: true,
              x: 5,
              y: 5,
            };
          } else {
            weekobj['y'] = Number(Number(cumulativeProduction).toFixed(0));
            weekobj['date'] = weeklyDurationDates[index];
            weekobj['dataLabels'] = {
              className: 'actual',
              enabled: true,
              allowOverlap: true,
              x: 5,
              y: 5,
            };
          }
          chart.yaxis[0].data[index] = weekobj;
      }
      index++;
    }
    weeklyTargets.forEach((obj: any, i: any) => {
      let weekTarget = {};
      weekTarget['y'] = obj.dispatch;
      weekTarget['dataLabels'] = {
        className: 'target',
        allowOverlap: true,
      };
      chart.yaxis[1].data[i] = weekTarget;
    });
     chart.label_y = 'MT';
     this.weekly_dispatch.push(chart);
  }
}
