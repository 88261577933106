<div class="overlay"></div>
<!-- <div class="loader">Loading...</div> -->
<div
  class="fa fa-gear fa-spin"
  style="
    font-size: 72px;
    color: lightblue;
    left: 47%;
    top: 45%;
    position: absolute;
    z-index: 9999999;
  "
></div>
