import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import {FormArray, FormControl, FormGroup,Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import * as dropdownValues from '../../../assets/json/jsplGenAI.json';
import Swal from 'sweetalert2';
import moment from 'moment';

@Component({
  selector: 'app-genaidashboard',
  templateUrl: './genaidashboard.component.html',
  styleUrls: ['./genaidashboard.component.scss']
})
export class GenaidashboardComponent implements OnInit {
  content_list: any =[];
  selectedTab: any;
  selectedContent: any;
  constructor(private modalService: NgbModal,private fs: FirestoreService, private util: UtilService) {
    this.setForm()
   }
  content_data: any =[];
  content: any;
  user: any;
  addContentForm: FormGroup = new FormGroup({});
  content_form: any = [
    {
      formControlName: 'content',
      label: 'Content',
      abstractControl: new FormControl('', [Validators.required]),
      type: 'input',
      mandatory: true,
    }
  ]
  commentForm: FormGroup = new FormGroup({});
  comment_form: any = [
    {
      formControlName: 'comment',
      label: 'Comment',
      abstractControl: new FormControl('', [Validators.required]),
      type: 'input',
      mandatory: true,
    }
  ]
  isedit: boolean = false;
  popupOpened: boolean;
  responseLiked: boolean;
  responseDisLiked: boolean;
  searched_content_list: any = [
      // {
      // "id": "jspl1",
      // "search": "JSPL",
      // "response": "JSPL is a product under Predict.AI which is basically monitoring the maintenance, health status of the machines and to reduce the downtime in the plant."
      // },
      // {
      //   "id": "jspl2",
      //   "search": "JSPL",
      //   "response": "BLP JSPL application is capable of handling data for different timestamps, check the defects in the machines, try to monitor the health status of machines by generating alerts in the assets through the application. By using the JSPL application we can check the safety measures machines in the plant and give the maintenance details of the same. "
      // },
      // {
      //   "id": "jspl3",
      //   "search": "JSPL",
      //   "response": "Monitor the health status of the machines and replace the machines if there are any problems in the machines in time without wastage of time for keeping the plant shut down for more time. Increased the Productivity of the plant. Able to find the safety of the machines and take necessary actions within the time."
      // },
      // {
      //   "id": "spectro1",
      //   "search": "Spectro",
      //   "response": "It is a Raw material (chemical composition) sample sent for testing. Raw material is sent to spectrometer labs, which has sub machines such as BF-1, BF-2, SMS-2, SMS-3, Rail Mill. Chemical values & specifications are directly from GCP. There are 5 spectrometer labs, under which it consists of different machines."
      // },
      // {
      //   "id": "spectro2",
      //   "search": "Spectro",
      //   "response": "BF1 & BF2 will be having same form fields. SMS-2, SMS-3, Rail Mill will be having same form fields. Inspection Point Dropdown and Grade Dropdowns Functionalities. If L1 , F1  to F6 inspection points are selected, grades dropdown will be an optional field and be mandatory if inspection point is other that L1 and F1 to F6."
      // }
  ]
  ngOnInit(): void {
    this.getSearchContent();
  }
  setForm(){
    return new Promise((resolve,rej) =>{
     this.addContentForm = new FormGroup({})
     this.content_form.forEach(form =>{
       this.addContentForm.addControl(form.formControlName,form.abstractControl)
     })
     this.commentForm = new FormGroup({})
     this.comment_form.forEach(form =>{
       this.commentForm.addControl(form.formControlName,form.abstractControl)
     })
     resolve(true)
    })
   }
   onKeyEnter(e, content) {
    if(e.key == 'Enter'){
      this.searchContent(content)
    }
  }
  searchContent(form: any){
    // let params=form.value
    // let conditions:any={
    //   content:params.content,
    // }
  //   this.fs.addSearchContent('genai_search_content',conditions).then(response =>{
  //     this.util.setLoaderValue(false)
  //     this.getSearchContent()
  //     this.util.showSnakbarMessage(
  //       'success',
  //       'Conent Searched Successfully',
  //       'end',
  //       'top'
  //     );
  //   }).catch(error=> {
  // this.util.setLoaderValue(false)
  //     this.util.showSnakbarMessage(
  //       'error',
  //       'Cannot search Content',
  //       'end',
  //       'top'
  //     )
  //   })
  //   this.modalService.dismissAll();
  let params = {
    text: form.value.content.toUpperCase(),
  }
  
  this.util.setLoaderValue(true)
  this.fs.getGenAIResponse(params).subscribe((res: any)=>{
    this.util.setLoaderValue(false)
    this.searched_content_list=[];
    console.log(res)
    if(res.status == 200){
    let obj= {
      "id": form.value.content.toLowerCase(),
      "response": res.message,
      "useremail": localStorage.getItem('email'),
      "updatedBy": localStorage.getItem('username'),
      "updatedAt": moment().toDate(),
    }
    this.searched_content_list.unshift(obj);
    console.log(this.searched_content_list);
  }
  else{
  }
  }, (err) =>{
    this.util.setLoaderValue(false)
  }
  )
  }
  comment(form: any){
    this.selectedContent={...this.selectedContent,...form.value}
    console.log(this.selectedContent)
    if(this.selectedContent){
      this.selectedContent.responseDisLiked = true;
      this.selectedContent.responseLiked = false;
    }
    this.searched_content_list.push(this.selectedContent);
    this.modalService.dismissAll();
  }
  editSearchContent(form: any){
    let params=form
    let conditions:any={
      content:params.content,
      id: params.ID
    }
    this.fs.updateSearchContent('genai_search_content',conditions.id, params).then((res) =>{
      this.util.setLoaderValue(false)
      this.getSearchContent()
      this.util.showSnakbarMessage(
        'success',
        'Search Content Updated Successfully',
        'end',
        'top'
      );
    }).catch((err) =>{
      this.util.setLoaderValue(false)
      this.util.showSnakbarMessage(
        'error',
        'Search Content Update Failed',
        'end',
        'top'
      )
    })
  }
  getSearchContent(){
    this.content_list=[]
    this.content_data=[]
    this.util.setLoaderValue(true)
    this.fs.getSearchContent('genai_search_content').subscribe((res: any)=>{
        this.util.setLoaderValue(false)
        if(res.size){
          let result=res.docs
          result.forEach((doc:any)=>{
            let value=doc.data()
            value['ID']=doc.id

          this.content_list.push(value)
          })
          this.content_data=this.content_list
        }else{
          this.util.showSnakbarMessage(
            'error',
            'No content Found Please search a content To View It',
            'end',
            'top'
          );
        }
    })
   
  }
  selectedTabs(data){
    this.selectedTab=data
  }
  deleteSearchContent(content){
    Swal.fire({
      title: 'Do you want to delete the searched content?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Delete`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.util.setLoaderValue(true)
        /***********FS********* */
        this.fs.deleteSearchContent('genai_search_content',content.ID).then((response: any) => {
    this.util.setLoaderValue(false)
            this.util.showSnakbarMessage("success", "Searched Content Deleted successfully!", "end", "top");
            this.getSearchContent();
          }).catch((err) =>{
    this.util.setLoaderValue(false)
            this.util.showSnakbarMessage("error", "Searched Content Deletion failed", "end", "top");
          });
      } else if (result.isDenied) {
        this.util.setLoaderValue(false)
      }
    })
  }
  likedResponse(){
    this.responseLiked = true;
    this.responseDisLiked = false;
  }
  // unLikedResponse(){

  // }
  open(content, type, data?) {
    let modalOptions: any = { size: "md", backdrop:'static' };
    if (type == "ADD") {
      this.responseDisLiked=true;
      this.responseLiked=false;
      this.isedit = false;
      this.selectedContent = data
      this.commentForm.reset()
    }
    else{
      this.isedit = true;
      this.responseDisLiked=false;
      this.responseLiked=true;
    }
    this.modalService.open(content, modalOptions).result.then((result) => {
      this.popupOpened = false;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.popupOpened = false;
    });
  }
  addComment(form: any){

  }
  closePopup(){

  }
}
