<ul class="list-group card">
  <ng-container *ngFor="let item of selectedAssetData?.sidebarConfig">
    <!-- *ngIf="
    item.title == 'Overview' ||
    item.title == 'Trends Monitor' ||
    item.timestamp
  " -->
  <!-- <ng-container
            *ngIf="!item.userSpecific || (item.userSpecific && item.userSpecific == 'Current User' && item.email==this.loggedinUser.email)"> -->
    <li
      class=""
      [ngClass]="{
        'list-group-item': !item.children,
        active: !item.children && sidebarSelected.title == item.title
      }"
      (click)="sidebarMenuClicked($event, item, 'parent')"
    >
      <div
        [ngClass]="{
          'list-group-item': item.children && item.children.length > 0,
          active:
            item.children &&
            item.children.length > 0 &&
            sidebarSelected.title == item.title
        }"
      >
        {{ item.title }}
      </div>
      <ul class="children" *ngIf="item.children && item.children.length > 0">
        <li
          class="list-group-item"
          [ngClass]="{ active: sidebarSelected.title == childMenu.title }"
          *ngFor="let childMenu of item.children"
          (click)="sidebarMenuClicked($event, childMenu, 'child')"
        >
          <div class="square"></div>
          {{ childMenu.title }}
        </li>
      </ul>
    </li>
    <!-- </ng-container> -->
  </ng-container>
</ul>
