import { Injectable } from '@angular/core';
import { ErrorDialogService } from './../services/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Json } from 'sequelize/types/utils';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public errorDialogService: ErrorDialogService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = sessionStorage.getItem('token') || "";
        let aggregationToken: any = localStorage.getItem('tokens') || "";

        let urlarr = request.url.split("/")
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
        if(aggregationToken && (urlarr[urlarr.length-2]=="historyData" || urlarr[urlarr.length-2]=="aggregatedData")){
          aggregationToken=JSON.parse(aggregationToken)
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + aggregationToken['access']) });
        }

        if (!request.headers.has('Content-Type')) {
            //request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        if(urlarr[urlarr.length-2]!="historyData" && urlarr[urlarr.length-2]!="aggregatedData"
        && urlarr[urlarr.length-2]!="token" &&  urlarr[urlarr.length-2]!="aggregatedData" ) {
          request = request.clone({ headers: request.headers.set("enctype", "multipart/form-data") });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
          if(urlarr[urlarr.length-1]=='ask'){
             request=  request.clone({ headers: request.headers.set('Content-Type', 'application/json') })
             request.headers.delete('Enctype')
          }
        //request.body.userEmailId = localStorage.getItem("email");

        if(urlarr[urlarr.length-1]=="saveEquipment") {
            request.headers.delete('Content-Type');
        }

        let roleSetting = localStorage.getItem('roleSetting');
        if (request.body?.category=="ASSETS" && roleSetting && roleSetting != "undefined") {
            roleSetting = JSON.parse(roleSetting);
            if (roleSetting && roleSetting["assets"] && roleSetting["assets"].length > 0) {
                let selectedAsset: any = [];
                roleSetting["assets"].forEach((element: any) => {
                    selectedAsset.push(element["assetcode"])
                });
                request.body.selectedAsset = JSON.stringify(selectedAsset);
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                // let data = {};
                // data = {
                //     reason: error && error.error && error.error.reason ? error.error.reason : '',
                //     status: error.status
                // };
                // this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
    }
}
