import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() values: any[] = [];
  validate: boolean = false;
  selected = ""
  borderStyle = 'default-border';
  @Input('borderStyle')
  set in(val: string) {
    if (val) {
      this.borderStyle = val;
    } else {
      this.borderStyle = 'default-border';
    }
  }
  border_red;
  @Input('validate')
  set setValidate(val: boolean) {
    // this.validate = val
  }
  @Input() controlName: string = '';
  @Input('selected') 
  set setSelected(val){
    this.selected = val
  }
  @Input() selectedArr: any[] = [];
  @Input() disabled: boolean = false;
  @Input() type: string = 'string';
  @Input() displayMember: string = 'name';
  @Input() isLabel: boolean = false;
  @Input() isMultiple: boolean = false;

  @Output() newSelectionEvent = new EventEmitter<string>();
  dropdownData: any = [];
  // _values:any = [];
  // _selected:any = [];
  // _displayMember:string="name";
  // @Input('values')
  // set in(val: any) {
  //   this._values = val;
  //   this.dropdownData = this._values;
  //   console.log("-----------values-----------");
  //   console.log(this._selected);
  // // };
  // @Input('selected')
  // set selectedFn(val: any) {
  //   this._selected = val;

  //   if(this.type=="object") {
  //     if(val && val["equipmentId"] && val["equipmentName"]) {
  //       this._displayMember = "equipmentName";
  //     }
  //     if(val && val[this._displayMember]) {
  //       let selectedData = this.dropdownData.filter((filterData)=>{
  //         return filterData[this._displayMember]==val[this._displayMember];
  //       })
  //       if(selectedData.length>0) {
  //         this._selected = selectedData[0];
  //         console.log("-----------selected-----------");
  //         console.log(this._selected);
  //       }
  //     }
  //   }
  // }

  // @Input('displayMember')
  // set dispFn(val: any) {
  //   this._displayMember = val;
  //   if(this._selected && this._selected[this._displayMember]) {
  //     let selectedData = this.dropdownData.filter((filterData)=>{
  //       return filterData[this._displayMember]==this._selected[this._displayMember];
  //     })
  //     if(selectedData.length>0) {
  //       this._selected = selectedData[0];
  //       console.log("-----------displaymember-----------");
  //       console.log(this._selected);
  //     }
  //   }
  // }

  constructor() {}

  ngOnInit() {
    if (this.type == 'multiplekey') {
      let displayMemberArr = this.displayMember.split(',');
      this.values.forEach((data: any) => {
        data['customDisplay'] = ' ';
        for (let d = 0; d < displayMemberArr.length; d++) {
          if (d == displayMemberArr.length - 1) {
            data['customDisplay'] += data[displayMemberArr[d]];
          } else {
            data['customDisplay'] += data[displayMemberArr[d]] + ' - ';
          }
        }
        // if(displayMemberArr.length==2) {
        //   data["customDisplay"] = data[displayMemberArr[0]]+"("+data[displayMemberArr[1]]+")";
        // }
      });
    }
    this.dropdownData = this.values;
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.displayMember) {
      this.displayMember = change.displayMember.currentValue;
    }
    if (change.selected) {
      this.selected = change.selected.currentValue;
    }
    if (change.type) {
      this.type = change.type.currentValue;
    }
    if (change.values) {
      this.values = change.values.currentValue;
      this.dropdownData = this.values;
    }
    if (this.type == 'object') {
      // if(this.selected && this.selected["equipmentId"] && this.selected["equipmentName"]) {
      //   this._displayMember = "equipmentName";
      // }
      if (this.selected && this.selected[this.displayMember]) {
        let selectedData = this.dropdownData.filter((filterData) => {
          return (
            filterData[this.displayMember] == this.selected[this.displayMember]
          );
        });
        if (selectedData.length > 0) {
          this.selected = selectedData[0];
        }
      }
    }
  }

  selectionChanged(event: any) {
    this.selected = event.value;
    this.newSelectionEvent.emit(event.value);
  }
}
