<div class="container-fluid">
    <div class="toolbar">
        <div class="headertext">
          <span style="margin-top:2px; color: #2a75f6; cursor: pointer;" (click)="navigate()"> Executive Dashboard </span> 
        <b>/</b><span style="font-weight:bolder; padding-left:2px;">History</span></div>
    </div> 
    <hr class="hr" style="margin-top: 2px;">
    <div class="his_dashboard " style="padding-left: 1% !important; padding-right: 1% !important;">
    <div class="row" style="padding: 0px ! important;">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" style="padding: 3px !important;">
        <div class="leftCard">
            <div class="title">
                <div class="headTitle">
                  Filter 
              </div>
              </div>
              <hr class="hr" style="margin-top: 4%; color: #ccc;">
              <div class="assetdropdown">
                <div style="font-size: 12px;color: #595959;">Select Asset</div>
                <app-dropdown
                [values]="asset"
                [selectedArr]="selectedAssetval"
                [isMultiple]="true"
                (newSelectionEvent)="selectedAsset($event)"
                type="string"
              >
              </app-dropdown>
              </div>
              <div class="datepicker" style="padding: 2%; margin-top:5%;">
                <div style="font-size: 12px;color: #595959;">Select Date</div>
                <app-daterangepicker
                [InitialDates]="initialDates"
                [opens]="'center'"
                (selectedDates)="customchange($event)"
              ></app-daterangepicker>
              </div>
              <hr class="hr" style="margin-top: 0%;">
              <div class="loadBtn" style="padding: 2%; text-align: end;">
                  <button class="btn btn-primary btn-sm" (click)="getHistoryData()">
                    <ng-container *ngIf="isGraph"> Load Graph  </ng-container>
                    <ng-container *ngIf="!isGraph"> Load Table  </ng-container>
                  </button>
              </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9" style="padding: 3px !important;">
        <div class="rightCard">
           <div class="title">
            <div class="headTitle">
                Data 
            </div>
            <div style="padding-right: 2%;"><label for="">Table</label>
              <span><mat-slide-toggle (change)="toggleChange($event)" checked="true"></mat-slide-toggle></span><label for="">Graph</label></div>
           </div>
           <hr class="hr" style=" color: #ccc;">
           <ng-container *ngIf="isGraph">
           <ng-container *ngIf="!HistoryChartData.length">  
            <app-infomessage [message]="'No Data Found'"></app-infomessage>
           </ng-container>
           <ng-container *ngIf="HistoryChartData && HistoryChartData.length ">
           <app-stockchart  [data]="HistoryChartData"></app-stockchart></ng-container>
          </ng-container>
          <ng-container *ngIf="!isGraph">
            <div style="padding: 0% ;padding-right:1%;padding-bottom: 1%;" >
              <div class="alertable card bg-color" style="height: calc(100vh - 268px);position: relative;padding: 10px;">
                <ng-container *ngIf="!tableData.data.length || tableData.header.length==1">
                  <div class="font-size-12" style="
                  height: calc(100vh - 210px);
                  padding: 20px;
                  font-size: 18px;
                  font-family: 'sfpro-r';
                ">
                     <app-infomessage [message]="'No Data Found'"></app-infomessage>
                  </div>
                </ng-container>
                <div *ngIf="tableData.data.length !== 0 && tableData.header.length>1 " style="height: calc(100vh - 280px); overflow:auto;">
            <div class="table" style="padding: 0%;">
              <table id="alerts">
                <thead>
                <tr class="header">
                  <ng-container *ngFor="let head of tableData.header">
                    <th>{{head.label}}</th>
                  </ng-container>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of tableData.data |paginate:configPage">
                      <td>{{data.timestamp|date}}</td>
                      <ng-container *ngFor="let head of tableData.header">
                      <ng-container [ngSwitch]="head.field">
                        <td *ngSwitchCase="'currentproductioninmt'">{{data.currentProductionInMt}}</td>
                        <td *ngSwitchCase="'currentproductioninpcs'">{{data.currentProductionInPcs}}</td>
                        <td *ngSwitchCase="'rolledquantity'">{{data.rolledQuantity}}</td>
                        <td *ngSwitchCase="'rateofrolling'">{{data.rateOfRolling}}</td>
                      </ng-container>
                    </ng-container>
                      
                  </tr>
                </tbody>
                </table>
            </div>
            <div style="position: absolute; bottom: -9px;" *ngIf="tableData.data && tableData.data.length>10">
              <app-pagination (changePage)="changePage($event)"></app-pagination>
            </div>
            </div>
            </div>
            </div>
          </ng-container>
        </div>
    </div>
</div>
    </div> 
</div>