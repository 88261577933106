import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SubjectService} from 'src/app/services/subject.service';
import moment, {Moment} from 'moment';
import * as screens from './../../../../../assets/json/screen.json';
import {AuthService} from 'src/app/services/auth/auth.service';
import * as _ from 'lodash';
import { FirestoreService } from 'src/app/services/firestore.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent implements OnInit {
  tabs: any = [];
  selectedIndex: any = 0;
  tabsWidth: number = 180;
  lastUpdate: any;
  currentTime = moment().format('DD-MMM-YYYY hh:mm:ss a');
  icons: any = [
    { id: 'Executive-Dashboard', icon: 'fa fa-line-chart' },
    { id: 'Dashboard', icon: 'fa fa-th' },
    { id: 'Tracking', icon: 'fa fa-compass' },
    { id: 'Alerts', icon: 'fa fa-bell' },
    { id: 'Configurations', icon: 'fa fa-cog' },
    { id: 'Maintenance', icon: 'fa fa-wrench' },
    { id: 'Reports', icon: 'fa fa-file-alt' },
    { id: 'Spectrometer', icon: 'fa fa-flask' },
    { id: 'Analytics', icon: 'fa fa-area-chart' },
  ];
  time;
  twoHourInterval;
  message: any;
  url: any;
  @ViewChild('message_container') message_container: ElementRef | undefined;
  constructor(
    private router: Router,
    private subject: SubjectService,
    private auth: AuthService,
    private fs: FirestoreService,
    private modalService: NgbModal
  ) {
    // this.subject.getLastUpdatedDate().subscribe((data)=>{
    //   if(data) {
    //     this.lastUpdate = moment.unix(data).format("D MMM HH:mm:ss A")
    //   }
    // });
  }
  ngOnInit() {
    this.subject.getselectedmenu.subscribe((data: any) => {
      this.selectedIndex = data.index;
    });
    let roleSetting: any = localStorage.getItem('roleSetting');
    if (roleSetting && roleSetting != 'undefined') {
      roleSetting = JSON.parse(roleSetting);
    } else {
      roleSetting = {};
    }
    if (roleSetting.screens && roleSetting.screens.length > 0) {
      this.tabs = roleSetting.screens;
    } else {
      this.tabs = screens['default']['mainScreens'];
    }
    let currentRoute = this.router.url.split('/');
    if (!currentRoute[2]) currentRoute[2] = '';
    if (currentRoute.length >= 3) {
      this.tabs.forEach((tab: any, index: number) => {
        if (tab.href === currentRoute[2]) {
          this.selectedIndex = index;
        }
      });
    }
    this.time = setInterval(() => {
      this.currentTime = moment().format('DD-MMM-YYYY hh:mm:ss a');
    }, 1000);
    // 7200000;
    // this.twoHourInterval = setInterval(() => {
    //   this.show_message();
    // }, 1000);
    let newScreen: any = {
      href: 'reports',
      text: 'Reports',
      tabId: 'reports',
      isTabs: true,
      // icon:"fa-file-alt"
    };
    let newScreen1 = {
      href: 'tracking',
      text: 'Tracking',
      tabId: 'tracking',
      isTabs: true,
      // icon:"fa-map-marker-alt"
    };

    // this.tabs.splice(5,0,newScreen)
    // this.tabs.splice(2,0,newScreen1)

    this.tabs.map((tab: any) => {
      let iconTab: any = this.icons.filter((icon: any) => {
        return tab.text == icon.id;
      });
      if (iconTab && iconTab.length) {
        tab['icon'] = iconTab[0]['icon'];
      }
    });
    // this.tabs.push({
    //   "href": "conversationai",
    //   "text": "Copilot",
    //   "tabId": "conversationai",
    //   "isTabs": true,
    //   "icon":"fa fa-comment"
    // },)
    //     this.tabs.push({
    //   "href": "genaidashboard",
    //   "text": "GenAI",
    //   "tabId": "genaidashboard",
    //   "isTabs": true,
    //   "icon":"fa fa-comment"
    // },)
  }

  navigateToTab(tab?: any, index?: number) {
    this.selectedIndex = index;

    this.router.navigate(['page', tab.href]);
  }
  async show_message() {
    let prev_months = {
      collection: `/site_url_change`,
      conditions: [],
      type: 'history',
    };
    let modalOptions: any = {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      backdrop: 'static',
      centered: true,
    };
    if (!this.modalService.hasOpenModals()) {
      let response: any = await this.fs.getFireBaseData(prev_months);
      if (response.status && response.status == 'success') {
        this.message = response['data'][0]['message'];
        this.url = response['data'][0]['new_url'];
        this.modalService.open(this.message_container, modalOptions);
      }
    }
  }
  navigate_back(){
    this.router.navigate(['/login'])
    this.modalService.dismissAll()
    clearInterval(this.twoHourInterval)
  }
}
