<div class="planCard">
  <div class="cardcontnet">
    <div class="firstline"></div>
    <div class="data1" style="padding-left: 1%; min-width: 100px; max-width: 500px">
      <div class="topData" style="padding-bottom: 2%">
        <div class="flex">
          <span class="header">Timestamp</span
          ><span class="content">{{ cardData.timestamp.seconds *1000 | date :"short"}}</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="data1" style="padding-left: 1%; min-width: 100px; max-width: 500px">
    <div class="bottomData">
      <div class="flex">
        <span class="header">Plan Order</span
        ><span class="content">{{ cardData.PLNUM }}</span>
      </div>
    </div>
    </div>
    <div class="line"></div>
    <div class="data2" style="padding-left: 1%; min-width: 100px; max-width: 500px">
      <div class="topData">
        <div class="flex">
          <span class="header">Campaign No</span
          ><span class="content">{{ cardData.CPNPL }}</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="data2" style="padding-left: 1%; min-width: 100px; max-width: 500px">
    <div class="bottomData">
      <div class="flex">
        <span class="header">Pieces</span
        ><span class="content">{{ cardData.PIECES }}</span>
      </div>
    </div>
    </div>
    <div class="line"></div>
    <div class="data3" style="padding-left: 1%; min-width: 100px; max-width: 500px">
      <div class="topData">
        <div class="flex">
          <span class="header">Final Section</span
          ><span class="content">{{ cardData.MATNR_F }}</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="data4" style="padding-left: 1%; min-width: 100px; max-width: 500px">
    <div class="bottomData">
      <div class="flex">
        <span class="header">Grade</span
        ><span class="content">{{ cardData.GRADE }}</span>
      </div>
    </div>
    </div>
    <div class="line"></div>
    <div class="data4" style="padding-left: 1%; min-width: 100px; max-width: 500px">
      <div class="topData">
        <div class="flex">
          <span class="header">Section Weight</span
          ><span class="content">{{ +cardData.SECTIONAL_WEIGHT }}</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="data4" style="padding-left: 1%; min-width: 100px; max-width: 500px">
    <div class="bottomData">
      <div class="flex">
        <span class="header">Final Length</span
        ><span class="content">{{ +cardData.ZCUN_LENGTH_F
        }}</span>
      </div>
    </div>
  </div>
</div>
</div>
