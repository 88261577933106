
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Modules **/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularImageViewerModule } from "angular-x-image-viewer";
import { MatSnackbarSeverityModule } from 'mat-snackbar-severity';

/** HightChart **/
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';

/** Components**/
import { DropdownComponent } from './dropdown/dropdown.component';
import { ImageMapComponent } from './image-map/image-map.component';
import { LinechartComponent } from './linechart/linechart.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { SidebarmenuComponent } from './sidebarmenu/sidebarmenu.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { LoaderComponent } from './loader/loader.component';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { DatetimerangepickerComponent } from './datetimerangepicker/datetimerangepicker.component';
import { StockchartComponent } from './charts/stockchart/stockchart.component';

/** Angular Material **/
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectFilterModule } from 'mat-select-filter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import { LocaleService, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'

import {NgxPaginationModule} from 'ngx-pagination';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SearchableTextboxComponent } from './searchable-textbox/searchable-textbox.component';
import { CardSingleComponent } from './card-single/card-single.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { HeadingComponent } from './heading/heading.component';
import { CardGridComponent } from './card-grid/card-grid.component';
import { CardListComponent } from './card-list/card-list.component';
import { ShiftwiseComponent } from './shiftwise/shiftwise.component';
import { DatatableComponent } from './datatable/datatable.component';
import { DiagramComponent } from './diagram/diagram.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';
import { SingleCardComponent } from './single-card/single-card.component';
import { ExpansionComponent } from './expansion/expansion.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatListModule} from '@angular/material/list';
import {MatTreeModule} from '@angular/material/tree';
import { FormfieldValidatorComponent } from './formfield-validator/formfield-validator.component';
import { MatTreeSidebarComponent } from './mat-tree-sidebar/mat-tree-sidebar.component';
import {SortPipe} from '../../Pipes/sort.pipe'
import { OrderModule } from 'ngx-order-pipe';
import { ModalPopupsComponent } from './modal-popups/modal-popups.component';
import { RemoveUnderscorePipe } from 'src/app/Pipes/removeunderscore.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MessageComponent } from './message/message.component';
import { WidgetMessageComponent } from './widget-message/widget-message.component';
import { PaginationComponent } from './pagination/pagination.component';
import {MatMenuModule} from '@angular/material/menu';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { SplinechartComponent } from './splinechart/splinechart.component';
import { InfomessageComponent } from './infomessage/infomessage.component';
import { SemiGuageComponent } from './semi-guage/semi-guage.component';
import { GaugechartComponent } from './gaugechart/gaugechart.component';
import { SortalphanumPipe } from 'src/app/Pipes/sortalphanum.pipe';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { MatDaterangepickerComponent } from './mat-daterangepicker/mat-daterangepicker.component';
import {MatButtonModule} from '@angular/material/button';

import { GetValueObjPipe } from 'src/app/Pipes/get-value-obj.pipe';
import { CardSpinnerComponent } from './card-spinner/card-spinner.component';
import { DashboardcardComponent } from './dashboardcard/dashboardcard.component';
export function highchartsModules() {
    return [stock, more];
  }

  export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    },
  };

@NgModule({
  declarations: [
    DropdownComponent,
    ImageMapComponent,
    LinechartComponent,
    ProgressbarComponent,
    SidebarmenuComponent,
    ErrorDialogComponent,
    PasswordStrengthComponent,
    LoaderComponent,
    SearchableDropdownComponent,
    DatetimerangepickerComponent,
    StockchartComponent,
    SearchableTextboxComponent,
    CardSingleComponent,
    HeadingComponent,
    CardGridComponent,
    CardListComponent,
    ShiftwiseComponent,
    DatatableComponent,
    DiagramComponent,
    DatepickerComponent,
    DaterangepickerComponent,
    SingleCardComponent,
    ExpansionComponent,
    FormfieldValidatorComponent,
    MatTreeSidebarComponent,
    SortPipe,
    ModalPopupsComponent,
    RemoveUnderscorePipe,
    MessageComponent,
    WidgetMessageComponent,
    PaginationComponent,
    PlanCardComponent,
    DonutChartComponent,
    SplinechartComponent,
    InfomessageComponent,
    SemiGuageComponent,
    GaugechartComponent,
    DeleteButtonComponent,
    SortalphanumPipe,
    MatDaterangepickerComponent,
    GetValueObjPipe,
    CardSpinnerComponent,
    DashboardcardComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSelectFilterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    HighchartsChartModule,
    DragDropModule,
    AngularImageViewerModule,
    MatCardModule,
    MatGridListModule,
    ChartModule,
    MatSnackbarSeverityModule,
    NgxDaterangepickerMd.forRoot(),
    NgxPaginationModule,
    MatProgressSpinnerModule,
    NgxGraphModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    Ng2SearchPipeModule,
    MatProgressBarModule,
    MatListModule,
    MatTreeModule,
    OrderModule,
    MatTooltipModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonModule,
  ],
  exports: [
    NgbModule,
    MatCardModule,
    MatGridListModule,
    ChartModule,
    DragDropModule,
    /** Components**/
    DropdownComponent,
    ImageMapComponent,
    LinechartComponent,
    ProgressbarComponent,
    SidebarmenuComponent,
    ErrorDialogComponent,
    PasswordStrengthComponent,
    LoaderComponent,
    SearchableDropdownComponent,
    DatetimerangepickerComponent,
    StockchartComponent,
    SearchableTextboxComponent,
    CardSingleComponent,
    HeadingComponent,
    CardGridComponent,
    CardListComponent,
    ShiftwiseComponent,
    DatatableComponent,
    DiagramComponent,
    DatepickerComponent,
    DaterangepickerComponent,
    NgxDaterangepickerMd,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    NgxGraphModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    SingleCardComponent,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    ExpansionComponent,
    FormfieldValidatorComponent,
    Ng2SearchPipeModule,
    MatProgressBarModule,
    MatListModule,
    MatTreeSidebarComponent,
    SortPipe,
    OrderModule,
    ModalPopupsComponent,
    RemoveUnderscorePipe,
    MatTooltipModule,
    MessageComponent,
    WidgetMessageComponent,
    PaginationComponent,
    PlanCardComponent,
    MatMenuModule,
    DonutChartComponent,
    SplinechartComponent,
    InfomessageComponent,
    SemiGuageComponent,
    MatSlideToggleModule,
    GaugechartComponent,
    DeleteButtonComponent,
    //Pipe
    SortalphanumPipe,
    MatDaterangepickerComponent,
    GetValueObjPipe,
    CardSpinnerComponent,
    DashboardcardComponent,
    MatIconModule
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    LocaleService, // add as factory to your providers
  ],
})
export class CommonComponentsModule {}
