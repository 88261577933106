import { Component, OnInit, Input } from '@angular/core';
import { StockChart } from 'angular-highcharts';

@Component({
  selector: 'app-stockchart',
  templateUrl: './stockchart.component.html',
  styleUrls: ['./stockchart.component.scss']
})
export class StockchartComponent implements OnInit {





  // stock: any;
  
  // @Input() data: any;
  // constructor() {
  //   this.stock = StockChart;
  //  }

  // ngOnInit(): void {
  //   this.loadChartData();
  // }

  // loadChartData() {
  //   this.stock = new StockChart({
  //     rangeSelector: {
  //       selected: 1
  //     },
  //     title: {
  //       text: ''
  //     },
      
  //     series: [
  //       {
  //         type:"line",
  //         tooltip: {
  //           valueDecimals: 2
  //         },
  //         name: 'AAPL',
  //         data: this.data

  //       }
  //     ]
  //   });
  // }







  @Input() data: any;
  stock!: StockChart;
  yAxis:any;
  constructor() { 
    this.yAxis= {
      lineColor:"var(--graph-text-color)",
      gridLineColor: "var(--gridline-color)",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      style: {
        color: 'var(--graph-text-color)',
        fontSize:'12'
      },
    },
    title: {
      style: {
        color: 'var(--graph-text-color)',
        fontSize: '14'
      }
    },
  }
  }

  ngOnInit() {
this.plotGraph(this.data)
  }
  plotGraph(data){
    this.stock = new StockChart({
      chart:{
        reflow:true,
        type:"line",
        style:{fontFamily: 'SF Pro Text',color:"var(--primary-text-color)"},
        backgroundColor: "transparent",
        events: data[0].events ? data[0].events : null,
      },
      time: {
        useUTC: false
       },
      credits:{
        enabled:false,
        style:{
        color:'#999999',
        cursor:'pointer',
        fontSize:'9px',
        },
        text:'Highcharts.com'
        },
      title: {
        text: ''
      },
      xAxis: {
        lineColor:"#cccccc",
        lineWidth:1,
      type: 'datetime',
      title:{
      text:"Time"
      },
      labels: {
        style: {
           color: '#427bff',
           fontSize:'12px'
        },
      },
    },
    rangeSelector: {
      selected: 5,
     buttonTheme:{
       style:{
         color:"#595959",
       },
     states: {
      select: {
          fill: '#2A7CF6',
          style: {
              color: '#FFFFFF'
          }
      }
    },
  },
},
    
      yAxis: data[0].yaxis?data[0].yaxis:this.yAxis,
    //   
    legend: {
      align: "center",
      enabled:true,
      verticalAlign: "bottom",
      layout: "horizontal",
      squareSymbol: true,
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      itemStyle: {
        color: 'var(--graph-text-color)',
        fontWeight: "ligther",
        fontSize: '13',
      },
      y:10
    },
      plotOptions:{
        series:{
          turboThreshold:5000
        }
      },
      series: data[0].series
    });
  }








}
