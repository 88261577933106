<!-- <select class="dropdown" (change)='selectionChanged($event)' [disabled]="disabled">
    <option value="{{value}}" *ngFor="let value of values" [selected]="value == selected">{{value}}</option>
  </select> -->
<mat-form-field
  appearance="fill"
  [ngClass]="borderStyle"
  *ngIf=" dropdownData && dropdownData?.length > 0"
>
  <mat-label *ngIf="isLabel">Select</mat-label>
  <!-- {{_selected|json}} -->
  <!-- {{displayMember}} -->
  <ng-container *ngIf="!isMultiple">
    <mat-select
      [(value)]="selected"
      (selectionChange)="selectionChanged($event)"
      [disabled]="disabled"
      panelClass="commondropdown"
    >
      <mat-option
        [disabled]="value?.disableOption"
        *ngFor="let value of dropdownData"
        [value]="value"
      >
        <span *ngIf="type == 'string'">{{ value }}</span>
        <span *ngIf="type == 'object'">{{ value[displayMember] }}</span>
        <span *ngIf="type == 'multiplekey'">{{ value["customDisplay"] }}</span>
      </mat-option>
    </mat-select>
  </ng-container>
  <ng-container *ngIf="isMultiple">
    <mat-select
      [(value)]="selectedArr"
      (selectionChange)="selectionChanged($event)"
      [disabled]="disabled"
      multiple
      panelClass="commondropdown"
    >
      <mat-option *ngFor="let value of dropdownData" [value]="value">
        <span *ngIf="type == 'string'">{{ value }}</span>
        <span *ngIf="type == 'object'">{{ value[displayMember] }}</span>
        <span *ngIf="type == 'multiplekey'">{{ value["customDisplay"] }}</span>
      </mat-option>
    </mat-select>
  </ng-container>
</mat-form-field>
