<div class="main">
    <div class="message">
        <div style="position: relative; top:32%">
            <!-- <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> -->
            <img class="warning" src="../../../../assets/images//monitor-svgrepo-com.png" alt="" /> 
        <div>{{message}}</div> 
        </div> 
    </div>
    
</div>
