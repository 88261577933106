<input
  #daterange
  type=""
  ngxDaterangepickerMd
  [ngClass]="{'hide':hide}"
  [lockStartDate]="false"
  [minDate]="minDate"
  [(ngModel)]="selected"
  [showRangeLabelOnInput]="true"
  [maxDate]="maxDate"
  [locale]="localdata"
  [timePickerIncrement]="5"
  [alwaysShowCalendars]="dateoptions.alwaysShowCalendars"
  [timePicker24Hour]="true"
  [timePicker]="isTimeShow"
  [singleDatePicker]="isSingle"
  [placeholder]="placeholder"
  [opens]="opens"
  id="reportdate"
  (change)="customchangeDuration($event)"
  autocomplete="off"
  name="daterange"
  id="daterange"
  class="form-control durationDatePicker"
/>

<!-- <input
type="text"
ngxDaterangepickerMd
[(ngModel)]="selected"
(rangeClicked)="rangeClicked($event)"
(datesUpdated)="datesUpdated($event)"
[showCustomRangeLabel]="true"
[alwaysShowCalendars]="true"
[ranges]="ranges"
[linkedCalendars]="true"
[showClearButton]="false"
[isInvalidDate] = "isInvalidDate"

/> -->
