<div class="footer">
    <div class="copyright">
        <div><span class="cursor-pointer">Copyright</span> © 2023. Designed by <a>Industry.AI</a>. All rights reserved</div>
    </div>
    <div class="blplogo">
        <div> Powered by </div>
        <div>
            <img src="../../../assets/images/blplogo.png" />
        </div>

    </div>

</div>
