
<div class="row w-100 p-0 m-0 ">
    <div class="card" [style.width]="cardData.cardWidth" [style.height]="cardData.cardHeight">
        <!-- <ng-container *ngIf="cardData.type == 'Unit'">
            <div class="col-md-1 unitleftdiv" *ngIf="false"></div>
        </ng-container> -->
        <!-- <ng-container >
            <div class="col-md-1 detilsleftdiv" *ngIf="false" [style.background-color]="cardData.leftBgColor"></div>
        </ng-container> -->
        <div class="col-md-12" style="padding: 10px 5px;">
              <div class="title_container">
                <div class="card_title">{{cardData.title}}</div>
                <div class="d-flex justify-content-end">
                   <img [src]="cardData.imagePath" alt="" style="height: 27px;">
                </div>
              </div>
                <div class="percentage" >
                  <span class="card_value">{{cardData.value}}</span>
                  <span class="units" *ngIf="cardData.unit">{{cardData.unit}}</span>
                </div>
                <div class="w-100 endContent" >
                  <span> {{cardData.range}}</span>
                 <span class="trend_arrow"
                      [matTooltipPosition]="'after'"
                      [matTooltip]="cardData.compare_result_month">
                      <span class="trend_score">{{cardData.compare_data_month}}</span>
                 <mat-icon aria-hidden="false" aria-label="Example home icon" class="position-absolute"
                 [ngStyle]="{'color':cardData.key=='delay'?'green':'red'}"
                  *ngIf="cardData.compare_result_month.includes('decrease')">trending_down</mat-icon>
                <mat-icon aria-hidden="false" aria-label="Example home icon" class="position-absolute"
                 [ngStyle]="{'color':cardData.key=='delay'?'red':'green'}"
                 *ngIf="cardData.compare_result_month.includes('increase')">trending_up</mat-icon>
                  <!-- <i class="fa fa-long-arrow-up" style="color:green" aria-hidden="true" *ngIf="cardData.compare_result_month.includes('increase')"></i>
                  <i class="fa fa-long-arrow-down" style="color:red" aria-hidden="true" *ngIf="cardData.compare_result_month.includes('decrease')"></i> -->
                </span>
                </div>
                <!-- <hr class="m-1"> -->
                <div class="percentage mt-1" >
                  <span class="card_value">{{cardData.year_value}}</span>
                  <span class="units" *ngIf="cardData.unit">{{cardData.unit}}</span>
                </div>
                <div class="w-100 endContent" >
                  <span>{{cardData.year_range}}</span>
                     <span *ngIf="true" class="trend_arrow"
                     [matTooltipPosition]="'after'"
                      [matTooltip]="cardData.compare_result_year"
                     >

                     <span class="trend_score">{{cardData.compare_data_year}}</span>
                <mat-icon aria-hidden="false" aria-label="Example home icon" class="position-absolute"
                [ngStyle]="{'color':cardData.key=='delay'?'green':'red'}"
                *ngIf="cardData.compare_result_year.includes('decrease')">trending_down</mat-icon>
                <mat-icon aria-hidden="false"
                [ngStyle]="{'color':cardData.key=='delay'?'red':'green'}"
                aria-label="Example home icon" class="position-absolute" *ngIf="cardData.compare_result_year.includes('increase')">trending_up</mat-icon>
                  <!-- <i class="fa fa-long-arrow-up" style="color:green" aria-hidden="true" *ngIf="cardData.compare_result_year.includes('increase')"></i>
                  <i class="fa fa-long-arrow-down" style="color:red" aria-hidden="true" *ngIf="cardData.compare_result_year.includes('decrease')"></i> -->
                </span>
                </div>
        </div>
    </div>
</div>


