import { Component, Input, OnChanges, OnInit } from "@angular/core";
import * as highcharts from "highcharts";
import { Chart } from 'highcharts';
import moment from "moment";

@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss'],
})
export class LinechartComponent implements OnInit {
  @Input() data: any;
  @Input() width: any;
  @Input() height: any;
  highcharts = highcharts;
  splinChartOptions: any;
  shouldUpdateChanges = true;
  plotOptions = {
    column: {
      pointWidth: 10,
      turboThreshold: 50000,
    },
    bar: {
      pointWidth: 10,
      stacking: 'normal',
      turboThreshold: 50000,
    },
    series: {
      turboThreshold: 50000,
    },
    spline: {
      turboThreshold: 50000,
    },
    line: {
      turboThreshold: 50000,
    },
  };
  constructor() {}
  ngOnInit() {
    this.splinechart(this.data[0]);
  }
  splinechart(data: any) {
    // Chart.resize(null)
    this.splinChartOptions = {
      time: {
        useUTC: false,
      },
      chart: {
        type: data.type ? data.type.toLowerCase() : 'spline',
        backgroundColor: 'transparent',
        marginRight: data.mr,
        marginTop: data.mt,
        marginLeft: data.ml,
        marginBottom: data.mb,
        events: data.livedata ? data.livedata : null,
        reflow: true,
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        style: { fontFamily: 'SF Pro Text' },
        height: data.height ? data.height : 260,
        // width:410,
        //   pushItems: true,
        // minCols: 6,
        // maxCols: 6,
        // minRows: 5,
        // fixedRowHeight: 120,
        // resizable: {
        //   enabled: true
        // },
        // draggable: {
        //   enabled: true
        // },
        showAxes: true,
      },
      credits: {
        enabled: false,
        style: {
          color: '#999999',
          cursor: 'pointer',
          fontSize: '9px',
        },
        text: 'Highcharts.com',
      },
      title: {
        text: data.chartname,
        align: 'left',
        style: {
          color: 'var(--graph-text-color)',
          fontSize: '16px',
          fontWeight: 'lighter',
        },
        useHTML: false,
      },
      subtitle: {
        text: '',
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        squareSymbol: true,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        itemStyle: {
          color: 'var(--graph-text-color)',
          fontWeight: 'ligther',
          fontSize: 13,
        },
        y: 10,
      },
      accessibility: data.accessibility ? data.accessibility : null,
      xAxis: {
        gridLineWidth: 1,
        plotBands: data.plotBands != undefined ? data.plotBands : null,
        lineWidth: 1,
        lineColor: 'var(--graph-text-color)',
        type: 'datetime',
        tickAmount:50000,
        labels: {
          staggerLines:1,
          style: {
            color: 'var(--graph-text-color)',
            fontSize: 12,
          },
          title: {
            style: {
              color: 'var(--graph-text-color)',
            },
            text: data.label_x ? data.label_x : '',
          },
          step: 0.0001,
          formatter: function () {
            if (data.format || data.format != undefined) {
              return moment(this['value']).format('HH:mm:ss');
            } else if (data.dateformat == 'day') {
              return moment(this['value']).format('DD-MM-YY');
            } else {
              return this['value'];
            }
          },
        },
        title: {
          text: 'Time',
          y: -10,
        },
        categories: data.xaxis,
        //tickInterval: data.tickinterval_x,
        tickInterval: 5,
        time: {
          useUTC: false,
        },
      },
      yAxis: data.y
        ? data.y
        : {
            gridLineWidth: 1,
            lineColor: 'var(--graph-text-color)',
            gridLineColor: 'var(--gridline-color)',
            lineWidth: 0,
            // tickInterval: data.tickinterval_y,
            // plotLines: data.threshold ? data.threshold : null,
            endOnTick: false,
            labels: {
              style: {
                color: 'var(--graph-text-color)',
                // fontSize:data.y_label_size ? data.y_label_size : 11
                fontSize: 12,
              },
              formatter: function () {
                if (!this['isFirst']) {
                  return this['axis'].defaultLabelFormatter.call(this);
                }
              },
            },
            title: {
              style: {
                color: 'var(--graph-text-color)',
                fontSize: 14,
              },
              text: data.label_y,
            },
          },
      tooltip: {
        time: {
          useUTC: false,
        },
        xDateFormat: '%d-%m-%y, %H:%M:%S',
        headerFormat:
          '<span style="font-size:14px; font-weight:bolder">' +
          '<br/> </span>' +
          "<span style='font-size:14px;'>on {point.key}</span><table>",
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          data.unit +
          '</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
        backgroundColor: '#eeeeee',
        borderColor: '#eeeeee',
      },
      plotOptions: data.plotOptions ? data.plotOptions : this.plotOptions,
      series: data.yaxis,
    };
    if (this.width) {
      this.splinChartOptions.chart.width = this.width;
    }
    if (this.height) {
      this.splinChartOptions.chart.height = this.height;
    }
    return this.splinChartOptions;
  }
}
