<div class="htoolbar">
  <div class="firstrow">
    <div class="headertext">
      <a (click)="redirectPage('dashboard')" class="cursor-pointer"
        >Dashboard</a
      >
      /
      <a (click)="redirectPage('assetdetail')" class="cursor-pointer"
        >Asset Detail</a
      >
      /
      <b>Live Data</b>
    </div>
    <div class="toolbar">
      <input
        type="text"
        placeholder="Search"
        [(ngModel)]="searchContent"
        class="wid100 input searchBox"
      />
      <div *ngIf="filter.asset.data.length > 0">
        <app-dropdown
          [values]="filter.asset.data"
          [selected]="filter.asset.selected"
          [displayMember]="'customName'"
          (newSelectionEvent)="changeFilter($event, 'asset')"
          type="object"
        >
        </app-dropdown>
      </div>
      <button class="btn btn-default createBtn" (click)="download()">
        Download
      </button>
    </div>
  </div>
</div>

<div class="card bg-color p-20 dataset" >
  <div class="pT-10" style="height: calc(100vh - 270px); overflow:auto">
    <table class="table table-bordered table-striped">
      <thead>
        <tr style="background-color: #dddddd">
          <th scope="col" *ngFor="let header of datatable.headers">
            {{ header["displayMember"] }}
            <i
              (click)="sortColums(header.valueMember)"
              class="fas fa-sort sort-icon"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of datatable.data
              | filter: searchContent
              | paginate: { itemsPerPage: 10, currentPage: p };
            let idx = index
          "
        >
          <td *ngFor="let field of datatable.headers">
            <div [ngSwitch]="field.valueMember">
              <div *ngSwitchDefault>
                {{ data[field.valueMember] || "" }}
              </div>
              <!-- <div *ngSwitchCase="'rowid'">
                {{ p > 1 ? (p - 1) * 100 + (idx + 1) : idx + 1 }}
              </div> -->
              <div *ngSwitchCase="'value'">
                {{ data[field.valueMember] || "" }}
                <span *ngIf="data['unit'] === '�C' || data['unit'] === 'C'"
                  >&#x00B0;C</span
                >
                <span *ngIf="data['unit'] !== '�C' && data['unit'] !== 'C'">{{
                  data?.unit
                }}</span>
              </div>
              <div *ngSwitchCase="'servertimestamp'">
                <!-- <ng-container
                  *ngIf="
                    data[field.valueMember] &&
                    (data[field.valueMember]._seconds ||
                      data[field.valueMember].seconds)
                  "
                >
                  <ng-container *ngIf="data[field.valueMember]._seconds">{{
                    data[field.valueMember]._seconds * 1000 | date: "medium"
                  }}</ng-container>
                  <ng-container *ngIf="data[field.valueMember].seconds">{{
                    data[field.valueMember].seconds * 1000 | date: "medium"
                  }}</ng-container>
                </ng-container> -->
                <ng-container
                  *ngIf="
                    data[field.valueMember] &&
                    !data[field.valueMember]._seconds &&
                    !data[field.valueMember].seconds
                  "
                >
                  {{ data[field.valueMember] | date: "medium" }}
                </ng-container>
              </div>
              <ng-container *ngSwitchCase="'component'">
                <ng-container *ngIf="!data.edit">
                  <div class="threshold">
                    {{ data[field.valueMember] }}
                    <i
                      *ngIf="checkAction('Edit Tag')"
                      class="fa fa-pencil float-right action-btn"
                      (click)="data.edit = true"
                      title="Edit Tag Description"
                    ></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.edit">
                  <div class="threshold">
                    <input
                      type="text"
                      [(ngModel)]="data[field.valueMember]"
                      class="col-md-7"
                    />
                    <i
                      class="fa fa-close float-right"
                      (click)="data.edit = false"
                      title="Cancel"
                    ></i>
                    <i
                      class="fa fa-check float-right"
                      (click)="editTag(data)"
                      title="Save Tag Description"
                    ></i>
                  </div>
                </ng-container>
              </ng-container>
            </div>
           
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf = "datatable.data.length && datatable.data.length > 10 " style="position: absolute; bottom:-8px" >
      <app-pagination (changePage)="p = $event" ></app-pagination>
    </div>
  </div>
</div>
