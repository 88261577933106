<div class="topbar">
  <div class="brandlogo">
    <img src="./../../../../../assets/images/predictailogo.png" (click)="navigatePage()" />
  </div>
  <div class="topbarmenu">
    <div class="btn-group userMenu" ngbDropdown role="group">
    <!-- <ng-container *ngIf="this.selectedRoute!='genaidashboard'">
      <button class="btn btn-primary genaibtn" (click)="genAI()">
        Gen AI
      </button>
    </ng-container>
    <ng-container *ngIf="this.selectedRoute=='genaidashboard'">
      <button class="btn btn-primary genaibtn" (click)="navigateToHome()">
        <i class="fa fa-home" aria-hidden="true"></i>
      </button>
    </ng-container> -->
      <button class="btn" ngbDropdownToggle>
        <img
        class="user"
        src="../../../../../assets/images/user-svgrepo-com@3x.png"
      />
        <!-- <i class="fa fa-user-circle userIcon"></i> -->
        <!-- <span class="profile">{{userFirstLetter}}</span> -->
      </button>

      <div class="dropdown-menu" ngbDropdownMenu>
        <div class="userDetail">
          <span class="profile userFirstLetter">{{userFirstLetter}}</span>
          <div class="userName">{{currentUser?.name}}</div>
          <div class="userEmail">{{currentUser?.email}}</div>
        </div>
        <button ngbDropdownItem class="fsize14" (click)="open(changePasswordPopup, 'PASSWORD')">Change Password</button>
        <button ngbDropdownItem class="fsize14" (click)="logout()">Logout</button>
        <hr style="position:relative; bottom: 10px;">
        <div class="version">
          <span class="version_name"> Version 4.0.03.24
            <!-- ( {{ current_time.LastUpdate }} ) -->
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #changePasswordPopup let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Change Password</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closePopup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form
    [formGroup]="passwordForm"
    (ngSubmit)="editSave(passwordForm, 'password')"
    autocomplete="off"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-md-4">
          <label>Old Password</label>
          <input
            type="password"
            name="oldpassword"
            class="form-control"
            formControlName="oldpassword"
            class="w-100"
            autocomplete="new-password"
            maxlength="25"
          />
          <div
            *ngIf="
              passwordForm.get('oldpassword')?.touched &&
              passwordForm.get('oldpassword')?.invalid
            "
            class="text-danger"
          >
            <div
              *ngIf="passwordForm.get('oldpassword')?.errors?.required"
              class="errorTextPawwsord"
            >
              Password is Required.
            </div>
            <div
              *ngIf="passwordForm.get('oldpassword')?.errors?.minlength"
              class="errorTextPawwsord"
            >
              Password should have 8 characters
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label>New Password</label>
          <input
            type="password"
            name="password"
            class="form-control"
            formControlName="password"
            class="w-100"
            autocomplete="new-password"
            maxlength="25"
          />
          <div style="font-size: 10px">
            A-Z, a-z, 0-9 and !@#$%^&*()_+-=[\];':"|,.>/? <br> Minimum length 8 and Maximum length 25
          </div>
          <div
            *ngIf="
              passwordForm.get('password')?.touched &&
              passwordForm.get('password')?.invalid
            "
            class="text-danger"
          >
            <div
              *ngIf="passwordForm.get('password')?.errors?.required"
              class="errorTextPawwsord"
            >
              Password is Required.
            </div>
            <div
              *ngIf="passwordForm.get('password')?.errors?.minlength"
              class="errorTextPawwsord"
            >
              Password should have 8 characters
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label>Confirm New Password</label>
          <input
            type="password"
            name="confirmpassword"
            class="form-control"
            formControlName="confirmpassword"
            class="w-100"
            maxlength="25"
          />
          <div
            *ngIf="
              passwordForm.get('confirmpassword')?.touched &&
              passwordForm.get('confirmpassword')?.invalid
            "
            class="text-danger"
          >
            <div
              *ngIf="passwordForm.get('confirmpassword')?.errors?.required"
              class="errorTextInput"
            >
              Confirm password is Required.
            </div>
            <div
              *ngIf="passwordForm.get('confirmpassword')?.errors?.notEquivalent"
              class="errorTextInput"
            >
              Password does not match.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        [disabled]="!passwordForm.valid"
        class="btn btn-info"
      >
        <i class="fa fa-save"></i> Save
      </button>
    </div>
  </form>
</ng-template>
