import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortalphanum'
})
export class SortalphanumPipe implements PipeTransform {

  transform(array: any, field: string): any[] {
    let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

array.sort((a, b) => collator.compare(a[field], b[field]))
return array
  }

}
