<div class="graphcard" *ngIf="cardData">
  <div class="left">
    <div class="title">{{ cardData.title }}</div>
    <div   style="
    overflow-x: hidden;
    width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
  ">
      <span
        class="value"
      
        >{{ cardData.value != null && cardData.value >= 0? cardData.value &nbsp;: 'NA'}}
      </span>
      <span *ngIf="cardData.value >= 0 && cardData.unit == 'Deg C'"
        >&#8451;</span
      >
      <span
        *ngIf="cardData.value >= 0 && cardData.unit != 'Deg C'"
        class="unit"
        >{{ cardData.unit }}</span
      >
    </div>
  </div>
  <div class="right">
    <i class="{{ cardData.icon }}"></i>
    <!-- <img [src]="cardData.image" /> -->
  </div>
</div>
<mat-progress-bar
  *ngIf="cardData?.showProgress"
  mode="determinate"
  [value]="cardData?.value"
></mat-progress-bar>
