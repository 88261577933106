import { AfterViewInit, Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { UtilService } from './services/util.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {filter} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'jspl-web';
  showLoader:boolean=false;
  constructor(private authService:AuthService,private activatedRoute: ActivatedRoute,private changeDet: ChangeDetectorRef,private utilService:UtilService, private router: Router) {
    this.utilService.getLoaderValue().subscribe((value)=>{
      this.showLoader = value;
    });
    const navEndEvents =router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event :any) =>{
      const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe(data => {
          gtag("config", environment.prod_tracking, {
            'user_id': localStorage.getItem("email"),
            'page_path': event.urlAfterRedirects,
            'page_title': data.title,
            'user_name':localStorage.getItem('username'),
            'role':localStorage.getItem('role')
          });
        });
    })
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  ngOnInit() {
    this.authService.autoAuthUser();
  }
  // ngAfterViewInit(): void{
  //   this.showLoader = true;
  //   this.changeDet.detectChanges();
  // }
}
