import { Component, OnInit } from '@angular/core';
import moment, { Moment } from "moment";
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  selectedDateRanges: any = {};
  assetData: any = [];
  selectedAssetData: any;
  datatable: any = {
    header: [
      {displayValue:"Asset",propertyValue:"asset"},
      {displayValue:"Parameter",propertyValue:"tag"},
      {displayValue:"Value",propertyValue:"value"},
      {displayValue:"Unit",propertyValue:"unit"},
      {displayValue:"Upper Threshold",propertyValue:"upper"},
      {displayValue:"Lower Threshold",propertyValue:"lower"}
    ],
    data: []
  };
  thresholdTags:any = [];
  historyDataSubscription:any;
  constructor(private fs: FirestoreService) { }

  ngOnInit(): void {
    this.datatable.data = [];
    this.selectedDateRanges = {
      start: moment().startOf('day'),
      end: moment()
    };
    let assetParams = {
      category:"ASSETS"
    };
    this.fs.getData(assetParams).subscribe((response: any) => {
      this.assetData = response.data;
      this.selectedAssetData = this.assetData[0];
      this.getThresholdTags();
      this.datatable.data = this.getReportData();
    });
    
  }

  getThresholdTags() {
    let Params = {
      category:"THRESHOLDS",
      conditions:[
        {
          key:"assetcode",
          symbol:"==",
          value:this.selectedAssetData.assetcode
        },
        {
          key:"removedtag",
          symbol:"==",
          value:false
        }
      ]
    };
    this.fs.getData(Params).subscribe((response: any) => {
      if(response.status=="success") {
        this.thresholdTags = response.data;
      }
    });
  }

  filterChange(data: any, type: string) {
    if(type==="date") {
      this.selectedDateRanges.start = data.start;
      this.selectedDateRanges.end = data.end;
    }
    else if(type==="asset") {
      this.selectedAssetData = data;
    }
    this.datatable.data = this.getReportData();
  }

  getReportData() {

    let filter = [
      {
        key: "timestamp",
        symbol: ">=",
        value: moment(this.selectedDateRanges.start).toDate()
      },
      {
        key: "timestamp",
        symbol: "<=",
        value: moment(this.selectedDateRanges.end).toDate()
      }
    ];
    let historyParams = {
      asset: this.selectedAssetData.asset,
      assetcode: this.selectedAssetData.assetcode,
      assettype: this.selectedAssetData.assettype,
      defaultAssetcode: this.selectedAssetData.originalAssetcode,
      startDate: this.selectedDateRanges.start,
      endDate: this.selectedDateRanges.end,
      conditions: filter,
      category: "HISTORY",
      //limit: 500
    };
    this.historyDataSubscription = this.fs.getData(historyParams).subscribe((response: any) => {
      this.datatable.data = [];
      if(response.status=="success") {
        response.data.forEach((assetData:any) => {
          assetData.tags.forEach((tagData:any) => {
            tagData.asset = assetData.asset;
            tagData.assetcode = assetData.assetcode;
            tagData.assettype = assetData.assettype;
            let filterTag = this.thresholdTags.filter((tagConfig)=>{
              return tagConfig.tag == tagData.tag
            });
            if(filterTag && filterTag.length>0) {
              tagData.upper = filterTag[0].upper;
              tagData.lower = filterTag[0].lower;
            }
            this.datatable.data.push(tagData);
          });
        });
      }
      return this.datatable.data;
    });

    // return [
    //   {
    //     "Line": "Rail Mill",
    //     "Asset": "Asset 1",
    //     "Parameter": "-",
    //     "Value": "-",
    //     "Unit": "-",
    //     "Upper Threshold": "-",
    //     "Lower Threshold": "-"
    //   },
    //   {
    //     "Line": "Rail Mill",
    //     "Asset": "Asset 1",
    //     "Parameter": "-",
    //     "Value": "-",
    //     "Unit": "-",
    //     "Upper Threshold": "-",
    //     "Lower Threshold": "-"
    //   },
    //   {
    //     "Line": "Rail Mill",
    //     "Asset": "Asset 1",
    //     "Parameter": "-",
    //     "Value": "-",
    //     "Unit": "-",
    //     "Upper Threshold": "-",
    //     "Lower Threshold": "-"
    //   },
    //   {
    //     "Line": "Rail Mill",
    //     "Asset": "Asset 1",
    //     "Parameter": "-",
    //     "Value": "-",
    //     "Unit": "-",
    //     "Upper Threshold": "-",
    //     "Lower Threshold": "-"
    //   }
    // ]
  }



}
