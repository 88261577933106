export const environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyAQtYLN-g7ZZi4rLa6OabUV3BZ34ZgLd04",
  //   authDomain: "las-prod-1.firebaseapp.com",
  //   databaseURL: "https://las-prod-1.firebaseio.com",
  //   projectId: "las-prod-1",
  //   storageBucket: "las-prod-1.appspot.com",
  //   messagingSenderId: "896074191368",
  // },
  firebase: {
    apiKey: 'AIzaSyDisxgAovljEF85L49NboPyr2hBprqJgC0',
    authDomain: 'jspl-355706.firebaseapp.com',
    databaseURL: 'https://jspl-355706-default-rtdb.firebaseio.com',
    projectId: 'jspl-355706',
    storageBucket: 'jspl-reports',
    messagingSenderId: '20739175086',
    appId: '1:20739175086:web:5532d6d9995cf434cfb33f',
    measurementId: 'G-0L0BZLNJJR',
  },
  build_number: 0,
  mode: 'firebase',
  api_endpoint: 'http://localhost:1990/',
  socket_endpoint: 'http://localhost:1990',
  appVersion: require('../../package.json').version,
  dev: true,
  cloud: true,
  prod_tracking: 'G-TZKHQGRY0M',
  // firestore_collection_url:"/Asset_OEE/JSPL",
  cloudFunctionUrl:
    'https://asia-south1-jspl-355706.cloudfunctions.net/PredictAI_JSPL/',
  // cloudFunctionUrl:"https://asia-south1-las-prod-1.cloudfunctions.net/PredictAI_JSPL/", //production
  // cloudFunctionUrl:"http://192.168.1.123:7000/", //Auth Test
  // cloudFunctionUrl:"https://asia-south1-las-prod-1.cloudfunctions.net/testingPredictAI_JSPL/"    //test
  QASAPSpectro: 'https://jsplpoqas.jspl.com:8400/RESTAdapter/BLPIoT_Spectro',
  QAXRFPM: 'https://jsplpoqas.jspl.com:8400/RESTAdapter/BLPIoT_XRFPM',
  QAXRFIP: 'https://jsplpoqas.jspl.com:8400/RESTAdapter/BLPIoT_XRFIP',
  QASRFRM: 'https://jsplpoqas.jspl.com:8400/RESTAdapter/BLPIoT_XRFRM',
  PRODXRFPM: 'https://vipcipo.jspl.com:8400/RESTAdapter/BLPIoT_XRFPM',
  PRODXRFIP: 'https://vipcipo.jspl.com:8400/RESTAdapter/BLPIoT_XRFIP',
  PRODSRFRM: 'https://vipcipo.jspl.com:8400/RESTAdapter/BLPIoT_XRFRM',
  DevSAPSpectro: 'https://jsplpodev.jspl.com:8403/RESTAdapter/BLPIoT_Spectro',
  DevXRFPM: 'https://jsplpodev.jspl.com:8403/RESTAdapter/BLPIoT_XRFPM',
  DevXRFIP: 'https://jsplpodev.jspl.com:8403/RESTAdapter/BLPIoT_XRFIP',
  DevSRFRM: 'https://jsplpodev.jspl.com:8403/RESTAdapter/BLPIoT_XRFRM',
  ProdSAPSpecro: 'https://vipcipo.jspl.com:8400/RESTAdapter/BLPIoT_Spectro',
  aggregationURL: 'https://jspl-dserver-5rtvyi44aq-uc.a.run.app',
};
