import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { Overlay, BlockScrollStrategy, ScrollStrategyOptions, CdkConnectedOverlay, ScrollStrategy } from '@angular/cdk/overlay';
import _ from 'lodash';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
   return () => overlay.scrollStrategies.block();
}

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
  ],
})
export class SearchableDropdownComponent implements OnInit {
  values = [];
  isFilterOption: boolean = false;
  duplicateSelected: any;
  @Input('values') set Values(val) {
    this.values = val;
  }
  @Input() displayMember: any = 'name';
  @Input() selectCaption: string = '';
  @Input() maxTags = 4;
  @Input() type = 'object';
  variables: any = [];
  filteredList: any = [];
  @Input() selectedData;
  @Output() newSelectionEvent = new EventEmitter<string>();
  @Input() isMultiple: boolean = false;
  // _values:any = [];
  // @Input('values')
  // set in(val: any) {
  //   this._values = val;
  //   this.variables = this._values;
  // }
  borderStyle = 'default-border';
  @Input('borderStyle')
  set in(val: string) {
    if (val) {
      this.borderStyle = val;
    } else {
      this.borderStyle = 'default-border';
    }
  }
  scrollStrategy: ScrollStrategy;
  noTags: boolean = false;
  selectedList: any = [];
  selectedFilteredList: any = [];
  constructor(private util: UtilService) {}
  filterText: string = '';

  get filteredVariables(): string[] {
    return this.variables.filter((variable) =>
      variable.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }
  ngOnInit(): void {
    this.variables = this.values;
    this.filteredList = this.variables;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.values && changes.values.currentValue) {
        this.values = changes.values.currentValue;
        this.variables = this.values;
        this.filteredList = this.variables;
      }
      if (changes.selected && changes.selected.currentValue) {
        if (typeof changes.selected.currentValue == 'object') {
          this.selectedData = { ...changes.selected.currentValue };
        } else {
          this.selectedData = changes.selected.currentValue;
        }
      }
    }
  }
  filterData(event) {
    this.filteredList = event;
    if (this.values.length == this.filteredList.length) {
      this.isFilterOption = false;
    } else {
      this.isFilterOption = true;
    }
  }
  selectionChanged(event: any) {
    if (event.value.length === 0) {
      this.noTags = true;
    } else {
      this.noTags = false;
    }
    if (this.isMultiple) {
      if (event.value.length <= this.maxTags) {
        if (this.isFilterOption) {
          this.selectedFilteredList = [...event.value, ...this.selectedList];
        } else {
          this.selectedList = [...event.value, ...this.selectedFilteredList];
        }
        this.selectedList = [...event.value, ...this.selectedFilteredList];
        this.selectedList = _.uniqBy(this.selectedList, this.displayMember);
        this.newSelectionEvent.emit(this.selectedList);
        this.selectedFilteredList = [];
      } else {
        event.value = event.value.splice(0, event.value.length - 1);
        this.selectedData = event.value;
        this.util.showSnakbarMessage(
          'warning',
          'Please select maximum ' + this.maxTags + ' tags.',
          'end',
          'top'
        );
      }
    } else {
      this.newSelectionEvent.emit(event.value);
    }
  }
}
