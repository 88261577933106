import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-card',
  templateUrl: './single-card.component.html',
  styleUrls: ['./single-card.component.scss']
})
export class SingleCardComponent implements OnInit {
  cardData:any = {}
  @Input('cardData')
  set in(val){
    // if(val){
    //   if(val.value)
    //   val.value  = val.value.toFixed(2)
    // }
    this.cardData = val;
  } 
    constructor() { }
  
    ngOnInit() {
    }
}
