import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import moment, { Moment } from "moment";

@Component({
  selector: 'app-datetimerangepicker',
  templateUrl: './datetimerangepicker.component.html',
  styleUrls: ['./datetimerangepicker.component.scss']
})
export class DatetimerangepickerComponent implements OnInit {

  @Input() dates: any;
  @Output() dateChangeEvent = new EventEmitter();

  startDate;
  endDate;

  constructor() {
    this.startDate = moment().startOf('day').format('YYYY-MM-DDTHH:mm');
    this.endDate = moment().format('YYYY-MM-DDTHH:mm');
  }

  ngOnInit() {
    if (this.dates && this.dates.start && this.dates.end) {
      this.startDate = this.dates.start.format('YYYY-MM-DDTHH:mm');
      this.endDate = this.dates.end.format('YYYY-MM-DDTHH:mm');
    }
  }

  selectionChanged(event: any, type: any) {
    if(type=="start") {
      this.startDate = moment(event.currentTarget.value).format('YYYY-MM-DDTHH:mm');
    } else if(type=="end") {
      this.endDate = moment(event.currentTarget.value).format('YYYY-MM-DDTHH:mm');
    }
    this.dateChangeEvent.emit({start:this.startDate, end:this.endDate});
  }

}