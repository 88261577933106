import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import * as _ from 'lodash';
import { SubjectService } from 'src/app/services/subject.service';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { environment } from './../../../../../environments/environment';
import { ExportToCsv } from 'export-to-csv';
import { UtilService } from 'src/app/services/util.service';
import { SortPipe } from 'src/app/Pipes/sort.pipe';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss'],
})
export class DatasetComponent implements OnInit {
  uniqueCode: string = '';
  subsink = new SubSink()
  searchContent: string = '';
  momemtFn = moment();
  filter: any = {
    asset: {
      data: [],
      selected: {},
    },
  };

  csvoptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  datatable: any = {
    headers: [
      {
        displayMember: 'S.No',
        valueMember: 'rowid',
      },
      {
        displayMember: 'Tag',
        valueMember: 'tag',
      },
      {
        displayMember: 'Tag Description',
        valueMember: 'component',
      },
      {
        displayMember: 'Value',
        valueMember: 'value',
      },
      {
        displayMember: 'Timestamp',
        valueMember: 'servertimestamp',
      },
    ],
    totalData: [],
    data: [],
  };
  mqttliveSubscription: Subscription | undefined;
  componentSubscription: any = {
    asset: Subscription,
  };
  p: number = 1;
  ascDescObj: any = {};

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fs: FirestoreService,
    private subject: SubjectService,
    private util: UtilService,
    private sortPipe:SortPipe
  ) {
    this.activeRoute.params.subscribe((param) => {
      this.uniqueCode = param.code;
    });
    let assetParams = {
      category: 'ASSETS',
      conditions:[{key:"is_maintenance_asset",symbol:"==",value:false}]
    };
    this.componentSubscription.asset = this.fs
      .getData(assetParams)
      .subscribe((response: any) => {
        if (response.status == 'success') {
          this.filter.asset.data = response.data;
          this.filter.asset.data.forEach((element) => {
            element.customName = element.customName
              ? element.customName
              : element.asset;
          });
          if (this.uniqueCode) {
            let assetFilter = this.filter.asset.data.filter((assetdata) => {
              return assetdata.id == this.uniqueCode;
            });
            if (assetFilter.length > 0) {
              this.filter.asset.selected = assetFilter[0];
              this.loadTagData(assetFilter[0])
            }
          }
        }
      });
  }

  ngOnInit(): void {
    if (!environment.cloud) {
      this.socketconnection();
    }
  }
  redirectPage(type: string) {
    if (type == 'dashboard') {
      this.router.navigate(['page/assetgrid']);
    } else if (type == 'assetdetail') {
      this.router.navigate(['page/assetgrid/assetdetails', this.uniqueCode]);
    }
  }
  changeFilter(data: any, type: string) {
    this.subsink.unsubscribe()
    // if(type == "asset"){
    //   if(data.asset) {
    //     this.subject.setSelectedAssetData(data);
    //     this.router.navigate(["page/assetdetails", data.id]);
    //   }
    // }
    this.p = 1;
    this.filter[type].selected = data;
    if (type == 'asset') {
      this.util.setGlobalSelectedAsset(data.assetcode);
    }
    this.loadTagData(data);
  }
  loadTagData(asset: any) {

    let conditions = [
      {
        key: "assetcode",
        symbol: "==",
        value: asset.assetcode
      },
      {
        key: "removedtag",
        symbol: "==",
        value: false
      }
    ];
    let tagParams = {
      category: "THRESHOLDS",
      conditions: conditions
    };
    this.util.setLoaderValue(true);
    this.subsink.sink= this.fs.getData(tagParams).subscribe((result: any) => {
      this.util.setLoaderValue(false);
      if (result.status == "success") {
        let customData:any = []
        result.data.forEach(f =>{
        let obj = {tagId:f.id,component:f.component,tag:f.tag}
        customData.push(obj)
        })
        this.loadLiveTags(customData);
        // this.loadLiveTags()
      }
    }, (err) => {
      this.util.showSnakbarMessage("error", err.error.message, "end", "top");
    });
  }
  loadLiveTags(tags) {
    let params = {
      category: 'CURRENTDATA',
      conditions: [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.filter['asset'].selected.assetcode,
        },
        // {
        //   key: "asset",
        //   symbol: "==",
        //   value: this.filter["asset"].selected.asset
        // }
      ],
      assetcode:this.filter['asset'].selected.assetcode
    };
    this.subsink.sink = this.fs
      .getLiveData(params)
      .subscribe((response: any) => {
        if (response) {
          let liveData = response;
          let totalTags: any = [];
          liveData.forEach((element: any) => {
            totalTags = _.unionBy(totalTags, element.tags, 'tag');
          });
          totalTags = totalTags.filter(f =>{
            return f.tag != ''
          })
          totalTags.forEach(t =>{
            tags.forEach(c =>{
              if(t.tag == c.tag){
                t['component'] = c.component
                t['tagId'] = c.tagId
              }
            })
          })
          this.datatable.data = totalTags;
          this.datatable.data.forEach((element: any,index) => {
            element['rowid'] = index+1
            element['component']
            element['value'] = element['value'] == 0 ? '0' : element['value'];
            // if(element['value'] > 100000){
            //   element['value'] = 1
            // }
            if(element.servertimestamp._seconds){
              element.servertimestamp = moment(element.servertimestamp._seconds * 1000).valueOf()
            }else if (element.servertimestamp.seconds){
              element.servertimestamp = moment(element.servertimestamp.seconds * 1000).valueOf()
            }
          });
        }
      });
  }

  socketconnection() {
    this.subject.setupSocketConnection();
    this.mqttliveSubscription = this.subject
      .getHistoryUpdateListener()
      .subscribe((historyUpdate: any) => {
        if (historyUpdate && historyUpdate.messageid) {
          this.subject.setLastUpdatedDate(historyUpdate.messageid);
        }
        if (
          historyUpdate.asset == this.filter.asset.selected.asset &&
          historyUpdate.assetcode == this.filter.asset.selected.assetcode
        ) {
          this.datatable.data = _.unionBy(
            historyUpdate.tags,
            this.datatable.data,
            'tag'
          );
          this.datatable.data.forEach((element: any) => {
            element.date = moment(element.servertimestamp)
              .tz('Asia/Kolkata')
              .format();
          });
        }
      });
  }

  ngOnDestroy() {
    for (let type in this.componentSubscription) {
      this.componentSubscription[type].unsubscribe();
    }
    this.subsink.unsubscribe()
  }
  download() {
    let array: any = { csv: [], title: '', filename: '' };
    array.title = this.filter.asset.selected.asset + '-' + 'Tags';
    array.filename = array.title;
    let final: any = [];
    this.datatable.data.forEach((d, i) => {
      let obj = {};
      obj['No'] = i + 1;
      obj['Tag'] = d.tag;
      obj['Description'] = d.component;
      obj['Value'] = d.value;
      obj['Timestamp'] = d.servertimestamp
        ? moment(d.servertimestamp).format("DD-MM-YYYY, HH:mm:ss")
        : moment(d.servertimestamp).toDate();
      final.push(obj);
    });
    array.csv = final;
    this.csvexport(array);
  }
  csvexport(data) {
    if (data) {
      this.csvoptions['title'] = data.title;
      this.csvoptions['filename'] = data.filename;
      const csvExporter = new ExportToCsv(this.csvoptions);
      csvExporter.generateCsv(data.csv);
    }
  }
  sortColums(column,order?){
    if(!order){
     if(this.ascDescObj[column]){
       this.ascDescObj[column] = this.ascDescObj[column] == 'asc'? 'desc':'asc';
    }else{
      this.ascDescObj[column] = 'desc'
    }
    }else{
     this.ascDescObj[column] = order
    }
    let sortedData = this.sortPipe.transform(this.datatable.data,this.ascDescObj[column],column)
    setTimeout(() =>{
    this.datatable.data = sortedData
    })
   }
   editTag(data){
    this.fs.fireStoreDocUpdate('THRESHOLDS',data.tagId,{component:data.component}).subscribe((res:any) =>{
      if(res.status =='success'){
        this.util.showSnakbarMessage("success", 'Tag Description Updated Successfully', "end", "top");
      }else{
        this.util.showSnakbarMessage("error", 'Tag Description Update Failed', "end", "top");
      }
      data.edit =false
    })
   }
   checkAction(action) {
    let access = this.util.getAccessForAction("Dashboard",'',action);
    return access;
  }
}
