<div class="card shiftwiseWidget">
    <div class="cardTitle">
        <div class="row">
            <div class="col-12 pad0">{{widgetInfo?.title}}</div>
        </div>
    </div>
    <div class="padding10">
       <div *ngIf="widgetInfo.options?.shiftwiseCategory=='Charge'">
            <div class="row">
                <div class="padding10 col-6" >
                    <div class="card cardWidget">
                        <div class="label">Planned</div>
                        <div class="value">56 <span class="unit"></span></div>
                    </div>
                </div>
                <div class="padding10 col-6" >
                    <div class="card cardWidget">
                        <div class="label">Charged</div>
                        <div class="value">47 <span class="unit"></span></div>
                    </div>
                </div>
            </div>
            <table class="wid100">
                <tr >
                    <td>Shift A</td>
                    <td>101 Pc</td>
                </tr>
                <tr >
                    <td>Shift B</td>
                    <td>101 Pc</td>
                </tr>
                <tr >
                    <td>Shift C</td>
                    <td>101 Pc</td>
                </tr>
            </table>
       </div>
       <div *ngIf="widgetInfo.options?.shiftwiseCategory=='Discharge'">
        <table class="wid100">
            <tr >
                <td>Shift A</td>
                <td>89 Pc</td>
                <td>101 Ton</td>
            </tr>
            <tr >
                <td>Shift B</td>
                <td>90 Pc</td>
                <td>101 Ton</td>
            </tr>
            <tr >
                <td>Shift C</td>
                <td>92 Pc</td>
                <td>101 Ton</td>
            </tr>
        </table>
       </div>
    </div>
</div>