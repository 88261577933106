import { Component, Input, OnInit } from '@angular/core';
import { stat } from 'fs';
import _, { result } from 'lodash';
import moment from 'moment-timezone';
import { SubSink } from 'subsink';

import { FirestoreService } from 'src/app/services/firestore.service';
import * as kpiData from '../../../../../assets/json/kpiDAta.json';
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit {
  kpiList = [
    { name: 'Charged', value: 'charged' },
    { name: 'Discharged', value: 'discharge' },
    { name: 'Fuel Consumption', value: 'fuel' },
    { name: 'Running Hours', value: 'run_hrs' },
  ];
  selectedKpi: any = this.kpiList[0];
  selectedAssetData: any;
  selectedFilter: any;
  startDate: any;
  endDate: any;
  datapoints: any = {};
  globalCards: any[];
  graphs: any[];
  shiftGraphs: any[];
  shiftWiseData: any[];
  date_frequency: any;
  hourlyProduction: any = [];
  formatDate: any;
  productionNo: any;
  indexval: any;
  holdDate: any;
  index: any;
  rhf2Data: any;
  p;
  @Input('values')
  set in(val) {
    this.graphs = [];
    this.globalCards = [];
    this.shiftWiseData = [];
    val.asset.asset == 'tandem'
      ? (val.asset.asset = 'Tandem')
      : val.asset.asset;
    this.selectedAssetData = val.asset;
    this.selectedFilter = val.date;
    this.initializeData(val);
  }
  reportSelected: any = 'table';
  reportTableHead = {
    RHF2: {
      headers: [
        { name: 'Date & Time', id: 'date' },
        { name: 'Production Nos', id: 'production' },
        // { name: 'Rate of Production', id: 'rateOfProduction' },
        { name: 'Running Hours', id: 'runningHrs' },
        { name: 'Fuel Consumption (BFG)', id: 'fuel' },
        { name: 'Fuel Consumption (HFO)', id: 'hfo' },
        { name: 'Weight (Tonn)', id: 'weight' },

      ],
      data: [],
    },
    BD: {
      headers: [
        { name: 'Date & Time', id: 'date' },
        { name: 'Production (Nos)', id: 'production' },
        { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption (BFG)', id: 'fuel' },
        // { name: 'Fuel Consumption (HFO)', id: 'hfo' },
        // { name: 'Weight (Tonn)', id: 'weight' },
        // { name: 'Rate of Production', id: 'rateOfProduction' },
        // { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption', id: 'fuel' },
      ],
      data: [],
    },
    BD2: {
      headers: [
        { name: 'Date & Time', id: 'date' },
        { name: 'Production (Nos)', id: 'production' },
        { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption (BFG)', id: 'fuel' },
        // { name: 'Fuel Consumption (HFO)', id: 'hfo' },
        // { name: 'Weight (Tonn)', id: 'weight' },
        // { name: 'Rate of Production', id: 'rateOfProduction' },
        // { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption', id: 'fuel' },
      ],
      data: [],
    },
    Tandem: {
      headers: [
        { name: 'Date & Time', id: 'date' },
        { name: 'Production (Nos)', id: 'production' },
        { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption (BFG)', id: 'fuel' },
        // { name: 'Fuel Consumption (HFO)', id: 'hfo' },
        // { name: 'Weight (Tonn)', id: 'weight' },
        // { name: 'Rate of Production', id: 'rateOfProduction' },
        // { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption', id: 'fuel' },
      ],
      data: [],
    },'HOT SAW': {
      headers: [
        { name: 'Date & Time', id: 'date' },
        { name: 'Production (Nos)', id: 'production' },
        { name: 'Hourly Cuts (Nos)', id: 'hourlyCuts' },
        { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption (BFG)', id: 'fuel' },
        // { name: 'Fuel Consumption (HFO)', id: 'hfo' },
        // { name: 'Weight (Tonn)', id: 'weight' },
        // { name: 'Rate of Production', id: 'rateOfProduction' },
        // { name: 'Running Hours', id: 'runningHrs' },
        // { name: 'Fuel Consumption', id: 'fuel' },
      ],
      data: [],
    },
  };
  subsink=new SubSink()
  RHF2Data:any=[]
  total_run_hours_rhf2:any=0
  constructor(private fs: FirestoreService) {}

  ngOnInit(): void {}
  setDates(val) {
    if (val.date == 'Custom') {
      this.startDate = val.customDates.startDate.$d;
      this.endDate = val.customDates.endDate.$d;
    } else if (val.date == 'Today') {
      this.startDate = moment().startOf('day').add(6, 'hours').toDate();
      this.endDate = moment(this.startDate).add(24, 'hours').toDate();
    } else if (val.date == 'Yesterday') {
      this.startDate = moment()
        .subtract(1, 'days')
        .startOf('day')
        .add(6, 'hours')
        .toDate();
      this.endDate = moment(this.startDate).add(24, 'hours').toDate();
    } else if (val.date == 'Week') {
      this.startDate = moment()
        .subtract(7, 'days')
        .startOf('day')
        .add(6, 'hours')
        .toDate();
      this.endDate = moment()
        .subtract(1, 'day')
        .startOf('day')
        .add(6, 'hours')
        .toDate();
    } else if (val.date == 'Month') {
      this.startDate = moment()
        .subtract(30, 'days')
        .startOf('day')
        .add(6, 'hours')
        .toDate();
      this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
    }

    this.getRunningHrs();
  }
  initializeData(val) {
    if (kpiData['default'][this.selectedAssetData.assetcode]) {
      let kpi = JSON.stringify(
        kpiData['default'][this.selectedAssetData.assetcode]
      );
      kpi = JSON.parse(kpi);
      if (kpi['global']) {
        this.globalCards = kpi['global'];
      }
      if (kpi['graphData']) {
        this.graphs = kpi['graphData'];
      }
      if (kpi['shiftData']) {
        this.shiftGraphs = kpi['shiftData'];
      }
      this.setDates(val);
    }
  }
  getRunningHrs(){
    let filter = [
      {
        key: 'assetcode',
        symbol: '==',
        value: 'OPCUARHF2',
      },
      {
        key: 'type',
        symbol: '==',
        value: 'hourly',
      },
      {
        key: 'Date',
        symbol: '>=',
        value: this.startDate,
      },
      {
        key: 'Date',
        symbol: '<=',
        value: this.endDate,
      },
      {
        key: 'asset',
        symbol: '==',
        value: 'RHF2',
      },


    ];
    let params = {
      asset: 'RHF2',
      conditions: filter,
    };
    this.RHF2Data=[]
     this.fs.getkpihistoryData(params).subscribe((data: any) => {
     let  result=data.docs
      if(result.length){
        result.forEach((data:any)=>{
         this.RHF2Data.push(data.data())
        })
       this.RHF2Data.forEach((result:any)=>{
          result.KPIS.forEach((kpi:any)=>{
            if(kpi.name=='Houly_running_hours'){
              this.total_run_hours_rhf2+=kpi.value?Number(kpi.value):0
            }
          })
        })
      }
        this.getAnalyticsData()

    })
  }
  getAnalyticsData() {
    let filter = [
      {
        key: 'assetcode',
        symbol: '==',
        value: this.selectedAssetData.assetcode,
      },
      {
        key: 'asset',
        symbol: '==',
        value: this.selectedAssetData.asset,
      },
      {
        key: 'Date',
        symbol: '>=',
        value: this.startDate,
      },
      {
        key: 'Date',
        symbol: '<=',
        value: this.endDate,
      },
    ];
    let params = {
      asset: this.selectedAssetData.asset,
      conditions: filter,
    };
    if (kpiData['default'][this.selectedAssetData.assetcode]) {
      let result:any=[]
        let seggregateData:any=[]
      this.fs.getkpihistoryData(params).subscribe((data: any) => {
        result=data.docs
        if(result.length){
          result.forEach((data:any)=>{
            seggregateData.push(data.data())
          })
          seggregateData=  _.groupBy(seggregateData,'type')
          this.hourlyCalculations(seggregateData.hourly);
          this.seggregateData(seggregateData.hourly, 'hourly');
            this.seggregateData(seggregateData.shift, 'shift');
          }else{

          }

        // if (data.status == 'success' && data.data.length) {
        //   let segregatedData = _.groupBy(data.data, 'type');
        //   this.hourlyCalculations(segregatedData.hourly);
        //   this.seggregateData(segregatedData.hourly, 'hourly');
        //   this.seggregateData(segregatedData.shift, 'shift');
        // } else {
        // }
      });
    }
  }
  hourlyCalculations(data) {
    let totalRunHrs = 0;
    let totalcuts:any=0
        let totalTonns = 0;
        let totalBlooms: any = 0;
        let bloomsPerHr: any = 0;
    if(this.selectedAssetData.assetcode=='OPCUARHF2'){
    data.forEach((result:any)=>{
      result.KPIS.forEach((kpi:any)=>{
        if(kpi.name=='Production_Rate(blooms/hour)'){
          totalBlooms+=kpi.value
        }
        if(kpi.name=='Total_Blooms'){
          bloomsPerHr+=kpi.value
        }
        else if(kpi.name=='Houly_running_hours'){
          totalRunHrs+=kpi.value
        }
        else if(kpi.name=='Houly_tonnage'){
          totalTonns+=kpi.value
        }
      })
    })
this.globalCards[0].value=totalBlooms
this.globalCards[1].value=(totalBlooms/(Number(totalRunHrs)/60)).toFixed(2)
this.globalCards[2].value=(totalTonns/(Number(totalRunHrs)/60)).toFixed(2)
}
else{
  let totalBlooms:any=0
  let totalProduction:any=0
  let averageEff:any=0
  data.forEach((result:any)=>{
result.KPIS.forEach((kpi:any)=>{
  if(kpi.name=='Production_Rate(blooms/hour)'){
    totalBlooms+=kpi.value
  }
  else if(kpi.name=='Production_Rate(blooms/hour)'){
    totalProduction+=kpi.value
  }
  else if(kpi.name=='Efficiency(in %)'){
    averageEff+=kpi.value
  }
  if(this.selectedAssetData.assetcode=='OPCUAHOT_SAW'){
   if(kpi.name=='houly_Cuts'){
    totalcuts+=kpi.value
  }
}
})
  })
  this.globalCards[0].value=totalBlooms
this.globalCards[1].value=(totalBlooms/(Number(this.total_run_hours_rhf2)/60)).toFixed(2)
this.globalCards[2].value=(averageEff/data?.length).toFixed(2)
if(this.selectedAssetData.assetcode=='OPCUAHOT_SAW'){
  this.globalCards[5].value=totalcuts
}
}
if(this.selectedAssetData.assetcode!='OPCUARHF2')
 data.forEach((result: any) => {
   result.KPIS.forEach((kpi: any) => {
    if (kpi.name == 'Houly_running_hours') {
       totalRunHrs += kpi.value;
     } else if (kpi.name == 'Houly_tonnage') {
       totalTonns += kpi.value;
     }
   });
 });
this.globalCards[3].value =(totalRunHrs)
this.globalCards[4].value=totalTonns.toFixed(0)
    // this.globalCards.forEach((g) => {
    //   data.forEach((d) => {
    //    let hrsData = this.rhf2Data.filter(rhf =>{
    //       return rhf.Date._seconds == d.Date._seconds
    //     })
    //     if( hrsData.length && hrsData[0].Houly_running_hours && hrsData[0].Houly_running_hours>=0){
    //      d.Houly_running_hours = hrsData[0].Houly_running_hours
    //     }else{
    //       d.Houly_running_hours = 0
    //     }
    //     if (d[g.tag]) {
    //       if (g.type == 'average') {
    //         if (g.tag != 'Efficiency(in %)') {
    //           if(g.tag == 'Production_Rate(blooms/hour)'){
    //             if(d.Houly_running_hours){
    //               totalRunHrs += d.Houly_running_hours
    //             }
    //           }else if(g.tag == 'Houly_tonnage'){
    //             if(d.Houly_tonnage){
    //               totalTonns += d.Houly_tonnage
    //             }
    //           }else{
    //             let value = d[g.tag];
    //             value = value / data.length;
    //             g.value = g.value ? (g.value += value) : 0 + value;
    //             g.value = Number(parseInt(g.value));
    //           }
    //         } else {
    //           g.value = null;
    //         }
    //       } else {
    //         let value = d[g.tag];
    //         g.value = g.value ? (g.value += value) : 0 + value;
    //         g.value = Number(parseInt(g.value));
    //       }
    //     }
    //   });
    // });
    // this.globalCards[1].value =(this.globalCards[0].value / (totalRunHrs/60)).toFixed(2)
    // this.globalCards[2].value =(totalTonns / (totalRunHrs/60)).toFixed(2)
  }
  seggregateData(data, type) {
    data = _.orderBy(
      data,
      [
        (d) => {
          return d.Date._seconds;
        },
        'Date',
      ],
      ['desc', 'desc']
    );

    if (type == 'hourly') {
      let time: any = [];
      this.graphs.forEach((obj) => {
        if (obj.dataType == 'hourly') {
          obj.data.forEach((h) => {
            data.forEach((result:any)=>{
              time.push(
                result.Date.seconds
                      ? new Date(result.Date.seconds * 1000).getTime()
                      : new Date(result.Date).getTime()
                  );
              result.KPIS.forEach((kpi:any)=>{
               if(kpi.name==h.tag){
                h.datapoints.push({
                        x: result.Date.seconds
                          ? new Date(result.Date.seconds * 1000).getTime()
                          : new Date(result.Date).getTime(),
                        y: kpi.value,
                      });
               }
              })
            })
            // data.KPIS.forEach((d) => {
            //   time.push(
            //     d.Date._seconds
            //       ? new Date(d.Date._seconds * 1000).getTime()
            //       : new Date(d.Date).getTime()
            //   );
            //   if (d[h.tag]) {
            //     h.datapoints.push({
            //       x: d.Date._seconds
            //         ? new Date(d.Date._seconds * 1000).getTime()
            //         : new Date(d.Date).getTime(),
            //       y: d[h.tag].value,
            //     });
            //   }
            // });
          });
          this.createchartdata(obj).then((data) => {
            obj['chart_data'] = data;
          });
        }
      });
      if (this.reportTableHead[this.selectedAssetData.asset]) {
        this.reportTableHead[this.selectedAssetData.asset].data = []
          let hourlyData = [...data];
          hourlyData.forEach((result,index) => {
            let obj:any={production:'NA',runningHrs:'NA',fuel:'NA',hfo:"NA",weight:"NA",hourlyCuts:"NA"}
            obj['date']=this.dateFormat(result.Date),
            result.KPIS.forEach((kpi:any)=>{
              if(kpi.name=='Production_Rate(blooms/hour)'){
                obj['production']=kpi.value?kpi.value:'0'
              }
              else if(kpi.name=='Houly_running_hours'){
                obj['runningHrs']=kpi.value?`${kpi.value} Min`:'0'
              }
              else if(kpi.name=='Houly_BFG_Consumption'){
                obj['fuel']=kpi.value?`${kpi.value.toFixed(0)} ${kpi.unit}`:`0 ${kpi.unit}`
              }
              else if(kpi.name=='Houly_HFO_Consumption'){
                obj['hfo']=kpi.value?`${kpi.value.toFixed(0)} ${kpi.unit}`:`0 ${kpi.unit}`
              }
              else if(kpi.name=='Houly_tonnage'){
                obj['weight']=kpi.value?`${kpi.value.toFixed(0)} ${kpi.unit}`:`0 ${kpi.unit}`
              }
              else if(kpi.name=='houly_Cuts'){
                obj['hourlyCuts']=kpi.value?`${kpi.value.toFixed(0)} ${kpi.unit}`:`0 ${kpi.unit}`
              }
              //  obj['production']=kpi.name=='Production_Rate(blooms/hour)'?kpi.value:"0",
              //   // rateOfProduction:h['Houly_running_hours']? (Number(this.productionNum(index))/(h['Houly_running_hours']/60)).toFixed(2):'NA',
              //   obj['runningHrs']=kpi.name=='Houly_running_hours'?`${kpi.value} Min`:'NA'
              //   obj['fuel']=kpi.name=='Houly_BFG_Consumption'?`${kpi.value.toFixed(0)} ${kpi.unit}`:'NA'
              //   obj['hfo']=kpi.name=='Houly_HFO_Consumption'?`${kpi.value.toFixed(0)} ${kpi.unit}`:"NA"
              //   obj['weight']=kpi.name=='Houly_tonnage'?`${kpi.value.toFixed(0)} ${kpi.unit}`:"NA"
                // runningHrs: h['Houly_running_hours']>=0?`${(h['Houly_running_hours'])} Min`:'NA',
                // fuel: h['Houly_BFG_Consumption']>=0?(Number(parseInt(h['Houly_BFG_Consumption'])) + ' Nm^3'):'NA',
                // hfo:h['Houly_HFO_Consumption']>=0?(Number(parseInt(h['Houly_HFO_Consumption'])) + ' kg'):'NA',
                // weight:h['Houly_tonnage']>=0?(Number(parseInt(h['Houly_tonnage'])) + ' Tonn'):'NA',
            })
            this.reportTableHead[this.selectedAssetData.asset].data.push(obj)
          });
      }
      let start = moment(Math.min(...time)).startOf('hour').format('DD-MMM-YYYY HH:mm');
      let end = moment(Math.max(...time)).add(1,'minute').format('DD-MMM-YYYY HH:mm');
      this.date_frequency = `${start} to ${end}`;
    } else {
      let total_blooms = 0;
      this.shiftWiseData = [];
      let grouped = _.groupBy(data, (d) => {
        let df = moment(d.Date.seconds * 1000)
          .startOf('day')
          .valueOf()
          .toString();
        return (d.Date = df);
      });
      for (let key in grouped) {
        let output: any = [];
        grouped[key].forEach((item) => {
          let existing = output.filter((v: any, i) => {
            return v.Shift_Running == item.Shift_Running;
          });
          if (existing.length) {
            let existingIndex = output.indexOf(existing[0]);
            output[existingIndex] = { ...item, ...output[existingIndex] };
          } else {
            output.push(item);
          }
        });
        grouped[key] = output;
        let obj = {};
        obj['date'] = moment(Number(key)).format('DD-MMM-YYYY');
        grouped[key].forEach((o) => {
          if (o.Shift_Running == 'A') {
            o.KPIS.forEach((kpi:any)=>{
              if(kpi.name=='Total_Shift_Charging'){
                obj['A_charged']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Shift_Discharge'){
                obj['A_discharge']=kpi.value?kpi.value :0
              }
              else if(kpi.name=='Total_Bfg_Consumption'){
                obj['A_fuel']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Hfo_Consumption'){
                obj['A_hfo']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Running_hours'){
                obj['A_run_hrs']=kpi.value?kpi.value:0
              }
            })
            // obj['A_charged'] = o.Total_Shift_Charging;
            // obj['A_discharge'] = o.Total_Shift_Discharge;
            // obj['A_fuel'] = Number(parseInt(o.Total_Bfg_Consumption));
            // obj['A_hfo'] = Number(parseInt(o.Total_Hfo_Consumption));
            // obj['A_run_hrs'] = o.Running_hours;
            if (obj['A_discharge']) {
              total_blooms += obj['A_discharge'];
            }
          } else if (o.Shift_Running == 'B') {
            o.KPIS.forEach((kpi:any)=>{
              if(kpi.name=='Total_Shift_Charging'){
                obj['B_charged']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Shift_Discharge'){
                obj['B_discharge']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Bfg_Consumption'){
                obj['B_fuel']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Hfo_Consumption'){
                obj['B_hfo']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Running_hours'){
                obj['B_run_hrs']=kpi.value?kpi.value:0
              }
            })
            // obj['B_charged'] = o.Total_Shift_Charging;
            // obj['B_discharge'] = o.Total_Shift_Discharge;
            // obj['B_fuel'] = Number(parseInt(o.Total_Bfg_Consumption));
            // obj['B_hfo'] = Number(parseInt(o.Total_Hfo_Consumption));
            // obj['B_run_hrs'] = o.Running_hours;
            if (obj['B_discharge']) {
              total_blooms += obj['B_discharge'];
            }
          } else if (o.Shift_Running == 'C') {
            o.KPIS.forEach((kpi:any)=>{
              if(kpi.name=='Total_Shift_Charging'){
                obj['C_charged']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Shift_Discharge'){
                obj['C_discharge']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Bfg_Consumption'){
                obj['C_fuel']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Total_Hfo_Consumption'){
                obj['C_hfo']=kpi.value?kpi.value:0
              }
              else if(kpi.name=='Running_hours'){
                obj['C_run_hrs']=kpi.value?kpi.value:0
              }
            })
            // obj['C_charged'] = o.Total_Shift_Charging;
            // obj['C_discharge'] = o.Total_Shift_Discharge;
            // obj['C_fuel'] = Number(parseInt(o.Total_Bfg_Consumption));
            // obj['C_hfo'] = Number(parseInt(o.Total_Hfo_Consumption));
            // obj['C_run_hrs'] = o.Running_hours;
            if (obj['C_discharge']) {
              total_blooms += obj['C_discharge'];
            }
          }
        });
        this.shiftWiseData.push(obj);
      }
      this.globalCards.forEach((g) => {
        if (g.tag == 'Production_Rate(blooms/hour)') {
          g = { ...g, ...{ value: total_blooms } };
        }
      });
    }
  }
  createchartdata2(Data: any) {
    const final_array: any = [];
    return new Promise((resolve, reject) => {
      let y_current: any = [];
      let yAxis: any[] = [];
      y_current = Data.data;
      let final = {
        chartname: Data?.chartname,
        xaxis: Data?.xaxis,
        type: Data.type,
        unit: Data.unit,
        yaxis: y_current,
        plotOptions: {
          column: {
            stacking: 'normal',
          },
        },
        label_y: '',
        accessibility: {
          announceNewData: {
            enabled: true,
            minAnnounceInterval: 15000,
            announcementFormatter: function (allSeries, newSeries, newPoint) {
              if (newPoint) {
                return 'New point added. Value: ' + newPoint.y;
              }
              return false;
            },
          },
        },
        mr: 40,
        mt: 20,
        ml: 40,
        mb: 70,
        min_y: 0,
        format: true,
        tickinterval_y: Data?.y_interval,
        tickinterval_x: 10,
      };
      final_array.push(final);
      resolve(final_array);
    });
  }
  createchartdata(Data: any) {
    const final_array: any = [];
    return new Promise((resolve, reject) => {
      let y_current: any = [];
      let yAxis: any[] = [];
      let chartColorArr = [
        '#427bff',
        '#FFC300',
        '#8750C9',
        '#BA38C2',
        '#00CADD',
        '#00B9FB',
        '#5E68C5',
      ];
      Data.data.forEach((element: any, index: number) => {
        if (element) {
          let y = {
            title: {
              enabled: index == 0 ? true : false,
              text: Data.unit,
              style: {
                color: chartColorArr[index],
              },
            },
            labels: {
              enabled: false,
              style: {
                color: chartColorArr[index],
              },
            },
          };
          yAxis.push(y);
          let l = index + 1;
          y_current.push({
            color: chartColorArr[index],
            marker: {
              enabled: false,
            },
            dataLabels: [
              {
                enabled: true,
              },
            ],
            name: element.name,
            yAxis: index,
            tootltip: {
              valueSuffix: element.unit,
            },
            data: element.datapoints,
          });
        }
        let final = {
          chartname: Data?.chartname,
          xaxis: Data?.xaxis,
          type: Data.type,
          unit: Data.unit,
          yaxis: y_current,
          y: yAxis,
          label_y: '',
          accessibility: {
            announceNewData: {
              enabled: true,
              minAnnounceInterval: 15000,
              announcementFormatter: function (allSeries, newSeries, newPoint) {
                if (newPoint) {
                  return 'New point added. Value: ' + newPoint.y;
                }
                return false;
              },
            },
          },
          mr: 40,
          mt: 20,
          ml: 60,
          mb: 70,
          min_y: 0,
          format: true,
          tickinterval_y: Data?.y_interval,
          tickinterval_x: 10,
        };
        final_array.push(final);
      });
      resolve(final_array);
    });
  }
  changeFilter(e) {
    this.selectedKpi = e;
  }
  reportDisplay() {
    if (this.reportSelected == 'table') {
      this.reportSelected = 'graph';
    } else {
      this.reportSelected = 'table';
    }
  }
  dateFormat(date: any) {
    let startHr = moment(date.seconds * 1000)
      .startOf('hour')
      .format('HH:mm');
    let endHr = moment(date.seconds * 1000)
      .startOf('hour')
      .add(1, 'hour')
      .format('HH:mm');
    return `${moment(date.seconds * 1000).format(
      'DD-MMM-YYYY'
    )} ${startHr}-${endHr}`;
  }
  productionNum(indexVal: any) {
    if(this.graphs[0].data[0].datapoints[indexVal] &&  this.graphs[0].data[0].datapoints[indexVal].y &&  this.graphs[0].data[0].datapoints[indexVal].y >=0){
     return this.graphs[0].data[0].datapoints[indexVal].y
    }else{
      return 'NA'
    }
  }
}
