<!-- <app-header></app-header> -->
<div class="main-content">
  <div class="genai-content">
    <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 card side-content">
        <ng-container *ngFor="let content of content_list">
            <span (click)="selectedTabs(content.ID)" [ngClass]="selectedTab == content.ID ? 'searched-contents' : ''" (click)="getSearchContent()"> <i class="fa fa-comment-o" aria-hidden="true"></i> {{content.content}} <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="editSearchContent(content)"></i> <i class="fa fa-trash" (click)="deleteSearchContent(content)" aria-hidden="true"></i></span>
        </ng-container>
    </div> -->
    <!-- <div
      class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 card search-content"
    > -->
    <div class="card search-content">
      <div class="input-group mb-3 mt-10">
        <ng-container *ngFor="let form of content_form">
          <form
            class="search-content-form"
            [formGroup]="addContentForm"
            autocomplete="off"
          >
            <input
              type="text"
              class="form-control"
              (keypress)="onKeyEnter($event, addContentForm)"
              [formControlName]="form.formControlName"
              placeholder="Enter a Prompt"
              aria-label="Enter a Prompt"
              aria-describedby="basic-addon2"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              (click)="searchContent(addContentForm)"
            >
              <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
            </button>
          </form>
        </ng-container>
      </div>
      <div class="card content_list">
        <ng-container *ngFor="let content of searched_content_list">
          <div class="card-body result-card">
            <span class="title">{{ content.search }}</span>
            <div class="card result">
              <span class="response"
                ><img
                  src="../../../assets/images/AI.jpg"
                  alt=""
                  class="ai-icon"
                />
                <span class="response">{{ content.response }}</span></span
              >
            </div>
            <div class="comments">
              <ng-container *ngIf="!content.responseLiked">
                <span class="liked" (click)="content.responseLiked = true; content.responseDisLiked = false"
                  ><i class="fa fa-thumbs-o-up position" aria-hidden="true"></i
                ></span>
              </ng-container>
              <ng-container *ngIf="content.responseLiked">
                <span class="like"
                  ><i
                    class="fa fa-thumbs-up position"
                    aria-hidden="true"
                  ></i> </span
                >&nbsp;
              </ng-container>
              <ng-container *ngIf="!content.responseDisLiked">
                <span
                  class="disliked"
                  (click)="open(disLikeInput, 'ADD', content)"
                  ><i
                    class="fa fa-thumbs-o-down position"
                    aria-hidden="true"
                  ></i
                ></span>
              </ng-container>
              <ng-container *ngIf="content.responseDisLiked">
                <span class="dislike"
                  ><i class="fa fa-thumbs-down position" aria-hidden="true"></i
                ></span>
              </ng-container>
            </div>
            <hr class="divider" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>

<ng-template #disLikeInput let-modal>
  <div class="modal-header">
    <span class="modal-title">Comments</span>
    <i
      class="fa fa-times"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"
    ></i>
  </div>
  <ng-container *ngFor="let form of comment_form">
    <form [formGroup]="commentForm" autocomplete="off" class="addUserForm">
      <div style="padding: 20px 25px">
        <div class="modal-body adduserPopupbody popup">
          <div class="row">
            <div class="col-md-4">
              <div class="label">
                {{ form.label }}
                <span *ngIf="form.mandatory" class="error">*</span>
              </div>
              <input
                type="text"
                class="form-control comment"
                [formControlName]="form.formControlName"
                placeholder="Enter Comment"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          [disabled]="false"
          class="btn btn-info"
          (click)="comment(commentForm)"
        >
          <i class="fa fa-save"></i> &nbsp;<span
            style="color: #ffff; font-size: 15px; font-family: 'sfpro-r'"
            >Save</span
          >
        </button>
      </div>
    </form>
  </ng-container>
</ng-template>

<!-- <app-footer></app-footer> -->
