import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthData } from './../../model/auth.model';
import { UserData } from './../../model/user.model';
import { UtilService } from '../util.service';
import { CookieService } from 'ngx-cookie-service';
import { AssetGridComponent } from 'src/app/components/page/asset-grid/asset-grid.component';
import { AssetdetailsComponent } from 'src/app/components/page/assetdetails/assetdetails.component';
import { MonitorComponent } from 'src/app/components/page/monitor/monitor.component';
import { DatasetComponent } from 'src/app/components/page/assetdetails/dataset/dataset.component';
import { FirestoreService } from '../firestore.service';
// import { AssetGridComponent } from 'src/app/components/page/asset-grid/asset-grid.component';
// import { AssetdetailsComponent } from 'src/app/components/page/assetdetails/assetdetails.component';
// import { MonitorComponent } from 'src/app/components/page/monitor/monitor.component';
// import { DatasetComponent } from 'src/app/components/page/assetdetails/dataset/dataset.component';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private token: string | undefined;
  private isAuthenticated = false;
  private authStatusListner = new Subject<boolean>();
  private tokenTimer: any;
  private userData: UserData | undefined;
  private customerId: string | undefined;
  private email: string | undefined;
  private roleSetting:any;
  private authenticateFunctionPath = "https://asia-south1-jspl-355706.cloudfunctions.net/JSPL_Authentication/";

  mainScreenRouting:any = {
    "GenAi-Dashboard":[
      { path:'genaidashboard',loadChildren:()=>import('../../components/genaidashboard/genaidashboard.module').then(m=>m.GenaidashboardModule)},
    ],
    "Executive-Dashboard":[{
      path: 'executive-dashboard',
      loadChildren: () => import('../../components/page/executive-dashboard/executive-dashboard.module').then(m => m.ExecutiveDashboardModule)
    }],
    "Dashboard":[
      { path: 'assetgrid', component: AssetGridComponent },
      { path: 'assetgrid/assetdetails/:code', component: AssetdetailsComponent },
      { path: 'assetgrid/monitor/:code', component: MonitorComponent },
      { path: 'assetgrid/dataset/:code', component: DatasetComponent },
    ],
    "Tracking": [{
      path: 'tracking',
      loadChildren: () => import('../../components/page/tracking/tracking.module').then(m => m.TrackingModule)

    }],
    "Alerts":[
      {
        path: 'alerts',
        loadChildren: () => import('./../../components/page/alert/alert.module').then(m => m.AlertModule)
      }
    ],
    "Configurations":[
      {
        path: 'configurations',
        loadChildren: () => import('./../../components/page/configuration/configuration.module').then(m => m.ConfigurationModule)
      }
    ],
    "Maintenance":[
      {
        path: 'maintenance',
        loadChildren: () => import('./../../components/page/maintaince/maintaince.module').then(m => m.MaintainceModule)
      }
    ],
    "Reports":[
      {
        path: 'reports',
        loadChildren: () => import('./../../components/page/ppc/ppc.module').then(m => m.PpcModule)
      }
    ],
    "Spectrometer":[
      {
        path: 'spectrometer',
        loadChildren: () => import('./../../components/page/spectrometer/spectrometer.module').then(m => m.SpectrometerModule)
      }
    ],
    "Time-Series":[
      {
        path: 'time-series',
        loadChildren: () => import('./../../components/page/time-series/time-series.module').then(m => m.TimeSeriesModule)
      }],
      "Analytics":[
        {
        path: 'analytics',
        loadChildren:()=>import('./../../components/page/analytics/analytics.module').then(m=>m.AnalyticsModule)
      },
      ]
  }
  username: any;
  constructor(private http: HttpClient, private router: Router, private util: UtilService, private cookie: CookieService, private fs:FirestoreService) {}

  getToken() {
    return this.token;
  }
  getAuthStatusListener() {
    return this.authStatusListner.asObservable();
  }

  getIsAuth() {
    return this.isAuthenticated;
  }
  getUserObject() {
    if(this.userData) {
      return this.userData;
    } else if(this.email && this.customerId) {
      return {
        email: this.email,
        customerId: this.customerId
      }
    } else {
      return undefined;
    }
  }
  login(data:any) {
    const authData: AuthData = {
      email:data.userid,
      password:data.password,
      clientId:"JSPL"
    };
    if (environment.mode === "onpremise") {
    } else if(environment.mode === "firebase") {
      this.http.post< { message: UserData } >(this.authenticateFunctionPath + "1.0/login", authData).toPromise()
        .then(response => {
          if(response && response.message) {
            const token = response.message.token;
            this.token = token;
            if (token) {
              if (data.remember) {
                  this.cookie.set("jspluserid", data.userid, 365);
                  this.cookie.set("jsplpassword", data.password, 365);
                  this.cookie.set("jsplloginremember", "true", 365);
                } else {
                  this.cookie.delete("jspluserid");
                  this.cookie.delete("jsplpassword");
                  this.cookie.set("jsplloginremember", "false", 365);
                }
              const expiresInDuration = response.message.expiresIn;
              this.setAuthTimer(expiresInDuration);
              this.isAuthenticated = true;
              this.userData = response.message;
              this.roleSetting = response.message["roleSetting"];
              this.customerId = response.message.customerId;
              this.email = response.message.email;
              this.username = response.message.name;
              this.authStatusListner.next(true);
              const now = new Date();
              const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
              this.saveAuthData(token, expirationDate, this.customerId, this.email,this.userData.role, this.roleSetting,this.username);
              this.util.showSnakbarMessage("success", "Successfully Logged in", "end", "top");
              this.router.navigate(['/page',this.roleSetting['screens'][0].href]);
            } else {
              this.util.showSnakbarMessage("error", "Token Missing", "end", "top");
              this.authStatusListner.next(false);
            }
          } else {
            this.util.showSnakbarMessage("error", "Login Failed", "end", "top");
            this.authStatusListner.next(false);
          }
        }).catch(err => {
         // this.util.showSnakbarMessage("error", "Login Failed", "end", "top");
          this.util.showSnakbarMessage("error", "Invalid credential", "end", "top");

          this.authStatusListner.next(false);
          console.log(err)
        })
      }
    }
    autoAuthUser() {
      const authInformation = this.getAuthData();
      if (!authInformation) {
        return;
      }
      const now = new Date();
      const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
      if (expiresIn > 0) {
        this.token = authInformation.token;
        this.isAuthenticated = true;
        this.email = authInformation.email;
        this.customerId = authInformation.customerId;
        //this.setAuthTimer(expiresIn / 1000);
        this.authStatusListner.next(true);
      }
    }
    logout() {
      this.token = undefined;
      this.isAuthenticated = false;
      this.userData = undefined;
      this.email = undefined;
      this.customerId = undefined;
      this.authStatusListner.next(false);
      this.router.navigate(['/']);
      this.clearAuthData();
      clearTimeout(this.tokenTimer);
      window.location.reload();
    }

    private setAuthTimer(duration: number){
      this.tokenTimer = setTimeout(() => {
        this.logout();
      }, duration * 1000);
    }

    private saveAuthData(token: string, expirationDate: Date, customerId: string, email: string, role:string, roleSetting:any,username:string) {
      localStorage.setItem('token', token);
      localStorage.setItem('email', email);
      localStorage.setItem('customerId', customerId);
      localStorage.setItem('username', username);
      localStorage.setItem('expiration', expirationDate.toISOString());
      localStorage.setItem('role', role);
      if(roleSetting) {
        localStorage.setItem('roleSetting', JSON.stringify(roleSetting));
        this.getAuthData()
      }
    }

    private clearAuthData() {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('customerId');
      localStorage.removeItem('expiration');
      localStorage.removeItem('role');
      localStorage.removeItem('roleSetting');
      localStorage.removeItem('globalAssetcode');
      localStorage.removeItem('globalSection');
    }
    private getAuthData() {
      const token = localStorage.getItem('token');
      const expirationDate = localStorage.getItem('expiration');
      const email = localStorage.getItem('email');
      const customerId = localStorage.getItem('customerId');
      let roleSetting = localStorage.getItem('roleSetting');
      if(roleSetting && roleSetting!="undefined") {
        roleSetting = JSON.parse(roleSetting)
      } else {
        roleSetting = null;
      }
      if (!token || !expirationDate || !email || !customerId) {
        return;
      }
      let authData:any = {
        token,
        email,
        customerId,
        expirationDate: new Date(expirationDate)
      };
      if(roleSetting) {
        authData["roleSetting"] = roleSetting;
        this.setRoleBasedScreen(roleSetting);
      }
      return authData;
    }

    setRoleBasedScreen(roleSetting) {
      if(roleSetting.screens.length>0) {
        this.router.config[3].children = [];
        let routingChildren = this.router.config[3].children
        let checkDashboard = roleSetting.screens.filter((screenData)=>{
          return screenData.text=="Dashboard";
        });
        if(checkDashboard.length>0) {
          routingChildren.push({ path: '', component: this.mainScreenRouting["Dashboard"][0].component });
        } else {
          routingChildren.push({ path: '', loadChildren: this.mainScreenRouting[roleSetting.screens[0].text][0].loadChildren });
        }
        // console.log('/page/'+roleSetting.screens[0].href)
        // this.router.navigate(['/page/'+roleSetting.screens[0].href]);
        roleSetting.screens.forEach(screenData => {
          if(screenData.text && this.mainScreenRouting[screenData.text]) {
            this.mainScreenRouting[screenData.text].forEach(route => {
              routingChildren.push(route);
            });
          }
        });
      }
    }
}
