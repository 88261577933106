import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);
import HC_solid_gauge from "highcharts/modules/solid-gauge";
HC_solid_gauge(Highcharts);
@Component({
  selector: "app-semi-guage",
  templateUrl: "./semi-guage.component.html",
  styleUrls: ["./semi-guage.component.scss"],
})
export class SemiGuageComponent implements OnInit {
  solidGaugeChartOptions = {};
  @Input("data")
  set in(val) {
    val.Value = Number(parseFloat(val.Value).toFixed(2));
    this.solidgauagechart(val);
  }
  highcharts = Highcharts;
  constructor() { }
  ngOnInit() { }
  solidgauagechart(data) {
    return (this.solidGaugeChartOptions = {
      chart: {
        type: "solidgauge",
        backgroundColor: "transparent",
        height: data.height || "100%",
        margin: 0,
        spacing: 0,
        marginTop:0,
        marginBottom:80,
        reflow: true,
        style: { fontFamily: "sfpro-r" },
      },
      credits: {
        enabled: false,
        style: {
          color: "#999999",
          cursor: "pointer",
          fontSize: "9px",
        },
        text: "Highcharts.com",
      },
      title: {
        text: "",
      },
      subtitle: {
        align: "right",
        useHTML: true,
      },

      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 20,
        itemStyle: {
          color: "var(--graph-text-color)",
        },
        labels: {
          enabled: true,
        },
        symbolHeight: 0.001,
        symbolWidth: 0.001,
        symbolRadius: 0.001,
      },
      pane: {
        center: ["50%", "50%"],
        size: "100%",
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            backgroundColor: "#DFDFE0",
            innerRadius: "50%",
            outerRadius: "90%",
            borderWidth: 0,
            shape: "arc",
          },
        ],

      },


      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        minorTickInterval: null,
        minorTickWidth: 0,
        minorTickLength: 10,
        minorTickPosition: "outside",
        minorTickColor: "#666",
        tickPixelInterval: 30,
        tickWidth: 0,
        tickPosition: "outside",
        tickLength: 10,
        tickColor: "#666",
        labels: {
          enabled: false,
          step: 10,
          y:15,
          rotation: null,
          style: {
            color: "#595959",
            paddingTop:10,
            fontWeight: "bold",
            fontSize: 13,
          },
        },
        title: {
          text: "",
        },
        // plotBands: [
        // {
        // from: 95,
        // to: 100,
        // innerRadius: "90%",
        // color: "#467BC9", // green
        // },
        // {
        // from: 90,
        // to: 95,
        // innerRadius: "90%",
        // color: "#A8CBFF", // yellow
        // },
        // {
        // from: 0,
        // to: 90,
        // innerRadius: "90%",
        // color: "#5599FF", // red
        // },
        // ],
      },
       tooltip:
       {
        enabled:false,
        time: {
          useUTC: false,
        },
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          "%" +
          "</b></td></tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor: "#eeeeee",
        borderColor: "#eeeeee",
      },
      plotOptions: {
        solidgauge: {
          innerRadius: "50%",
          outerRadius: "90%",
          linecap: "round",
          stickyTracking: false,
          rounded: false,
          dataLabels: {
            y: 0,
            borderWidth: 0,
            useHTML: false,
          },
          // dial:{
          // backgroundColor:"#000000",
          // baseLength:"70%",
          // baseWidth:5,
          // borderColor:"#cccccc",
          // borderWidth:0,
          // radius:"80%",
          // rearLength:"30%",
          // topWidth:1
          // }
        },
      },
      series: [
        {
          type: "solidgauge",
          name: data.title || "Value",
          rounded: false,
          showInLegend:false,
          dataLabels: {
            enabled: false,
            color: "black",
            fontWeight: "lighter",
            style: {
              fontSize: 12,
            },
          },
          // dial: {
          //   radius: '45%',
          //   backgroundColor: '#D8D8D8',
          //   baseWidth: 8,
          //   baseLength: '0%',
          //   rearLength: '0%'
          // },
          // pivot: {
          //   backgroundColor: '#D8D8D8',
          //   radius: 4
          // },
          data: [
            {
              color: data.color,
              radius: "90%",
              innerRadius: "50%",
              outerRadius: "90%",
              y: Number(parseFloat(data.Value)),
              dataLabels: {
                enabled:
                  data.dataLabelEnabled != undefined
                    ? false
                    : false,
                y: -30,
                formatter: function () {
                  return parseFloat(data.Value) + "%";
                },
                borderWidth: 0,
                style: {
                  fontSize: "16px",
                  color: "var(--graph-text-color)",
                  fontWeight: "lighter",
                },
              },
            },
          ],
        },
      ],
    });
  }
}
