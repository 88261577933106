<div class="navbar">
  <div class="row" style="width: 101%;">
    <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
      <nav mat-tab-nav-bar>
        <a mat-tab-link
           *ngFor="let tab of tabs index as i"
           [routerLink]="tab.href"
           routerLinkActive
           #rla="routerLinkActive"
           [active]="rla.isActive">
           <ng-container *ngIf="tab.icon">
            <i [class]="tab.icon"  style="margin-right: 4px;" aria-hidden="true"></i>
           </ng-container>
           <ng-container *ngIf="tab.image">
            <img [src]="tab.image" alt="AI" >
           </ng-container>
          {{tab.text}}
        </a>
      </nav>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
      <div class="status">
        <!-- <div class="statustext" *ngIf="lastUpdate">
          Last update: {{ lastUpdate }}
        </div> -->
        <div style="color: #595959;padding-top: 3%;">
          {{ currentTime }}
        </div>
        <!-- <div>
          <div class="statusindicator">
            <div class="circle green"></div>
            <div class="onlineText">ONLINE</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #message_container let-modal>
  <div class="modal-body" style="display: grid;gap: 20px;">
    <div class="icon">
      <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 3rem;
    text-align: center;
    width: 100%;
    color: gainsboro;"></i>
    </div>
    <div class="message_text" style="line-height: 1.4;
    font-size: 13px;
    font-family: 'sfpro-sb';
">
      {{message}}
    </div>
    <div>
      <button class="btn btn-sm btn-primary" (click)="navigate_back()">Back to Login</button>
    </div>
    <div class="url">
      <a [href]="url" style="color: #007bff;
    text-decoration: underline !important;
    text-align: center;
    width: 100%;
    display: block;">{{url}}</a>
    </div>
  </div>
</ng-template>
