<div class="container-fluid" style="margin: 0px; padding: 0px; ">
  <div class="status" style="padding-left: 0% !important; padding-right: 0% !important; padding-top: 7px;">
    <ng-container *ngIf="selectedKpi.label=='Pass Schedule'">

    <div class="row" style="padding: 0px ! important;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 3px !important;">
          <div class="topCard">
            <div class="title">
                <div class="headTitle" style="margin-left: 10px; margin-top: 2px;">
                  <i class="fa fa-circle title-dot"></i> Pass Schedule
              </div>
              </div>
              <hr style="margin-top:8px;margin-left:15px;margin-right: 15px;">
              <div class="detailsCard" style="margin-top: -5px !important;">
                <div class="row">
                  <ng-container *ngIf="passScheduleData && passScheduleData?.length">
                    <ng-container *ngFor="let data of passScheduleData ">
                      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                      <div style="padding-left: 15px;padding-bottom: 0px; ">
                        <div class="scheduleCard">
                          <div class="flex" style="display: flex;">
                             <div class="line"></div>
                          <div>
                          <div class="header"> {{data.description}}  </div>
                          <div class="content">{{data.value}}
                            <span class="unit" style="color: #595959 !important; font-size: 11px !important;">{{data.unit?data.unit:''}}</span>
                          </div>
                        </div>
                        </div>
                      </div>
                      </div>
                    </div>
                     </ng-container>
                  </ng-container>

                 <ng-container *ngIf="!passScheduleData?.length">
                  <div class="font-size-12" style="
                  height: calc(100vh - 210px);
                  padding: 20px;
                  font-size: 18px;
                  font-family: 'sfpro-r';
                  width: -webkit-fill-available;
                ">
                     <app-infomessage [message]="'No Data Found'"></app-infomessage>
                  </div>
                  </ng-container>
                  <!-- <div>
                    <span class="header">Shedule : </span><span class="content"></span>
                  </div>
                  <div>
                    <span class="header">Agency : </span><span class="content"></span>
                  </div>
                  <div>
                    <span class="header">Agency : </span><span class="content"></span>
                  </div>
                  <div>
                    <span class="header">Agency : </span><span class="content"></span>
                  </div>
                  <div>
                    <span class="header">Agency : </span><span class="content"></span>
                  </div>
                  <div>
               <span class="header">Alert Type : </span><span class="content"></span>
                  </div>
                  <div>
                    <span class="header">Frequency : </span><span class="content"></span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedKpi.label=='Ready Checklist'">
          <div class="row" style="padding: 0px ! important;">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 3px !important;">
              <div class="bottomCard">
                <div class="title">
                    <div class="headTitle" style="margin-left: 10px; margin-top: 2px;">
                      <i class="fa fa-circle title-dot" style="margin-right: 5px;"></i>Ready Checklist
                  </div>
                  </div>
                    <div style="padding: 0% ;padding-right:1%;padding-bottom: 1%;" >
                      <div class="alertable card bg-color" style="height: calc(100vh - 260px);position: relative;padding: 10px; margin-left: 5px; margin-top: 5px;">
                        <ng-container *ngIf="!readyChecklist.data.length" >
                          <div class="font-size-12" style="
                          height: calc(100vh - 210px);
                          padding: 20px;
                          font-size: 18px;
                          font-family: 'sfpro-r';
                        ">
                             <app-infomessage [message]="'No Data Found'"></app-infomessage>
                          </div>
                        </ng-container>
                        <div *ngIf="readyChecklist.data.length" style="height: calc(100vh - 313px); overflow:auto;">
                    <div class="table" style="padding: 0%;">
                      <table id="alerts">
                        <thead>
                        <tr class="header">
                          <ng-container *ngFor="let head of readyChecklist.header">
                            <th>{{head.label}}</th>
                          </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of readyChecklist.data |paginate:configPage">
                            <td>{{data.tag}}</td>
                            <td>{{data.description}}</td>
                            <td>{{data.status}}</td>
                            <td>{{data.dateTime}}</td>
                            <!-- <ng-container *ngIf="data.status==1 || data.status==0">

                            <td><i
                              [ngClass]="
                                data.status == 1
                                  ? 'on fa fa-check-circle'
                                  : 'off fa fa-times-circle'
                              "
                              aria-hidden="true"
                            ></i></td>
                          </ng-container>
                          <ng-container *ngIf="data.status!=1 && data.status!=0">
                            <td>{{data.status}}</td>
                           </ng-container> -->

                          </tr>
                        </tbody>
                        </table>
                    </div>
                    <div style="position: absolute; bottom: -9px;" *ngIf="readyChecklist.data && readyChecklist.data.length>10">
                      <app-pagination (changePage)="changePage($event)"></app-pagination>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
              </div>
          </div>
          </ng-container>
          <ng-container *ngIf="selectedKpi.label=='Motor Parameters'">
          <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 3px !important;">
                <div class="bottomCard">
                  <div class="title">
                      <div class="headTitle" style="margin-left: 10px; margin-top: 2px;">
                        <i class="fa fa-circle title-dot " style="margin-right: 5px;"></i>Motor Parameters
                    </div>
                    </div>
                    <div style="padding: 0% ;padding-right:1%;padding-bottom: 1%;" >
                      <div class="alertable card bg-color" style="height: calc(100vh - 260px);position: relative;padding: 10px; margin-left: 5px; margin-top: 5px;">
                        <ng-container *ngIf="!Lubricant.data.length" >
                          <div class="font-size-12" style="
                          height: calc(100vh - 210px);
                          padding: 20px;
                          font-size: 18px;
                          font-family: 'sfpro-r';
                        ">
                             <app-infomessage [message]="'No Data Found'"></app-infomessage>
                          </div>
                        </ng-container>
                        <div *ngIf="Lubricant.data.length" style="height: calc(100vh - 313px); overflow:auto;">
                    <div class="table" style="padding: 0%;">
                      <table id="alerts">
                        <thead>
                        <tr class="header">
                          <ng-container *ngFor="let head of Lubricant.header">
                            <th>{{head.label}}</th>
                          </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of Lubricant.data |paginate:configPage1">
                            <td>{{data.tag}}</td>
                            <td>{{data.description}}</td>
                            <td>{{data.status}}</td>
                            <td>{{data.dateTime}}</td>
                            <!-- <ng-container *ngIf="data.status==1 || data.status==0">

                              <td><i
                                [ngClass]="
                                  data.status == 1
                                    ? 'on fa fa-check-circle'
                                    : 'off fa fa-times-circle'
                                "
                                aria-hidden="true"
                              ></i></td>
                            </ng-container>
                            <ng-container *ngIf="data.status!=1 && data.status!=0">
                             <td>{{data.status}}</td>
                            </ng-container> -->
                          </tr>
                        </tbody>
                        </table>
                    </div>
                    <div style="position: absolute; bottom: -9px;" *ngIf="Lubricant.data && Lubricant.data.length>10">
                      <app-pagination (changePage)="changePage1($event)"></app-pagination>
                    </div>
                    </div>
                    </div>
                    </div>

                  </div>
                </div>

              </div>
              </ng-container>
              <ng-container *ngIf="((selectedAsset.asset).toLowerCase()=='tandem' || selectedAsset.asset=='HOT SAW' || selectedAsset.asset=='RHF2') && selectedKpi.label!='Pass Schedule'  && selectedKpi.label!='Roll Set Configuration'">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 3px !important;">
                      <div class="bottomCard">
                        <div class="title">
                            <div class="headTitle" style="margin-left: 10px; margin-top: 2px;">
                              <i class="fa fa-circle title-dot " style="margin-right: 5px;"></i>
                              {{selectedKpi.label}}
                          </div>
                          </div>
                          <div style="padding: 0% ;padding-right:1%;padding-bottom: 1%;" >
                            <div class="alertable card bg-color" style="height: calc(100vh - 260px);position: relative;padding: 10px; margin-left: 5px; margin-top: 5px;">
                              <ng-container *ngIf="!tableData.data.length" >
                                <div class="font-size-12" style="
                                height: calc(100vh - 210px);
                                padding: 20px;
                                font-size: 18px;
                                font-family: 'sfpro-r';
                              ">
                                   <app-infomessage [message]="'No Data Found'"></app-infomessage>
                                </div>
                              </ng-container>
                              <div *ngIf="tableData.data.length" style="height: calc(100vh - 313px); overflow:auto;">
                          <div class="table" style="padding: 0%;">
                            <table id="alerts">
                              <thead>
                              <tr class="header">
                                <ng-container *ngFor="let head of tableData.header">
                                  <th>{{head.label}}</th>
                                </ng-container>
                              </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of tableData.data |paginate:configPage1">
                                  <td>{{data.tag}}</td>
                                  <td>{{data.description}}</td>
                                  <td>{{data.status}}</td>
                                  <td>{{data.dateTime}}</td>
                                  <!-- <ng-container *ngIf="data.status==1 || data.status==0">

                                    <td><i
                                      [ngClass]="
                                        data.status == 1
                                          ? 'on fa fa-check-circle'
                                          : 'off fa fa-times-circle'
                                      "
                                      aria-hidden="true"
                                    ></i></td>
                                  </ng-container>
                                  <ng-container *ngIf="data.status!=1 && data.status!=0">
                                    <td>{{data.status}}</td>
                                   </ng-container> -->
                                </tr>
                              </tbody>
                              </table>
                          </div>
                          <div style="position: absolute; bottom: -9px;" *ngIf="tableData.data && tableData.data.length>10">
                            <app-pagination (changePage)="changePage1($event)"></app-pagination>
                          </div>
                          </div>
                          </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    </ng-container>
                    <ng-container *ngIf="(selectedAsset.asset).toLowerCase()=='tandem'  && selectedKpi.label=='Roll Set Configuration' ">
                      <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 3px !important;">
                            <div class="bottomCard">
                              <div class="title">
                                  <div class="headTitle" style="margin-left: 10px; margin-top: 2px;">
                                    <i class="fa fa-circle title-dot " style="margin-right: 5px;"></i>
                                    {{selectedKpi.label}}
                                </div>
                                </div>
                                <div style="padding: 0% ;padding-right:1%;padding-bottom: 1%;" >
                                  <div class="alertable card bg-color" style="height: calc(100vh - 260px);position: relative;padding: 10px; margin-left: 5px; margin-top: 5px;">
                                    <ng-container *ngIf="!rollsetTableData.length" >
                                      <div class="font-size-12" style="
                                      height: calc(100vh - 210px);
                                      padding: 20px;
                                      font-size: 18px;
                                      font-family: 'sfpro-r';
                                    ">
                                         <app-infomessage [message]="'No Data Found'"></app-infomessage>
                                      </div>
                                    </ng-container>
                                    <div *ngIf="rollsetTableData.length" style="height: calc(100vh - 300px); overflow:auto;">
                                <div class="table" style="padding: 0%;">
                                  <table id="alerts" style="margin-top: 5px !important;">
                                    <thead >
                                      <ng-container *ngFor="let object of rollsetTableRows |keyvalue as any">
                                        <tr class="header">
                                        <ng-container *ngFor="let values of object?.value">
                                          <th [attr.rowspan]="values.rowspan"
                                          [attr.colspan]="values.colspan" style="text-align: center;vertical-align: middle !important;">{{values.label}}</th>
                                        </ng-container>
                                      </tr>
                                    </ng-container>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let data of rollsetTableData |paginate:configPage1" style="text-align: center;">
                                        <ng-container *ngIf="data.title=='Angle'">
                                          <td>{{data.title}} in (&#176;)</td>
                                        </ng-container>
                                        <ng-container *ngIf="data.title!='Angle'">
                                          <td>{{data.title}}</td>
                                        </ng-container>
                                        <ng-container *ngIf="data['key1'] && data['key1'].length">
                                          <ng-container *ngFor="let key of data['key1']">
                                            <td [attr.colspan]="key.colspan">{{key.value}}</td>
                                          </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="data['key2'] && data['key2'].length">
                                          <ng-container *ngFor="let key of data['key2']">
                                            <td [attr.colspan]="key.colspan">{{key.value}}</td>
                                          </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="data['key3'] && data['key3'].length">
                                          <ng-container *ngFor="let key of data['key3']">
                                            <td [attr.colspan]="key.colspan">{{key.value}}</td>
                                          </ng-container>
                                        </ng-container>

                                      </tr>
                                    </tbody>
                                    </table>
                                </div>
                                <div style="position: absolute; bottom: -9px;" *ngIf="rollsetTableData && rollsetTableData?.length>10">
                                  <app-pagination (changePage)="changePage1($event)"></app-pagination>
                                </div>
                                </div>
                                </div>
                                </div>

                              </div>
                            </div>

                          </div>
                          </ng-container>
              </div>
          </div>



