import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Injectable,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { DatePipe } from '@angular/common';
// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { default as _rollupMoment, Moment } from 'moment';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangePicker,
} from '@angular/material/datepicker';
// @Injectable()
// export class FiveDayRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
//   constructor(private _dateAdapter: DateAdapter<D>) {}

//   selectionFinished(date: D | null): DateRange<D> {
//     return this._createFiveDayRange(date);
//   }

//   createPreview(activeDate: D | null): DateRange<D> {
//     return this._createFiveDayRange(activeDate);
//   }

//   private _createFiveDayRange(date: D | null): DateRange<D> {
//     if (date) {
//       const start = this._dateAdapter.addCalendarDays(date, 0);
//       const end = this._dateAdapter.addCalendarDays(date, 6);
//       return new DateRange<D>(start, end);
//     }

//     return new DateRange<D>(null, null);
//   }
// }
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-mat-daterangepicker',
  templateUrl: './mat-daterangepicker.component.html',
  styleUrls: ['./mat-daterangepicker.component.scss'],
  // providers: [{
  //   provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
  //   useClass: FiveDayRangeSelectionStrategy
  // }]
})
export class MatDaterangepickerComponent implements OnInit, OnChanges {
  @Output() selectedDates = new EventEmitter();
  _InitialDates: any;
  @Input() enableSelectionStrategy: boolean = false;
  placeholder: any = {
    startDate: moment,
    enddate: moment,
  };
  startDate: any;
  endDate: any;
  maxDate = moment().toDate()
  @ViewChild('dateRangePicker') dateRangePicker: MatDateRangePicker<Date>;
  @Input() open: boolean = false;
  @Input() InitialDates: any;
  @Input() minDate: any;
  @Input() hide = false;
  @Input() isSingle: boolean = false;
  @Input() date: Date;
  @Input() feedDays: number;
  @Input() futureDays: number;
  @Input() disabled: boolean;
  @Input('maxDate')
  set in(value: any) {
    // console.log(value)
    if (value) {
      this.maxDate = moment(value).toDate();
    } else {
      this.maxDate = undefined;
    }
  }

  @Output() dateChangeEvent = new EventEmitter<Date>();

 tomorrow = moment().add(1,'day').toDate()
 
  dateForm = new FormGroup({
    start: new FormControl('', [Validators.required]),
    end: new FormControl('', [Validators.required]),
  });
  datePipe = new DatePipe('en-US'); 
  constructor() {
    // this.feedDays = 30;
    this.date = new Date();
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.startDate = changes.date.currentValue;
      this.disabled = true;
    }
  }

  ngOnInit(): void {
    if (this.date && this.date.getTime() > 0) {
      this.startDate = moment(this.date).startOf('day').toDate();
    }
    if (this.open) {
      // pick
      document.getElementById('dataPicker')?.click();
    }
    if (
      this.InitialDates['startDate']?.seconds &&
      this.InitialDates['endDate']?.seconds
    ) {
      this.placeholder['startDate'] = moment(
        this.InitialDates['startDate']?.seconds * 1000
      ).format('DD-MM-YYYY');
      this.placeholder['endDate'] = moment(
        this.InitialDates['endDate']?.seconds * 1000
      ).format('DD-MM-YYYY');
      this.startDate = moment(
        this.InitialDates['startDate']?.seconds * 1000
      ).toDate();
      this.endDate = moment(
        this.InitialDates['endDate']?.seconds * 1000
      ).toDate();
    } else {
      this.placeholder['startDate'] = moment(
        this.InitialDates['startDate']
      ).format('DD-MM-YYYY');
      this.placeholder['endDate'] = moment(this.InitialDates['endDate']).format(
        'DD-MM-YYYY'
      );
      this.startDate = moment(this.InitialDates['startDate']).toDate();
      this.endDate = moment(this.InitialDates['endDate']).toDate();
    }
    this.formatDate({ value: { start: this.startDate, end: this.endDate } });
  }
  formatDate(date: any) {
    this.placeholder['startDate'] = this.datePipe.transform(
      date['value'].start,
      'dd-MM-YYYY'
    );
    this.placeholder['endDate'] = this.datePipe.transform(
      date['value'].end,
      'dd-MM-YYYY'
    );
  }
  dateChangd() {
    this.dateChangeEvent.emit(this.startDate);
  }

  onDateRangeSelected(date: FormGroup) {
    if (this.isSingle) {
      this.dateChangd()
      date.get('end')?.setValue(date?.value?.start);
      
    }
    if (date.valid) {
      this.formatDate(date);
      this.selectedDates.emit({
        startDate: date?.value?.start,
        endDate: date?.value?.end,
      });
    }
  }
  openDateRangePicker() {
    this.dateRangePicker.open();
  }
}
