<!-- <app-header></app-header> -->
<!-- <div class="main-content">
  <div class="genai-content row">
    <div class="col-md-3">
      <ng-container>
        <h6 class="card-title">Suggested Questions</h6>
        <ul class="list-group" *ngFor="let data of questions; let i = index">
          <li class="list-group-item">
            <a class="question" (click)="query(data)">
              {{ i + 1 }}. {{ data.question }}</a
            >
          </li>
        </ul>
      </ng-container>
    </div>
    <div class="col-md-9">
      <div class="card search-content">
        <div
          class="card content_list"
          #scrollMe
          [scrollTop]="scrollMe.scrollHeight">
          <ng-container *ngFor="let content of searched_content_list">
            <div class="card-body result-card">
              <div class="card result">
                  <div class="d-flex justify-content-between align-items-center ">
                    <div>
                      <img
                      src="../../../assets/images/AI.jpg"
                      alt=""
                      class="ai-icon" />
                      <span class="ml-3 ">
                        <b>{{ content.id }}</b>
                      </span>
                    </div>
                   <div class="downloadIcon m-2 " *ngIf="content.isDownloadable">
                    <button type="button"
                    (click)="downloadData(content)" class="btn btn-outline-dark btn-sm">Download</button>
                   </div>
                  </div>
                    <div
                      class="response"
                      *ngIf="content.displayType == 'table'">
                      <table class="table">
                        <thead>
                          <tr>
                            <th
                              *ngFor="
                                let head of content.response[0] | keyvalue
                              ">
                              {{ head.key }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let list of content.response">
                            <td
                              *ngFor="
                                let head of content.response[0] | keyvalue
                              ">
                              {{ list | getValueObj : head }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="response" *ngIf="content.displayType=='text'">
                      {{ content.response }}
                    </div>
              </div>
              <div class="comments">
                <ng-container *ngIf="!content.responseLiked">
                  <span
                    class="liked"
                    (click)="
                      content.responseLiked = true;
                      content.responseDisLiked = false
                    "
                    ><i
                      class="fa fa-thumbs-o-up position"
                      aria-hidden="true"></i
                  ></span>
                </ng-container>
                <ng-container *ngIf="content.responseLiked">
                  <span class="like"
                    ><i
                      class="fa fa-thumbs-up position"
                      aria-hidden="true"></i> </span
                  >&nbsp;
                </ng-container>
                <ng-container *ngIf="!content.responseDisLiked">
                  <span
                    class="disliked"
                    (click)="open(disLikeInput, 'ADD', content)"
                    ><i
                      class="fa fa-thumbs-o-down position"
                      aria-hidden="true"></i
                  ></span>
                </ng-container>
                <ng-container *ngIf="content.responseDisLiked">
                  <span class="dislike"
                    ><i
                      class="fa fa-thumbs-down position"
                      aria-hidden="true"></i
                  ></span>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="input-group mb-3 mt-10">
          <ng-container *ngFor="let form of content_form">
            <form
              class="search-content-form"
              [formGroup]="addContentForm"
              autocomplete="off">
              <input
                type="text"
                class="form-control"
                (keypress)="onKeyEnter($event, addContentForm)"
                [formControlName]="form.formControlName"
                placeholder="Enter a Prompt"
                aria-label="Enter a Prompt"
                aria-describedby="basic-addon2" />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                (click)="searchContent(addContentForm)">
                <i class="fa fa-arrow-circle-o-right" aria-hidden="true" *ngIf="!showLoader"></i>
                <div class="spinner-border spinner-border-sm  text-primary" role="status" *ngIf="showLoader">
                </div>

              </button>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div style="padding: 10px;">
  <ng-container *ngIf="converstaionAIData['mainContents']['questions'] && converstaionAIData['mainContents']['questions'].length">
    <lib-ngx-blp-conversationAI [conversationAI]="converstaionAIData"></lib-ngx-blp-conversationAI>
  </ng-container>


</div>

<ng-template #disLikeInput let-modal>
  <div class="modal-header">
    <span class="modal-title">Comments</span>
    <i
      class="fa fa-times"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"></i>
  </div>
  <ng-container *ngFor="let form of comment_form">
    <form [formGroup]="commentForm" autocomplete="off" class="addUserForm">
      <div style="padding: 20px 25px">
        <div class="modal-body adduserPopupbody popup">
          <div class="row">
            <div class="col-md-4">
              <div class="label">
                {{ form.label }}
                <span *ngIf="form.mandatory" class="error">*</span>
              </div>
              <input
                type="text"
                class="form-control comment"
                [formControlName]="form.formControlName"
                placeholder="Enter Comment" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          [disabled]="false"
          class="btn btn-info"
          (click)="comment(commentForm)">
          <i class="fa fa-save"></i> &nbsp;<span
            style="color: #ffff; font-size: 15px; font-family: 'sfpro-r'"
            >Save</span
          >
        </button>
      </div>
    </form>
  </ng-container>
</ng-template>

<!-- <app-footer></app-footer> -->
