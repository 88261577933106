<mat-form-field appearance="fill" id="dataPicker" (click)="picker.open()"
   [ngClass]="{'hide':hide}"
>
  <!-- <mat-label>Enter a date range</mat-label> -->
  <mat-date-range-input [rangePicker]="picker" [formGroup]="dateForm" (click)="picker.open()"
  [attr.displayFormat]="'dd/MM/yyyy'" [min]="minDate" [max]="maxDate" >
    <input matStartDate formControlName="start" [placeholder]="placeholder.startDate"(click)="picker.open()"
    [(ngModel)]="startDate" [attr.ngModelOptions]="{standalone: true}"
   >

    <input matEndDate formControlName="end" [placeholder]="placeholder.endDate"(click)="picker.open()"
    [(ngModel)]="endDate" [attr.ngModelOptions]="{standalone: true}"
  >

  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker" (click)="picker.open()"></mat-datepicker-toggle>
  <!-- <button (click)="openDateRangePicker()">Production Report</button>s -->
    <mat-date-range-picker #picker>
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-raised-button color="primary" matDateRangePickerApply (click)="onDateRangeSelected(dateForm)">Apply</button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>

