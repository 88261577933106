import { Component, OnInit, ElementRef, HostListener } from "@angular/core";
import { AbstractControl, FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { FirestoreService } from "src/app/services/firestore.service";
import { HttpService } from "src/app/services/http.service";
import { UtilService } from "src/app/services/util.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  // loginForm = new FormGroup({
  //   useremail: new FormControl("", [Validators.email, Validators.required]),
  //   password: new FormControl("", [
  //     Validators.minLength(6),
  //     Validators.required,
  //   ]),
  //   confirmpassword: new FormControl("", []),
  // },
  // {validator: this.pwdConfirming('pwd', 'pwdConfirm')});
  loginForm: FormGroup;
  passwordIsValid = false;
  //   fb:FormGroup=undefined ;
  hide : boolean = true;

  checked = false;
  id: any;
IsClicked:boolean=false
  constructor(
    private router: Router,
    private cookie: CookieService,
    private http: HttpService,
    private util: UtilService,
    public formBuilder: FormBuilder,
    private element: ElementRef,
    private fs: FirestoreService
  ) {


    this.loginForm = this.formBuilder.group({
      useremail: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmpassword: [null, [Validators.required]],
    }, { validator: this.pwdConfirming('password', 'confirmpassword') });

  }

  ngOnInit() {
    this.IsClicked=false
  }
  pwdConfirming(key: string, confirmationKey: string) {
    return (group: FormGroup) => {
      const input = group.controls[key];
      const confirmationInput = group.controls[confirmationKey];
      if (!input.value || !confirmationInput.value) return null;
      if (!input.value.match(/\d/)) {
        return input.setErrors({ hasNumber: true });
      }
      else if (!input.value.match(/[A-Z]/)) {
        return input.setErrors({ hasCapitalCase: true });
      }
      else if (!input.value.match(/[a-z]/)) {
        return input.setErrors({ hasLowerCase: true });
      }
      else if (!input.value.match(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        return input.setErrors({ hasSpecialCharacters: true });
      }

      if (input.value !== confirmationInput.value) {
        return confirmationInput.setErrors({ notEquivalent: true });
      }

    };
  }

  login(data: any) {
    this.IsClicked=true
    if (!this.checked) {
      let json = {
        userid: data.value.useremail,
      };
      let params = {
        category: "USERS",
        conditions: [
          {
            key: "email",
            symbol: "==",
            value: data.value.useremail
          }
        ]
      }
      this.fs.getData(params).subscribe((response: any) => {
        // console.log(response);
        if (response.status == "success") {
          if (response.data.length == 1) {
            this.checked = true;
            this.IsClicked=false
            this.id = response.data[0].id;
          } else if (response.data.length == 0) {
            this.IsClicked=false
            this.util.showSnakbarMessage("error", "User name does not exists.", "end", "top");
          }
        }
      });

      // this.fs.getUserDetail(json).subscribe((data)=>{
      //   if (data.size == 1) {
      //     let fetcheddata: any = [];
      //     data.forEach(element => {
      //       this.id = element.id;
      //       fetcheddata.push(element.data())
      //     });

      //   } else {
      //     this.util.showSnakbarMessage("error", "User name does not exists.", "end", "top");
      //   }
      // });


    } else {
      let json = {
        id: this.id,
        userid: data.value.useremail,
        password: data.value.password,
        update: "password"
      };


      let updateData = { documentId: this.id, password: data.value.password };
      this.fs.passwordUpdate(updateData).subscribe((response: any) => {
        if (response.status == "success") {
          this.IsClicked=false
          this.router.navigateByUrl("login");
          this.util.showSnakbarMessage("success", "User password updated successfully!", "end", "top");
        } else {
          this.util.showSnakbarMessage("error", "User password update failed", "end", "top");
        }
      }, (err) => {
        console.error(err);
        this.IsClicked=false
        this.util.showSnakbarMessage("error", "User password update failed", "end", "top");
      });

      // this.fs.fireStoreDocUpdate("USERS", this.id, updateData).subscribe((response: any) => {
      //   this.router.navigateByUrl("login");
      //   this.util.showSnakbarMessage("success", "User password updated successfully!", "end", "top");
      // },(err) => {
      //   console.error(err);
      //   this.util.showSnakbarMessage("error", "User password update failed", "end", "top");
      // });
    }
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }
  passwordView() {
    this.hide = !this.hide;
  }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
