import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss']
})
export class CardGridComponent implements OnInit {
  @Input() widgetInfo: any = {};
  gridWidth:string = "col-6";
  constructor() { }

  ngOnInit(): void {
    if(this.widgetInfo.options && this.widgetInfo.options.gridColumn) {
      let colNumber = 12/parseInt(this.widgetInfo.options.gridColumn);
      this.gridWidth = "col-"+colNumber;

      if(this.gridWidth==="col-12") {
        this.gridWidth += " fullWidth";
      }
    }
  }

}
