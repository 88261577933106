import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {
  @Input() widgetInfo: any = {};
  constructor() { }

  ngOnInit(): void {
    if(!this.widgetInfo.options) {
      this.widgetInfo.options = {};
    }
    if(!this.widgetInfo.options.listDisplayType) {
      this.widgetInfo.options.listDisplayType = "Horizontal";
    }
  }

}
