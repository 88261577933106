
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

/** Components **/
import { AssetGridComponent } from './asset-grid/asset-grid.component';
import { AssetdetailsComponent } from './assetdetails/assetdetails.component';
import { MonitorComponent } from './monitor/monitor.component';
import { ReportsComponent } from './reports/reports.component';
import { DatasetComponent } from './assetdetails/dataset/dataset.component';
import { OverviewComponent } from './overview/overview.component';
import { KpiComponent } from './assetdetails/kpi/kpi.component';
import { CommonComponentsModule } from '../common/common.module';
import { ExecutiveDashboardModule } from './executive-dashboard/executive-dashboard.module';
import { StatusComponent } from './assetdetails/status/status.component';
import { ConversationAiComponent } from './conversation-ai/conversation-ai.component';
import {NgxBlpConversationAIModule} from 'ngx-blp-conversation-ai'
@NgModule({
  declarations: [
    // AnalyticsComponent,
    AssetGridComponent,
    AssetdetailsComponent,
    MonitorComponent,
    ReportsComponent,
    DatasetComponent,
    OverviewComponent,
    KpiComponent,
    StatusComponent,
    ConversationAiComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    CommonComponentsModule,
    FormsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    ExecutiveDashboardModule,
    NgxBlpConversationAIModule,
  ],
  exports: [
    // AnalyticsComponent,
    AssetGridComponent,
    AssetdetailsComponent,
    MonitorComponent,
    ReportsComponent,
    OverviewComponent,
  ],
  providers: [],

})
export class CommonPageModule { }
