import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import { element } from 'protractor';
@Component({
  selector: 'app-mat-tree-sidebar',
  templateUrl: './mat-tree-sidebar.component.html',
  styleUrls: ['./mat-tree-sidebar.component.scss']
})
export class MatTreeSidebarComponent{
  selectedNode;
  @Input('selectednode')
  set nodeselection(val:any){
    this.selectedNode = val
    // if(this.selectedNode.includes('bf_1') || this.selectedNode.includes('sms')){
    //   // this.openNode(this.dataSource.data)
    // }
  };
  isParentSelected: boolean = true;
  @Output() SelectedEvent = new EventEmitter()
  @Input('sidebarData')
  set in(val: any) {
    this.selectedNode = val[0].equipmentId? val[0].equipmentId: this.selectedNode
    if(val[0].equipmentId){
    this.selectNode(val[0])
    }
    this.dataSource.data = val;
  }
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();
  constructor() {
    // this.treeControl.expandAll()
    // console.log(this.treeControl)

  }
  openNode(data){
    for(let node of data){
      if(node.children && node.children.length){
        for(let child of node.children){
          if(child.id == this.selectedNode){
          this.treeControl.expand(node)
            break;
        }
        // else{
        //   if(node.children && node.children.length){
        //     this.openNode(node.children)
        //   }
        // }
        }
      }

    }
  }
  selectNode(node, type?){
    if(type == 'parent'){
      if(node.equipmentId){
        this.selectedNode = node.equipmentId?node.equipmentId:node.id
        this.SelectedEvent.emit(node)
        this.isParentSelected = false
        this.treeControl.expand(node)
      }
    }else{
      this.selectedNode = node.equipmentId?node.equipmentId:node.id
        this.SelectedEvent.emit(node)
        this.isParentSelected = false
        this.treeControl.expand(node)
    }

  }
  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;
}
