import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
@Output() changePage = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }
  page(e){
this.changePage.emit(e)
  }
}
