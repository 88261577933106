<button #vm if="maintenance" (click)="open(viewMaintainenance)"></button>
<button #check if="maintenance" (click)="open(viewChecklist)"></button>
<button
  #pm
  if="maintenance"
  (click)="open(viewPredictiveMaintainenance)"
></button>

<ng-template #viewPredictiveMaintainenance let-modal>
  <div class="modal-dialog" style="margin: 0px !important" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Preventive Maintenance
          <span style="font-weight: 600"
            >[ {{ predictiveList[0].asset }} >
            {{ predictiveList[0].equipmentName }} ]</span
          >
        </h5>
        <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
        <i class="fa fa-times" aria-hidden="true" (click)="modal.dismiss('Cross click')"></i>
      </div>
      <div class="modal-body" style="padding: 20px 10px">
        <ng-container>
          <table id="alerts">
            <tr class="header">
              <th *ngFor="let header of headerList">
                {{ header.columnName }}
              </th>
              <th>Actions</th>
            </tr>
            <ng-container *ngFor="let point of predictiveList; let i = index">
              <tr class="acc">
                <td *ngFor="let header of headerList">
                  <ng-container *ngIf="header.columnId == 'frequency'">
                    {{ point[header.columnId].name }}
                  </ng-container>
                  <ng-container *ngIf="header.columnId == 'maintenanceDate'">
                    <ng-container *ngIf="point.maintenanceHistory && point.maintenanceHistory.length">
                    <ng-container
                      *ngIf="tpeOf(point?.maintenanceHistory[0]?.maintenanceDate) == 'object'"
                    >
                      {{
                        point?.maintenanceHistory[0]?.maintenanceDate?._seconds * 1000
                          | date: "dd-MMM-YYYY"
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="tpeOf(point?.maintenanceHistory[0]?.maintenanceDate) == 'string'"
                    >
                      {{ point?.maintenanceHistory[0]?.maintenanceDate | date: "dd-MMM-YYYY" }}
                    </ng-container>
                  </ng-container>
                </ng-container>
                  <ng-container *ngIf="header.columnId == 'nextMaintenance'">
                    <ng-container
                    *ngIf="tpeOf(point[header.columnId]) == 'object'"
                  >
                    {{
                      point[header.columnId]?._seconds * 1000
                        | date: "dd-MMM-YYYY"
                    }}
                  </ng-container>
                  <ng-container
                    *ngIf="tpeOf(point?.maintenanceHistory[0]?.maintenanceDate) == 'string'"
                  >
                    {{  point[header.columnId] | date: "dd-MMM-YYYY" }}
                  </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      header.columnId != 'frequency' &&
                      header.columnId != 'nextMaintenance' &&
                      header.columnId != 'maintenanceDate'
                    "
                  >
                    {{ point[header.columnId] }}
                  </ng-container>
                </td>
                <td style="width: 5%" (click)="[toggleFunc(i)]">
                  <i class="fa fa-angle-down" *ngIf="activeField[i]"></i>
                  <i class="fa fa-angle-up" *ngIf="!activeField[i]"></i>
                </td>
              </tr>

              <tr
                class="acc-panel"
                [ngClass]="!activeField[i] ? 'active' : 'inactive'"
              >
                <td colspan="5">
                  <div class="acc-expand">
                    <div
                      class="innerlist row"
                      style="
                        align-items: baseline !important;
                        border-bottom: 1px solid #eeeeee;
                      "
                    >
                      <ng-container *ngIf="point.maintenanceHistory">
                        <div class="col">
                          <label>Maintenance Date</label>
                        </div>
                        <div class="col">
                          <label>Done By</label>
                        </div>
                        <div class="col">
                          <label>Manpower (Nos)</label>
                        </div>
                        <div class="col">
                          <label>Time Taken (Hrs)</label>
                        </div>
                        <div class="col">
                          <label>Remarks</label>
                        </div>
                        <div class="col">
                          <label>Action</label>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!point.maintenanceHistory">
                        <div style="margin: 0px 40%">
                          No Maintenance History
                        </div>
                      </ng-container>
                    </div>
                    <div
                      class="row"
                      style="padding: 10px 0px"
                      *ngFor="
                        let field of point.maintenanceHistory;
                        let i = index
                      "
                    >
                      <div class="col">
                        {{ field["maintenanceDate"] | date: "dd-MMM-YYYY" }}
                      </div>
                      <div class="col">
                        {{ field["person"] }} {{ field["email"] }}
                      </div>
                      <div class="col">
                        {{ field["manpower"] }}
                      </div>
                      <div class="col">
                        {{ field["time_taken"] }}
                      </div>
                      <div class="col">
                        {{ field["remarks"] ? field["remarks"] : "---" }}
                      </div>
                      <div class="col">
                        <i
                          class="fa fa-trash cursor-pointer"
                          (click)="predictiveMaintenanceDelete(point, i)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
        <div><span></span></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #viewMaintainenance let-modal>
  <div class="modal-dialog" style="margin: 0px !important" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 15px; font-family:'sfpro-r'">
          Breakdown Maintenance
        </h5>
        <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
        <i class="fa fa-times" aria-hidden="true" (click)="modal.dismiss('Cross click')"></i>
      </div>
      <div class="modal-body" style="padding: 20px 10px">
        <ng-container>
          <div class="row">
            <div class="col-7" style="border-right: 1px solid #eeeeee">
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Maintenance Date</div>
                <div class="col-6">
                  {{
                    formatDate(
                      lastMaintenance?.maintenanceDate
                        ? lastMaintenance.maintenanceDate
                        : "NA"
                    )
                  }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Equipment Name</div>
                <div class="col-6">
                  {{ equipmentPath ? equipmentPath : "NA" }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Action Taken</div>
                <div class="col-6">
                  {{
                    lastMaintenance?.actionTaken
                      ? lastMaintenance.actionTaken
                      : "NA"
                  }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Manpower</div>
                <div class="col-6">
                  {{
                    lastMaintenance?.manpower ? lastMaintenance.manpower : "NA"
                  }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Time Taken</div>
                <div class="col-6">
                  {{
                    lastMaintenance?.time_taken
                      ? lastMaintenance.time_taken + " Hrs"
                      : "NA"
                  }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Observations</div>
                <div class="col-6">
                  {{
                    lastMaintenance?.observations
                      ? lastMaintenance.observations
                      : "NA"
                  }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Section</div>
                <div class="col-6">
                  {{ lastMaintenance?.agency ? lastMaintenance.agency : "NA" }}
                </div>
              </div>
              <!-- <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Area</div>
                <div class="col-6">
                  {{ lastMaintenance?.area ? lastMaintenance.area : "NA" }}
                </div>
              </div> -->
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Criticality</div>
                <div class="col-6">
                  {{
                    lastMaintenance?.criticality
                      ? lastMaintenance.criticality
                      : "NA"
                  }}
                </div>
              </div>
              <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Maintenance Done By</div>
                <div class="col-6">
                  {{
                    lastMaintenance.person || lastMaintenance.email
                      ? lastMaintenance.person + " " + lastMaintenance.email
                      : "NA"
                  }}
                </div>
              </div>
              <!-- <div class="row" style="padding: 15px 5px">
                <div class="col-6 headerStyle">Vendor</div>
                <div class="col-6">
                  {{
                    lastMaintenance?.newVendor
                      ? lastMaintenance.newVendor
                      : "NA"
                  }}
                </div>
              </div> -->
            </div>
            <div class="col-5">
              <div
                class="row"
                style="
                  border-bottom: 1px solid #eeeeee;
                  padding: 20px 5px;
                  margin-right: 10px;
                "
              >
                <div class="col-10 input">
                  <div class="label">Comments</div>
                  <input type="text" [(ngModel)]="comments" class="wid100" />
                </div>
                <div class="col-2">
                  <button
                  style="margin-top: 30px;
                  height: 32px;
                  width: max-content;"
                    (click)="addComents()"
                    class="btn btn-primary"
                  >
                  <span style="
                  position: relative;
                  bottom: 3px;">Save</span>
                  </button>
                </div>
              </div>
              <div
                *ngIf="lastMaintenance?.comments?.length"
                class="row"
                style="
                  padding: 10px 5px;
                  border-bottom: 1px solid #eeeeee;
                  margin-right: 10px;
                "
              >
                <div class="col-4">Comments</div>
                <div class="col-4">Comments By</div>
                <div class="col-4">Timestamp</div>
              </div>
              <ng-container *ngFor="let data of lastMaintenance.comments">
                <div class="row" style="padding: 5px 5px">
                  <div class="col-4">{{ data.comment }}</div>
                  <div class="col-4">
                    {{ data.commentsBy }}
                  </div>
                  <div class="col-4">
                    {{ data.timestamp | date: "short" }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div><span></span></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template style="max-width: 1200px !important" #viewChecklist let-modal>
  <div class="modal-dialog" style="margin: 0px !important" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Checklist
          <span style="font-weight: 600">[{{ checklist.checklist_name }}]</span>
        </h5>
        <i class="fa fa-times" aria-hidden="true" (click)="modal.dismiss('Cross click')"></i>
      </div>
      <div class="modal-body" style="padding: 20px 10px">
        <div class="d-fc p-20">
          <div class="datatableWidget">
            <!-- <div class="checklist-title"></div> -->
            <div class="checklistDetails">
              <div>
                <span class="label-color">Asset: </span>{{ checklist.asset }}
              </div>
              <div>
                <span class="label-color">Section: </span>{{ checklist.agency }}
              </div>
              <div>
                <span class="label-color">Frequency: </span
                >{{ checklist.frequency }}
              </div>
              <!-- <div>
                <span class="label-color">Area: </span
                >{{ checklist.area }}
              </div> -->
              <div>
                <span class="label-color">Checked By: </span
                >{{ checklist?.email ? checklist.email : "None" }}
              </div>
              <div>
                <span class="label-color">Maintenance Date: </span
                >{{ formatDate(checklist.maintenanceDate) }}
              </div>
              <div>
                <span class="label-color">Filled Date: </span
                >{{ formatDate(checklist.actualFilledDate,'dateTime') }}
              </div>
            </div>
            <div class="card">
              <table class="table" *ngIf="checklist?.check_points.length">
                <thead>
                  <tr>
                    <th *ngFor="let head of checklist?.headerColumns">
                      {{ head?.columnName }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of checklist?.check_points">
                    <ng-container *ngIf="data.header">
                      <div class="subHeaderStyle">
                        {{ data.header }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!data.header">
                      <td *ngFor="let head of checklist?.headerColumns">
                        <ng-container
                          *ngIf="tpeOf(data[head?.columnId]) == 'object'"
                        >
                          {{
                            data[head?.columnId]
                              ? data[head?.columnId]["equipmentName"]
                              : "NA"
                          }}
                        </ng-container>
                        <ng-container
                          *ngIf="tpeOf(data[head?.columnId]) != 'object'"
                        >
                          {{
                            data[head?.columnId]
                              ? data[head?.columnId]
                              : "------"
                          }}
                        </ng-container>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
